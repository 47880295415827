import React from "react";
import "../../style/main.scss";
import step1img from "../../assets/icons/step1.svg";
import step2img from "../../assets/icons/step2.svg";
import step3img from "../../assets/icons/step3.svg";
import { useTranslation } from "react-i18next";
const StepCards = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="agency-stepcards flex-column v-center h-center">
        <div className="stepCard-title">
          <h2 className="text-black mt-5">
            {t("Help_Clients_Connect")}
          </h2>
        </div>
        <div className="step-cards d-flex v-center h-center">
          <div className="social-card-content mb-5">
            <div style={{
              height:"95%"
            }} className="content">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Understand_Customer_Journey")}</span>

                </div>
                <div className="card-img">
                  <img src={step1img} alt="instagram" />
                </div>
                <div className="card-paragraph">
                  <p>
                   {t("Begin_by_comprehensively_understanding")}
                  </p>
                </div>
              </div>
            </div>

            <div style={{
              height:"95%"
            }} className="content">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Tailor_Communication_Channels")} </span>

                </div>
                <div className="card-img">
                  <img src={step2img} alt="instagram" />
                </div>
                <div className="card-paragraph">
                  <p>
                  {t("Utilize_diverse_communication")} 
                  </p>
                </div>
              </div>
            </div>
            <div style={{
              height:"95%"
            }} className="content">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Personalize_Engagement")} </span>

                </div>
                <div className="card-img">
                  <img src={step3img} alt="instagram" />
                </div>
                <div className="card-paragraph">
                  <p>
                  {t("Implement_personalized_strategies")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepCards;
