import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./refreshmodel.css"
import reicon from "../../../assets/icons/repeaticon.svg"
import sitelogo from "../../../assets/images/sitelogo.png"
import fblogo from "../../../assets/icons/facebooklogo.svg"

function Refreshmodel({show2,handleModal2}) {


  return (
    <>
      {/* <Button variant="primary" onClick={handleModal}>
        Launch demo modal
      </Button> */}

      <Modal show={show2} onHide={handleModal2} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title className='toptext'>
            <div className="modhead">
                <div className='fbimg'><img src={fblogo} alt="" /></div>
                <div className='reimg'><img src={reicon} alt="" />
                </div>
                <div className='siteimg'><img src={sitelogo} alt="" /></div>
            </div>
            </Modal.Title> 
        </Modal.Header>
        <div className="refresh">
        <Modal.Body>
            <div><h2>DM BOT is requesting access to:</h2></div>
            <div><p>Your manage comments on your page.</p></div>
            <div><a href="#">Edit access
                </a></div>
            </Modal.Body> 
            </div>
            <div className="butonsec">
              <div className='leftbtn'><button>Continue as</button></div>
              <div className='rightbtn'><button>Cancel</button></div>
            </div>
            <div className="botomsec">
              <p>
              By continuing, DM BOT will receive ongoing access to the information that you share and Facebook will record when DM BOT accesses it. <span><a href="#">Learn more</a></span>  about this sharing and the settings you have.
              </p>
              <p>
              DM BOT's <span><a href="#">Privacy Policy</a></span>  and <span><a href="#">Terms of Service</a></span> Terms of Service
              </p>
            </div>
           
      </Modal>
    </>
  );
}

export default Refreshmodel;