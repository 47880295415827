import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import "./conditioncanvas.scss";

const ConditionCanvas = ({ show, handleModal, sho }) => {
  const [isBottomDivOpen, setIsBottomDivOpen] = useState(false);

  const handleAllTimeClick = () => {
    setIsBottomDivOpen(!isBottomDivOpen);
  };
  const [activeSection, setActiveSection] = useState("");

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => {
          handleModal();
        }}
      >
        {/* <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header> */}
        <Offcanvas.Body className="mainbody">
          <div className="canvasBody">
            <div className="conditionTitle">
              <h6>Condition</h6>
            </div>
            <div className="middle">
              <div className="content">
                <p>
                  Does the contact match <br />
                  <span>all of the following conditions?</span>
                  <hr />
                </p>

                {/* <div className="conditionButton">
                          {" "}
                          <button>+ Condition</button>
                        </div> */}
              </div>
              <div className="conditionSection">
                <div className="conditionButton">
                  <div className="all-times" onClick={handleAllTimeClick}>
                    <button>+ Condition</button>
                  </div>
                  {isBottomDivOpen && (
                    <div className="bottom-divescancel">
                      <div className="searchBar">
                        <input type="search" placeholder="Search" />
                      </div>
                      <div className="filterSection">
                        <div className="leftSection">
                          <button
                            className="generalFilter"
                            onClick={() => handleButtonClick("rightSection1")}
                          >
                            General Filters
                          </button>
                          <button
                            className="systemField"
                            onClick={() => handleButtonClick("rightSection2")}
                          >
                            System Fields
                          </button>
                        </div>
                        <div
                          className={
                            activeSection === "rightSection1"
                              ? "rightSection1"
                              : "hidden"
                          }
                        >
                          <div className="innerText">
                            <div className="a">
                              <span>Tag</span>
                            </div>
                            <div className="a">
                              <span>Opted-In Through Widget</span>
                            </div>
                            <div className="a">
                              <span> Opted-In Through Widget</span>
                            </div>
                            <div className="a">
                              <span>Opted-In Through Widget</span>
                            </div>
                            <div className="a">
                              <span> Opted-In Through Widget</span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            activeSection === "rightSection2"
                              ? "rightSection2"
                              : "hidden"
                          }
                        >
                          <div className="innerText">
                            <div className="a">
                              <span>Tag</span>
                            </div>
                            <div className="a">
                              <span>test</span>
                            </div>
                            <div className="a">
                              <span> Opted-In Through Widget</span>
                            </div>
                            <div className="a">
                              <span>Opted-In Through Widget</span>
                            </div>
                            <div className="a">
                              <span> Opted-In Through Widget</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="conditionc-step">
                <div className="title">
                  <h6>Yes, the user matches</h6>
                </div>
                <div className="nextstep-btn" onClick={sho}>
                  <button>Choose Next Step</button>
                </div>
                <div className="anotherCondition">
                  <div className="contents">
                    <div className="firstSection">
                      <div className="left">
                        <hr className="hr" />
                      </div>
                      <div className="middle">
                        <span>If not</span>
                      </div>
                      <div className="left">
                        <hr className="hr" />
                      </div>
                    </div>
                    <button>Check Another Condition</button>
                  </div>
                </div>
                <div className="nextstep-btn" onClick={sho}>
                  <button>Choose Next Step</button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default ConditionCanvas;
