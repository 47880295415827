import React from "react";
import "../../style/main.scss";
import instagram from "../../assets/images/homepage/insta.png";
import youtube from "../../assets/images/homepage/youtube.png";
import WeGo from "../../assets/images/homepage/we_go_eng.png";
import WeGokor from "../../assets/images/homepage/we_go_kor.png";
import tiktok from "../../assets/images/homepage/tiktok.png";
import talk from "../../assets/images/homepage/talk.png";
import newapp from "../../assets/images/homepage/newapp.png";
import platform from "../../assets/images/homepage/platform.png";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const Card = () => {
  const { t } = useTranslation();
  const {i18n} = useTranslation()
  return (
    <div>
      <div className="social-cards d-flex flex-column">
        <h1 className="text-white text-center">{t("WE_HAVE_GOT_YOUR")}</h1>
        <div className="social-card-content">
          <div class="image-container">
            <img src={instagram} alt="instagram" class="img-fluid" />
            <div class="hover-content">
            <h3 style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}>Instagram </h3>
              <p style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}>
             {t("Capture_high_quality")}
              </p>
            </div>
          </div>
            <img src={youtube} alt="youtube" class="img-fluid" />
          {/* <div class="image-container">
            <div class="hover-content">
            <h3 style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}>Instagram </h3>
              <p style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}>
              Capture high-quality leads, boost sales, and engage in round-the-clock conversations through Instagram Direct Message Marketing.
              </p>
            </div>
          </div> */}
            <img src={tiktok} alt="tiktok" class="img-fluid" />
          {/* <div class="image-container">
            <div class="hover-content">
            <h3 style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}>Instagram </h3>
              <p style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}>
              Capture high-quality leads, boost sales, and engage in round-the-clock conversations through Instagram Direct Message Marketing.
              </p>
            </div>
          </div> */}
          <div class="image-container">
            <img src={talk} alt="talk" class="img-fluid" />
            <div class="hover-content">
            <h3 style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}>Talk</h3>
              <p style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}>
              {t("Enhance_revenue")}
              </p>
            </div>
          </div>
          <div class="image-container">
            <img src={newapp} alt="newapp" class="img-fluid" />
            <div class="hover-content">
            <h3 style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "normal",
            }}>Naver</h3>
              <p style={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}>
             {t("Stay_Responsive_in")}
              </p>
            </div>
          </div>
          {/* <img src={youtube} alt="instagram" className="img-fluid" /> */}
          {/* <img src={tiktok} alt="instagram" className="img-fluid" /> */}
          {/* <img src={talk} alt="instagram" className="img-fluid" /> */}
          {/* <img src={newapp} alt="instagram" className="img-fluid" /> */}
        </div>
        <div className="platform">
          {/* <h1 className="text-white mx-auto  mt-5 pt-5">{t("WE_GO_WHERE")}</h1> */}
          <img src={i18n.language == 'en' ? WeGo : WeGokor} alt="platform" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Card;
