import React from "react";
import "../style/main.scss";
import Blogtabs from "../components/blog/blogTabs";

const Blog = () => {
  return (
    <div>
      <Blogtabs />
    </div>
  );
};

export default Blog;
