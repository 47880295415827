import React, { useState } from "react";
import "./EBookExample.css";
import { Icon, InlineIcon } from "@iconify/react";

import Footer from "../shared/footer";

import { useTranslation } from "react-i18next";

import Freebotdm from "../home/freebotdm";

function EBookExample() {
  const { t } = useTranslation();
  // State to keep track of whether the dropdown is open or closed
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTwo, setIsOpenTwo] = useState(false);

  const cdata = [
    {
      img: "/assets/images/card1.png",
      title: `${t("About Us")}`,
    },
    {
      img: "/assets/images/card2.png",
      title: `${t("Collect Contact Information")}`,
    },
    {
      img: "/assets/images/card3.png",
      title: `${t("Contact Us")}`,
    },
    {
      img: "/assets/images/card4.png",
      title: `${t("Our Locations")}`,
    },
    {
      img: "/assets/images/card5.png",
      title: `${t("Qualify Leads")}`,
    },
    {
      img: "/assets/images/card6.png",
      title: `${t("Quote Customers")}`,
    },
    {
      img: "/assets/images/card7.png",
      title: `${t("Remarket to Your List & Scale Your Store With Ease")}`,
    },
    {
      img: "/assets/images/card8.png",
      title: `${t("Sell Products")}`,
    },
    {
      img: "/assets/images/card9.png",
      title: `${t("Send Coupon Codes")}`,
    },
    {
      img: "/assets/images/card10.png",
      title: `${t("Share Recipes")}`,
    },
    {
      img: "/assets/images/card11.png",
      title: `${t("Show Menu and Specials")}`,
    },
    {
      img: "/assets/images/card12.png",
      title: `${t("Take Reservations")}`,
    },
  ];

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdownTwo = () => {
    setIsOpenTwo(!isOpenTwo);
  };

  // Function to handle item selection (you can implement this as needed)
  const handleItemClick = (item) => {
    // Implement your logic here when an item is selected
    console.log(`Selected item: ${item}`);
    setIsOpen(false); // Close the dropdown after selection
    setIsOpenTwo(false);
  };

  return (
    <div className="ebook-example">
      <p className="headingMain">{t("Messenger_Marketing")}</p>

      <p className="headingSub">{t("What_can_you_do")}</p>

      <div className="subHeader">
        <div className="selectDivFirst">
          <div className="selectDiv" onClick={toggleDropdown}>
            <p className="selectDivText">{t("Industries")}</p>
            <Icon icon="ph:caret-down-fill" width="24" height="24" />

            {isOpen && (
              <div className="dropdownContent">
                {/* Example dropdown items */}
                <div onClick={() => handleItemClick("Option 1")}>
                  {t("Option")} 1
                </div>
                <div onClick={() => handleItemClick("Option 2")}>
                  {t("Option")} 2
                </div>
                <div onClick={() => handleItemClick("Option 3")}>
                  {t("Option")} 3
                </div>
                {/* Add more items as needed */}
              </div>
            )}
          </div>
          <div className="selectDiv" onClick={toggleDropdownTwo}>
            <p className="selectDivText">{t("Use_Cases")}</p>
            <Icon icon="ph:caret-down-fill" width="24" height="24" />
            {isOpenTwo && (
              <div className="dropdownContent">
                {/* Example dropdown items */}
                <div onClick={() => handleItemClick("Option 1")}>
                  {t("Option")} 1
                </div>
                <div onClick={() => handleItemClick("Option 2")}>
                  {t("Option")} 2
                </div>
                <div onClick={() => handleItemClick("Option 3")}>
                  {t("Option")} 3
                </div>
                {/* Add more items as needed */}
              </div>
            )}
          </div>
        </div>
        {/* Search DIV */}
        <div className="searchBar">
          <input
            type="text"
            id="textInput"
            placeholder={t("Search_Term")}
            className="inputStyle"
          />
          <div className="seacrhButton">
            <p className="searchButtonText">{t("Search")}</p>
          </div>
        </div>
      </div>

      <div className="firstMainDiv">
        {cdata.map((item) => (
          <div className="indust-card">
            <img src={item.img} alt="..." />
            <h4 className="cardTitle">{item.title}</h4>
          </div>
        ))}
      </div>

      {/* Template Section */}
      <div className="templateSection">
        <p className="heading">{t("Start_with")}</p>

        <p className="headingTwo">{t("We_offer_several")}</p>

        <img
          src="/assets/images/gptImage.png"
          className={"templateImage"}
          alt="..."
        />

        <div className="templateButton">
          <button>{t("Get_Started")}</button>
        </div>
      </div>

      <Freebotdm />
      <Footer />
    </div>
  );
}

export default EBookExample;
