import React, { Component, useEffect, useState } from "react";
import "./Profile.css";
import { Icon, InlineIcon } from "@iconify/react";
import { auth, logout } from "../../../../config/firebase";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Circles, Hourglass, InfinitySpin } from "react-loader-spinner";
import { useLogin } from "react-facebook";
import { getLongLivedToken } from "../../../../config/facebookApis";
// import secureLocalStorage from "react-secure-storage";

import {
  getAllPages,
  getMyData,
  getMyUserData,
  getUserData,
} from "../../../../config/facebookApis";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import log from "../../../../assets/images/header.png";
import enterpre from "../../../../assets/images/enterpre.png";

import { getUserAuth } from "../../../../config/kakaotalkApis";
const Profile = () => {
  const imageUrl = `https://graph.facebook.com/6519040928191837/picture?type=large`;
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [language, setLanguage] = useState("English");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [profileData, setProfileData] = useState({ name: "", dp: "" });
  const [loader, setLoader] = useState(true);
  const { login, status, isLoading, error } = useLogin();
  // Modal
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true); // Help section modal
  const [showHelp, setShowHelp] = useState(false);
  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = () => setShowHelp(true);

  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [userPages, setUserPages] = useState([]);
  const [kakaoData, setKakaoData] = useState("");
  const [naverData, setNaverData] = useState("");

  //Navigate user to login if not logged in
  useEffect(() => {
    checkUserProfiles();
  }, []);

  const checkUserProfiles = () => {
    const userData = secureLocalStorage.getItem("user");
    setUserProfile(secureLocalStorage.getItem("userProfileData"));
    setUser(userData);
    console.log(userData);
    const currentURL = new URL(window.location.href);
    const code = currentURL.searchParams.get("code");
    const urlState = currentURL.searchParams.get("state");
    const kakaoSecure = secureLocalStorage.getItem("Kakao");
    const naverSecure = secureLocalStorage.getItem("naver");
    console.log("kakaoSecure::", kakaoSecure);
    if (kakaoSecure) {
      console.log("kakao called");
      setProfileData({
        name: kakaoSecure.kakaodata.name,
        dp: kakaoSecure.kakaodata.dp,
      });
      setUserPages([
        {
          id: kakaoSecure.fbdata[0].id,
          name: kakaoSecure.fbdata[0].name,
          picture: kakaoSecure.fbdata[0].picture,
          access_token: kakaoSecure.fbdata[0].access_token,
        },
        {
          id: kakaoSecure.fbdata[1].id,
          name: kakaoSecure.fbdata[1].name,
          picture: kakaoSecure.fbdata[1].picture,
          access_token: kakaoSecure.fbdata[1].access_token,
        },
      ]);
    } else if (naverSecure) {
      console.log("naver called");
      setProfileData({
        name: naverSecure.naverdata.name,
        dp: naverSecure.naverdata.dp,
      });
      setUserPages([
        {
          id: naverSecure.fbdata[0].id,
          name: naverSecure.fbdata[0].name,
          picture: naverSecure.fbdata[0].picture,
          access_token: naverSecure.fbdata[0].access_token,
        },
        {
          id: naverSecure.fbdata[1].id,
          name: naverSecure.fbdata[1].name,
          picture: naverSecure.fbdata[1].picture,
          access_token: naverSecure.fbdata[1].access_token,
        },
      ]);
    }

    if (urlState) {
      if (urlState == "kakao") {
        sendKakaoUrltoBackend(code);
      } else if (urlState == "naver") {
        sendNaverUrlToBackend(code);
      }
    } else {
    }
    // if (!userData) {
    //   navigate("/Newlogin");
    // }

    if (i18n.language == "en") {
      setLanguage("English");
    } else {
      setLanguage("Korean");
    }

    setTimeout(() => {
      setLoader(false);
    }, 3000);
  };

  const sendKakaoUrltoBackend = async (code) => {
    setLoader(true);
    const dataToSend = {
      key: code,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/kakao/callback`,
        dataToSend
      );
      setProfileData({
        name: response.data.userdata.kakao_account.profile.nickname,
        dp: response.data.userdata.kakao_account.profile.thumbnail_image_url,
      });
      if (response) {
        setLoader(false);
      }
      setKakaoData({
        id: response.data.userdata.id,
        name: response.data.userdata.kakao_account.profile.nickname,
        dp: response.data.userdata.kakao_account.profile.thumbnail_image_url,
      });
    } catch (error) {
      console.error("Error fetching data from backend:", error);
    }
  };

  const sendNaverUrlToBackend = async (code) => {
    setLoader(true);
    const dataToSend = {
      key: code,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/endpoint`,
        dataToSend
      );
      console.log("Response from server:", response.data);

      setProfileData({
        name: response.data.userdata.response.name,
        dp: response.data.userdata.response.profile_image,
      });
      if (response.data) {
        setLoader(false);
      }
      setNaverData({
        id: response.data.userdata.response.id,
        name: response.data.userdata.response.name,
        dp: response.data.userdata.response.profile_image,
      });
    } catch (error) {
      // Handle errors
      console.error("There was a problem with the Axios request:", error);
    }
  };

  const logoutUser = () => {
    secureLocalStorage.clear();
    navigate("/Newlogin");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const getAllUserPages = () => {
    setLoader(true);
    getAllPages()
      .then((res) => {
        console.log("res", res);
        const [res1, res2] = res;
        const currentURL = new URL(window.location.href);
        const urlState = currentURL.searchParams.get("state");
        const kakaoData = secureLocalStorage.getItem("Kakao");
        const naverData = secureLocalStorage.getItem("naver");
        if (!kakaoData && !naverData) {
          setProfileData({ name: res1[0].name, dp: res1[0].picture.data.url });
        }
        const fbData = [];
        res1.map((data, index) => {
          console.log("res 1 data:", data);

          fbData.push({
            id: data.id,
            name: data.name,
            picture: data.picture.data.url,
            access_token: data.access_token,
          });
          data.instagram_business_account &&
            fbData.push({
              id: data?.instagram_business_account.id,
              name: data?.instagram_business_account.name,
              picture: data?.instagram_business_account.profile_picture_url,
              access_token: data.access_token,
            });
        });
        setLoader(false);
        setUserPages(fbData);
        console.log("userPages:", userPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      getAllUserPages();
    }
  }, [user]);

  const chooseProfile = (pageDetail, index) => {
    console.log("pageDetail:", pageDetail.id.length);
    secureLocalStorage.setItem("currentPageProfile", pageDetail);
    const userName = pageDetail.name;
    const userDp = pageDetail.picture;
    index == 0
      ? navigate(`/fb${pageDetail.id}/dashboard`, {
          state: { userName },
        })
      : navigate(`/insta${pageDetail.id}/dashboard`, {
          state: { userName },
        });
  };

  async function handleFacebookLogin() {
    try {
      const response = await login({
        scope: process.env.REACT_APP_SCOPE,
      });
      console.log(response);
      getLongLivedToken(response);
      if (response) {
        setTimeout(() => {
          getAllPages()
            .then((res) => {
              const [res1, res2] = res;
              console.log("response in login func:", res1);
              const fbData = [];
              res1.map((data, index) => {
                fbData.push({
                  id: data.id,
                  name: data.name,
                  picture: data.picture.data.url,
                  access_token: data.access_token,
                });
                data.instagram_business_account &&
                  fbData.push({
                    id: data?.instagram_business_account.id,
                    name: data?.instagram_business_account.name,
                    picture:
                      data?.instagram_business_account.profile_picture_url,
                    access_token: data.access_token,
                  });
              });
              console.log("dbdata:", fbData);
              console.log("kakaoData", kakaoData);
              const currentURL = new URL(window.location.href);
              const urlState = currentURL.searchParams.get("state");
              if (urlState == "kakao") {
                secureLocalStorage.setItem("Kakao", {
                  kakaodata: kakaoData,
                  fbdata: fbData,
                });
                // secureLocalStorage.setItem('isLogin', true)
              } else if (urlState == "naver") {
                secureLocalStorage.setItem("naver", {
                  naverdata: naverData,
                  fbdata: fbData,
                });
                // secureLocalStorage.setItem('isLogin', true)
              }
              setUserPages(fbData);
            })
            .catch((err) => {
              console.log(err);
            });
        }, 2000);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <div className="profile-info">
      {loader && (
        <div
          style={{
            background: "white",
            height: "100vh",
            width: "100%",
            position: "absolute",
            zIndex: "2",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Circles
            height="150"
            width="150"
            color="#00d3b9"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          {/* <h3 style={{ color: "#4fa94d" }}>Fetching Data</h3> */}
        </div>
      )}
      <div className="two-dropdowns">
        <img src={log} alt="logo" />
        <div className="left">
          <div className="language-dropdown">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <Icon icon="mdi:web" width="24" height="24" />
                <span className="px-2">{language}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="#/action-1"
                  onClick={() => {
                    setLanguage("English");
                    changeLanguage("en");
                  }}
                >
                  English
                </Dropdown.Item>
                <Dropdown.Item
                  href="#/action-2"
                  onClick={() => {
                    setLanguage("Korean");
                    changeLanguage("kor");
                  }}
                >
                  korean
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="profile-modal">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
              }}
              onClick={handleShow}
            >
              {profileData.dp ? (
                <img
                  src={profileData.dp}
                  alt=""
                  width={40}
                  height={40}
                  style={{ borderRadius: "20px" }}
                />
              ) : (
                <Icon
                  icon="healthicons:ui-user-profile"
                  width="24"
                  height="24"
                />
              )}
            </div>
            {profileData.name ? <h6>{profileData.name}</h6> : null}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header>
                <Modal.Title>
                  <h6>{t("My_Profile")}</h6>
                  <button className="logout-btn">
                    {" "}
                    <Icon icon="carbon:logout" width="24" height="24" />
                    {t("Logout")}
                  </button>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="profile-image">
                  <img src={profileData.dp} alt="profile-img" />
                </div>
                <div className="input-fields mt-3">
                  <div className="for-name">
                    <h6>{t("Name")}</h6>
                    <h3 style={{ color: "black" }}>{profileData.name}</h3>
                    {/* <input
                      type="text"
                      name=""
                      id=""
                      className="mt-2"
                      placeholder={t("Enter_your_name")}
                    />
                  </div>
                  <div className="for-email">
                    <h6>
                      {t("Email")}{" "}
                      <span className="px-1 verified">({t("Verified")})</span>
                    </h6>
                    <div className="two mt-2">
                      <input
                        type="email"
                        name=""
                        id=""
                        placeholder="xyz@gmail.com"
                      />
                      <button>{t("Edit")}</button>
                    </div> */}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="bottom">
                  <button onClick={handleShowModal}>{t("Manage_sign")}</button>
                  <Modal
                    size="lg"
                    centered
                    show={showModal}
                    onHide={handleCloseModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <h6>{t("Manage_sign")}</h6>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="body2">
                        <div className="body-para">
                          <p className=" text-black">
                            {t("Connect_alternative_sign")}
                          </p>
                        </div>
                        <div className="three-things">
                          <div className="image-sec">
                            <Icon
                              icon="ic:outline-facebook"
                              color="#0084ff"
                              width="30"
                              height="30"
                            />
                            <span className="px-1"> Facebook</span>
                          </div>
                          <div className="profile-sec">
                            <img src="" alt="" />
                            <div className="names">
                              <h6 className="text-black">Muhammad Shehryar</h6>
                              <p className="text-black">
                                muhammadshehryar017@gmail.com
                              </p>
                            </div>
                          </div>
                          <div className="button-sec">
                            <button>{t("Connect")}</button>
                          </div>
                        </div>
                        <div className="three-things">
                          <div className="image-sec">
                            <Icon
                              icon="flat-color-icons:google"
                              width="30"
                              height="30"
                            />
                            <span className="px-1"> Google</span>
                          </div>
                          <div className="profile-sec">
                            <img src="" alt="" />
                            <div className="names">
                              <h6 className="text-black">Muhammad Shehryar</h6>
                              <p className="text-black">
                                muhammadshehryar017@gmail.com
                              </p>
                            </div>
                          </div>
                          <div className="button-sec">
                            <button>{t("Connect")}</button>
                          </div>
                        </div>
                        <div className="three-things">
                          <div className="image-sec">
                            <Icon icon="logos:apple" width="30" height="30" />
                            <span className="px-1"> Apple</span>
                          </div>
                          <div className="profile-sec">
                            <img src="" alt="" />
                            <div className="names">
                              <h6 className="text-black">Muhammad Shehryar</h6>
                              <p className="text-black">
                                muhammadshehryar017@gmail.com
                              </p>
                            </div>
                          </div>
                          <div className="button-sec">
                            <button>{t("Connect")}</button>
                          </div>
                        </div>
                        <div className="three-things">
                          <div className="image-sec">
                            <Icon
                              icon="basil:telegram-solid"
                              color="#0084ff"
                              width="30"
                              height="30"
                            />
                            <span className="px-1"> Telegram</span>
                          </div>
                          <div className="profile-sec">
                            <img src="" alt="" />
                            <div className="names">
                              <h6 className="text-black">Muhammad Shehryar</h6>
                              <p className="text-black">
                                muhammadshehryar017@gmail.com
                              </p>
                            </div>
                          </div>
                          <div className="button-sec">
                            <button>{t("Connect")}</button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      {/* <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleCloseModal}>
                        Save Changes
                      </Button> */}
                    </Modal.Footer>
                  </Modal>
                  <div className="icons">
                    <a href="#">
                      <Icon
                        icon="ic:outline-facebook"
                        color="#0084ff"
                        width="24"
                        height="24"
                      />
                    </a>
                    <a href="#">
                      {" "}
                      <Icon
                        icon="flat-color-icons:google"
                        width="24"
                        height="24"
                      />
                    </a>
                    <a href="#">
                      {" "}
                      <Icon
                        icon="file-icons:apple"
                        color="#8592a3"
                        width="24"
                        height="24"
                      />
                    </a>
                    <a href="#">
                      {" "}
                      <Icon
                        icon="basil:telegram-solid"
                        color="#8592a3"
                        width="24"
                        height="24"
                      />
                    </a>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <div className="mainDiv mt-5">
        <div className="add-account">
          <div className="search">
            <div class="container">
              <label htmlFor="bar">
                <input
                  placeholder={t("Search")}
                  class="js-search"
                  type="text"
                  id="bar"
                />
                {/* <i class="fa fa-search"></i> */}
                <Icon
                  icon="iconamoon:search-bold"
                  color="#9a3fd2"
                  width="24"
                  height="24"
                  id="bar"
                  className="search-icon"
                />
              </label>
            </div>

            <div class="social">
              <a href="https://twitter.com/StefCharle" target="_blank">
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/149103/twitter.svg"
                  alt=""
                />
              </a>
            </div>
            {/* <label htmlFor="sear">
              <Icon
                icon="carbon:search"
                color="#adb4bf"
                width="40"
                height="40"
              />
              <input
                type="search"
                name=""
                id="sear"
                placeholder="Search"
                className="search-input"
              />
            </label> */}
          </div>
          <button className="text">{t("Add_new_account")} </button>
        </div>
        <div className="first">
          <p className="text">{t("Active_contact")} </p>
          <p className="textSubscribe">{t("Subscription")}</p>
          <p className="text">{t("Role")} </p>
          <p className="text">{t("Assigned_live_chat")}</p>
          <p className="text">{t("Active_contact")}</p>
          <p className="textPin">{t("Pin")}</p>
          <p className="text">{t("Hide")}</p>
        </div>

        <div className="second">
          {userPages && userPages.length > 0 ? (
            userPages.map((item, index) => {
              return (
                <div
                  className="secondInner customClick"
                  key={index}
                  onClick={(e) => {
                    chooseProfile(item, index);
                  }}
                >
                  <img
                    style={{ width: "50px", height: "50px" }}
                    src={item?.picture}
                    alt="img"
                  />
                  <p className="text px-4">{item?.name}</p>
                  <p className="text">Free</p>
                  <p className="text px-5">Admin</p>
                  <p className="text px-5">00</p>
                  <Icon
                    icon="bi:pin-fill"
                    width={20}
                    height={20}
                    color="#000"
                  />
                  <div className="ico px-3">
                    <Icon icon="mdi:hide" width={20} height={20} color="#000" />
                  </div>
                </div>
              );
            })
          ) : (
            <>
              {/* <p style={{ color: "black" }}>no data found</p>
               */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleFacebookLogin}
              >
                <p
                  style={{
                    color: "black",
                    padding: "5px 10px",
                    backgroundColor: "#3eb095",
                    borderRadius: "10px",
                    margin: "15px 0",
                    color: "white",
                  }}
                >
                  Sign in with{" "}
                  <Icon icon="logos:facebook" width="30" height="30" />
                </p>
              </div>
            </>
          )}
        </div>
        <div className="third">
          <div className="help-modal">
            <div className="help-btn">
              <Button onClick={handleShowHelp}>
                <Icon
                  icon="mdi:question-mark-circle-outline"
                  color="black"
                  width="24"
                  height="24"
                />
                <p className="text-black">{t("Help")}</p>
              </Button>
            </div>
            <Modal show={showHelp} onHide={handleCloseHelp} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5 className="d-flex">{t("Help")}</h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="help-body">
                  <div className="bottom">
                    <div className="two d-flex mt-3">
                      <Icon
                        icon="solar:help-outline"
                        color="#0084ff"
                        width="24"
                        height="24"
                      />
                      <p className="text-black">{t("Help_Center")}</p>
                    </div>
                    <div className="two d-flex mt-3">
                      <Icon
                        icon="tdesign:video"
                        color="#0084ff"
                        width="24"
                        height="24"
                      />
                      <p className="text-black">{t("Video_tutorials")}</p>
                    </div>
                    <div className="two d-flex mt-3">
                      <Icon
                        icon="la:hire-a-helper"
                        color="#0084ff"
                        width="24"
                        height="24"
                      />
                      <p className="text-black">{t("Hire_an_agency")} </p>
                    </div>
                    <div className="two d-flex mt-3">
                      <Icon
                        icon="icon-park-outline:log"
                        color="#0084ff"
                        width="24"
                        height="24"
                      />
                      <p className="text-black">{t("Public_Changelog")}</p>
                    </div>
                    <div className="two d-flex mt-3">
                      <Icon
                        icon="fluent-mdl2:file-request"
                        color="#0084ff"
                        width="24"
                        height="24"
                      />
                      <p className="text-black">{t("Request_Feature")}</p>
                    </div>
                    <div className="two d-flex mt-3">
                      <Icon
                        icon="iconoir:community"
                        color="#0084ff"
                        width="24"
                        height="24"
                      />
                      <p className="text-black">{t("Join_the_Community")}</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="help-footer">
                  <Button onClick={handleCloseHelp}>
                    {t("Terms_Of_Services")}
                  </Button>
                  <Button onClick={handleCloseHelp}>
                    {t("Privacy_Policy")}
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="button customPointer" onClick={logoutUser}>
            <p className="text">{t("Logout")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
