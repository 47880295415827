import { AuthErrorCodes } from "firebase/auth";

export default function returnErrorMessages(errorCode) {
  var msg = "";
  // convert code to a human readable message (some messages can be found here: <https://firebase.google.com/docs/auth/admin/errors?hl=en>)
  switch (errorCode) {
    case AuthErrorCodes.ARGUMENT_ERROR: {
      msg = "Argument error.";
      break;
    }
    case AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN: {
      msg = "Please logout, re-login, and try again.";
      break;
    }
    case AuthErrorCodes.INVALID_PASSWORD: {
      msg = "Incorrect password.";
      break;
    }
    case AuthErrorCodes.TOKEN_EXPIRED: {
      msg = "Your token has expired.  Please logout and re-login.";
      break;
    }
    case AuthErrorCodes.USER_CANCELLED: {
      msg = "Login process was stopped by you.";
      break;
    }
    case AuthErrorCodes.USER_DELETED: {
      msg = "User does not exist.";
      break;
    }
    case AuthErrorCodes.USER_DISABLED: {
      msg = "Your account has been disabled.";
      break;
    }
    case AuthErrorCodes.USER_MISMATCH: {
      msg = "Credential given does not correspond to you.";
      break;
    }
    case AuthErrorCodes.USER_SIGNED_OUT: {
      msg = "You are signed out.  Please re-sign in.";
      break;
    }
    case AuthErrorCodes.WEAK_PASSWORD: {
      msg =
        "Your password is too weak.  It must be at least six characters long.";
      break;
    }
    case AuthErrorCodes.INVALID_EMAIL: {
      msg = "The email address is improperly formatted.";
      break;
    }
    case AuthErrorCodes.INTERNAL_ERROR: {
      msg = "Internal Error.";
      break;
    }
    case AuthErrorCodes.INVALID_API_KEY: {
      msg = "Invalid API key.";
      break;
    }
    case AuthErrorCodes.INVALID_APP_CREDENTIAL: {
      msg = "Invalid app credential.";
      break;
    }
    case AuthErrorCodes.INVALID_APP_ID: {
      msg = "Invalid app ID.";
      break;
    }
    case AuthErrorCodes.INVALID_AUTH: {
      msg = "Invalid user token.";
      break;
    }
    case AuthErrorCodes.TIMEOUT: {
      msg = "Authentication timeout.";
      break;
    }
    case AuthErrorCodes.UNVERIFIED_EMAIL: {
      msg = "Your email address is not verified.  Please verify it.";
      break;
    }
    case AuthErrorCodes.WEB_STORAGE_UNSUPPORTED: {
      msg =
        "Web storage is unsupported.  Please update or use a different browser.";
      break;
    }
    case AuthErrorCodes.ALREADY_INITIALIZED: {
      msg = "Already initialized.";
      break;
    }
    case "auth/too-many-requests": {
      msg =
        "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
      break;
    }
    case "auth/account-exists-with-different-credential": {
      msg = "Number already exisits with different user";
      break;
    }
    case "auth/email-already-in-use": {
      msg = "Email Already in user by different account";
      break;
    }
    case "auth/invalid-email": {
      msg = "Invalid Email";
      break;
    }
    case "auth/code-expired": {
      msg = "Code Expired";
      break;
    }
    case "auth/invalid-verification-code": {
      msg = "Invalid Verfication Code";
      break;
    }
    default: {
      msg = `Unknown error >> code = ${errorCode}`;
      break;
    }
  }
  return msg;
}
