import React from "react";
import Banner from "../components/messanger/banner";
import Trusted from "../components/home/trusted";
import Deliver from "../components/messanger/deliver";
import StepCards from "../components/home/stepCards";
import Grow from "../components/messanger/grow";
import Drag from "../components/messanger/drag";
import ActiCards from "../components/messanger/actiCards";
import Faq from "../components/messanger/faq";
import Freebotdm from "../components/home/freebotdm";
import Footer from "../components/shared/footer";
import { useTranslation } from "react-i18next";
const Messanger = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Banner />
      <Trusted />
      <Deliver />
      <div className="stepCard-wrapper mt-5">
        <StepCards
          head={t("Connect_with_your_Customers")}
          hide="d-none"
          customClass="main-header "
        />
      </div>
      <Grow head={t("Grow_Analyze_Audience")} />
      <Drag />
      <div className="activ foot">
        <ActiCards />
      </div>
      <Faq />
      <div className="both foot">
        <Freebotdm />
        <Footer />
      </div>
    </div>
  );
};

export default Messanger;
