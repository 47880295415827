import React from 'react'
import "./widget.css"
import { useTranslation } from "react-i18next";
const Widget = () => {
    const { t } = useTranslation();
  return (
    <div className='widget'>
        <div className="first">
            <h2>{t("Widget_Localization")}</h2>
            <input type="text" placeholder={t("English")} />
            <p>{t("Localize_your_widgets")}</p>
        </div>
        <div className="second">
            <div className="web">
            <div className="head">
                <h2>{t("Authorize_Websites")}</h2>
            </div>
            <div className="webinput">
                <div className='input1' >
                    <input type="text"  placeholder='abcxyz.com'/>
                </div>
                <div className='input2'>
                    <input type="text"  placeholder='www.abcxyz.com'/>
                </div>
                <div className='input3'>
                    <input type="text"  placeholder='my.abcxyz.com'/>
                    </div>
            </div>
            <div className='para'>
                <p>{t("authorized_websites_list")}</p>
            </div>
        </div>

        <div className="input4">
            <input type="text" placeholder='e.g mysite.com' />
            <button className='addbtn'>{t("Add")}</button>
        </div>
        </div>
        <div className="third">
        
            <h2>{t("Branding")}</h2>
            <div class="form-check form-switch enablebtn">

            <div><label class="form-check-label" for="flexSwitchCheckDefault">{t("Disabled")}</label></div>
            <div> <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/></div>
            <div><label class="form-check-label" for="flexSwitchCheckDefault">{t("Enabled")}</label></div>
</div>
            <p>{t("Turns_off_DM_BOT")}</p>
        

        </div>
      
    </div>
  )
}

export default Widget
