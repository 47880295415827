import React from "react";
import Happy from "../components/agencies/happy";
import Trusted from "../components/home/trusted";
import StepCards from "../components/agencies/stepCards";
import Priority from "../components/agencies/priority";
import Enterpreneur from "../components/tiktok/enterpreneur";
import Combined from "../components/agencies/combined";
import Freebotdm from "../components/home/freebotdm";
import Footer from "../components/shared/footer";
import { useTranslation } from "react-i18next";
const Agencies = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Happy />
      <Trusted />
      <StepCards />
      <Priority />
      <div className="enterpre foot">
        <h2 className="text-black">{t("What_Agencies")}</h2>
        <Enterpreneur />
      </div>
      <Combined />
      <Freebotdm />
      <Footer />
    </div>
  );
};

export default Agencies;
