import React from "react";
import "../../style/_template.scss";
import { useTranslation } from "react-i18next";
const Customer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="customer">
        <div className="customerContent">
          <p>
            {t("Our_customer_support_extensive_library_of_best")}
          </p>
          <span>— {t("CO-FOUNDER")}</span>
        </div>
      </div>
    </div>
  );
};

export default Customer;
