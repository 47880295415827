import React from "react";
import "../../style/_webnar.scss";
import Tab1img from "../../assets/images/tab1 img.png";
import Tabbimg from "../../assets/images/tab1b.png";
import Contractimg from "../../assets/images/contacts (1).png";
import First from "../../assets/images/Rectangle 148 (2) (1).png";
import Second from "../../assets/images/Rectangle 148 (1) (1).png";
import Third from "../../assets/images/Rectangle 148 (3) (1).png";
import Four from "../../assets/images/Rectangle 148 (4) (1).png";
import Five from "../../assets/images/Rectangle 148 (5) (1).png";
import Six from "../../assets/images/Rectangle 148 (6) (1).png";
import Seven from "../../assets/images/Rectangle 148 (7) (1).png";
import Eight from "../../assets/images/Rectangle 148 (8) (1).png";
import Nine from "../../assets/images/Rectangle 148 (9) (1).png";
import Ten from "../../assets/images/Rectangle 148 (10) (1).png";
import Eleven from "../../assets/images/Rectangle 148 (11) (1).png";
import Twelve from "../../assets/images/Rectangle 148 (12) (1).png";
import Thirteen from "../../assets/images/Rectangle 148 (13) (1).png";
import Fourteen from "../../assets/images/Rectangle 148 (14) (1).png";
import Fifteen from "../../assets/images/Rectangle 148 (15) (1).png";
import Sixteen from "../../assets/images/Rectangle 148 (15) (1).png";
import { useTranslation } from "react-i18next";
const Tab1 = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="tab1">
        <div className="tab1-content">
          <div className="tabsImage">
            <div className="leftImage">
              <img src={Tab1img} alt="..." />
            </div>
            <div className="rightImage ">
              <div className="first-div">
                <img src={Tabbimg} alt="..." />
                <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }}  className="image-content">
                  <span  >{t("started_on_Naver")}</span>
                </div>
              </div>
              <div className="first-div">
                <img src={Contractimg} alt="..." />
                <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="image-content">
                  <span>{t("DM_BOT_supports_Instagram")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="second-image">
            <div className="first-image">
              <img src={First} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }}  className="cardText">
                <span style={{
                    backgroundColor:"black"
                  }} >{t("The_Cart_Abandonment")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Second} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("DM_BOT_allows_you")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Third} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("explore_our_templates_page")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Four} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("DM_BOT_provides_the")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Five} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("supports_a_range_of")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Six} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("Set_up_automated")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Seven} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("seamlessly_integrates_with")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Eight} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("track_the_performance")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Nine} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("efficiently_manages_multichannel")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Ten} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("use_DM_BOT_to")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Eleven} alt="..." />
              <div  style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("provides_features_like")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Twelve} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("utilize_DM_BOT_for")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Thirteen} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("prioritizes_user_data")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Fourteen} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("leverage_DM_BOT_for")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Fifteen} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("Customize_message_appearance")}</span>
              </div>
            </div>
            <div className="first-image">
              <img src={Sixteen} alt="..." />
              <div style={{
                   margin:"auto 0",
                    height:"100%"
                  }} className="cardText">
                <span>{t("we_provide_customer")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tab1;