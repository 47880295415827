import React from "react";
import "../../style/_program.scss";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="program-banner">
        <div className="banner-content">
          <h6>{t("1_Affiliate_Program")}</h6>
          <p>
           {t("businesses_use_DM_BOT_to_engage")}
          </p>
          <button>{t("Get_Started")}</button> <br />
          <span>{t("month_FREE_Pro")}</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
