import React from "react";
import "../../style/_ecommerce.scss";
import { useTranslation } from "react-i18next";
const Courses = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="courses">
        <div className="courses-content">
          <div className="left  ">
            <h6>
              {t("E_commerce_with")} <br /> 
            </h6>
            <p>
             {t("Learn_how_to_incorporate")} <br />
            </p>
          </div>
          <div className="right">
            <video
              autoPlay
              loop
              muted
              playsInline
              //   poster="\assets\images\hero\banner.jpg"
            >
              <source
                src="../../../assets/split-slider_drive-sales.webm"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
