import React from 'react'
import "./whatsapp.css"
import { useTranslation } from "react-i18next";
const Whatsapp = () => {
  const { t } = useTranslation();
  return (
    <div className='whatsapp'>
        <div className="cont">
      <h2>{t("WhatsApp_business")}</h2>
      <p>{t("reach_WhatsApp’s_2_billion")} <span><a href="">{t("Use_Auto_Assignment_learn_More")}</a></span></p>
      <div className='btn'>
        <button>{t("Connect")}</button>
        </div>
        <div className='gethelp'>
            <a href="#"> {t("Get_help_connection")}</a>
        </div>
      </div>
    </div>
  )
}

export default Whatsapp
