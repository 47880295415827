import React, { Component, useState } from "react";
import "./Broadcasting.css";
import { useTranslation } from "react-i18next";
const Broadcasting = () => {
  const [selectTab, setSelectTab] = useState("Dummy");
  const { t } = useTranslation();
  return (
    <div className="broadcasting">
      <div className="bannerMain">
        <p className="heading">
          {t("subscribers_to_Broadcast")}
        </p>
        <p className="text">
          {t("subscribers_before_start_Broadcasting")}
        </p>

        <div className="button">
          <p className="buttonText">{t("Collecting_Subscribers")}</p>
        </div>
      </div>
    </div>
  );
};

export default Broadcasting;
