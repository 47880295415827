import React from "react";
import "../../style/_price.scss";
import Questionmark from "../../assets/icons/question mark.svg";
import { useTranslation } from "react-i18next";
const Detail = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="detail">
        <div className="detail-content">
          <div className="detail-title">
            <span>{t("Details")}</span>
          </div>
          <div className="subdetail">
            <div className="left-content">
              <div className="left-title">
                <span>{t("Channel_Access")}:</span>
              </div>
              <div className="left-links">
                <ol style={{ listStyleType: "disc" }}>
                  <li>{t("Instagram_Direct")}</li>
                  <li>{t("Facebook Messenger")}</li>
                  <li>{t("WhatsApp")}</li>
                </ol>
              </div>
            </div>
            <div className="left-content">
              <div className="left-title">
                <span>{t("Channel_Access")}:</span>
              </div>
              <div className="left-links">
                <ol style={{ listStyleType: "disc" }}>
                  <li>{t("Instagram_Direct")}</li>
                  <li>{t("Facebook Messenger")}</li>
                  <li>{t("WhatsApp")}</li>
                  <li>{t("SMS_Text")}</li>
                  <li>{t("Email")}</li>
                </ol>
              </div>
            </div>
            <div className="left-content">
              <div className="left-title">
                <span>{t("Channel_Access")}:</span>
              </div>
              <div className="left-links">
                <ol style={{ listStyleType: "disc" }}>
                  <li>{t("Instagram_Direct")}</li>
                  <li>{t("Facebook Messenger")}</li>
                  <li>{t("WhatsApp")}</li>
                  <li>{t("SMS_Text")}</li>
                  <li>{t("Email")}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="subdetail">
            <div className="left-content">
              <div className="left-title">
                <span>{t("Key_features")}:</span>
              </div>
              <div className="left-links">
                <ol style={{ listStyleType: "disc" }}>
                  <li>
                    {t("Automate_conversations")} <br />{" "}
                    {t("engage_up")}    <br />
                    <img src={Questionmark} alt="..." />
                  </li>
                  <li>
                    {t("Acquire_new")} <br /> {t("Access_basic_Growth")}
                  </li>
                  <li>{t("Segment_your")}</li>
                </ol>
              </div>
            </div>
            <div className="left-content">
              <div className="left-title">
                <span>{t("Everything_in")}:</span>
              </div>
              <div className="left-links">
                <ol style={{ listStyleType: "disc" }}>
                  <li>
                    {t("Engage_unlimited")} <br /> {t("Scaled_pricing")}
                  </li>
                  <li>
                    {t("Expand_customer")} <br /> {t("Unlimited_Growth")}
                  </li>
                  <li>
                    {t("Build_advanced")} <br />
                    {t("Unlimited_Tags")} <br /> {t("advanced_Segments")}
                  </li>
                  <li>
                    {t("Optimize_performance")} <br /> {t("Analytics_&_Insights")}
                  </li>
                  <li>
                    {t("Save_time")} <br />
                    {t("tools_you")}
                  </li>
                  <li>
                    {t("Make_it_your")} <br /> {t("DM_BOT_branding")}
                  </li>
                  <li>{t("Email_anytime")}</li>
                </ol>
              </div>
            </div>
            <div className="left-content">
              <div className="left-title">
                <span>{t("Everything_in_Pro")}:</span>
              </div>
              <div className="left-links">
                <ol style={{ listStyleType: "disc" }}>
                  <li>
                    {t("custom_built")} <br /> {t("tailored_to")}
                    <br /> {t("needs")}
                  </li>
                  <li>
                    {t("White glove")} <br /> {t("onboarding")}{" "}
                    <br /> {t("chat_support")} <br /> {t("experts_to")} <br />
                    {t("the_way")}
                  </li>
                  <li>
                    {t("Custom_integrations")}<br />
                    {t("CRMs_ad_platforms")}
                  </li>
                  <li>
                    {t("Priority_access")} <br />{t("automation_experts")}
                  </li>
                  <li>
                    {t("No_price")} <br />{t("of_contacts")}
                  </li>
                  <li>
                    {t("Save_money")} <br /> {t("plan")}
                  </li>
                  <li>{t("live_support")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
