import React, { useState } from "react";
import { Handle, Position, useNodeId } from "reactflow";
import { Icon, InlineIcon } from "@iconify/react";
import Button from "react-bootstrap/Button";
import "./scratchNode.css";
import "./Flow.css";
import { useTranslation } from "react-i18next";

const ScratchNode = () => {
  const { t } = useTranslation();
  // const nodeID = useNodeId();
  // console.log("use Node id:", nodeID);
  return (
    <>
      <div className=".scratch-node flow">
        {/* <Handle type="source" position={Position.Top} /> */}
        <Handle
          type="source"
          position={Position.Right}
          id="a"
          isConnectable={true}
        />
        {/* <Handle type="target" position={Position.Bottom} />
      // <Handle type="target" position={Position.Left} /> */}
        <div className="one">
          <Icon
            icon={"material-symbols:electric-bolt"}
            width="1em"
            height="1em"
            color="black"
          />
          <p className="text">{t("When")}</p>
        </div>
        <div className="two">
          <p className="text">{t("A_Trigger_is_an_event")}</p>
        </div>
        <div
          className="three"
          //  onClick={openModal}
        >
          <p className="text">{t("Start_From_Scratch")}</p>
        </div>
      </div>
    </>
  );
};

export default ScratchNode;
