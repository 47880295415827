import React from "react";
import "../../../style/main.scss";
import update from "../../../assets/images/blog/update.png";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="creator-banner v-center h-center flex-column">
        <div className="freebotdm-title v-center h-center flex-column">
          <h2 className="one">{t("Product_Updates")}</h2>
          <p className="large text-black">{t("Check_out_the_latest")}</p>
          <img src={update} alt="EcommerceBanner-image" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};
export default Banner;