import React from "react";
import Detail from "../components/price/detail";
import Feature from "../components/price/feature";
import Faq from "../components/price/faq";
import Footer from "../components/shared/footer";
import Freebotdm from "../components/home/freebotdm";
import PricePlan from "../components/price/pricePlan";

const Price = () => {
  return (
    <>
      <PricePlan />
      <Detail />
      <Feature />
      <Faq />
      <div className="new ">
        <Freebotdm />
        <Footer />
      </div>
    </>
  );
};

export default Price;
