import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./action.scss";
import { Icon, InlineIcon } from "@iconify/react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Action = ({ show, handleModalaction, sho, setShow }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectTabModal, setSelectTabModal] = useState(true);
  const [selectTabModal2, setSelectTabModal2] = useState(true);
  const [showNew, setShowNew] = useState(false);

  const handleClose = () => setShowNew(false);
  const handleShow = (e) => {
    e.stopPropagation();
    setShowNew(true);
  };
  const ModalTab = () => {
    setSelectTabModal(true);
    setSelectTabModal2(false);
  };
  const ModalTab2 = () => {
    setSelectTabModal(false);
    setSelectTabModal2(true);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [dataOne, setDataOne] = useState([
    {
      id: "1",
      headingOne: "Add Tags",
      headingTwo: "Label contacts for convenient organization and segmentation",
    },
    {
      id: "2",
      headingOne: "Set User Field",
      headingTwo:
        "Manage customer data, including birthdates or store item counts.",
    },
  ]);
  const [dataTwo, setDataTwo] = useState([
    {
      id: "1",
      headingOne: "Message Model",
      headingTwo: "Pop-up modal window",
    },
  ]);
  const [dataThree, setDataThree] = useState([
    {
      id: "1",
      headingOne: "Add Tags",
      headingTwo: "Label contacts for convenient organization and segmentation",
    },
    {
      id: "2",
      headingOne: "Remove Tags",
      headingTwo:
        "Delete assigned Tags when they are no longer applicable to the contact.",
    },
    {
      id: "3",
      headingOne: "Set User Field",
      headingTwo:
        "Manage customer data, including birthdates or store item counts.",
    },
    {
      id: "4",
      headingOne: "Clear User Field",
      headingTwo: "Delete stored data",
    },
    {
      id: "5",
      headingOne: "Set Messenger Opt-in",
      headingTwo:
        "Enable followers to receive automated direct messages from your account after opting in",
    },
    {
      id: "6",
      headingOne: "Set Messenger Opt-in",
      headingTwo: "opt-out prevents automated direct messages to followers",
    },
    // {
    //   id: "7",
    //   headingOne: "Contact Event",
    //   headingTwo: "contact subscribed to a sequence",
    // },
    // {
    //   id: "8",
    //   headingOne: "Contact Event",
    //   headingTwo: "contact unsubscribed from a sequence",
    // },
    // {
    //   id: "9",
    //   headingOne: "Contact Event",
    //   headingTwo: "Date/Time occured",
    // },
  ]);
  return (
    <div
      onClick={() => {
        setShow(false);
      }}
    >
      <div className="can">
        <Offcanvas
          show={show}
          //  onHide={handleModalaction}
        >
          <Offcanvas.Body className="actionCanvas">
            <div className="actionCanvas">
              <div className="title">
                <h6>Actions</h6>
              </div>
              <div className="MainContent">
                <div className="titles">
                  <h6>Perform following actions:</h6>
                  <button
                    onClick={(e) => {
                      handleShow(e);
                    }}
                  >
                    + Action
                  </button>
                </div>
                <hr />
                <button onClick={sho}>Choose Next Step</button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      {/* New modal */}
      <Modal show={showNew} size="xl" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="header-add">
              <h5>Perform next action...</h5>
              {/* <input
                className="inputStyles"
                placeholder="Search in Messenger"
              ></input> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="menuModal-add">
            <div className="left">
              <div className="top">
                <div className="divOne" onClick={ModalTab}>
                  <Icon
                    icon="material-symbols-light:star-outline"
                    width="24"
                    height="24"
                  />
                  <p className="text">Recently used</p>
                </div>
                <div className="divTwo" onClick={ModalTab2}>
                  <Icon icon="carbon:tag" width="20" height="20" rotate={1} />
                  <p className="text">Contact data</p>
                </div>
                <div className="divOne" onClick={ModalTab}>
                  <Icon icon="uim:react" width="24" height="24" />
                  <p className="text">Automation</p>
                </div>
                <div className="divTwo" onClick={ModalTab2}>
                  <Icon icon="et:chat" width="24" height="24" />
                  <p className="text">Live Chat</p>
                </div>
              </div>
              <div className="bottom">
                <p className="first-para">
                  Unlock more Actions
                  <Icon
                    icon="solar:arrow-up-outline"
                    width="24"
                    height="24"
                    rotate={1}
                  />
                </p>
                <p className="text-black">
                  Explore the list of integrations like Google Sheets, Klaviyo
                  and more. Unlock extra Actions in your automation.
                </p>
              </div>
            </div>

            <div className="right">
              {selectTabModal == true ? (
                <>
                  <p className="heading">Recently used</p>
                  <p className="subHeading">Actions that you used recenlty.</p>

                  {dataOne.map((item) => (
                    <div className="mapDiv">
                      <Icon
                        icon="carbon:tag"
                        width="24"
                        height="24"
                        rotate={1}
                      />
                      <div className="mapDivInner">
                        <p className="textOne">{item.headingOne}</p>
                        <p className="textTwo">{item.headingTwo}</p>
                      </div>
                    </div>
                  ))}

                  {/* <p className="heading">Website widgets</p>
                  <p className="subHeading">
                    Embeddable blocks with call to action button or checkbox on
                    your website.
                  </p>
                  {dataTwo.map((item) => (
                    <div className="mapDiv">
                      <Icon
                        icon={"logos:messenger"}
                        width="1em"
                        height="1em"
                        color="black"
                      />
                      <div className="mapDivInner">
                        <p className="textOne">{item.headingOne}</p>
                        <p className="textTwo">{item.headingTwo}</p>
                      </div>
                    </div>
                  ))} */}
                </>
              ) : selectTabModal2 == true ? (
                <>
                  <p className="heading">Contact Events</p>
                  <p className="subHeading">
                    Contact event is a specific event on the DM BOT side
                    that starts your automation.
                  </p>

                  {dataThree.map((item) => (
                    <div className="mapDiv">
                      <Icon
                        icon="carbon:tag"
                        width="24"
                        height="24"
                        rotate={1}
                      />
                      <div className="mapDivInner">
                        <p className="textOne">{item.headingOne}</p>
                        <p className="textTwo">{item.headingTwo}</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Action;
