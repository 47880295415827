import React from "react";
import "../../style/main.scss";
import football from "../../assets/images/homepage/football.png";

import community from "../../assets/images/messenger/community.png";
import communityKor from "../../assets/images/messenger/community.png";
import blog from "../../assets/images/messenger/blogs.png";
import blogKor from "../../assets/images/messenger/blogs.png";
import agencies from "../../assets/images/messenger/agencies.png";
import agenciesKor from "../../assets/images/messenger/agencies.png";
import mindvalley from "../../assets/images/homepage/mindvalley.png";
import frazer from "../../assets/images/homepage/frazer.png";
import { useTranslation } from "react-i18next";
const ActiCards = () => {
  const { t } = useTranslation();
  const {i18n} = useTranslation();
  return (
    <div>
      <div className="acticards v-center h-center flex-column">
        <h3 className="medium text-black">{t("Got_Your_Back")}</h3>
        <p className="large text-black  mt-4">
        {t("Our_customer_support_extensive_library_of_best")}
        </p>
        <div className="customer-cards d-flex">
          <div className="card">
            <div className="card-head">
              <img src={football} alt="football-img" className="img-fluid" />
            </div>
            <div className=" card-body v-center h-center">
              <p className="small-2">{t("Join_Our_Community")}</p>
            </div>
            <div className="community">
              <img src={i18n.language == 'en'  ? community : communityKor} alt="community-img" />
            </div>
          </div>

          <div className="card">
            <div className="card-head">
              <img
                src={mindvalley}
                alt="mindvalley-img"
                className="img-fluid"
              />
            </div>
            <div className="card-body  v-center h-center">
              <p className="small-2">{t("Discover_Our_Blog")}</p>
            </div>
            <div className="blog">
              <img src={i18n.language == 'en'  ? blog : blogKor} alt="blog-img" />
            </div>
          </div>
          <div className="card">
            <div className="card-head">
              <img src={frazer} alt="mindvalley-img" className="img-fluid" />
            </div>
            <div className="card-body  v-center h-center">
              <p className="small-2">{t("Get_Help_Experts")}</p>
            </div>
            <div className="blog">
              <img src={i18n.language == 'en'  ? agencies : agenciesKor} alt="blog-img" />
            </div>
          </div>
          {/* 
          <div className="card">
            <div className="card-head">
              <img src={frazer} alt="frazer-img" className="img-fluid" />
            </div>
            <div className=" card-body v-center h-center">
              <p className="small-2">Get Help From the Experts</p>
            </div>
            <div className="agencies">
              <img src={agencies} alt="agencies-img" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ActiCards;
