import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { Component, useEffect, useState } from "react";
import { useLogin } from "react-facebook";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { getLongLivedToken } from "../../config/facebookApis";
import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
} from "../../config/firebase";
import { getUserAuth } from "../../config/kakaotalkApis";
import "./Login.css";
import { useTranslation } from "react-i18next";
const Login = () => {
  const { t } = useTranslation();
  //Facebook login
  const { login, status, isLoading, error } = useLogin();
  const navigate = useNavigate();
  const [selectTab, setSelectTab] = useState("Dummy");
  //Navigate user to dashboard if already logged in
  useEffect(() => {
    const userData = secureLocalStorage.getItem("user");
    if (userData) {
      navigate("/profile/dashboard");
    }
  }, []);
  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     console.log(user)
  //     navigate("/")
  //   } else {
  //     //User is not signed in
  //     console.log("No User")
  //   }
  // })
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    logInWithEmailAndPassword(data.email, data.password);
  };
  async function handleFacebookLogin() {
    try {
      const response = await login({
        scope: process.env.REACT_APP_SCOPE,
      });

      console.log(response);
      getLongLivedToken(response, navigate, "/profile/dashboard");
    } catch (error) {
      console.log(error.message);
    }
  }

  const getNaverAuthUrl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/sendUrltoLogin`
      );
      console.log("response", response.data.url);
      window.open(response.data.url, "_self");
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getKakaoAuthUrl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/auth/kakao`,
        {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        }
      );
      console.log("response", response.data.url);
      window.open(response.data.url, "_self");
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="login">
      <img
        src={"/assets/images/registerLoginBack.png"}
        alt="..."
        className="image"
      />

      <div className="upper">
        <div className="extra"></div>
        {/* <p className="text">Please Fill out form to Register! Login</p> */}
        <div
          className="button"
          onClick={(e) => {
            navigate("/Signup");
          }}
        >
          <p className="text">{t("SIGN UP")}</p>
        </div>
      </div>

      <div className="center">
        {/* Section One */}


        <p className="text">{t("Welcome_Back")}</p>
         <div className="formfields"> <form onSubmit={handleSubmit(onSubmit)}>

          {/* Section One */}
          <p className="heading">{t("Email")}:</p>
          <input
            className="inputstyles"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            })}
          ></input>
          {errors?.email?.type === "required" && (
            <p className="customRed">{t("This_field")}</p>
          )}
          {errors?.email?.type === "pattern" && (
            <p className="customRed">{t("Invalid_Email")}</p>
          )}
          {/* Section One */}
          <p className="heading">{t("Password")}:</p>
          <input
            type="password"
            className="inputstyles"
            {...register("password", {
              required: true,
              pattern: /^(?=.*[A-Z])(?=.*\d).{8,20}$/,
            })}
          ></input>
          {errors?.password?.type === "required" && (
            <p className="customRed">{t("This_field")}</p>
          )}
          {errors?.password?.type === "pattern" && (
            <p className="customRed">
              {t("Password_must")}("#,& etc."){" "}
            </p>
          )}
          <button className="buttonSubmit" type="submit">
            {t("Login")}
          </button>
        </form></div>
        {/* <div className="button">
          <p className="text">Login</p>
        </div> */}

        <div className="divvv">
          <p className="textOne">{t("Dont_have")}</p>
          <p className="textTwo">{t("Register")}</p>
        </div>
        <p className="textOR">{t("OR")}</p>
        <div className="imagesDiv">
          <img
            className="customPointer"
            src={"/assets/images/googleImage.png"}
            onClick={getKakaoAuthUrl}
            alt="..."
          />

          <img
            className="customPointer"
            src={"/assets/images/appleImage.png"}
            onClick={getNaverAuthUrl}
            alt="..."
          />

          <img
            className="customPointer"
            src={"/assets/images/facebookImage.png"}
            alt="..."
            onClick={handleFacebookLogin}
          />
        </div>
      </div>

      <div className="lower">
        <img src={"/assets/images/dmLogo.png"} alt="..." />

        <p className="text">© 2021. - 2025 {t("All_Rights")}</p>
        <div className="extra"></div>

      </div>
    </div>
  );
};

export default Login;
