import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../../style/_resturant.scss";
import { useTranslation } from "react-i18next";
const Faqs = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="second-faq">
        <div className="faq">
          <h1 className="light-purple v-center h-center text-white">
            {t("Questions")}
          </h1>

          <div className="accor">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {t("What_is_DM_Bot")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("DM_Bot_short_for")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {t("integrated_with_other_applications")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("DM_Bot_offers_integration")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {t("How_customizable_is")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("DM_Bot_is_highly_customizable")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  {t("suitable_for_small_businesses")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("DM_Bot_caters_to_businesses")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  {t("What_platforms_does")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("DM_Bot_supports_various")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
