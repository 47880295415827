import React from 'react'
import "./relationship.css"
import { useTranslation } from "react-i18next";
const Relationship = () => {
  const { t } = useTranslation();
  return (
    <div className='relation'>
        <div className="cont">
      <h2>{t("User_Establish_customer")}</h2>
      <p>{t("Build_powerful_automations")}</p>
      <div className='btn'>
        <button>{t("Connect")}</button>
        </div>
      </div>
    </div>
  )
}

export default Relationship
