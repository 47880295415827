import React from "react";
import "../../../style/main.scss";
import creator from "../../../assets/images/blog/creator.png";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="creator-banner v-center h-center flex-column">
        <div className="freebotdm-title v-center h-center flex-column">
          <h2 className="one">{t("Chat_Marketing")}</h2>
          <p className="large text-black">{t("Learn_how_to_deliver")}</p>
          {/* <video
            autoPlay
            loop
            muted
            playsInline
          >
            <source src="../../../assets/cheering.mp4" type="video/mp4" />
          </video> */}
          <img
            src={creator}
            alt="EcommerceBanner-image"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};
export default Banner;







