// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { initializeApp } from 'firebase-admin/app';

import {
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendSignInLinkToEmail,
  AuthErrorCodes,
} from "firebase/auth";
import { uploadBytesResumable, ref as refS } from "firebase/storage";
import { toast } from "react-toastify";

import { getStorage } from "firebase/storage";
import { useEffect, useState, createContext, useContext } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import { getDatabase, onValue, update } from "firebase/database";
import {
  doc,
  getFirestore,
  setDoc,
  deleteDoc,
  deleteField,
  onSnapshot,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  writeBatch,
  Firestore,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { FacebookAuthProvider } from "firebase/auth";
// import { newdb } from "./firebase";
import { Navigate } from "react-router-dom";
import returnErrorMessages from "./firebaseErrorMessages";
import secureLocalStorage from "react-secure-storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//Qasim Configs
// const firebaseConfig = {
//   apiKey: "AIzaSyAOl7F2a3LV3b4LATRaQPh2gAiYcW25IO0",
//   authDomain: "multiart-7652e.firebaseapp.com",
//   projectId: "multiart-7652e",
//   storageBucket: "multiart-7652e.appspot.com",
//   messagingSenderId: "560199681939",
//   appId: "1:560199681939:web:bedcce4f3e48ab60bd4a66",
//   measurementId: "G-RKCF49F6CV",
// };
//Faisal Configs
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestoredb = getFirestore(app);

//Secondary Firebase
const secondary_app = initializeApp(firebaseConfig);
const secondary_auth = getAuth(secondary_app);
const secondary_storage = getStorage(secondary_app);
const secondary_db = getDatabase(secondary_app);
const secondary_firestoredb = getFirestore(secondary_app);

//userData

// const userData = secureLocalStorage.getItem("userProfileData");
const userData = secureLocalStorage.getItem("currentPageProfile");

// const currentUsera = "";
export const AuthContext = createContext();

onAuthStateChanged(auth, (currentUser) => {
  if (currentUser) {
    // console.log("logged in " + currentUser.displayName);
    // setAbc(currentUser.displayName);
  } else {
  }
});

export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth?.user != null };
};

export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
    return unsub;
  }, []);
  return currentUser;
};

export const uploadImg = async (image, currentUser) => {
  // const currentUser = useAuth();
  const imageRef = ref(storage, currentUser.uid + "image");
  const snapshot = await uploadBytes(imageRef, image);

  const photoURL = await getDownloadURL(imageRef);
  updateProfile(currentUser, { photoURL });
  toast("Profile uploaded succesfully");
  // window.location.reload();
};

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(
      collection(firestoredb, "users"),
      where("uid", "==", user.uid)
    );
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(firestoredb, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const signInWithFacebook = async () => {
  try {
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        console.log(accessToken);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
      });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCred) => {
        console.log(userCred);
        localStorage.isSigninSuccess = true;
      })
      .catch((e) => {
        console.log(e.code);
        toast.error(returnErrorMessages(e.code), {
          toastId: "loginError",
        });
      });
  } catch (err) {
    console.error(err);
    toast.error(" User and Password not correct", {
      toastId: "loginError",
    });
  }
};
const registerWithEmailAndPassword = async (
  email,
  password,
  username,
  fullname
) => {
  try {
    console.log(secondary_app);
    await createUserWithEmailAndPassword(secondary_auth, email, password)
      .then(async (res) => {
        const user = res.user;
        await setDoc(
          doc(firestoredb, "userInfo", user.uid),
          {
            fullname: fullname,
            uid: user.uid,
            email: email,
            username: username,
          },
          { merge: true }
        )
          .then((res) => {
            signOut(secondary_auth);
            toast.success("User Registered!", {
              toastId: "userAdded",
            });
          })
          .catch(function (error) {
            console.log(error);
            return false;
          });
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.code);
        toast.error(returnErrorMessages(error.code), {
          toastId: "registerError",
        });
        return false;
      });
  } catch (err) {
    console.error(err);
    alert(err.message);
    return false;
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success("Password reset link sent!", { toastId: "forgotPassword" });
    // alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const emailLinkAuthentication = async (email) => {
  console.log("Email:", email);

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: "http://localhost:5173/login",
    // This must be true.
    handleCodeInApp: true,
  };
  try {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        toast.success(`Email has been sent to "${email}" with login link`, {
          toastId: "emailLink",
          autoClose: false,
        });
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error Sending SignIn Link:", error);

        // ...
      });
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
const uploadImageToFirebase = async (file, storagePath, maxSize) => {
  return new Promise((resolve, reject) => {
    if (file) {
      if (file.size < maxSize) {
        const imageRef = refS(
          storage,
          `/${storagePath}/${file.name}` + Date.now()
        );
        const uploadTask = uploadBytesResumable(imageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (err) => {
            toast.error("Error while adding cover image", {
              toastId: "coverImageError",
            });
            console.log(err);
            reject({ success: false, thumbnail_url: "" });
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((thumbnail_url) => {
                console.log(thumbnail_url);
                resolve({ success: true, thumbnail_url });
              })
              .catch((err) => {
                console.log(err);
                reject({ success: false, thumbnail_url: "" });
              });
          }
        );
      } else {
        toast.error(
          maxSize == 2999999
            ? "File is too big. Must be less than 3 MBs"
            : maxSize == 104857600
            ? "File is too big. Must be less than 100 MBs"
            : "File is too big. Must be less than 5 MBs",
          {
            toastId: "sizeError",
          }
        );
        reject({ success: false, thumbnail_url: "" });
      }
    } else {
      resolve({ success: false, thumbnail_url: "" });
    }
  });
};
const uploadPreviewImageToFirebase = async (file, storagePath, maxSize) => {
  return new Promise((resolve, reject) => {
    if (file) {
      if (file.size < maxSize) {
        try {
          const imageRef = refS(
            storage,
            `/${storagePath}/${file.name}` + Date.now()
          );
          const uploadTask = uploadBytesResumable(imageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
            },
            (err) => {
              toast.error("Error while adding cover image", {
                toastId: "coverImageError",
              });
              console.log(err);
              reject({ success: false, thumbnail_url: "" });
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((thumbnail_url) => {
                  console.log(thumbnail_url);
                  resolve({ success: true, thumbnail_url });
                })
                .catch((err) => {
                  console.log(err);
                  reject({ success: false, thumbnail_url: "" });
                });
            }
          );
        } catch (err) {
          console.log(err);
          reject({ success: false, thumbnail_url: "" });
        }
      } else {
        toast.error(
          maxSize == 2999999
            ? "File is too big. Must be less than 3 MBs"
            : maxSize == 104857600
            ? "File is too big. Must be less than 100 MBs"
            : "File is too big. Must be less than 5 MBs",
          {
            toastId: "sizeError",
          }
        );
        reject({ success: false, thumbnail_url: "" });
      }
    } else {
      resolve({ success: false, thumbnail_url: "" });
    }
  });
};

const addDataToFirestore = (
  collectionName,
  documentName,
  dataArray,
  id,
  liveData
) => {
  // return new Promise(async (resolve, reject) => {
  //   try {
  //     const collectionRef = doc(firestoredb, collectionName, documentName);
  //     // const querySnapshot = await getDoc(collectionRef);
  //     // querySnapshot.then(async (doc) => {
  //     //   await deleteDoc(doc);
  //     // });
  //     await setDoc(collectionRef, { [id]: dataArray }, { merge: true });

  //     resolve(`Collection ${collectionName} replaced successfully with array.`);
  //   } catch (e) {
  //     reject(`Error replacing collection ${collectionName}: ${e}`);
  //   }
  // });

  return new Promise(async (resolve, reject) => {
    try {
      const userData = secureLocalStorage.getItem("currentPageProfile");

      const parentDocRef = doc(firestoredb, "Flow", userData.id);
      const nestedColRef = collection(parentDocRef, collectionName);
      const nestedDocRef = doc(nestedColRef, documentName);
      await setDoc(
        nestedDocRef,
        { [id]: dataArray },
        // { live: false },
        { merge: true }
      );
      console.log("Data added to nested collection within Firestore document!");
      resolve("Data added successfully");
    } catch (error) {
      console.error("Error adding data: ", error);
      reject(error);
    }
  });
};
const getDataFromFirestore = (collectionName, documentName) => {
  // return new Promise((resolve, reject) => {
  //   const docRef = doc(firestoredb, collectionName, documentName);
  //   getDoc(docRef)
  //     .then((docSnapshot) => {
  //       if (docSnapshot.exists()) {
  //         resolve(docSnapshot.data());
  //       } else {
  //         reject(new Error("No such document!"));
  //       }
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  return new Promise(async (resolve, reject) => {
    try {
      const userData = secureLocalStorage.getItem("currentPageProfile");

      const parentDocRef = doc(firestoredb, "Flow", userData.id);
      const nestedColRef = collection(parentDocRef, collectionName);
      const nestedDocRef = doc(nestedColRef, documentName);
      const docSnapshot = await getDoc(nestedDocRef);

      if (docSnapshot.exists()) {
        resolve(docSnapshot.data());
      } else {
        resolve(null); // Document doesn't exist
      }
    } catch (error) {
      console.error("Error getting data: ", error);
      reject(error);
      // window.location.reload();
    }
  });
};

const listenForUpdates = (collectionName, documentName, callback) => {
  try {
    const userData = secureLocalStorage.getItem("currentPageProfile");

    const parentDocRef = doc(firestoredb, "Flow", userData.id);
    const nestedColRef = collection(parentDocRef, collectionName);
    const nestedDocRef = doc(nestedColRef, documentName);

    return onSnapshot(nestedDocRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        callback(data);
      } else {
        console.log("No such document!");
        // Handle when document doesn't exist
        // callback(null);
      }
    });
    // return unsubscribe;
  } catch (error) {
    console.error("Error getting real-time data: ", error);
    // window.location.reload();
    throw error;
  }

  // const collectionRef = doc(firestoredb, collectionName, documentName);

  // // Using onSnapshot to listen for real-time updates
  // const unsubscribe = onSnapshot(collectionRef, (docSnapshot) => {
  //   if (docSnapshot.exists()) {
  //     const data = docSnapshot.data();
  //     callback(data);
  //   } else {
  //     console.log("Document does not exist");
  //   }
  // });

  // // Return the unsubscribe function for stopping updates when needed
  // return unsubscribe;
};

const getCollection = (collectionName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userData = secureLocalStorage.getItem("currentPageProfile");

      const parentDocRef = doc(firestoredb, "Flow", userData.id);
      const nestedColRef = collection(parentDocRef, collectionName);
      const querySnapshot = await getDocs(nestedColRef);
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      resolve(data);
    } catch (error) {
      console.error("Error getting data: ", error);
      reject(error);
      // window.location.reload();
    }
  });

  // return new Promise((resolve, reject) => {
  //   const collectionRef = collection(firestoredb, collectionName);
  //   getDocs(collectionRef)
  //     .then((querySnapshot) => {
  //       const data = [];
  //       querySnapshot.forEach((doc) => {
  //         data.push({
  //           id: doc.id,
  //           data: doc.data(),
  //         });
  //       });
  //       resolve(data);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
};

const deleteDocument = async (documentName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const parentDocRef = doc(firestoredb, "Flow", userData.id);
      const nestedColRef = collection(parentDocRef, "Flow");
      const nestedDocRef = doc(nestedColRef, documentName);

      await deleteDoc(nestedDocRef);
      console.log("Document successfully deleted from the nested collection!");
      resolve("Document deleted successfully");
    } catch (error) {
      console.error("Error deleting document: ", error);
      reject(error);
    }
  });
};

export {
  auth,
  app,
  storage,
  firestoredb,
  signInWithGoogle,
  signInWithFacebook,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  emailLinkAuthentication,
  uploadImageToFirebase,
  uploadPreviewImageToFirebase,
  addDataToFirestore,
  getDataFromFirestore,
  listenForUpdates,
  getCollection,
  deleteDocument,
};
