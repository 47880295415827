import React from "react";
import "./instroinsta.css";
import instagram from "../../assets/images/instagram.png";
import line from "../../assets/images/line.png";
import background from "../../assets/images/background.png";

import Freebot from "../ctxads/freebot";
import Footer from "../shared/footer";

import { useTranslation } from "react-i18next";

const Introinsta = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="head">
        <h2>{t("Instagram_Automation")}</h2> <h2>{t("by_DM_BOT")}</h2>
      </div>
      <div className="top-insta">
        <div className="left">
          <img src={instagram} alt="" />
          <p>{t("USE_INSTAGRAM_AUTOMATION")}</p>
          <img src={line} alt="" />
        </div>
        <div className="right">
          <ul>
            <li >
              <a href="#"> {t("Instagram_Automation")}  {t("by_DM_BOT")}</a>
            </li>
            <li>
              <a href="#">
                {t("How_to_Connect")}
              </a>
            </li>
            <li>
              <a href="#">{t("Builder_Basics")}</a>
            </li>
            <li>
              <a href="#">{t("Builder_Breakdown")}</a>
            </li>
            <li>
              <a href="#">{t("Growth_Tool")}</a>
            </li>
            <li>
              <a href="#">{t("Conversation_Starters")}</a>
            </li>
            <li>
              <a href="#">{t("Feature_Keywords")}</a>
            </li>
            <li>
              <a href="#">{t("Story_Mention")}</a>
            </li>
            <li>
              <a href="#">{t("Message_Template")}</a>
            </li>
            <li>
              <a href="#">{t("Rule_Explained")}</a>
            </li>
            <li>
              <a href="#">{t("Takeaways")}</a>
            </li>
            <li>
              <a href="#">{t("Instagram_Automation")}  {t("by_DM_BOT")} </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="mack">
        <div className="top-insta-para">
          <p>
            {t("Instagram_the_largest_photo")}
          </p>
          <p>
            {t("is_a_solution_for")}
          </p>
          <ul>
            <li>{t("How_to_connect_your")}</li>
            <li>{t("What_are_the_key")}</li>
            <li>{t("Best_practices")}</li>
            <li>{t("And_more")}</li>
          </ul>
          <p>
            {t("DM_BOT_Course_Expert")}
          </p>
        </div>
        <div className="bot-para">
          <h2>{t("About_Mackensie")}</h2>
          <p>
            {t("Mackensie_Liberman")}
          </p>
          <div className="connect">
            <a href="#">
              {t("How_to_Connect_Your_Instagram")}
            </a>
          </div>
        </div>
      </div>
      <Freebot />
      <Footer />
    </div>
  );
};

export default Introinsta;
