import React from "react";
import "../../style/_ecommerce.scss";
import Moobilesale from "../../assets/images/mobile sale.png";
import { useTranslation } from "react-i18next";
const Mobilesale = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mobilesale">
        <div className="mobilesale-content">
          <h6>{t("Grow_Your_Sales")}</h6>
          <p>
          {t("Create_a_better_shopping_experience")}
          </p>
          <button>{t("Get_Started")}</button> <br />
          <img src={Moobilesale} alt=".." className="img-fluid" />
          <h6>{t("Could_you_be")}</h6>
        </div>
      </div>
    </>
  );
};

export default Mobilesale;
