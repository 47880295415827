import React, { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import { Icon, InlineIcon } from "@iconify/react";
import { useSelector } from "react-redux";
import "./messengerInputNode.css";

import { listenForUpdates } from "../../../../../../config/firebase";
const MessengerInputNode = (props) => {
  const [nodeData, setNodeData] = useState([]);

  const FlowId = useSelector((state) => state.flow.text);
  const flowName = FlowId == "" && FlowId == "id" ? "2" : FlowId;

  // }

  useEffect(() => {
    const unsubscribe = listenForUpdates(
      "Flow",
      flowName,

      (data) => {
        setNodeData(data[props.id]);
      }
    );

    console.log(nodeData);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      {/* <Handle type="source" position={Position.Top} /> */}
      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={true}
      />

      <Handle position={Position.Right} isConnectable={true} />

      <div className="messenger-input-node">
        <div className="header">
          <div>
            <Icon icon="logos:messenger" width="30" height="30" />
          </div>
          <div>
            <p className="text-1">Facebook</p>
            <p className="text-2">Send Message</p>
          </div>
        </div>
        <>
          {nodeData ? (
            nodeData.map((item, index) => (
              <div key={item.id} className="input-area">
                {item.type == "text" && (
                  <p>{item.text ? item.text : "Enter Some Text"}</p>
                )}
                {item.type == "image" && (
                  <>
                    {!item.image ? (
                      <Icon
                        icon="ph:image-thin"
                        width="60"
                        height="60"
                        color="gray"
                      />
                    ) : (
                      <>
                        <div style={{ position: "relative" }}>
                          <Icon
                            icon="ph:image-thin"
                            width="30"
                            height="30"
                            color="white"
                            style={{
                              position: "absolute",
                              right: "80%",
                              top: "5%",
                            }}
                          />
                          <img
                            style={{
                              width: "200px",
                              height: "150px",
                              marginBottom: "20px",
                              borderRadius: "10px",
                              border: "none",
                            }}
                            src={item.image}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {item.type == "video" && (
                  <>
                    {!item.video ? (
                      <Icon
                        icon="ph:video-thin"
                        width="60"
                        height="60"
                        color="gray"
                      />
                    ) : (
                      <>
                        <div style={{ position: "relative" }}>
                          <Icon
                            icon="ph:video-thin"
                            width="30"
                            height="30"
                            color="white"
                            style={{
                              position: "absolute",
                              right: "80%",
                              top: "15%",
                              // top: "73%",
                            }}
                          />

                          <video
                            style={{
                              width: "200px",
                              height: "150px",
                              marginBottom: "10px",
                              borderRadius: "10px",
                              border: "none",
                            }}
                            borderRadius={"10px"}
                            autoPlay={false}
                            // controls={true}
                            src={item.video}
                          ></video>
                        </div>
                      </>
                    )}
                  </>
                )}

                {item.type == "audio" && (
                  <>
                    {!item.audio ? (
                      <Icon
                        icon="iconamoon:file-audio-thin"
                        width="60"
                        height="60"
                        color="gray"
                      />
                    ) : (
                      <>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Icon
                            icon="iconamoon:file-audio-thin"
                            width="35"
                            height="35"
                            color="gray"
                          />
                          <h5>AUDIO UPLOADED</h5>
                        </div>
                      </>
                    )}
                  </>
                )}

                {item.type == "file" && (
                  <>
                    {!item.file ? (
                      <Icon
                        icon="ph:file"
                        width="60"
                        height="60"
                        color="gray"
                      />
                    ) : (
                      <>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Icon
                            icon="ph:file"
                            width="35"
                            height="35"
                            color="gray"
                          />
                          <h5 style={{ color: "gray" }}>FILE UPLOADED</h5>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            ))
          ) : (
            <div className="input-area">
              <p>Enter Text or Image</p>
            </div>
          )}
        </>
      </div>
    </>
  );
};
export default MessengerInputNode;
