import React from "react";
import Popular from "../components/integratn/popular";
import Integrations from "../components/integratn/integrations";
import Footer from "../components/shared/footer";
import Freebotdm from "../components/home/freebotdm";

const Integration = () => {
  return (
    <div>
      <div className="integration">
        <Popular />
        <Integrations />
        <Freebotdm bgColor={"#f5f5f7"} />
        <Footer />
      </div>
    </div>
  );
};

export default Integration;
