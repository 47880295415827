import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
const Protected = (props) => {
  const { Component } = props;
  const navigator = useNavigate();

  useEffect(() => {
    const isLogin = secureLocalStorage.getItem("isLogin");
    if (!isLogin) {
      navigator("/Newlogin");
    }
  }, []);

  return (
    <div>
      <Component />
    </div>
  );
};

export default Protected;
