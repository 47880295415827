import React, { useState } from "react";
import "./IntroMessenger.css";
import { Icon, InlineIcon } from "@iconify/react";
import Freebotdm from "../home/freebotdm";
import Footer from "../shared/footer";
import Enterpreneur from "../home/enterpreneur";
import { useTranslation } from "react-i18next";
function IntroMessenger() {
  const { t } = useTranslation();
  // State to keep track of whether the dropdown is open or closed
  const [isOpen, setIsOpen] = useState(false);

  const cdata = [
    {
      img: "/assets/images/cubeImage.png",
      title: `15 ${t("Lessons")}`,
      text: `${t("How_to_Navigate")}`,
    },
    {
      img: "/assets/images/settingsImage.png",
      title: `08 ${t("Lessons")}`,
      text: `${t("How_to_Navigate")}`,
    },
    {
      img: "/assets/images/statsImage.png",
      title: `14 ${t("Lessons")}`,
      text: `${t("Account_Administration")}`,
    },
    {
      img: "/assets/images/cubeImage.png",
      title: `15 ${t("Lessons")}`,
      text: `${t("Understanding_Facebook")}`,
    },
    {
      img: "/assets/images/settingsImage.png",
      title: `08 ${t("Lessons")}`,
      text: `${t("to_Use_Instagram")}`,
    },
    {
      img: "/assets/images/statsImage.png",
      title: `14 ${t("Lessons")}`,
      text: `${t("Instagram_DM_Automation")}`,
    },
    {
      img: "/assets/images/cubeImage.png",
      title: `08 ${t("Lessons")}`,
      text: `${t("How_to_Use_Facebook")}`,
    },
    {
      img: "/assets/images/settingsImage.png",
      title: `${t("Coming_Soon")}`,
      text: `${t("How_to_Use_DM_BOT")}`,
    },
  ];
  return (
    <div className="introMessengerMainDiv">
      <p className="headingMain">{t("DM BOT FREE")}</p>

      <p className="headingSub">
        {t("Everything_you_need")}
        <br className="hide-mob" />
       {t("first_chatbot")}{" "}
        <br className="hide-mob" />
       {t("demand_videos")}
      </p>

      <div className="firstMainDiv">
        {cdata.map((item) => (
          <div className="indust-card">
            <h4 className="cardTitle">{item.title}</h4>
            <img src={item.img} alt="..." className="imggg img-fluid" />
            <p className="cardText">{item.text}</p>
          </div>
        ))}
      </div>
      <div className="enterpren ">
        <Enterpreneur />
      </div>
      <p className="headingMain">{t("Meet_the_dm")}</p>

      <p className="headingSub">
        {t("Marketers_of_all")} <br className="hide-mob" /> {t("at_their_fingertips")}
      </p>

      {/* section one */}
      <div className="courseExpertDiv">
        <div className="left">
          <h4>{t("Marketing_Professionals")}</h4>
          <p className="leftText">
            {t("Consultants_that")}
          </p>
        </div>
        <div className="right">
          <h4>{t("Marketing_Agencies")}</h4>
          <p className="rightText">
            {t("Deliver_results")}{" "}
          </p>
        </div>
      </div>

      {/* section Two */}
      <div className="courseExpertDiv">
        <div className="left">
          <h4>{t("Business_Owners")}</h4>
          <p className="leftText">
            {t("More_than")}
          </p>
        </div>
        <div className="right">
          <h4>{t("eCommerce")}</h4>
          <p className="rightText">
           {t("Email_vs_Messenger")}
          </p>
        </div>
      </div>
      <div className="try ">
        <Freebotdm />
      </div>
      <div className="log">
        <Footer />
      </div>
    </div>
  );
}

export default IntroMessenger;
