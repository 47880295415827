import React from "react";
import "./customers.css";
import connect from "../../../assets/icons/messengericon.svg";
import { useNavigate } from "react-router-dom";
import { useLogin } from "react-facebook";
import { useEffect } from "react";
import axios from "axios";
import { getLongLivedToken } from "../../../config/facebookApis";
import { useTranslation } from "react-i18next";
const ConnectMessenger = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { login, status, isLoading, error } = useLogin();

    async function handleFacebookLogin() {
        try {
            const response = await login({
                scope: process.env.REACT_APP_SCOPE,
            });
            console.log(response);
            getLongLivedToken(response, navigate)
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <div>
            <div className="customers">
                <div className="cont">
                    <img src={connect} alt="image" />
                    <h2>{t("Facebook_Messenger")}</h2>
                    <p>
                        {t("Sell_products_book_appointments")}{" "}
                        <span>
                            <button className="bg-primary"> {t("Use_Auto_Assignment_learn_More")}...</button>
                        </span>
                    </p>
                    <div className="connectbtn mt-3" onClick={handleFacebookLogin}>
                        <button className="bg-primary">{t("Connect")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectMessenger;
