import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import sitelogo from "../../assets/images/header.png";
// import { Button } from "bootstrap";
import Button from "../shared/button";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
const Navbar = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("EN");

  const navigate = useNavigate();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    const languageSwitch = localStorage.getItem("languageSwitch");
    console.log("lan switch:", languageSwitch);

    if (languageSwitch) {
      if (languageSwitch == "en") {
        setLanguage("en");
        localStorage.setItem(languageSwitch, "en");
      } else {
        setLanguage("kor");
        localStorage.setItem(languageSwitch, "kor");
      }
      console.log(languageSwitch);
    } else {
      if (i18n.language == "en") {
        setLanguage("EN");
        localStorage.setItem(languageSwitch, "en");
      } else {
        setLanguage("KN");
        localStorage.setItem(languageSwitch, "kor");
      }
    }
  }, [setLanguage]);
  return (
    <div>
      <nav class="navbar navbar-expand-lg ">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={sitelogo} alt="sitelogo" className="sitelogo" />
          </a>

          <Dropdown
            style={{
              marginTop: "15px",
              border: "1px solid black",
              borderRadius: "10px",
            }}
          >
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <Icon icon="mdi:web" width="24" height="24" />
              <span className="px-2">{language}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href="#/action-1"
                onClick={() => {
                  setLanguage("EN");
                  changeLanguage("en");
                }}
              >
                English
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-2"
                onClick={() => {
                  setLanguage("KN");
                  changeLanguage("kor");
                }}
              >
                Korean (한국인)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="dropdownToggles"
                  >
                    {t("Products")}
                    <Icon
                      icon="tabler:chevron-right"
                      color="black"
                      width="24"
                      height="24"
                      rotate={1}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/messanger">Facebook Messenger</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      {/* <Link to="/tiktok">Tiktok</Link> */}
                      <Link to="/tiktok">Instagram</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/naver">Naver</Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/sms">Talk</Link>
                    </Dropdown.Item>
                    {/* <Dropdown.Item href="#/action-3">YouTube</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li class="nav-item">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="dropdownToggles"
                  >
                    {t("Solution")}
                    <Icon
                      icon="tabler:chevron-right"
                      color="black"
                      width="24"
                      height="24"
                      rotate={1}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <Link to="/CtxAds"> {t("CTX_Ads")}</Link>
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                      <Link to="/resturant"> {t("For_Restuarants")} </Link>
                    </Dropdown.Item> */}
                    <Dropdown.Item>
                      <Link to="/ecommerce"> {t("For_eCommerce")} </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to="/agencies"> {t("For_Agencies")}</Link>
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                      <Link to="/creater">{t("Fore_Creators")}</Link>
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li class="nav-item">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="dropdownToggles"
                  >
                    {t("Agencies")}
                    <Icon
                      icon="tabler:chevron-right"
                      color="black"
                      width="24"
                      height="24"
                      rotate={1}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>
                      <Link to="/hireagencies">{t("Hire")}</Link>
                    </Dropdown.Item> */}
                    <Dropdown.Item>
                      <Link to="/program"> {t("Join_Affiliate_Program")}</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li class="nav-item">
                <Link
                  to="/pricing"
                  className="nav-link fw-normal dropdownToggles"
                >
                  {t("Pricing")}
                  <Icon
                    icon="tabler:chevron-right"
                    color="black"
                    width="24"
                    height="24"
                    rotate={1}
                  />
                </Link>
              </li>
              <li class="nav-item">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="dropdownToggles"
                  >
                    {t("Resources")}
                    <Icon
                      icon="tabler:chevron-right"
                      color="black"
                      width="24"
                      height="24"
                      rotate={1}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="resources d-flex">
                      <div className="left">
                        <Dropdown.Item>
                          <Link to="/blog">{t("Blog")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/help">{t("Help_Center")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/guide">{t("How_To_Guide")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/Webnar">{t("Webinar")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/Introinsta">{t("Free_Insta")}</Link>
                        </Dropdown.Item>
                      </div>
                      <div className="right">
                        <Dropdown.Item>
                          <Link to="/IntroMessenger">
                            {t("Free_Messenger")}
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/EBook">{t("eBook")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/EBookExample">{t("ChatBot")}</Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-4">
                          {t("Community")}
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to="/template">{t("Templates")}</Link>
                        </Dropdown.Item>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
            <form class="d-flex" role="search">
              <Button
                text={t("Get_Started")}
                width="123px "
                height="41px"
                className="btn-primary "
                onClick={() => navigate("/dashboard")}
              />
              <Button
                text={t("SIGN_IN")}
                width="103px "
                height="41px"
                className="btn-secondary "
                onClick={() => navigate("/Newlogin")}
              />
            </form>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
