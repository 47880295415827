import React from 'react'
import "../log.css"
import { useTranslation } from "react-i18next";
const Faceboktab = () => {
  const { t } = useTranslation();
  return (
    <div className='fbook'>
      <h2>{t("error_warning_logs")}</h2>
      <p>
      {t("Everything_is_OK_right_now")}
      </p>
    </div>
  )
}

export default Faceboktab


