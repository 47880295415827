import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import green from "../../assets/images/agencies/green.png";
import black from "../../assets/images/agencies/black.png";
import arrow from "../../assets/images/agencies/arrow.png";
import revenue from "../../assets/images/agencies/revenue.png";
import { useTranslation } from "react-i18next";
const Priority = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="priority d-flex flex-column v-center h-center">
        <h2 className="text-black">
          {t("Get_Priority_Support")}
        </h2>
        <Button
          text={t("Agency_Program")}
          width="283px "
          height="51px"
          className="btn-primary mt-5 "
          onClick={() => alert("Hello!")}
        />
        <div className="turn">
          <div className="usechat-content ist d-flex v-center">
            <div className="left">
              <img src={green} alt="green" className="img-fluid" />
            </div>
            <div className="right">
              <img src={black} alt="black-image" className="img-fluid" />
            </div>
          </div>
          <div className="usechat-content second d-flex v-center mt-5">
            <div className="left">
              <img src={arrow} alt="arrow" className="img-fluid" />
            </div>
            <div className="right">
              <img src={revenue} alt="revenue-image" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Priority;
