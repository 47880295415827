import React, { Component, useState } from "react";
import "./GrowthTools.css";
import { useTranslation } from "react-i18next";
const GrowthTools = () => {
  const [selectTab, setSelectTab] = useState("Dummy");
  const { t } = useTranslation();
  return (
    <div className="growth">
      <div className="bannerMain">
        <p className="heading">{t("Create_your_first_Growth_Tool")} </p>
        <p className="text">{t("Growth_Tools_help_you_grow")}</p>

        <div className="button">
          <p className="buttonText">{t("New_Growth_Tool")} </p>
        </div>
      </div>
    </div>
  );
};

export default GrowthTools;
