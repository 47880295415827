import React from "react";
import Button from "../shared/button";
import homeb from "../../assets/images/ctxbanner.png";
import "../../style/_ctxAds.scss";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="ctx-banner d-flex">
        <div className="home-banner  v-center d-flex">
          <div className="left">
            <h1>{t("Turn_conversations_into_sales")}</h1>
            <h4 className="medium mt-4">{t("Build_shopping_experiences")}</h4>
            <Button
              text={t("Sign_In_to_DM_BOT")}
              width="234px "
              height="51px"
              className="btn-primary mt-5 "
              onClick={() => alert("Hello!")}
            />
          </div>
          <div className="right">
            <img
              src={homeb}
              alt="home-banner"
              className="banner-img img-fluid"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
