import React from 'react'
import "./payment.css"
import { useTranslation } from "react-i18next";
const Payment = () => {
    const { t } = useTranslation();
  return (
    <div >
      
      <div className="paycont">
      <div className="payheader">
        <h2>{t("payment")}</h2>
        {/* <hr /> */}
      </div>

        <div className="account">
            <div><h2>{t("Stripe_Account")}</h2></div>
            <div><button>{t("Connect_Stripe")}</button></div>
            <div><p>{t("payments_via_Messenger_and_Instagram")}</p></div>
        </div>
        <div className="paypal">
            <div>
                <h2>{t("PayPal_Account")}</h2>
            </div>
            <div className='conect'>
                <div className='clientid'>
                    <input type="text" placeholder={t("Sandbox_Client")} />
                </div>
                <div className='clientid2'>
                    <input type="text"className='pl-3' placeholder={t("Sandbox_Client")} />
                </div>
                <div className='conectbtn'>
                    <button>
                    {t("Connect_PayPal")}
                    </button>
                </div>
            </div>
            <div>
                <p>{t("PayPal_payments_via_Messenger_and_Instagram")}</p>
            </div>
        </div>

        <div className="currency">
            <div><h2>{t("Currency")}</h2></div>
            <div className='selcurrency'>
                <input type="text" placeholder={t("US_Dollar_Input")} />
            </div>
            <div className='para'>
                <p>
                {t("Select_currency")}
                </p>
            </div>
        </div>
        <div className="neworder">
            <div>
                <h2>
            {t("Notify_Assignees_Orders")}
                </h2>
                </div>
            <div className='check'>
                <div className='d-flex'><input type="checkbox" /> <span>{t("Messenger")}</span></div>
                <div><input type="checkbox" /> <span>{t("Email")}</span></div>
            </div>
            <div>
                <p>
                {t("Notify_Assignees_new_payment")}
                </p>
            </div>
        </div>
        <div className="send">
            <div>
                <h2>
                {t("Send_To_Contact")}
                </h2>
            </div>
            <div className='check2'>
                <input type="checkbox" /> <span>{t("Email")}</span>
            </div>
            <div>
                <p>
               {t("user_about_successful_payment_by_e-mail")}
                </p>
            </div>
        </div>


      </div>
    </div>
  )
}

export default Payment
