import React from "react";
import "../../../style/main.scss";
import messenger from "../../../assets/images/blog/messenger.png";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="creator-banner v-center h-center flex-column">
        <div className="freebotdm-title v-center h-center flex-column">
          <h2 className="one">{t("Messenger_Bots")}</h2>
          <p className="large text-black">{t("Your_guide_to_everything")}</p>
          <img
            src={messenger}
            alt="EcommerceBanner-image"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
};
export default Banner;












