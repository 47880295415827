import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./howlooksmodel.css";
import timetop from "../../../assets/images/statustop.svg";
import backicon from "../../../assets/icons/backicon.svg";
import profilelogo from "../../../assets/icons/profilelogo.svg";
import messageicon from "../../../assets/icons/message-bubble.svg";
import alerticon from "../../../assets/icons/alerticon2.svg";

function Howlooksmodel({ show3, handleModal3 }) {
  return (
    <>
      {/* <Button variant="primary" onClick={handleModal}>
        Launch demo modal
      </Button> */}

      <Modal show={show3} onHide={handleModal3} animation={false}>
        <Modal.Header closeButton>
          <div className="top-text">
            <Modal.Title>
              <h2>Greeting Text Preview</h2>
            </Modal.Title>
          </div>
        </Modal.Header>
        <div className="profile">
          <Modal.Body>
            <div className="outborder">
              <div className="inrborder">
                <div className="timetop">
                  <div>
                    <p>12:23 PM</p>
                  </div>
                  <div>
                    <img src={timetop} alt="" />
                  </div>
                </div>
                <div className="profilename">
                  <div>
                    <img src={backicon} alt="" />
                  </div>
                  <div className="pflogo">
                    <img src={profilelogo} alt="" />
                  </div>
                  <div>
                    <h2>Octaloop</h2>
                    <p>Typically replies instantly</p>
                  </div>
                </div>

                <div className="pfdetail">
                  <div className="pfimage">
                    <img src={profilelogo} alt="" />
                  </div>
                  <div>
                    <h2>Octaloop</h2>
                    <p>Information technology company</p>
                  </div>
                </div>
                <div className="profilemsg">
                  <div className="firstmsg">
                    <div>
                      <img src={messageicon} alt="" />
                    </div>
                    <div>
                      <p>Typically replies instantly</p>
                    </div>
                  </div>
                  <div className="secondmsg">
                    <div>
                      <img src={alerticon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="fotor">
                  <div>
                    <p>
                      When you tap Get Started, Octaloop will see your public
                      info.
                    </p>
                  </div>

                  <div className="started">
                    <Button onClick={handleModal3}>Get Started</Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default Howlooksmodel;
