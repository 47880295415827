import React from "react";
import "../../style/main.scss";
import enterpreneur from "../../assets/images/homepage/enterpreneur.png";
import backbtn from "../../assets/images/tiktok/backbtn.png";
import { useTranslation } from "react-i18next";
import Title from "../creater/title";
const Enterpreneur = () => {
  const { t } = useTranslation();
  return (
    <div>
    <Title/>
      <div className="tiktok-enterpreneur v-center h-center flex-column">
        <div className="enterpreneus-content">
          <div className="right ">
            <div className="background-div">
              <img
                src={enterpreneur}
                alt="enterpreneur-img"
                className="img-fluid enterpreneur-img"
              />
              <div className="right-content">
                <p>
                  {t("Manychat_helped_us_drive")}{" "}
                </p>
                <div className="subheading">
                  <h3>{t("KAHLEA_WADE,_ENTREPRENEUR")}</h3>
                  <h3>@KAHLEANICOLEE, </h3>
                  <h3>{t("24.4K_FOLLOWERS")}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="back-btn ">
          <img src={backbtn} alt="backbtn-img" />
        </div>
      </div>
    </div>
  );
};

export default Enterpreneur;
