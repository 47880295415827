import React from "react";
import "../../../style/main.scss";
import laptop from "../../../assets/images/blog/Rectangle 117.png";
import whatsapp from "../../../assets/images/blog/Rectangle 119.png";
import instagram from "../../../assets/images/blog/Rectangle 121.png";
import low from "../../../assets/images/blog/Rectangle 118.png";
import little from "../../../assets/images/blog/Rectangle 120.png";
import clever from "../../../assets/images/blog/Rectangle 122.png";
import { t } from "i18next";
const Instagram = () => {
  return (
    <div>
      <div className="instagram">
        <h3>{t("Instagram")}</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={laptop} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Use_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={whatsapp} alt="laptop" className="img-fluid" />
            <p>{t("5_Instagram_Hacks")}</p>
          </div>
          <div className="home-cards">
            <img src={instagram} alt="laptop" className="img-fluid" />
            <p>{t("What_Is_Instagram_Automation")} </p>
          </div>
          <div className="home-cards">
            <img src={low} alt="low" className="img-fluid" />
            <p>{t("Ho_to_Build_an_Instagram")} </p>
          </div>
          <div className="home-cards">
            <img src={little} alt="little" className="img-fluid" />
            <p>{t("The_Best_Way_to_Schedule")}</p>
          </div>
          <div className="home-cards">
            <img src={clever} alt="clever" className="img-fluid" />
            <p>{t("Lead_Generation_and")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instagram;
