import React from "react";
import First from "../../assets/images/one.png";
import Second from "../../assets/images/two.png";
import Three from "../../assets/images/three.png";
import Four from "../../assets/images/four.png";
import Five from "../../assets/images/five.png";
import { useTranslation } from "react-i18next";
const Imagecard = () => {
  const { t } = useTranslation();
  return (
    <div className="template-imagecard">
      {" "}
      <div className="imagecard">
        <div className="turn">
          <div className="usechat-title v-center h-center flex-column">
            {/* <h3 className="medium text-black text-center">
        Turn followers into dollars
      </h3>
      <Button
        text="Get Started For Free"
        width="244px "
        height="51px"
        className="btn-primary-2 mt-5  "
        onClick={() => alert("Hello!")}
      /> */}
          </div>
          <div className="usechat-content ist d-flex v-center ">
            <div className="left">
              <img src={First} alt="have" className="img-fluid" />
            </div>
            <div className="right">
              <img src={Second} alt="tiktok-image" className="img-fluid" />
            </div>
          </div>
          <div className="usechat-content second d-flex v-center ">
            <div className="left">
              <img src={Three} alt="tiktok" className="img-fluid" />
            </div>
            <div className="right">
              <img src={Four} alt="searchbar-image" className="img-fluid" />
            </div>
          </div>
          <div className="usechat-content second d-flex v-center ">
            <div className="left">
              <img src={Five} alt="tiktok" className="img-fluid" />
            </div>
            <div className="right">
              <h6>{t("Marketing_Templates")}</h6>
              {/* <img src={Four} alt="searchbar-image" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Imagecard;
