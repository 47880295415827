import React from "react";
import "../../style/_ctxAds.scss";
import Shopper from "../../assets/images/shopper.png";
import chart from "../../assets/images/chart_infographics_04 [Converted]-01 1.png";
import Store from "../../assets/images/shop.png";
import call from "../../assets/images/call.png";
import { useTranslation } from "react-i18next";

const Storecustomer = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="storecustomer">
        <div className="store-content">
          <div className="top-section">
            <h4>{t("Bring_in_store_experience")}</h4>
            <p>
              {t("Take_the_guesswork_out")}
            </p>
          </div>
          <div className="storeBottom">
            <div className="left">
              <img src={Shopper} alt="..." className="img-fluid" />
            </div>
            <div className="right">
              <h4>{t("Find_and_engage")}</h4>
              <p>
                {t("Forget_your_static")}
              </p>
            </div>
          </div>
          <div className="storeBottomfirst">
            <div className="right">
              <h4>{t("Drive_ROI")}</h4>
              <p>
                {t("Respond_to_shoppers_instantly")}
              </p>
            </div>
            <div className="left">
              <img src={chart} alt="..." className="img-fluid" />
            </div>
          </div>
          <div className="storeBottom">
            <div className="left">
              <img src={Store} alt="..." className="img-fluid" />
            </div>
            <div className="right">
              <h4>{t("Connect_your_store")}</h4>
              <p>
                {t("Connect_your_ecommerce")}
              </p>
            </div>
          </div>
          <div className="storeBottomfirst">
            <div className="right">
              <h4>{t("Take_action_on")}</h4>
              <p>
                {t("There_no_need_to_spend")}
              </p>
            </div>
            <div className="left">
              <img src={call} alt="..." className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Storecustomer;
