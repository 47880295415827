import React from "react";
import Mobilesale from "../components/ecommerce/mobilesale";
import Imagecard from "../components/ecommerce/imagecard";
import Gotback from "../components/ecommerce/gotback";
import Courses from "../components/ecommerce/courses";
import Footer from "../components/shared/footer";
import Freebot from "../components/resturant/freebot";
import Faqs from "../components/resturant/faq";

const Ecommerce = () => {
  return (
    <>
      <div className="ecommerce">
        <Mobilesale />
        <Imagecard />
        <Gotback />
        <Courses />
        <div className="resturant-last">
          <Faqs />
        </div>
        {/* <Freebot bgColor="white" /> */}
        <Freebot />
        <Footer />
      </div>
    </>
  );
};

export default Ecommerce;
