import React from "react";
import Banner from "./update/banner";
import Editor from "./creator/editor";
import Latest from "./creator/latest";
import Freebotdm from "../home/freebotdm";
import Footer from "../shared/footer";
const Update = () => {
  return (
    <div>
      <Banner />
      <Editor />
      <Latest />
      <Freebotdm />
      <Footer />
    </div>
  );
};

export default Update;
