import React from "react";
import Storecustomer from "../components/ctxads/storecustomer";
import Banner from "../components/ctxads/banner";
import Drag from "../components/ctxads/drag";
import Popular from "../components/integratn/popular";
import Integrations from "../components/integratn/integrations";
import Freebotdm from "../components/home/freebotdm";
import Footer from "../components/shared/footer";
import Mostdm from "../components/ctxads/mostdm";
import Freebot from "../components/ctxads/freebot";

const CtxAds = () => {
  return (
    <div>
      {" "}
      <Banner />
      <Storecustomer />
      <Drag />
      <Popular />
      <Integrations />
      <Freebot />
      <Footer />
    </div>
  );
};

export default CtxAds;
