import React from "react";
import Banner from "./event/banner";
import Editor from "./partner/editor";
import Latest from "./partner/latest";

const Event = () => {
  return (
    <div>
      <Banner />
      <Editor />
      <Latest />
    </div>
  );
};

export default Event;
