import React from "react";
import "./integration.css";
import { useTranslation } from "react-i18next";
const Integration = () => {
  const { t } = useTranslation();
  return (
    <div className="integration">
      <div className="intheader">
        <h2>{t("Integration")}</h2>
        <hr />
      </div>
      <div className="intcont">
        <h1>{t("Connect_Your_Account_to_google_sheet")}</h1>
        <div className="contbtn">
          <button>{t("Connect_Your_Account")}</button>
        </div>
      </div>
    </div>
  );
};

export default Integration;
