import "./randomcanvas.scss";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Icon, InlineIcon } from "@iconify/react";
import React from "react";
const RandomCanvas = ({ show, handleModalrandom }) => {
  return (
    <div>
      {" "}
      <Offcanvas
        show={show}
        onHide={() => {
          handleModalrandom();
        }}
      >
        <Offcanvas.Body className="randombody-canvas">
          <div className="randomBody">
            <div className="randombody-content">
              <div className="randomTopbar">
                <h6>Randomizer</h6>
              </div>
            </div>
            <div className="randomMiddle">
              <div className="middleContent">
                <h6>Split the traffic</h6>
                <p>Distribution total must equal 100%</p>
                <div className="checkbox">
                  <div className="checkboxContent">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <span>
                      Random path every time{" "}
                      <Icon
                        icon="codicon:question"
                        color="#d6d9de"
                        width="20"
                        height="20"
                      />
                    </span>
                  </div>
                </div>
                <div className="range">
                  <form className="multi-range-field ">
                    A
                    <input
                      id="multi"
                      className="multi-range firstRange"
                      type="range"
                    />
                    <div className="percentage">
                      <span>50 %</span>
                    </div>
                  </form>
                  <hr />
                </div>
                <div className="range">
                  <form className="multi-range-field ">
                    A
                    <input
                      id="multi"
                      className="multi-range firstRange"
                      type="range"
                    />
                    <div className="percentage">
                      <span>50 %</span>
                    </div>
                  </form>
                  <hr />
                </div>
                {/* <div className="rangeSecond">
                  <form className="multi-range-field ">
                    A
                    <div
                      style={{
                        transform: "scale(1)",
                        transformOrigin: "top left",
                      }}
                      className="scale"
                    >
                      <input
                        id="multi"
                        className="multi-range firstRange"
                        type="range"
                      />
                      <label>
                        <input
                          type="range"
                          checked={range}
                          onChange={() => setRange(!range)}
                          className="rangeColor"
                        />
                      </label>
                    </div>
                    <div className="percentage">
                      <span>70 %</span>
                    </div>
                  </form>
                  <hr />
                </div> */}

                <div className="newVariation">
                  <button>+ New Variation</button>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default RandomCanvas;
