import React, { useState } from "react";
import "./Test.css"; // Import the CSS file
import MessengerCustomerChat from "react-messenger-customer-chat";

const Test = () => {
  const openMessenger = () => {
    // Replace the URL with the messenger.com URL for your page
    window.open("https://www.messenger.com/t/152690394588498", "_blank");
  };
  return (
    <div className="test">
      {/* <h1>This is Messenger chat Screen</h1>
      <button onClick={openMessenger}>Open Messenger</button> */}
      {/* <MessengerCustomerChat
        pageId="152690394588498"
        appId="702171728098489"
        shouldShowDialog={true} // Chatbot starts automatically
        greetingDialogDisplay="show" // Display the greeting message
        onCustomerChatDialogShow={() => {
          // Handle when the chat dialog is shown
          // You can track analytics or perform actions here
        }}
        onCustomerChatDialogHide={() => {
          // Handle when the chat dialog is hidden
          // You can track analytics or perform actions here
        }}
        minimized={true} // Set to true to initially minimize the chat
        themeColor="#0084FF" // Change the theme color
        loggedInGreeting="Hello! How can I assist you?" // Customize the greeting
        loggedOutGreeting="Please log in to chat." // Customize the greeting when the user is logged out
      /> */}
    </div>
  );
};

export default Test;

// import React from "react";
// import Chatbot from "react-chatbot-kit";
// import "react-chatbot-kit/build/main.css";
// import config from "./Config";
// import ActionProvider from "./ActionProvider";
// import MessageParser from "./MessageParser";

// import "./Test.css";

// function Test() {
//   return (
//     <div className="test">
//       <div className="inner">
//         <Chatbot
//           config={config}
//           actionProvider={ActionProvider}
//           messageParser={MessageParser}
//         />
//       </div>
//     </div>
//   );
// }

// export default Test;
