import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import React, { Component, useEffect, useState } from "react";
import { useLogin } from "react-facebook";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Icon } from "@iconify/react";
import FacebookLogin from "react-facebook-login";
import { getLongLivedToken } from "../../config/facebookApis";
import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
} from "../../config/firebase";
import "./newlogin.css";
import { fbLogin, getFacebookLoginStatus, initFacebookSdk } from "../FacebookSDK";
const Newlogin = () => {
  //Facebook login
  const { login, status, isLoading, error } = useLogin();
  const navigate = useNavigate();
  const [selectTab, setSelectTab] = useState("Dummy");
  //Navigate user to dashboard if already logged in
  useEffect(() => {
    secureLocalStorage.clear();

    const isLogin = secureLocalStorage.getItem("isLogin");
    const userData = secureLocalStorage.getItem("user");
    const kakaoData = secureLocalStorage.getItem("kakao");
    const naverData = secureLocalStorage.getItem("naver");
    if (userData || kakaoData || naverData || isLogin) {
      navigate("/profile/dashboard");
    }
  }, []);
  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     console.log(user)
  //     navigate("/")
  //   } else {
  //     //User is not signed in
  //     console.log("No User")
  //   }
  // })
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    logInWithEmailAndPassword(data.email, data.password);
  };
  useEffect(() => {
    console.log("Started use effect");
    initFacebookSdk().then(() => {
      getFacebookLoginStatus().then((response) => {
        if (response == null) {
          console.log("No login status for the person");
        } else {
          console.log(response);
        }
      });
    });
  }, []);
  function loginTest() {
    console.log("reached log in button");
    fbLogin().then((response) => {
      console.log(response);
      if (response.status === "connected") {
        console.log("Person is connected");
        secureLocalStorage.setItem("isLogin", true);

        getLongLivedToken(response, navigate, "/profile/dashboard");
      } else {
        // something
      }
    });
  }

  async function handleFacebookLogin() {
    try {
      const response = await login({
        scope: process.env.REACT_APP_SCOPE,

      });
      console.log(response);
      secureLocalStorage.setItem("isLogin", true);

      getLongLivedToken(response, navigate, "/profile/dashboard");
    } catch (error) {
      console.log(error.message);
    }
  }
  const responseFacebook = (response) => {
    try {
      console.log(`response`, response);
      // getLongLivedToken(response, navigate, "/profile/dashboard");
    } catch (error) {
      console.log(error.message);
    }
  };

  const getNaverAuthUrl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/sendUrltoLogin`
      );
      console.log("response", response.data.url);
      window.open(response.data.url, "_self");
      secureLocalStorage.setItem("isLogin", true);

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getKakaoAuthUrl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/auth/kakao`,
        {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        }
      );
      console.log("response", response.data.url);
      window.open(response.data.url, "_self");
      secureLocalStorage.setItem("isLogin", true);

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="newlogin">
      <img
        src={"/assets/images/registerLoginBack.png"}
        alt="..."
        className="image"
      />
      {/* <div>
        <button onClick={loginTest}>Login</button>
      </div> */}
      {/* <fb:login-button 
  scope="public_profile,email"
  onlogin="checkLoginState();">
</fb:login-button> */}
      {/* 
      <FacebookLogin
        appId="714029483985409"
        autoLoad={false}
        // fields="name,email,picture"
        callback={responseFacebook}
        icon="fa-facebook"
      /> */}

      {/* <div className="upper">
        <div className="extra"></div>
        <div
          className="button"
          onClick={(e) => {
            navigate("/Signup");
          }}
        >
          <p className="text">SIGN UP</p>
        </div>
      </div> */}

      <div className="center">
        {/* Section One */}

        <p className="text">Welcome Back!</p>
        <div className="newlogin-button">
          {/* <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={true}
            fields={process.env.REACT_APP_SCOPE}
            // onClick={handleFacebookLogin}
            callback={responseFacebook}
          /> */}
          <button onClick={handleFacebookLogin}>
            <Icon icon="logos:facebook" width="30" height="30" />

            <span>Sign In With Facebook</span>
          </button>
          {/* <button>
            <Icon icon="skill-icons:instagram" width="30" height="30" />
            <span> Sign In With Instagram</span>
          </button> */}
          <button onClick={getKakaoAuthUrl}>
            <Icon
              icon="simple-icons:kakaotalk"
              color="yellow"
              width="30"
              height="30"
            />
            <span>Sign In With KakaoTalk</span>
          </button>
          <button onClick={getNaverAuthUrl}>
            <Icon
              icon="simple-icons:naver"
              color="#00bf6d"
              width="30"
              height="30"
            />
            <span>Sign In With Naver</span>
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="d-none">
          {/* Section One */}
          <p className="heading">Email:</p>
          <input
            className="inputstyles"
            {...register("email", {
              required: true,
              pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            })}
          ></input>
          {errors?.email?.type === "required" && (
            <p className="customRed">This field is required</p>
          )}
          {errors?.email?.type === "pattern" && (
            <p className="customRed">Invalid Email</p>
          )}
          {/* Section One */}
          <p className="heading">Password:</p>
          <input
            type="password"
            className="inputstyles"
            {...register("password", {
              required: true,
              pattern: /^(?=.*[A-Z])(?=.*\d).{8,20}$/,
            })}
          ></input>
          {errors?.password?.type === "required" && (
            <p className="customRed">This field is required</p>
          )}
          {errors?.password?.type === "pattern" && (
            <p className="customRed">
              Password must contain an uppercase letter, numbers and
              characters("#,& etc."){" "}
            </p>
          )}
          <button className="buttonSubmit" type="submit">
            Login
          </button>
        </form>
        {/* <div className="button">
          <p className="text">Login</p>
        </div> */}

        {/* <div className="divvv">
          <p className="textOne">Dont have an account?</p>
          <p className="textTwo">Register</p>
        </div> */}
        {/* <p className="textOR">OR</p>
        <div className="imagesDiv">
          <img
            className="customPointer"
            src={"/assets/images/googleImage.png"}
            alt="..."
          />

          <img
            className="customPointer"
            src={"/assets/images/appleImage.png"}
            alt="..."
          />

          <img
            className="customPointer"
            src={"/assets/images/facebookImage.png"}
            alt="..."
            onClick={handleFacebookLogin}
          />
        </div> */}
      </div>

      <div className="lower">
        <img src={"/assets/images/dmLogo.png"} alt="..." />
        <p className="text">© 2021. - 2025 All Rights Reserved.</p>
        <div className="extra"></div>
      </div>
    </div>
  );
};

export default Newlogin;
