import { configureStore } from "@reduxjs/toolkit";
import textReducer from "../slices/textSlice";
import updaterReducer from "../slices/updaterSlice";
import objectReducer from "../slices/objectSlice";
import flowReducer from "../slices/flowSlice";

const store = configureStore({
  reducer: {
    text: textReducer,
    updater: updaterReducer,
    objects: objectReducer,
    flow: flowReducer,
  },
});

export default store;
