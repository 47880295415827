import React from "react";
import "../../style/main.scss";
import people from "../../assets/images/homepage/people.png";
import { useTranslation } from "react-i18next";

const People = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="people d-flex">
        <div className="left">
          <img src={people} alt="people-img" className="img-fluid" />
        </div>
        <div className="right">
          <h1>{t("people_are_on_messaging")}</h1>
          <p className="body-large mt-4">
            {t("In_this_day_and_age")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default People;
