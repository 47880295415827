import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./disconmodel.css"
import error from "../../../assets/icons/erroricon.svg"

function Disconmodel({show1,handleModal1}) {


  return (
    <>
      {/* <Button variant="primary" onClick={handleModal}>
        Launch demo modal
      </Button> */}

      <Modal show={show1} onHide={handleModal1} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title className='toptext'>
            <h2>Do you really want to disconnect Facebook page?</h2></Modal.Title>
        </Modal.Header>
        <div className="remid">
        <Modal.Body>
        <div className="contact">
            <div>
                <img src={error} alt="" />
            </div>
            <div className='rpara'>
                <p>If you disconnect Facebook page, all your flows will be stopped. You won’t be able to reach your subscribers in Messenger. You can reconnect your page later.</p>
            </div>
        </div>
          <div className="under">
            <div className="remvacc">
               
                <div className="remthird">
                   <div><h2>Type "Disconnect" to confirm
                </h2></div> 
                   <div className="entext">
                    <input type="text" placeholder='Enter text' />
                   </div>
                    
                </div>
            </div>
          </div>
            </Modal.Body> 
            </div>
            <div className="fot">
                
                
            <div className='cancel'>
          <Button  onClick={handleModal1}>
            Cancel
          </Button>
          </div>
          <div className='confirm'>
             <Button  onClick={handleModal1}>
            Confirm
          </Button>
          </div>
         
        </div>
      </Modal>
    </>
  );
}

export default Disconmodel;