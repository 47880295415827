import React from 'react'
import "./notag.css"
import { useTranslation } from "react-i18next";
const Notag = () => {
  const { t } = useTranslation();
  return (
    <div className='tag'>
    <div className="notag">
        <h2>{t("No_Tags")}</h2>
        <p>{t("first_Tag")}</p>
        <div className="botbtn">
            <button>+ {t("New_Tag")}</button>
        </div>
    </div>
  
</div>
  )
}

export default Notag
