import React from "react";
import "../../style/_ctxAds.scss";
import Messagebox from "../../assets/images/messagebox.png";
import { useTranslation } from "react-i18next";
const Mostdm = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mostdm">
        <div className="content v-center">
          <div className="left">
            <h4>{t("Get_the_most")}</h4>
            <p>
              {t("Explore_free_video_courses")}
            </p>
            <button>{t("Sign_In_to_DM_BOT")}</button>
          </div>
          <div className="right">
            <img src={Messagebox} alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mostdm;
