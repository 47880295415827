import React from "react";
import Campaign from "../../assets/icons/campaign.svg";
import Hubspot from "../../assets/icons/hubspot.svg";
import kit from "../../assets/icons/convertkit.svg";
import Mailchimp from "../../assets/icons/mailchimp.svg";
import paypal from "../../assets/icons/paypal.svg";
import Stripe from "../../assets/icons/stripe.svg";
import Integromat from "../../assets/icons/integronat.svg";
import Klaviyo from "../../assets/icons/klaviyo.svg";
import Mostdm from "../ctxads/mostdm";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Integrations = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <div className="integrations">
        <div className="integration-content">
          <div className="title">
            <h6>{t("More_great_integrations")}</h6>
          </div>
          <div className="cards">
            <div className="firstcard">
              <div className="cardContent">
                <div className="left">
                  <img src={Campaign} alt="..." />
                </div>
                <div className="right">
                  <h6>{t("ActiveCampaign")}</h6>
                  <p>
                    {t("Automatically_send_contact")}
                  </p>
                </div>
              </div>
            </div>
            <div className="firstcard">
              <div className="cardContent">
                <div className="left">
                  <img src={Hubspot} alt="..." />
                </div>
                <div className="right">
                  <h6>{t("HubSpot")}</h6>
                  <p>
                    {t("Submit_subscriber_data_to_Hubspot")}
                  </p>
                </div>
              </div>
            </div>
            <div className="firstcard">
              <div className="cardContent">
                <div className="left">
                  <img src={kit} alt="..." />
                </div>
                <div className="right">
                  <h6>{t("ConvertKit")}</h6>
                  <p>
                    {t("Create_update_tag")}
                  </p>
                </div>
              </div>
            </div>
            <div className="firstcard">
              <div className="cardContent">
                <div className="left">
                  <img src={Mailchimp} alt="..." />
                </div>
                <div className="right">
                  <h6>{t("MailChimp")}</h6>
                  <p>
                    {t("Automatically_send_contact_data_MailChimp")}
                  </p>
                </div>
              </div>
            </div>
            <div className="firstcard">
              <div className="cardContent">
                <div className="left">
                  <img src={paypal} alt="..." />
                </div>
                <div className="right">
                  <h6>{t("PayPal")}</h6>
                  <p>
                    {t("Accept_payments_inside_of_Facebook_Paypal")}
                  </p>
                </div>
              </div>
            </div>
            <div className="firstcard">
              <div className="cardContent">
                <div className="left">
                  <img src={Stripe} alt="..." />
                </div>
                <div className="right">
                  <h6>{t("Stripe")}</h6>
                  <p>
                    {t("Accept_payments_inside_of_Facebook_Stripe")}
                  </p>
                </div>
              </div>
            </div>
            <div className="firstcard">
              <div className="cardContent">
                <div className="left">
                  <img src={Integromat} alt="..." />
                </div>
                <div className="right">
                  <h6>{t("Integromat")}</h6>
                  <p>
                    {t("hundreds_of_other_applications")}
                  </p>
                </div>
              </div>
            </div>
            <div className="firstcard">
              <div className="cardContent">
                <div className="left">
                  <img src={Klaviyo} alt="..." />
                </div>
                <div className="right">
                  <h6>{t("Klaviyo")}</h6>
                  <p>
                   {t("collect_DM_BOT_to_Klaviyo")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {location.pathname != "/intergration" ? <Mostdm /> : null}
      </div>
    </>
  );
};

export default Integrations;
