import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Terms.css";
import { useTranslation } from "react-i18next";
const TermsCondtion = ({ modalValue, setModalValue }) => {
  const handleClose = () => setModalValue(false);
  const { t } = useTranslation();
  // const handleShow = () => setModalValue(true);

  return (
    //

    <>
      <Modal
        className="TermModal"
        scrollable
        show={modalValue}
        onHide={handleClose}
      >
      
        <Modal.Header
          style={{
            background: "linear-gradient(#4ECCAA, #A1F7E2, #97F5E3, #7EDCC3)",
            
          }}
          closeButton
        >
          <Modal.Title className="m-auto">
            <h5 style={{
              color:'#4EAFCC !important'
            }}>{t("Terms_and_Condition")}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Body">
            <p>
              {t("Terms_of_Service_establish")}
            </p>
            <h6 style={{
              marginBottom:"4%"
            }}>
              {t("By_clicking_on_the_I_AGREE")}
            </h6>
            <p>
              {t("These_Terms_of_Service")}
            </p>
            <p>
              {t("If_you_accept_or_agree")}
            </p>
            <ol
              style={{
                listStyleType: "lower-roman",
              }}
            >
              <li>
                {t("you_have_full_legal")}
              </li>
              <li>{t("you_have_read_and_understand")}</li>
              <li>
                {t("you_agree_to_these_Terms_of_Service")}
              </li>
            </ol>
            <p>
              {t("These_comprehensive_Terms_of")}
            </p>
            <h5>1. {t("OUR_SERVICES")}</h5>
            <p>
               {t("DM_Bot_is_designed_to_offer")}
            </p>
            <h5>2. {t("ACCOUNT_PASSWORD_SECURITY")}</h5>
            <h6>2.1 {t("Account_Creation")}</h6>
            <p>
              {t("To_unlock_the_full_potential")}
            </p>
            <h6>2.2 {t("Security")}</h6>
            <p>
              {t("Ensuring_the_security_of_your")}
            </p>
            <h6>2.3 {t("Service_Communication")}</h6>
            <p>
             {t("By_providing_your_phone_number")}
            </p>
            <ol>
              <li>{t("performing_and_enhancing")}</li>
              <li>{t("facilitating_the_execution")}</li>
              <li>
                {t("providing_you_with_information")}
              </li>
            </ol>
            <p>
              {t("In_facilitating_seamless_communication")}
            </p>
            <h5>3. {t("Agencies_and_Business")}</h5>
            <p>
              {t("If_you_are_representing_an")}
            </p>
            <p>{t("As_an_agency_representing")}</p>
            <ol>
              <li>
                {t("agree_to_correctly_identify")}
              </li>
              <li>
                {t("confirm_you_are_authorized")}
              </li>
            </ol>
            <p>
              {t("The_business_owner_shall")}
            </p>
            <h5>4. {t("User_Generated_Content")}</h5>
            <p>
              {t("You_hold_complete_responsibility")}
            </p>
            <ol className="ListAlpha">
              <li>
                {t("Plays_no_role_in_the")}
              </li>
              <li>{t("Disavows_any_accountability")}</li>
              <li>
                {t("Cannot_be_held_responsible")}
              </li>
              <li>
                {t("Is_not_obliged_to_actively")}
              </li>
            </ol>
            <h6>4.1 {t("Ownership")}</h6>
            <p>
              {t("While_users_retain_ownership")}
            </p>
            <h6>4.2 {t("Prohibited_Content")}</h6>
            <p>
              {t("Users_are_expressly_prohibited")}
            </p>
            <p>
              {t("You_hereby_represent_and")}
            </p>
            <ol>
              <li>{t("will_not_be_false")}</li>
              <li>
                {t("will_not_infringe_on")}
              </li>
              <li>
                {t("will_not_violate_any")}
              </li>
              <li>
                {t("will_not_be_defamatory")}
              </li>
              <li>
                {t("will_not_be_obscene")}
              </li>
              <li>
                {t("will_not_contain_any_viruses")}
              </li>
            </ol>
            <h5>5. {t("Communication_by_Text")}</h5>
            <p>
              {t("To_communicate_with_your")}
            </p>
            <h5>6. {t("Communication_with_the")}</h5>
            <p>
              {t("DM_Bot_maintains_open")}
            </p>
            <p>
              {t("You_confirm_the_accuracy")}
            </p>
            <h5>7. {t("Prohibited_Uses")}</h5>
            <p>
              {t("In_addition_to_the_restrictions_outlined")}
            </p>
            <ul style={{
              listStyle:"circle",
              marginLeft:"8%"
            }}>
              <li>{t("Engaging_in_any")}</li>
              <li>{t("Soliciting_or_participating")}</li>
              <li>
                {t("Violating_international_federal")}
              </li>
              <li>
                {t("Infringing_upon_the_rights")}
              </li>
              <li>
                {t("Harassing_abusing_insulting")}
              </li>
              <li>{t("Providing_false")}</li>
              <li>{t("Uploading_or_transmitting")}</li>
              <li>{t("Using_the_services_for")}</li>
              <li>{t("Interfering_with_or")}</li>
              <li>
                {t("Threatening_the_performance")}
              </li>
              <li>
                {t("Attempting_to_access_unauthorized")}
              </li>
              <li>{t("Abusing_trial_or")}</li>
              <li>
                {t("Violating_the_policies_of")}
              </li>
              <li>
                {t("Copying_distributing_disclosing")}
              </li>
              <li>
                {t("Transmitting_spam_engaging")}
              </li>
              <li>
                {t("Impersonating_another_person")}
              </li>
              <li>
                {t("Encouraging_enabling_others")}
              </li>
            </ul>
            <p>
              {t("DM_Bot_reserves_the")}
            </p>
            <h5>8. {t("Representations_and_Warranties")}</h5>
            <p>
              {t("You_confirm_that_you")}
            </p>
            <p>
              {t("It_is_your_responsibility_to")}
            </p>
            <p>
              {t("You_declare_and_ensure")}
            </p>
            <p>
              {t("You_warrant_and_represent")}
            </p>
            <h5>9. {t("Billing_and_Payment")}</h5>
            <p>
              {t("To_pay_for_services")}
            </p>
            <p>
              {t("Charges_for_subscription_plans")}
            </p>
            <p>
              {t("Fee_adjustments_may_occur")}
            </p>
            <p>
              {t("If_you_utilize_payment_features")}
            </p>
            <p>
              {t("DM_Bot_retains_the_right")}
            </p>
            <h5>10. {t("Termination_Suspension")}</h5>
            <p>
              {t("Either_party_has_the")}
            </p>
            <p>
              {t("After_the_termination")}
            </p>
          </div>
          <h5>11. {t("Ownership_Intellectual")}</h5>
          <p>
            {t("All_text_graphics_editorial")}
          </p>
          <p>
            {t("Any_use_of_Proprietary")}
          </p>
          <h5>12. {t("Copyright_Complaints")}</h5>
          <p>
            {t("DM_Bot_takes_intellectual")}
          </p>
          <h5>13. {t("Confidential_Information")}</h5>
          <p>
            {t("The_term_Confidential_Information")}
          </p>
          <h5>14. {t("Disclaimer_Warranties")}</h5>
          <p>
            {t("The_services_are_provided")}
          </p>
          <h5>15. {t("Indemnification")}</h5>
          <p>
            {t("Users_commit_to_indemnifying")}
          </p>
          <h5>16. {t("Dispute_Resolution")}</h5>
          <p>
            {t("Dispute_resolution_through")}
          </p>
          <h5>17. {t("No_Agency")}</h5>
          <p>
            {t("This_section_emphasizes")}
          </p>
          <h5>18. {t("Changes_to_this")}</h5>
          <p>
            {t("DM_Bot_reserves_the_right")}
          </p>
          <h5>19. {t("No_Rights_of")}</h5>
          <p>
            {t("This_section_reinforces")}
          </p>
          <h5>20. {t("Notices_and_Consent")}</h5>
          <p>
            {t("You_agree_to_receive")}
          </p>
          <h5>21. {t("Contacting_Us")}</h5>
          <p>
            {t("For_any_inquiries_concerns")}
          </p>
          <h5 style={{
            textAlign:"center"
          }}>{t("Thank_you_for_entrusting")}</h5>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TermsCondtion;
