import React from 'react'
import "./autoassign.css"
import { useTranslation } from "react-i18next";
const Autoassign = () => {
  const { t } = useTranslation();
  return (
    <div className='autoasign'>
        <div className="tophead">
           <div> <h2>{t("Auto_Assignment")}</h2></div>
          <div>  <p>
          {t("Use_Auto_Assignment")} <span><a href="#">{t("Use_Auto_Assignment_learn_More")}</a></span>
            </p>
            </div>
        </div>
        <div className="botmcont">
            <div className='off'>
                  <div className='box'>  
                  <input type="checkbox" checked />
                  
                  </div>
                  <div className='rightcont'>
                    <h2>{t("Off")}</h2>
                    <p>
                    {t("Team_members_pick")}
                    </p>
                  </div>
            </div>
            <div className='basic'>
                  <div className='box'>  
                  <input type="checkbox"  />
                  
                  </div>
                  <div className='rightdiv'>
                    <h2>{t("Basic")}</h2>
                    <p>
                    {t("Distribute_conversations")}
                    </p>
                  </div>
            </div>
            <div className='advance'>
                  <div className='box'>  
                  <input type="checkbox"  />
                  
                  </div>
                  <div className='rightside'>
                    <h2>{t("Advanced")}</h2>
                    <p>
                    {t("Assign_conversations_to_Groups")}
                    </p>
                  </div>
            </div>
            
            
           

        </div>
      
    </div>
  )
}

export default Autoassign
