// import React from "react";
// import step1img from "../../assets/icons/step1.svg";
// import step2img from "../../assets/icons/step2.svg";
// import step3img from "../../assets/icons/step3.svg";
// import "../../style/_program.scss";
// import { useTranslation } from "react-i18next";

// const Card = () => {
//   const { t } = useTranslation();
//   return (
//     <div className="program-card">
//       <div className="programContent">
//         <div className="stepCard-title">
//           <h2 className="text-black ">{t("How_it_works")}</h2>
//         </div>
//         <div className="step-cards d-flex v-center h-center">
//           <div className="social-card-content mb-5">
//           <div style={{
//               height:"100%",
//               backgroundColor:"white"
//             }} className="content">
//               <div className="card-content">
//                 <div className="card-title">

//                   <span style={{
//                     color:"black"
//                   }}>{t("Sign_Up")}:</span>

//                 </div>
//                 <div className="card-img">
//                   <img src={step1img} alt="instagram" />
//                 </div>
//                 <div className="card-paragraph">
//                   <p style={{
//                     color:"black"
//                   }}>
//                   {t("Visit_the")}
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <div style={{
//               height:"100%",
//               backgroundColor:"white"
//             }} className="content">
//               <div className="card-content">
//                 <div className="card-title">

//                   <span style={{
//                     color:"black"
//                   }}>{t("Approval_Process")}:</span>

//                 </div>
//                 <div className="card-img">
//                   <img src={step1img} alt="instagram" />
//                 </div>
//                 <div className="card-paragraph">
//                   <p style={{
//                     color:"black"
//                   }}>
//                   {t("Submit_the_application")}
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div style={{
//               height:"100%",
//               backgroundColor:"white"
//             }} className="content">
//               <div className="card-content">
//                 <div className="card-title">

//                   <span style={{
//                     color:"black"
//                   }}>{t("Start_Promoting")}:</span>

//                 </div>
//                 <div className="card-img">
//                   <img src={step1img} alt="instagram" />
//                 </div>
//                 <div className="card-paragraph">
//                   <p style={{
//                     color:"black"
//                   }}>
//                   {t("Get_your_affiliate_link")}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="card-btn">
//           <button>{t("Get_Started_Free")}</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Card;


import React from "react";
import step1img from "../../assets/icons/step1.svg";
import step2img from "../../assets/icons/step2.svg";
import step3img from "../../assets/icons/step3.svg";
import "../../style/_program.scss";
import { useTranslation } from "react-i18next";

const Card = () => {
  const { t } = useTranslation();
  return (
    <div className="program-card">
      <div className="programContent">
        <div className="stepCard-title">
          <h2 className="text-black ">{t("How_it_works")}</h2>
        </div>
        <div className="step-cards d-flex v-center h-center">
          <div className="social-card-content mb-5">
            <div className="contents">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Sign_Up")}:</span>

                </div>
                <div className="card-img">
                  <img src={step1img} alt="instagram" />
                </div>
                <div className="card-paragraph">
                  <p>
                  {t("Visit_the")}
                  </p>
                </div>
              </div>
            </div>

            <div className="contents">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Approval_Process")}:</span>

                </div>
                <div className="card-img">
                  <img src={step2img} alt="instagram" />
                </div>
                <div className="card-paragraph">
                  <p>
                  {t("Submit_the_application")}
                  </p>
                </div>
              </div>
            </div>
            <div className="contents">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Start_Promoting")}:</span>

                </div>
                <div className="card-img">
                  <img src={step3img} alt="instagram" />
                </div>
                <div className="card-paragraph">
                  <p>
                  {t("Get_your_affiliate_link")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-btn">
          <button>{t("Get_Started_Free")}</button>
        </div>
      </div>
    </div>
  );
};

export default Card;