import React from "react";
import Banner from "../components/programs/banner";
import Testimonial from "../components/programs/testimonial";
import Footer from "../components/shared/footer";
import Freebot from "../components/resturant/freebot";
import Faq from "../components/programs/faq";
import Card from "../components/programs/card";

const Program = () => {
  return (
    <div>
      {" "}
      <Banner />
      <Testimonial />
      <Card />
      <Faq />
      <Freebot />
      <Footer />
    </div>
  );
};

export default Program;
