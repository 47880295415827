import React, { useState } from "react";
import "./smartDelay.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Icon } from "@iconify/react";
import Form from "react-bootstrap/Form";

const SmartDelay = ({ show, handleModalrandom }) => {
  const [selectedOptionid, setSelectedOptionid] = useState("");
  const handleSelectChangeid = (event) => {
    setSelectedOptionid(event.target.value);
  };
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsTyping(true);
  };
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked((prevIsChecked) => !prevIsChecked);
  };
  const [selectedValue, setSelectedValue] = useState(null);
  const handleCheckboxChanges = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
  };
  const [isBottomDivOpe2, setIsBottomDivOpe2] = useState(false);
  const handleClick2 = () => {
    setIsBottomDivOpe2(!isBottomDivOpe2);
  };
  const [selectedDatetime, setSelectedDatetime] = useState("");

  const handleDatetimeChange = (event) => {
    setSelectedDatetime(event.target.value);
  };
  return (
    <div>
      {" "}
      <Offcanvas show={show} onHide={handleModalrandom}>
        {/* <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header> */}
        <Offcanvas.Body className="smartdelay">
          <div className="smartDelay">
            <div className="smartdelay-content">
              <div className="delayTopbar">
                <h6>Smart Delay</h6>
              </div>
              <div className="middleContent">
                <div className="title">
                  <h6>Delay Type: </h6>
                  <div className="orderidcancel-head">
                    <select
                      value={selectedOptionid}
                      onChange={handleSelectChangeid}
                      className="selectoptionid"
                    >
                      <option value="">
                        <span>Duration</span>
                      </option>
                      <option value="option1">
                        {" "}
                        <span>Date</span>
                      </option>
                    </select>
                  </div>
                </div>
                <div className="idContent">
                  {selectedOptionid === "" && (
                    <div className="durationContent">
                      <div className="durationTitle">
                        <h6>
                          Delay duration{" "}
                          <Icon
                            icon="eva:question-mark-circle-outline"
                            color="#bcc3cc"
                            width="20"
                            height="20"
                          />
                        </h6>
                      </div>
                      <div className="durationInput">
                        <input
                          type="text"
                          className={isTyping ? "red-border" : ""}
                          value={inputValue}
                          onChange={handleInputChange}
                          placeholder="Enter number or formula"
                        />
                        <Form.Select
                          aria-label="Default select example"
                          className="formSelect"
                        >
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </div>
                      <div className="durationcheckbox">
                        <div className="boxContent">
                          <label className="checkboxs">
                            Set continue time limit
                            <input
                              type="checkbox"
                              className="checkbox"
                              onChange={handleCheckboxChange}
                              checked={isCheckboxChecked}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        {isCheckboxChecked && (
                          <div className="checkedContent">
                            <hr />
                            <h6 className="mt-3">Contine between</h6>
                            <div className="mainContent">
                              <Form.Select
                                aria-label="Default select example"
                                className="formSelects"
                              >
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </Form.Select>
                              <Form.Select
                                aria-label="Default select example"
                                className="formSelects"
                              >
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </Form.Select>
                            </div>
                            <div className="all-timeorderfirst">
                              <div
                                className="all-timesorder"
                                onClick={handleClick2}
                              >
                                <span className="alltime-text">Any Day</span>
                                <Icon
                                  icon="ep:arrow-down"
                                  color="rgba(0, 0, 0, 0.71)"
                                  width="12"
                                  height="12"
                                />
                              </div>
                              {isBottomDivOpe2 && (
                                <div className="bottom-divordera">
                                  <div className="div-weekdropdown2">
                                    <label>
                                      <input
                                        type="checkbox"
                                        value="1 week"
                                        checked={selectedValue === "1 week"}
                                        onChange={handleCheckboxChanges}
                                      />
                                      <span className="bottomdiv-week2">
                                        Monday
                                      </span>
                                    </label>
                                  </div>
                                  <div className="div-weekdropdown2">
                                    <label>
                                      <input
                                        type="checkbox"
                                        value="2 weeks"
                                        checked={selectedValue === "2 weeks"}
                                        onChange={handleCheckboxChanges}
                                      />
                                      <span className="bottomdiv-week2">
                                        Tuesday
                                      </span>
                                    </label>
                                  </div>
                                  <div className="div-weekdropdown2">
                                    <label>
                                      <input
                                        type="checkbox"
                                        value="wednesday"
                                        checked={selectedValue === "wednesday"}
                                        onChange={handleCheckboxChanges}
                                      />
                                      <span className="bottomdiv-week2">
                                        Wednesday
                                      </span>
                                    </label>
                                  </div>
                                  <div className="div-weekdropdown2">
                                    <label>
                                      <input
                                        type="checkbox"
                                        value="thursday"
                                        checked={selectedValue === "thursday"}
                                        onChange={handleCheckboxChanges}
                                      />
                                      <span className="bottomdiv-week2">
                                        Thursday
                                      </span>
                                    </label>
                                  </div>
                                  <div className="div-weekdropdown2">
                                    <label>
                                      <input
                                        type="checkbox"
                                        value="friday"
                                        checked={selectedValue === "friday"}
                                        onChange={handleCheckboxChanges}
                                      />
                                      <span className="bottomdiv-week2">
                                        Friday
                                      </span>
                                    </label>
                                  </div>
                                  <div className="div-weekdropdown2">
                                    <label>
                                      <input
                                        type="checkbox"
                                        value="saturday"
                                        checked={selectedValue === "saturday"}
                                        onChange={handleCheckboxChanges}
                                      />
                                      <span className="bottomdiv-week2">
                                        Saturday
                                      </span>
                                    </label>
                                  </div>
                                  <div className="div-weekdropdown2">
                                    <label>
                                      <input
                                        type="checkbox"
                                        value="sunday"
                                        checked={selectedValue === "sunday"}
                                        onChange={handleCheckboxChanges}
                                      />
                                      <span className="bottomdiv-week2">
                                        Sunday
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                            <span>
                              Wait at least 1 Minute and then continue at <br />
                              4:00-22:00
                            </span>
                          </div>
                        )}
                        <hr />
                        <div className="nextButton mt-2">
                          <button>Choose Next Step</button>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedOptionid === "option1" && (
                    <div className="dateContent">
                      <div className="upperContent">
                        <div>
                          <input
                            placeholder="Select Date and Time"
                            className="textboxDate"
                            type="datetime-local"
                            id="datetime"
                            value={selectedDatetime}
                            onChange={handleDatetimeChange}
                          />
                          {/* <p> {selectedDatetime}</p> */}
                        </div>
                      </div>
                      <button>Choose Next Step</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SmartDelay;
