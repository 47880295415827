import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import "./chatbot.css";

const Chatbot = () => {
  const theme = {
    background: "#fafcfd",
    fontFamily: "serif",
    headerBgColor: "#00d3b9",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#00d3b9",
    botFontColor: "#fff",
    userBubbleColor: "#fff",
    userFontColor: "#4a4a4a",
  };

  const steps = [
    {
      id: "1",
      // message: "Welcome to DM BOT. \n How can we  assist  you today?",
      component: (
        <div>
          <h6 style={{ color: "#00d3b9" }}>WELCOME TO DM BOT</h6>
          <h6 style={{ color: "#00d3b9" }}>How can we assist you today?</h6>
        </div>
      ),
      trigger: "2",
    },
    {
      id: "2",
      options: [
        {
          value: 1,
          label: "Q: any tip for effective chat marketing?",
          trigger: "3",
        },
        {
          value: 2,
          label: "Q: Which platforms supports DM BOT?",
          trigger: "4",
        },
        {
          value: 3,
          label: "Q: automation for different platforms?",
          trigger: "5",
        },
        {
          value: 4,
          label: "Q: response time for automation?",
          trigger: "6",
        },

        {
          value: 5,
          label: "Q: More about templates for my brand?",
          trigger: "7",
        },

        {
          value: 5,
          label: "Q: Collection of my data by DM Bot?",
          trigger: "8",
        },
      ],
    },
    {
      id: "3",
      message:
        "A: Personalize your messages and templates to reflect your brand identity, use emojis, and schedule strategically covering every aspect of your product and services to boost engagement.",
      trigger: "9",
    },
    {
      id: "4",
      message:
        "A: DM BOT supports Instagram, Messenger, Naver, and Takao Talk for versatile marketing and reaching potential customers at different niches.",
      trigger: "9",
    },
    {
      id: "5",
      message:
        "A: Yes, each platform has tailored automation to enhance user interaction while preserving brand identity. Each platform is integrated in a manner for a native, smoother user experience.",
      trigger: "9",
    },
    {
      id: "6",
      message:
        "A: Responses are instant and available 24/7. With the right prompts, DM BOT can even complete full orders seamlessly enabling businesses to maximize on sale leads.",
      trigger: "9",
    },
    {
      id: "7",
      message:
        "A: Absolutely! Create unique templates to align with your brand and connect more effectively with your audience. Offer them various options and integrate products, services and support to meet every demand of the customer on the go.",
      trigger: "9",
    },
    {
      id: "8",
      message:
        "A: Your data is gathered to target customers effectively, increasing lead generation and effectively executing customer queries while ensuring the utmost security and privacy.",
      trigger: "9",
    },
    {
      id: "9",
      message: "want to ask another question?",
      trigger: "10",
    },
    {
      id: "10",
      options: [
        {
          value: 1,
          label: "yes",
          trigger: "2",
        },
      ],
    },
  ];
  return (
    <div className="chatbot-main">
      <ThemeProvider theme={theme}>
        <ChatBot
          headerTitle={"DM BOT Cutomer Service"}
          enableSmoothScroll={true}
          steps={steps}
          floating={true}
          hideSubmitButton={true}
          placeholder={""}
          footerStyle={{ backgroundColor: "#f5f8fb" }}
          customStyle={{ backgroundColor: "#f5f8fb", color: "red" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default Chatbot;
