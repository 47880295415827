import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./page/home";
import { useState } from "react";

// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "./components/shared/navbar";
import HireAgencies from "./page/hireAgencies";
import Tiktok from "./page/tiktok";
import Messanger from "./page/messanger";
import Sms from "./page/sms";
import Price from "./page/price";
import Integration from "./page/integration";
import CtxAds from "./page/ctxAds";
// import Resturant from "./page/resturant";
import Naver from "./page/naver";
import Agencies from "./page/agencies";
import Blog from "./page/blog";
import Program from "./page/program";
import Guide from "./page/guide";
import Ecommerce from "./page/ecommerce";
import Webnar from "./page/webnar";
import Template from "./page/template";
import Creaters from "./page/creaters";
import Help from "./components/Help/Help";
import MyDashboard from "./components/MyDashBoard/MyDashboard";
import Integrations from "./components/integratn/integrations";
import Blogdetail from "../src/components/blogdetail/blogdetail";
import Introinsta from "./components/introinsta/introinsta";
import Introdm from "./components/introdm/introdm";
import HBlog from "./components/hBlog/hBlog";
import IntroMessenger from "./components/IntroMessenger/IntroMessenger";
import EBook from "./components/EBook/EBook";
import EBookExample from "./components/EBookExample/EBookExample";
import Partner from "./components/blog/partner";
import Messenger from "./components/blog/messenger";
import Case from "./components/blog/case";
import Material from "./components/blog/material";
import Press from "./components/blog/press";
import Update from "./components/blog/update";
import Signup from "./components/Signup/Signup";
import Test from "./components/Test/Test";
import Login from "./components/Login/Login";
import { useLocation } from "react-router-dom";
import FlowMessenger from "./components/MyDashBoard/DashboardComponents/Automation/Components/Flow/flowMessenger";
import Facebook from "./page/facebook";
import Scratch from "./components/MyDashBoard/DashboardComponents/Automation/Components/Flow/Flow";
import { FacebookProvider } from "react-facebook";
import AddButtonModal from "./components/MyDashBoard/DashboardComponents/Automation/Components/Flow/addButtonModal";
import Profile from "./components/MyDashBoard/DashboardComponents/Profile/Profile";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { getMyData, getUserData } from "./config/facebookApis";
import Newlogin from "./components/newLogin/newlogin";
import LanguageSwitch from "./i18n/languageSwitch";
import TermsCondtion from "./components/shared/TermsCondtion";
import Chatbot from "./components/chatbot/chatbot";
import Protected from "./components/proctectedRoute/protected";

// import AOS from "aos";
function App() {
  // AOS.init();
  const location = useLocation();
  const routesWithoutNavbar = [
    "/dashboard",
    "/facebook",
    "/scratch",
    "/Newlogin",
  ];

  // const [chatOpen, setChatOpen] = useState(false);

  // const toggleChat = () => {
  //   setChatOpen(!chatOpen);
  // };

  // const shouldHideNavbar = routesWithoutNavbar.includes(location.pathname);

  const navigate = useNavigate();
  // const routesWithoutNavbar = ["/dashboard", "/facebook"];
  // const shouldHideNavbar = routesWithoutNavbar.includes(location.pathname);
  // const routesWithoutNavbar = ["dashboard", "facebook"]; // Specify the route parts that should hide the navbar
  // Get the current pathname from the location object
  const currentPathname = location.pathname;
  // Check if the current pathname contains any of the route parts
  const shouldHideNavbar = routesWithoutNavbar.some((routePart) =>
    currentPathname.includes(routePart)
  );
  useEffect(() => {
    const userData = secureLocalStorage.getItem("user");
    if (userData) {
      getMyData(userData);
    }

    const lanSwitch = secureLocalStorage.getItem("languageSwitch");
    if (lanSwitch) {
      localStorage.setItem("languageSwitch", lanSwitch);
    } else {
      localStorage.setItem("languageSwitch", "en");
    }

    console.log("lanSwicth", lanSwitch);

    // console.log("lan app:", secureLocalStorage.getItem())
  }, []);
  // Now, shouldHideNavbar will be true if the current pathname contains any of the specified route parts
  useEffect(() => {
    const page = secureLocalStorage.getItem("currentPageProfile");
    if (page) {
      page.id.length == 15
        ? navigate(`/fb${page.id}/dashboard`)
        : navigate(`/insta${page.id}/dashboard`);
    }
  }, []);
  return (
    <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_APP_ID} version={process.env.REACT_APP_LATEST_API_VERSION}>
      {/* <LanguageSwitch /> */}

      <div className="App">
        <Chatbot />

        {!shouldHideNavbar && <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/hireagencies" element={<HireAgencies />} /> */}
          <Route path="/tiktok" element={<Tiktok />} />
          <Route path="/sms" element={<Sms />} />
          <Route path="/pricing" element={<Price />} />
          <Route path="/messanger" element={<Messanger />} />
          <Route path="/naver" element={<Naver />} />
          <Route path="/intergration" element={<Integration />} />
          <Route path="/agencies" element={<Agencies />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/program" element={<Program />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/Webnar" element={<Webnar />} />
          <Route path="/template" element={<Template />} />
          {/* <Route path="/creater" element={<Creaters />} /> */}
          <Route path="/help" element={<Help />} />
          <Route path="/:id/dashboard" element={<MyDashboard />} />
          <Route path="/CtxAds" element={<CtxAds />} />
          {/* <Route path="/resturant" element={<Resturant />} /> */}
          <Route path="/Blogdetail" element={<Blogdetail />} />
          <Route path="/Introinsta" element={<Introinsta />} />
          <Route path="/Introdm" element={<Introdm />} />
          <Route path="/HBlog" element={<HBlog />} />
          <Route path="/IntroMessenger" element={<IntroMessenger />} />
          <Route path="/EBook" element={<EBook />} />
          <Route path="/EBookExample" element={<EBookExample />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/messenger" element={<Messenger />} />
          <Route path="/case" element={<Case />} />
          <Route path="/material" element={<Material />} />
          <Route path="/press" element={<Press />} />
          <Route path="/update" element={<Update />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Test" element={<Test />} />
          <Route path="/flowMessenger" element={<FlowMessenger />} />
          <Route path="/facebook" element={<Facebook />} />
          <Route path="/scratch/:id" element={<Scratch />} />
          <Route path="/modal" element={<AddButtonModal />} />
          <Route path="/Newlogin" element={<Newlogin />} />
          <Route
            path="/dashboard"
            element={<Protected Component={MyDashboard} />}
          />
          <Route path=""></Route>
          {/* <Route path="/profile/dashboard" element={<Profile />} /> */}
          <Route
            path="/profile/dashboard"
            element={<Protected Component={Profile} />}
          />

          <Route path="/termscondition" element={<TermsCondtion />} />
        </Routes>
      </div>
    </FacebookProvider>
  );
}

export default App;
