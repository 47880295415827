import React from "react";
import "./EBook.css";
import Freebotdm from "../home/freebotdm";
import Footer from "../shared/footer";
import { useTranslation } from "react-i18next";
function EBook() {
  const { t } = useTranslation();
  const Data = [
    {
      title: `${t("Ultimate_Chat")}`,
      details:`${t("Learn_the_who")}`
    },
    {
      title: `${t("Master_Lead")}`,
      details:`${t("This_will_discuss")}`
    },{
      title: `${t("How_to_Grow")}`,
      details:`${t("Go_from_0")}`
    },{
      title: `${t("SMS_Guide")}`,
      details:`${t("Learn_the_best")}`
    },{
      title: `${t("Other Guide")}`,
      details:`${t("for_guide_marketing")}`
    },
  ];
  return (
    <div className="ebookMainDiv">
      <h1 className="headingMain">{t("Download_dm")}</h1>

      <p className="headingSub">
      {t("looking_to_expand")} <br className="hide-mob" />{t("things_DM_BOT")}
      </p>

      <div className="firstMainDiv">
        {Data.map((item) => (
          <div className="cardDiv">
            <h4 className="cardHeading">{item.title}</h4>
            <p className="cardText">{item.details}</p>
            <div className="cardButton">
              <p className="cardButtonText">{t("Download_Free")}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="try">
        <Freebotdm />
      </div>
      <div className="log">
        <Footer />
      </div>
    </div>
  );
}

export default EBook;
