import React from "react";
import "../../style/main.scss";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
const Faq = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="faq">
        <h1 className="light-purple v-center h-center">{t("FAQ")}</h1>
        <div className="accor">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t("How_can_I")}
              </Accordion.Header>
              <Accordion.Body>
                {t("To_begin_ensure")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {t("Can_DM_Bot_be")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Yes_DM_Bot_seamlessly")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {t("Can_I_customize")}
              </Accordion.Header>
              <Accordion.Body>
               {t("Absolutely_DM_Bot_is")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t("Does_DM_Bot_support")}
              </Accordion.Header>
              <Accordion.Body>
               {t("Yes_DM_Bot_allows")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
               {t("How_secure_is_user_data")}
              </Accordion.Header>
              <Accordion.Body>
                {t("DM_Bot_prioritizes")}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
