import React, { useState } from "react";
// import AdminAccountTab1 from "./AdminAccountTab1";
// import AdminAccountTab2 from "./AdminAccountTab2";
import "./log.css";
import Faceboktab from "./logs/faceboktab"
import Generaltab from "./logs/generaltab"
import { useTranslation } from "react-i18next";
const Log = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <div>
      <div className="teamMember">
        <div className="teamMember-content">
          <div className="main-profile">
            <div className="main-primary">
              {/* <div className="profile-heading">
                <span className="SellerProfile">Seller Profile</span>
              </div> */}
              <div className="tabs-content">
                <div className="tabs-container">
                  <div className="tabs mb-2">
                    <a
                      className={`tab ${activeTab === 0 ? "active" : ""} `}
                      onClick={() => handleTabClick(0)}
                    >
                      {t("General")}
                    </a>
                    <a
                      className={`tab ${activeTab === 1 ? "active" : ""}`}
                      onClick={() => handleTabClick(1)}
                    >
                     {t("Facebook_page_restrictions")}
                    </a>
                  </div>
                  <div className="tab-content">
                    <div
                      className={`tab-pane ${activeTab === 0 ? "active" : ""}`}
                    >
                      <Faceboktab />
                    </div>
                    <div
                      className={`tab-pane ${activeTab === 1 ? "active" : ""}`}
                    >
                      <Generaltab />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Log;
