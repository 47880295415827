import React from "react";
import "../../style/_webnar.scss";
import { useTranslation } from "react-i18next";
const Courses = () => {
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <div className="webnar-courses">
        <div className="courses-guide">
          <div className="courses">
            <div className="title bg-black">
              <h6 className="text-white">{t("DM_BOT_Free_Video_Course")}</h6>
            </div>
            <div className="courses-contentweb">
              <div className="left  ">
                <h6>
                  {t("dm_bot_free")} <br />
                  {t("video_cousre")}
                </h6>
                <p>{t("Intro,_Use_Cases")}</p>
              </div>
              <div className="right">
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  //   poster="\assets\images\hero\banner.jpg"
                >
                  <source
                    src="../../../assets/split-slider_drive-sales.webm"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Courses;