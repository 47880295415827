import React from "react";
import "../../style/_guides.scss";
import { useTranslation } from "react-i18next";
const Usebot = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="usebot">
        <div className="usebot-content">
          <h6>{t("How_to_Use_DM")}</h6>
          <p>{t("Find_answers_to_all")}</p>
        </div>
      </div>
    </div>
  );
};
export default Usebot;