// import AWS from 'aws-sdk';
import axios from "axios";
import { toast } from "react-toastify";

/**
 *
 **AWS Function to upload json file to aws s3 buckets
 *
 */
export const UploadJsonToS3 = async (JSONBody) => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${process.env.REACT_APP_SERVER_URL}/aws/uploadJson`;
      axios
        .post(url, {
          JSONBody: JSON.stringify(JSONBody),
        })
        .then((response) => {
          if (response.data.success == true) {
            resolve({ success: true, awsUrl: response.data.awsUrl });
          } else {
            reject({ success: false, error: response.data.error });
          }
        })
        .catch((err) => {
          console.log(err);
          reject({ success: false, error: err });
          toast.error(`Error! Please try again`, { toastId: "awsError" });
        });
    } catch (error) {
      console.log(error);
      reject({ success: false, error });
      toast.error(`Error! Please try again`, { toastId: "awsError" });
    }
  });
};

/**
 *
 **AWS Function to upload image/video/audio file to AWS S3 buckets
 *
 */
export const UploadFilesToS3 = async (file, fileFolderPath) => {
  // const convertedFile = await convertToBase64(file);
  return new Promise(async (resolve, reject) => {
    try {
      let url = `${process.env.REACT_APP_SERVER_URL}/aws/uploadFiles`;
      const formData = new FormData();
      formData.append("file", file); // selectedFile is the file to be uploaded
      // Add other form data if needed

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          // Handle response
          if (response.data.success == true) {
            resolve({
              success: true,
              thumbnail_url: response.data.thumbnail_url,
            });
          } else {
            reject({ success: false, error: response.data.error });
          }
        })
        .catch((err) => {
          // Handle error
          console.log(err);
          reject({ success: false, error: err });

          toast.error(`Error! Please try again`, { toastId: "awsError" });
        });
      // axios
      //   .post(url, {
      //     file: convertedFile,
      //     fileFolderPath: fileFolderPath,
      //     fileName: file.name,
      //     fileType: file.type,
      //   })
      //   .then((response) => {
      //     if (response.data.success == true) {
      //       resolve({
      //         success: true,
      //         thumbnail_url: response.data.thumbnail_url,
      //       });
      //     } else {
      //       reject({ success: false, error: response.data.error });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     reject({ success: false, error: err });

      //     toast.error(`Error! Please try again`, { toastId: "awsError" });
      //   });
    } catch (error) {
      console.log(error);
      reject({ success: false, error });
      toast.error(`Error! Please try again`, { toastId: "awsError" });
    }
  });
};

//Converting Files to base64
const convertToBase64 = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};
