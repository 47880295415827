import React from "react";
import "../../style/main.scss";
import Button from "../../components/shared/button";
import restuarant from "../../assets/images/tiktok/restuarant.png";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Drive = ({ data }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const isRestaurantRoute = location.pathname === "/resturant";

  return (
    <div>
      <div className="drive ">
        <div className="head flex-column v-center h-center">
          <h3 className="medium ">{data}</h3>
          {isRestaurantRoute ? null : (
            <>
              <Button
                text={t("Get_Started_Free")}
                width="244px "
                height="51px"
                className="btn-secondary mt-5 "
                onClick={() => alert("Hello!")}
              />
              <p className="large1 mt-3">
                {t("Amplify_every_action")}
              </p>
            </>
          )}
          {/* <Button
            text="Get Started For Free"
            width="244px "
            height="51px"
            className="btn-secondary mt-5 "
            onClick={() => alert("Hello!")}
          />
          <p className="large1 mt-3">
            Amplify every action using IG DM Marketing
          </p> */}
        </div>
        <div className="bottom d-flex">
          <div className="left">
            <div className="top mt-4">
              <h4 className="small">{t("Attract_New_Leads")}</h4>
              <p className="medium text-black">
              {t("Combine_DM_BOT__Facebook_Ad")}
              </p>
            </div>
            <div className="bot d-flex flex-column">
              <h4 className="small">{t("Re_engage")}</h4>
              <h4 className="small">{t("Collect_Feedback_Reviews")}</h4>
              <h4 className="small">{t("Create_Loyalty")}</h4>
              <h4 className="small">{t("Order_Online")}</h4>
              <h4 className="small">{t("Book_Table")}</h4>
            </div>
          </div>
          <div className="right">
            <img src={restuarant} alt="restuarant-img" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drive;
