import React from "react";
import "../../style/main.scss";
import supercharge from "../../assets/images/homepage/supercharge.png";
import superchargeKor from "../../assets/images/homepage/supercharge.png";
import increase from "../../assets/images/homepage/increase.png";
import increaseKor from "../../assets/images/homepage/increase.png";
import automatically from "../../assets/images/homepage/automatically.png";
import automaticallyKor from "../../assets/images/homepage/automatically.png";
import claim from "../../assets/images/homepage/claim.png";
import claimKor from "../../assets/images/homepage/claim.png";
import notech from "../../assets/images/homepage/notech.png";
import notechKor from "../../assets/images/homepage/notech.png";
import sure from "../../assets/images/homepage/sure.png";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";

const UseChat = (props) => {
  const { t } = useTranslation();
  const {i18n} = useTranslation()
  return (
    <div>
      <div className="usechat  d-flex v-center h-center flex-column">
        <div className="usechat-title">
          <h1 className="text-center">{props.head}</h1>
        </div>
        <div className="usechat-content ist d-flex v-center mt-5">
          <div className="left">
            <div className="image-container">
              <img src={ i18n.language == 'en'  ? supercharge  :superchargeKor} alt="supercharge" className="img-fluid" />
              <div className="overlay">
                <div className="overlayContent">
                  <h4>
                    {t("Supercharge_your_lead")} <br /> 
                  </h4>
                  <p>{t("Attract_and_engage")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="image-container">
              <img src={ i18n.language == 'en' ? increase :increaseKor} alt="increase" className="img-fluid" />
              <div className="overlay">
                <div className="overlayContent">
                  <h4>{t("Increase_conversion_rates")}</h4>
                  <p>{t("With_DM_BOT")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="usechat-content second d-flex v-center mt-5">
          {/* <div className="left">
            <img src={claim} alt="claim" className="img-fluid" />
          </div>
           */}
           <div className="left">
            <div className="image-container">
              <img src={i18n.language == 'en' ? claim :automaticallyKor} alt="claim" className="img-fluid" />
              <div className="overlay">
                <div className="overlayContent">
                  <h4>
                    {t("Take_back_control")} <br /> 
                  </h4>
                  <p>{t("Automate_responses_to")}</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right">
            <img src={automatically} alt="increase" className="img-fluid" />
          </div> */}
          <div className="right">
            <div className="image-container">
              <img src={i18n.language == 'en' ? automatically : claimKor} alt="automatically" className="img-fluid" />
              <div className="overlay">
                <div className="overlayContent">
                  <h4>{t("No_tech_expertise")}</h4>
                  <p>{t("Utilize_our_Assistant")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="usechat-content ist d-flex v-center mt-5">
          {/* <div className="left">
            <img src={notech} alt="automatically" className="img-fluid" />
          </div> */}
          <div className="left">
            <div className="image-container">
              <img src={i18n.language == 'en' ? notech :notechKor} alt="notech" className="img-fluid" />
              <div className="overlay">
                <div className="overlayContent">
                  <h4>
                    {t("Automate_responses_for")} <br /> 
                  </h4>
                  <p>{t("Immediately_respond_to_each")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            {/* <img src={sure} alt="sure" className="img-fluid" /> */}
            <h1 className="text-center">{t("ENSURING_A_SMOOTH")}</h1>
          </div>
        </div>

        <div className="free-btn d-flex h-center ">
          <Button
            text={t("Get_Started_For_Free")}
            width="194px "
            height="51px"
            className={`btn-primary  ${props.hide} `}
            onClick={() => alert("Hello!")}
          />
        </div>
      </div>
    </div>
  );
};

export default UseChat;
