import React from 'react'
import "./outside.css"
import { useTranslation } from "react-i18next";
const Outside = () => {
  const { t } = useTranslation();
  return (
    <div className='outside'>
        <div className="cont">
      <h2>{t("Think_outside")}</h2>
      <p>{t("Create_powerful_Email")}<span><a href="">{t("Use_Auto_Assignment_learn_More")}</a></span></p>
      <div className='btn'>
        <button>{t("Connect")}</button>
        </div>
      </div>
    </div>
  )
}

export default Outside
