import React from "react";
import "../../style/main.scss";
import { useTranslation } from "react-i18next";

const Discover = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="discover">

        <h2 className="text-white">{t("Discover_why_1M")}</h2>
        <div className="discover-content  mt-5">
          <div className="content flex-column v-center ">
            <h3 className="text-white">{t("1M")}</h3>
            <h4 className="text-white"></h4>
            <p className="body-large text-white">
              {t("chose_DM_BOT_to_grow")}{" "}
            </p>
          </div>
          <div className="content flex-column v-center">
            <h3 className="text-white">48M+</h3>
            <h4 className="text-white"></h4>
            <p className="body-large text-white">{t("powered_by_DM-BOT")} </p>
          </div>
          <div className="content flex-column v-center">
            <h3 className="text-white">{t("170M+")}</h3>
            <h4 className="text-white">{t("Countries")}</h4>
            <p className="body-large text-white">{t("use_DM_BOT")}</p>

          </div>
          <div className="content flex-column v-center">
            <h3 className="text-white">#1</h3>
            <h4 className="text-white">{t("Businesses")}</h4>

            <p className="body-large text-white">{t("use_DM_BOT")}</p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;
