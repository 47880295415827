import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import naver from "../../assets/images/naver/naver.png";
import naverKor from "../../assets/images/naver/naver.png";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  const {i18n} = useTranslation();
  return (
    <div>
      <div className="naver-banner d-flex v-center">
        <div className="left ">
          <h1>{t("Connect_Customers_on_Naver")}</h1>
          <h4 className="medium mt-4">
            {t("Automate_your_sales_and_support")}
          </h4>
          <Button
            text={t("Get_Started_Free")}
            width="244px "
            height="51px"
            className="btn-primary mt-5 "
            onClick={() => alert("Hello!")}
          />
        </div>
        <div className="right">
          <img
            src={i18n.language == 'en' ? naver : naverKor}
            alt="naver-banner"
            className="facebook-img img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
