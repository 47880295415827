import React from "react";
import "./introdm.css";
import chatbot from "../../assets/images/chatbot.png";
import line from "../../assets/images/line.png";
import background from "../../assets/images/background.png";
import Freebot from "../ctxads/freebot";
import Footer from "../shared/footer";
const Introdm = () => {
  return (
    <div>
      <div className="head">
        <h2>How to Navigate Through</h2> <h2>DM BOT</h2>
      </div>
      <div className="parent">
        <div className="top-dm">
          <div className="left">
            <img src={chatbot} alt="..." />
            <p>HOW TO NAVIGATE THROUNGH DM BOT</p>
            <img src={line} alt="" />
          </div>
          <div className="right">
            <ul>
              <li>
                {" "}
                <a href="#"> How to Navigate Through DM BOT</a>
              </li>
              <li>
                <a href="#"> How to connect DM BOT to your Facebook Page</a>
              </li>
              <li>
                <a href="#">Understanding the DM BOT Dashboard</a>
              </li>
              <li>
                {" "}
                <a href="#">
                  Welcome Message, Greeting Text, and Conversation Starters
                </a>
              </li>
              <li>
                <a href="#">Flow Builder Basics</a>
              </li>
              <li>
                <a href="#">Flow Builder Breakdown</a>
              </li>
              <li>
                <a href="#">Starting Step</a>
              </li>
              <li>
                <a href="#">Growth Tools - Ref URL</a>
              </li>
              <li>
                <a href="#">Analytics (Setup and Use)</a>
              </li>
              <li>
                <a href="#">Audience Tab</a>
              </li>
              <li>
                {" "}
                <a href="#">Importing Contacts</a>
              </li>
              <li>
                {" "}
                <a href="#">Exporting Contacts </a>
              </li>
              <li>
                {" "}
                <a href="#">Keywords </a>
              </li>
              <li>
                {" "}
                <a href="#">Setting up Messenger Main Menu</a>
              </li>
              <li>
                {" "}
                <a href="#">How to Navigate Through DM BOT Conclusion </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="res">
          <div className="top-dm-para">
            <p>
              Learning a new tool can sometimes be overwhelming. We don’t want
              that to be the case, here at DM BOT. How to Navigate Through DM
              BOT will give you the DM BOT fundamentals to get your bot running
              smoothly and comfortable with your dashboard. Once you learn the
              basics, you’ll be able to connect the dots more easily with other
              features.
            </p>
            <p>In this module you’ll learn:</p>
            <ul>
              <li>The DM BOT dashboard</li>
              <li>How to connect your Facebook page to DM BOT</li>
              <li>Creating your first flow</li>
              <li>What the Growth Tool feature does and how to use it</li>
              <li>Importing and exporting contacts</li>
              <li>The importance of keywords</li>
              <li>Using templates</li>
            </ul>
            <p>
              DM BOT Course Expert: Natasha Takahashi, Co-Founder of School of
              Bots
            </p>
          </div>
          <div className="bot-para">
            <h2>About Natasha</h2>
            <p>
              Natasha Takahashi is the chief marketing officer and co-founder of
              School of Bots, the trusted chatbot resource for marketers and
              entrepreneurs. Natasha has spoken on more than 70 stages and
              podcasts across 7+ countries about bots alongside influencers like
              Richard Branson, Randi Zuckerberg, and Gary Vaynerchuk.
            </p>
            <div className="connect">
              <a href="#">>> How to connect DM BOT to your Facebook Page >>></a>
            </div>
          </div>
        </div>
      </div>
      <Freebot />
      <Footer />
    </div>
  );
};

export default Introdm;
