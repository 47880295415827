import React from "react";
import "../../../style/_main.scss";
import { useTranslation } from "react-i18next";
const Notification = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="Notification">
        <div className="notification-content">
          <div className="topSection">
            <div className="leftSide">
              <div className="title">
                <p>{t("Notify_Assignees")}</p>
              </div>
            </div>
            <div className="middleside">
              <span></span>
              <div className="middleFirst">
                {" "}
                <div className="maincheckbox d-flex">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                    {t("Messenger")}
                  </label>
                </div>
                <div className="maincheckbox d-flex">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                    {t("SMS")}
                  </label>
                </div>
                <div className="maincheckbox d-flex">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                    {t("Email")}
                  </label>
                </div>
                <div className="maincheckbox">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                    {t("Mobile_push_notification")}
                  </label>
                </div>
                <div className="maincheckbox">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                    {t("Telegram")}
                  </label>
                </div>
              </div>
            </div>
            <div className="rightSide">
              <p>
            {t("DM_BOT_send_you_notifications")}
              </p>
              <p>
                {t("Download_DM_BOT_application")}
              </p>
            </div>
          </div>
          <div className="topSection">
            <div className="leftSide">
              <div className="title">
                <p>{t("Desktop_Notifications")}</p>
              </div>
            </div>
            <div className="middleside">
              <div className="middleFirst">
                <span>{t("Notify_me_when")}</span>{" "}
                <div className="maincheckbox">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">{t("new_message")}                  </label>
                </div>
                <div className="maincheckbox">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">{t("new_conversation_in_unassigned")}                  </label>
                </div>
                <div className="maincheckbox">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                    {t("conversation_assigned_to_me")}
                  </label>
                </div>
              </div>
            </div>
            <div className="rightSide">
              <p>
                {t("Enable_instant_popup_notifications")}
              </p>
            </div>
          </div>
          <div className="topSection">
            <div className="leftSide">
              <div className="title">
                <p>{t("Channel_Notifications")}</p>
              </div>
            </div>
            <div className="middleside">
              <div className="middleFirst">
                <span>{t("Notify_me_when")}</span>{" "}
                <div className="maincheckbox">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                  {t("conversation_assigned_to_me")}
                  </label>
                </div>
              </div>
            </div>
            <div className="rightSide">
              <p>
              {t("Enable_instant_popup_notifications")}
              </p>
            </div>
          </div>
          <div className="topSectionsecond">
            <div className="leftSide">
              <div className="title">
                <p>{t("Messenger_Notifications")}</p>
              </div>
            </div>
            <div className="middleside">
              <div className="middleFirst">
                <button>{t("Subscribe")}</button>
              </div>
            </div>
            <div className="rightSide">
              <p>
                {t("Enable_instant_popup_notifications")}
              </p>
            </div>
          </div>
          <div className="topSectionsecond">
            <div className="leftSide">
              <div className="title">
                <p>{t("Telegram_Notifications")}</p>
              </div>
            </div>
            <div className="middleside">
              <div className="middleFirst">
                <button>{t("Subscribe_Telegram")}</button>
              </div>
            </div>
            <div className="rightSide">
              <p>
               {t("receive_bot_notifications_in_Telegram")}
              </p>
            </div>
          </div>
          <div className="topSectionsecond">
            <div className="leftSide">
              <div className="title">
                <p>{t("Telegram_Notifications")}</p>
              </div>
            </div>
            <div className="middleside">
              <div className="middleFirst">
                <input type="email" placeholder={t("Enter_email")}/>
                <button>{t("Update_btn")}</button>
              </div>
            </div>
            <div className="rightSide">
              <p>
                {/* Opt-in to our bot to be able to receive bot notifications in
                Telegram */}
              </p>
            </div>
          </div>
          <div className="topSectionsecond last">
            <div className="leftSide">
              <div className="title">
                <p>{t("Telegram_Notifications")}</p>
              </div>
            </div>
            <div className="middleside">
              <div className="middleFirst">
                <input type="email" placeholder={t("Enter_email")} />
                <button>{t("Save_btn")}</button>
                <div className="maincheckbox">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                    {t("phone_number_is_owned")}
                  </label>
                </div>
              </div>
            </div>
            <div className="rightSide">
              <p>
                {t("Add_your_phone_number")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
