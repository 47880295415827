import React from 'react'
import "./getapi.css"
import { useTranslation } from "react-i18next";
const Getapi = () => {
  const { t } = useTranslation();
  return (
    <div className='getapi'>
      <div className="apicont">
       <div className="head">
            <h2>{t("API_Key")}</h2>
       </div>
       <div className="yourapi">
        <input type="text" placeholder={t("Your_API_Key")} />
       </div>
       <div className="apidesc">
        <p>{t("DM_BOT_API_Pro_feature")}</p>
       </div>
      </div>
      <div className='btnmain'>
      <div className="apibtn">
        <button>{t("Generate_API_Key")}</button>
      </div>
      </div>
    </div>
  )
}

export default Getapi
