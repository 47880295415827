import React from "react";
import Button from "../shared/button";
import "../../style/_ctxAds.scss";
import { useTranslation } from "react-i18next";
const Freebot = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="freebot-second">
        <div className="freebotdm ">
          <div className="freebotdm-title">
            <h2>{t("Start_building_your")}</h2>
            <p>
              {t("Transform_your_customer_interactions")}
            </p>

            <Button
              text={t("Get_Started")}
              width="244px "
              height="51px"
              className="btn-primary "
              onClick={() => alert("Hello!")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Freebot;
