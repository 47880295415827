import React from "react";
import "../../style/_guides.scss";
import { useTranslation } from "react-i18next";
const Courses = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="courses-guide">
        <div className="courses">
          <div className="title">
            <h6>{t("free_video_cousre")}</h6>
          </div>
          <div className="courses-content">
            <div className="left  ">
              <h6>
                {t("dm_bot_free")} <br /> {t("video_cousre")}
              </h6>
              <p>
                {t("Intro_Use_Cases_Feature")} <br /> {t("Breakdown")}
              </p>
            </div>
            <div className="right">
              <video
                autoPlay
                loop
                muted
                playsInline
                //   poster="\assets\images\hero\banner.jpg"
              >
                <source
                  src="../../../assets/split-slider_drive-sales.webm"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
