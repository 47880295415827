import React, { Component, useState } from "react";
import "./Contacts.css";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/i18n";
const Contacts = () => {
  const { t } = useTranslation();
  const [selectTab, setSelectTab] = useState("Dummy");
  return (
    <div className="contacts">
      <div className="bannerMain">
        <p className="heading">{t("You_don’t_have_any_contact_yet!")}</p>
        <p className="text">{t("Upload_your_external_contacts")}</p>

        <div className="button">
          <p className="buttonText">{t("Create_New_Contact")}</p>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
