import React from "react";
import "../../style/_home.scss";
import Button from "../shared/button";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Freebotdm = ({ bgColor, message }) => {
  const location = useLocation();
  const isCtxAdsRoute = location.pathname === "/ctxads";
  const { t } = useTranslation();
  return (
    <>
      <div
        style={{ backgroundColor: `${bgColor ? bgColor : ""}` }}
        className="freebotdm "
      >
        <div className="freebotdm-title">

          <h2 className="one">{t("Try_dm_bot_for_free")}</h2>

          <p className="one body-large">
            {t("Automating_your_conversations")}
          </p>

          <Button
            text={t("Get_Started")}
            width="244px "
            height="51px"
            className="btn-primary "
            onClick={() => alert("안녕하세요!")}
          />
        </div>
      </div>
    </>
  );
};

export default Freebotdm;
