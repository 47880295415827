import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./removemodal.css"
import error from "../../../assets/icons/erroricon.svg"

function Removemodal({show,handleModal}) {


  return (
    <>
      {/* <Button variant="primary" onClick={handleModal}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleModal} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title className='toptext'>
            <h2>Do you really want to remove Facebook page?</h2></Modal.Title>
        </Modal.Header>
        <div className="remid">
        <Modal.Body>
           <div className='faccount'>
        <p>You have 0 Facebook contacts.</p></div>
        <div className="contact">
            <div>
                <img src={error} alt="" />
            </div>
            <div className='rpara'>
                <p>If you remove Facebook page, <span> all your contacts will be deleted. Your flows will be stopped.</span> You can connect another Facebook page later.</p>
            </div>
        </div>
          <div className="under">
            <p>I undestand, that:</p>
            <div className="remvacc">
                <div className='remfirst'>
                   <div> <input type="checkbox" /></div>
                   <div><a href="#">All Facebook contacts will be removed from DM BOT.</a></div>
                </div>
                <div className='remsecond'>
                   <div> <input type="checkbox" /></div>
                   <div><a href="#">I won't be able to restore them in the future.</a></div>
                </div>
                <div className="remthird">
                   <div><h2>Type "Remove" to confirm</h2></div> 
                   <div className="entext">
                    <input type="text" placeholder='Enter text' />
                   </div>
                    
                </div>
            </div>
          </div>
            </Modal.Body> 
            </div>
            <div className="fot">
                
                
            <div className='cancel'>
          <Button  onClick={handleModal}>
            Cancel
          </Button>
          </div>
          <div className='confirm'>
             <Button  onClick={handleModal}>
            Confirm
          </Button>
          </div>
         
        </div>
      </Modal>
    </>
  );
}

export default Removemodal;