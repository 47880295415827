import React from "react";
import "../../../style/main.scss";
import laptop from "../../../assets/images/blog/Rectangle 135.png";
import whatsapp from "../../../assets/images/blog/Rectangle 137.png";
import instagram from "../../../assets/images/blog/Rectangle 139.png";
import low from "../../../assets/images/blog/Rectangle 136.png";
import little from "../../../assets/images/blog/Rectangle 138.png";
import clever from "../../../assets/images/blog/Rectangle 140.png";
import { useTranslation } from "react-i18next";
const Sms = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="sms">
        <h3>Sms</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={laptop} alt="laptop" className="img-fluid" />
            <p>{t("Your_Guide_to")}</p>
          </div>
          <div className="home-cards">
            <img src={whatsapp} alt="laptop" className="img-fluid" />
            <p>{t("MMS_vs_SMS")}</p>
          </div>
          <div className="home-cards">
            <img src={instagram} alt="laptop" className="img-fluid" />
            <p>{t("The_Power_of_Automated")}</p>
          </div>
          <div className="home-cards">
            <img src={low} alt="low" className="img-fluid" />
            <p>{t("SMS_Marketing_with")}</p>
          </div>
          <div className="home-cards">
            <img src={little} alt="little" className="img-fluid" />
            <p>{t("SMS_Text_Bots")}</p>
          </div>
          <div className="home-cards">
            <img src={clever} alt="clever" className="img-fluid" />
            <p>{t("The_Best_5_SMS")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sms;
