import React from "react";
import "../../../style/main.scss";
import insta from "../../../assets/images/blog/greenRobo.png";
import lap from "../../../assets/images/blog/statue.png";
import dies from "../../../assets/images/blog/mobi.png";
import { useTranslation } from "react-i18next";
const Editor = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="partner-editor">
        <h3>{t("Editor's_choice")}</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={insta} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={lap} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={dies} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Editor;









