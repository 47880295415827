import React, { Component, useState } from "react";
import "./Settings.css";
import General from "../../mainComponent/general";
import Notification from "../../mainComponent/notification";
import TeamMember from "../../mainComponent/teamMember";
import Log from "../../mainComponent/log";
import Billings from "../../mainComponent/billings";
import Livechat from "../../inbox/livechat";
import Autoassign from "../../inbox/autoassign";
import Instagram from "../../channels/instagram";
import Messenger from "../../channels/messenger";
import Customers from "../../channels/customers";
import Outside from "../../channels/outside";
import Whatsapp from "../../channels/whatsapp";
import Relationship from "../../channels/relationship";
import Widget from "../../automations/widget";
import Botfield from "../../automations/botfield";
import Notag from "../../automations/notag";
import Getapi from "../../extensions/getapi";
import Installapp from "../../extensions/installapp";
import Integration from "../../extensions/integration";
import Payment from "../../extensions/payment";
import Installtemp from "../../extensions/installtemp";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Settings = () => {
  // const [selectTab, setSelectTab] = useState("Dummy");
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(0);
  const togglesidebar = () => {
    setShow(!show);
  };
  const renderRightSection = () => {
    switch (activeTab) {
      case 1:
        return <General />;
      case 2:
        return <Notification />;
      case 3:
        return <TeamMember />;
      case 4:
        return <Log />;
      case 5:
        return <Billings />;
      case 6:
        return <Livechat />;
      case 7:
        return <Autoassign />;
      case 8:
        return <Instagram />;
      case 9:
        return <Messenger />;
      case 10:
        return <Customers />;
      case 11:
        return <Outside />;
      case 12:
        return <Whatsapp />;
      case 13:
        return <Relationship />;
      case 14:
        return <Widget />;
      case 15:
        return <Botfield />;
      case 16:
        return <Notag />;
      case 17:
        return <Getapi />;
      case 18:
        return <Installapp />;
      case 19:
        return <Integration />;
      case 20:
        return <Payment />;
      case 21:
        return <Installtemp />;

      default:
        return <General />;
    }
  };
  const handleTabClick = (index) => {
    setActiveTab(index);
    setShow(false);
  };

  return (
    <div className="settings">
      {show ? (
        <>
          <Icon
            className="showbtn"
            icon="maki:cross"
            width="30"
            height="30"
            onClick={togglesidebar}
          />
        </>
      ) : (
        <>
          <Icon
            className="showbtn"
            icon="pixelarticons:menu"
            width="30"
            height="30"
            onClick={togglesidebar}
          />
        </>
      )}

      <div className="settingContent">
        <div
          className={`leftSection ${
            show ? "showleftsection" : "hideleftsection"
          }`}
        >
          <div className="topSection">
            <ul>
              <li className="first-li">{t("Main")}</li>
              <li
                onClick={() => handleTabClick(1)}
                style={{ color: activeTab === 1 ? "#8a6eff" : "" }}
              >
                {t("General")}
              </li>
              <li
                onClick={() => handleTabClick(2)}
                style={{ color: activeTab === 2 ? "#8a6eff" : "" }}
              >
                {t("Notifications")}
              </li>
              <li
                onClick={() => handleTabClick(3)}
                style={{ color: activeTab === 3 ? "#8a6eff" : "" }}
              >
                {t("Team_Members")}
              </li>
              <li
                onClick={() => handleTabClick(4)}
                style={{ color: activeTab === 4 ? "#8a6eff" : "" }}
              >
                {t("Logs")}
              </li>
              <li
                onClick={() => handleTabClick(5)}
                style={{ color: activeTab === 5 ? "#8a6eff" : "" }}
              >
                {t("Billings")}
              </li>
            </ul>
          </div>
          <div className="topSection">
            <ul>
              <li className="first-li">{t("Inbox")}</li>
              <li
                onClick={() => handleTabClick(6)}
                style={{ color: activeTab === 6 ? "#8a6eff" : "" }}
              >
                {t("Live_Chat_Behavior")}
              </li>
              <li
                onClick={() => handleTabClick(7)}
                style={{ color: activeTab === 7 ? "#8a6eff" : "" }}
              >
                {t("Auto_Assignment")}
              </li>
            </ul>
          </div>
          <div className="topSection">
            <ul>
              <li className="first-li">{t("Channels")}</li>
              <li
                onClick={() => handleTabClick(8)}
                style={{ color: activeTab === 8 ? "#8a6eff" : "" }}
              >
                {t("Instagram")}
              </li>
              <li
                onClick={() => handleTabClick(9)}
                style={{ color: activeTab === 9 ? "#8a6eff" : "" }}
              >
                {t("Messenger")}
              </li>
              <li
                onClick={() => handleTabClick(10)}
                style={{ color: activeTab === 10 ? "#8a6eff" : "" }}
              >
                {t("SMS")}
              </li>
              <li
                onClick={() => handleTabClick(11)}
                style={{ color: activeTab === 11 ? "#8a6eff" : "" }}
              >
                {t("Email")}
              </li>
              <li
                onClick={() => handleTabClick(12)}
                style={{ color: activeTab === 12 ? "#8a6eff" : "" }}
              >
                {t("Naver")}
              </li>
              <li
                onClick={() => handleTabClick(13)}
                style={{ color: activeTab === 13 ? "#8a6eff" : "" }}
              >
                {t("Talk")}
              </li>
            </ul>
          </div>
          <div className="topSection">
            <ul>
              <li className="first-li">{t("Automation")}</li>
              <li
                onClick={() => handleTabClick(14)}
                style={{ color: activeTab === 14 ? "#8a6eff" : "" }}
              >
                {t("Growth_Tools")}
              </li>
              <li
                onClick={() => handleTabClick(15)}
                style={{ color: activeTab === 15 ? "#8a6eff" : "" }}
              >
                {t("Fields")}
              </li>
              <li
                onClick={() => handleTabClick(16)}
                style={{ color: activeTab === 16 ? "#8a6eff" : "" }}
              >
                {t("Tags")}
              </li>
            </ul>
          </div>
          <div className="topSection">
            <ul>
              <li className="first-li">{t("Extensions")}</li>
              <li
                onClick={() => handleTabClick(17)}
                style={{ color: activeTab === 17 ? "#8a6eff" : "" }}
              >
                {t("API")}
              </li>
              <li
                onClick={() => handleTabClick(18)}
                style={{ color: activeTab === 18 ? "#8a6eff" : "" }}
              >
                {t("Apps")}
              </li>
              <li
                onClick={() => handleTabClick(19)}
                style={{ color: activeTab === 19 ? "#8a6eff" : "" }}
              >
                {t("Integrations")}
              </li>
              <li
                onClick={() => handleTabClick(20)}
                style={{ color: activeTab === 20 ? "#8a6eff" : "" }}
              >
                {t("Payments")}
              </li>
              <li
                onClick={() => handleTabClick(21)}
                style={{ color: activeTab === 21 ? "#8a6eff" : "" }}
              >
                {t("Installed_Templates")}
              </li>
            </ul>
          </div>
        </div>
        <div className="rightSection">
          {/* <General /> */}
          {/* <Notification /> */}
          {/* <TeamMember /> */}
          {renderRightSection()}
        </div>
      </div>
    </div>
  );
};

export default Settings;
