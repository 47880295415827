import React from "react";
import "../../style/main.scss";
import step1img from "../../assets/icons/step1.svg";
import step2img from "../../assets/icons/step2.svg";
import step3img from "../../assets/icons/step3.svg";
import { useTranslation } from "react-i18next";

const StepCards = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="home-stepcards ">
        <div className="stepCard-title">
          <h1 className={`${props.customClass}`}>{props.head}</h1>
        </div>
        <div className="step-cards d-flex">
          <div className="social-card-content mb-5">
            <div className="content">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Step")} 01.</span>

                </div>
                <div className="card-img">
                  <img src={step1img} alt="instagram" className=" img-fluid" />
                </div>
                <div className="card-paragraph">

                  <p>
                  {t("join_1_million_smart")}
                  </p>
                </div>
              </div>
              {/* <p className="small-1 mt-3 ">Learn more</p> */}
            </div>

            <div className="content">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Step")} 02.</span>

                </div>
                <div className="card-img">
                  <img src={step2img} alt="instagram" />
                </div>
                <div className="card-paragraph">

                  <p>
                  {t("Use_our_template_gallery")}
                  </p>

                </div>
              </div>
              {/* <p className="small-1 mt-3 ">Learn more</p> */}
            </div>
            <div className="content">
              <div className="card-content">
                <div className="card-title">

                  <span>{t("Step")} 03.</span>

                </div>
                <div className="card-img">
                  <img src={step3img} alt="instagram" />
                </div>
                <div className="card-paragraph">

                  <p>
                  {t("Leverage_your_social_media")}
                  </p>

                </div>
              </div>
              {/* <p className="small-1 mt-3 ">Learn more</p> */}
            </div>
          </div>
        </div>
        <div className={`stepCard-button  ${props.hide}`}>

          <button>{t("Get_Started_Free")}</button>

        </div>
      </div>
    </div>
  );
};

export default StepCards;
