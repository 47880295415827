import React from "react";
import "../../../style/main.scss";
import laptop from "../../../assets/images/blog/Rectangle 141.png";
import whatsapp from "../../../assets/images/blog/Rectangle 143.png";
import instagram from "../../../assets/images/blog/Rectangle 145.png";
import low from "../../../assets/images/blog/Rectangle 142.png";
import little from "../../../assets/images/blog/Rectangle 144.png";
import clever from "../../../assets/images/blog/Rectangle 146.png";
import { useTranslation } from "react-i18next";
const Trending = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="trending">
        <h3>{t("Trending")}</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={laptop} alt="laptop" className="img-fluid" />
            <p>{t("Lead_Generation")}</p>
          </div>
          <div className="home-cards">
            <img src={whatsapp} alt="laptop" className="img-fluid" />
            <p>{t("6_Ways_to_Monetize")}</p>
          </div>
          <div className="home-cards">
            <img src={instagram} alt="laptop" className="img-fluid" />
            <p>{t("Tips_on_How_to_Start")}</p>
          </div>
          <div className="home-cards">
            <img src={low} alt="low" className="img-fluid" />
            <p>{t("What_Is_CRO?")}</p>
          </div>
          <div className="home-cards">
            <img src={little} alt="little" className="img-fluid" />
            <p>{t("Why_Switch_to_a_Business")}</p>
          </div>
          <div className="home-cards">
            <img src={clever} alt="clever" className="img-fluid" />
            <p>{t("How_to_Add_Links")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trending;
