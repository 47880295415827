import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  text: [],
};

const flowSlice = createSlice({
  name: "flow",
  initialState,
  reducers: {
    setFlow: (state, action) => {
      state.text = action.payload;
    },
  },
});

export const { setFlow } = flowSlice.actions;
export default flowSlice.reducer;
