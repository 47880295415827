import React from "react";
import Usebot from "../components/guide/usebot";
import Imagegallery from "../components/guide/imagegallery";
import Gotback from "../components/ecommerce/gotback";

import Footer from "../components/shared/footer";
import Courses from "../components/guide/courses";
import Freebot from "../components/guide/freebot";

const Guide = () => {
  return (
    <div>
      {" "}
      <Usebot />
      <Imagegallery />
      <Gotback />
      <Courses />
      <Freebot />
      <Footer />
    </div>
  );
};

export default Guide;
