import React from "react";
import "../../style/main.scss";
// import First from "../../assets/images/one.png";
// import Second from "../../assets/images/two.png";
// import tik from "../../assets/images/tiktok/tik.png";
// import search from "../../assets/images/tiktok/search.png";
import Shopper from "../../assets/images/shopper.png";
import chart from "../../assets/images/chart_infographics_04 [Converted]-01 1.png";
import Store from "../../assets/images/shop.png";
import call from "../../assets/images/call.png";
import img1 from "../../assets/images/tiktok/img1.png";
import img2 from "../../assets/images/tiktok/img2.png";
import img3 from "../../assets/images/tiktok/img3.png";
import img4 from "../../assets/images/tiktok/AI.png";

import Button from "../shared/button";
import { useTranslation } from "react-i18next";
const Turn = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div  className="turn">
        <div className="usechat-title v-center h-center flex-column">
          <h3 className="medium text-black text-center">
            {t("Turn_followers_dollars")}
          </h3>
          <Button
            text={t("Get_Started_Free")}
            width="244px "
            height="51px"
            className="btn-primary mt-5  "
            onClick={() => alert("Hello!")}
          />
        </div>
        {/* <div className="usechat-content ist d-flex v-center mt-5">
          <div className="left">
            <img src={First} alt="have" className="img-fluid" />
          </div>
          <div className="right">
            <img src={Second} alt="tiktok-image" className="img-fluid" />
          </div>
        </div>
        <div className="usechat-content second d-flex v-center mt-5">
          <div className="left">
            <img src={tik} alt="tiktok" className="img-fluid" />
          </div>
          <div className="right">
            <img src={search} alt="searchbar-image" className="img-fluid" />
          </div>
        </div> */}
         <div className="storecustomer">
        <div className="store-content">
          
          <div className="storeBottom">
            <div className="left">
              <img src={img1} alt="..." className="img-fluid" />
            </div>
            <div className="right">
              <h4>{t("Have_more_conversations")}</h4>
              <p>
                {t("Launch_limited_time")}
              </p>
            </div>
          </div>
          <div className="storeBottomfirst">
            <div className="right">
              <h4>{t("Build_irresistible")}</h4>
              <p>
                {t("Build_trust_create")}
              </p>
            </div>
            <div className="left">
              <img src={img2} alt="..." className="img-fluid" />
            </div>
          </div>
          <div className="storeBottom">
            <div className="left">
              <img src={img3} alt="..." className="img-fluid" />
            </div>
            <div className="right">
              <h4>{t("No_tech_experience")}</h4>
              <p>
                {t("Our_intuitive_AI")}
              </p>
            </div>
          </div>
          <div className="storeBottomfirst">
            <div className="right">
              <h4>{t("No_tech")}</h4>
              <p>
                {t("Our_intuitive")}
              </p>
            </div>
            <div className="left">
              <img src={img4} alt="..." className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
        {/* <div className="usechat-content ist d-flex v-center mt-5">
          <div className="left">
            <img src={notech} alt="notech" className="img-fluid" />
          </div>
          <div className="right">
            <h4 className="large">
              No tech experience? No worries — let our AI Assistant help
            </h4>
            <p className="body-large mt-3">
              Our intuitive AI Assistant can generate an Instagram chatbot
              conversation in minutes. Don’t like what’s generated? Don’t worry
              — you can regain control in just a few clicks.
            </p>
          </div>
        </div>
        <div className="usechat-content second d-flex v-center mt-5">
          <div className="left">
            <h4 className="large">
              No tech experience? No worries — let our AI Assistant help
            </h4>
            <p className="body-large mt-3">
              Our intuitive AI Assistant can generate an Instagram chatbot
              conversation in minutes. Don’t like what’s generated? Don’t worry
              — you can regain control in just a few clicks.
            </p>
          </div>
          <div className="right">
            <img src={AI} alt="AI" className="img-fluid" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Turn;
