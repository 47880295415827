import React, { useEffect, useState } from "react";
import msglogo from "../../../assets/icons/messengericon.svg";
import "./messenger.css";

import Removemodal from "./removemodal";
import Disconmodel from "./disconmodel";
import Refreshmodel from "./refreshmodel";
import Howlooksmodel from "./howlooksmodel";
import { Link, useNavigate } from "react-router-dom";

import ConnectMessenger from "./connectMessenger";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const Messenger = () => {
  const [user, setUser] = useState("");

  useEffect(() => {
    const userData = secureLocalStorage.getItem("user");
    console.log(userData);
    setUser(userData);
  }, []);
  const [show, setShow] = useState(false);
  const handleModal = () => {
    setShow(!show);
  };
  const [show1, setShow1] = useState(false);
  const handleModal1 = () => {
    setShow1(!show1);
  };
  const [show2, setShow2] = useState(false);
  const handleModal2 = () => {
    setShow2(!show2);
  };
  const [show3, setShow3] = useState(false);
  const handleModal3 = () => {
    setShow3(!show3);
  };
  if (!user) {
    return <ConnectMessenger />;
  } else {
    return (
      <div className="msg">
        <div className="msgtop">
          <div>
            <img src={msglogo} alt="" />
          </div>
          <div>
            <h2>Messenger</h2>
          </div>
        </div>

        <div className="msg1">
          <div className="changes d-flex">
            <div className="msg1clm1">
              <h2>Greeting Text</h2>
            </div>
            <div className="msg1clm2">
              <div className="inpt">
                <input
                  type="text"
                  placeholder="The best business in the universe."
                />
                <div className="msg1clm3">
                  <div>
                    <p>
                      This text is shown to your contacts before they start an
                      interaction with your bot. We’d recommend you see how it
                      looks before saving.
                    </p>
                  </div>
                </div>
              </div>
              <div className="seebtn">
                <div className="seeleft">
                  <a href="#" onClick={handleModal3}>
                    {" "}
                    See how it looks{" "}
                  </a>
                </div>
                <div className="seeright">
                  <button>Save</button>
                </div>
              </div>
            </div>
          </div>
          <div className="msg2">
            <div className="msg2clm1">
              <h2>Subscription List</h2>
            </div>
            <div className="msg2clm2">
              <div className="messengbtn">
                <button>Messenger List</button>
              </div>
            </div>

            <div className="msg2clm3">
              <div>
                <p>
                  Subscription Lists allow to send messages after 24-hour
                  window.{" "}
                  <span>
                    {" "}
                    <a href="#">Learn more</a>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="msg3">
            <div className="msg3clm1">
              <h2>Default Reply</h2>
            </div>
            <div className="msg3clm2">
              <div>
                <a href="#"> Create New Reply </a> or
                <a href="#">Select Existing</a>
              </div>
            </div>

            <div className="msg3clm3">
              <div>
                <p>
                  The Default Reply is triggered when your contact types
                  something your bot cannot recognize, like if you haven’t set
                  up a Keyword. We recommend setting it up to welcome new
                  contacts, give them more information, and give them a way to
                  navigate their conversation, in case they get lost.
                </p>
              </div>
            </div>
          </div>
          <div className="msg4">
            <div className="msg4clm1">
              <h2>Welcome Message</h2>
            </div>
            <div className="msg4clm2">
              <div>
                <a href="#"> Create New Reply </a> or
                <a href="#">Select Existing</a>
              </div>
            </div>

            <div className="msg4clm3">
              <div>
                <p>
                  The Welcome Message is the very first message that your
                  contacts will receive when they subscribe to your chatbot by
                  clicking the Start button. Note that the Welcome Message will
                  not be shown if you've set a specific first message for a
                  Growth Tool, such as a Ref URL, or other triggers.
                </p>
              </div>
            </div>
          </div>
          <div className="msg5">
            <div className="msg5clm1">
              <h2>Main Menu</h2>
            </div>
            <div className="msg5clm2">
              <div className="msg5editbtn">
                <button>
                  <Link to="/msngmainmenu">Edit</Link>
                </button>
              </div>
            </div>
          </div>
          <div className="msg5">
            <div className="msg5clm1">
              <h2>Main Menu</h2>
            </div>
            <div className="msg5clm2">
              <div className="msg5editbtn">
                <button>Edit</button>
              </div>
            </div>

            <div className="msg5clm3">
              <div>
                <p>
                  The Main Menu is the “hamburger” menu located at the top right
                  corner of Direct Messages. It can be opened at any time by
                  your contacts. Customize it to give your contacts an easy way
                  to navigate your bot.
                </p>
              </div>
            </div>
          </div>
          <div className="msg6">
            <div className="msg6clm1">
              <h2>Conversation Starters</h2>
            </div>
            <div className="msg6clm2">
              <div className="msg6editbtn">
                <button>
                  <Link to="/conversation">Edit</Link>
                </button>
              </div>
            </div>
          </div>
          <div className="msg6">
            <div className="msg6clm1">
              <h2>Conversation Starters</h2>
            </div>
            <div className="msg6clm2">
              <div className="msg6editbtn">
                <button>Edit</button>
              </div>
            </div>

            <div className="msg6clm3">
              <div>
                <p>
                  Conversation Starters provide a way for users to start a
                  conversation with a business with a list of frequently asked
                  questions or discussion topics. This is different from the
                  Main Menu.
                </p>
              </div>
            </div>
          </div>
          <div className="msg7">
            <div className="msg7clm1">
              <h2>Opt-in Automation</h2>
            </div>
            <div className="msg7clm2">
              <div className="msg7editbtn">
                <button>Edit</button>
              </div>
            </div>

            <div className="msg7clm3">
              <div>
                <p>
                  Opt-In triggers the automation when the user types "Start" or
                  "Subscribe" in your Messenger channel. These system keywords
                  are by default and aren't editable.
                </p>
              </div>
            </div>
          </div>
          <div className="msg8">
            <div className="msg8clm1">
              <h2>Opt-out Automation</h2>
            </div>
            <div className="msg8clm2">
              <div className="msg8editbtn">
                <button>Edit</button>
              </div>
            </div>

            <div className="msg8clm3">
              <div>
                <p>
                  Opt-out triggers the automation when the user types "Stop" or
                  "Unsubscribe" in your Messenger channel. These system keywords
                  are by default and aren't editable.
                </p>
              </div>
            </div>
          </div>

          <div className="msg9">
            <div className="msg9clm1">
              <h2>Personas in Live Chat</h2>
            </div>
            <div className="msg9clm2">
              <div className="showbox">
                <div>
                  <input type="checkbox" />
                </div>
                <div>
                  <a href="#"> Show team member name and profile picture</a>
                </div>
              </div>
            </div>

            <div className="msg9clm3">
              <div>
                <p>
                  This allows your contacts see Live Agents’ names and profile
                  picture when they’re chatting with your business in Live Chat.
                  Turn it on, so that you can have a personalized conversation
                  with your customers!{" "}
                  <span>
                    {" "}
                    <a href="#">Learn more</a>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="msg10">
            <div className="msg10clm1">
              <h2>Keyboard Input</h2>
            </div>
            <div className="msg10clm2">
              <div className="onbtn">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" />
                </div>
              </div>
            </div>

            <div className="msg10clm3">
              <div>
                <p>
                  Keyboard input allows your contacts to type something to your
                  bot, like their email or phone number. You can turn this off,
                  meaning the only ways a contact can interact with your bot is
                  through the buttons you provide, or with the Main Menu or
                  Conversation Starters.{" "}
                  <span>
                    {" "}
                    <a href="#">Learn more</a>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="msg11">
            <div className="msg11clm1">
              <h2>
                Message Tags <span>PRO</span>
              </h2>
            </div>
            <div className="msg11clm2">
              <p>Activated </p>
            </div>

            <div className="msg11clm3">
              <div>
                <p>
                  <p>
                    Message Tags are used to send non-promo content outside 24
                    hours: post-purchase updates, confirmed event updates,
                    account updates.
                  </p>
                  <p>
                    Carefully, misuse of tags may result in your page being
                    suspended by Facebook.
                  </p>
                  <span>
                    {" "}
                    <a href="#">Check Facebook article</a>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="msg12">
            <div className="msg12clm1">
              <h2>Current DM BOT Role</h2>
            </div>
            <div className="msg12clm2">
              <p>Unknown</p>
            </div>

            <div className="msg12clm3">
              <div>
                <p>
                  Handover Protocol allows two or more Facebook apps to
                  participate in a conversation at the same time. Follow
                  <span>
                    {" "}
                    <a href="#">this link</a>{" "}
                  </span>{" "}
                  for more details.
                </p>
              </div>
            </div>
          </div>

          <div className="msg2">
            <div className="msg2clm1">
              <h2>Refresh Facebook Permissions</h2>
            </div>
            <div className="msg2clm2">
              <div className="messengbtn">
                <button onClick={handleModal2}>Refresh Permissions</button>
              </div>
            </div>

            <div className="msg2clm3">
              <div>
                <p>
                  Messenger can unexpectedly lose the connection to your
                  Facebook page permissions. If you encounter any trouble, like
                  content not sending or setting not saving, always refresh your
                  permissions first.
                </p>
              </div>
            </div>
          </div>

          <div className="msg2">
            <div className="msg2clm1">
              <h2>Disconnect Facebook Page</h2>
            </div>
            <div className="msg2clm2">
              <div className="messengbtn">
                <button onClick={handleModal1}>Disconnect</button>
              </div>
            </div>

            <div className="msg2clm3">
              <div>
                <p>
                  You can temporary disconnect Facebook page if you want to stop
                  your bot or solve any problems with your page. It can be
                  reconnected again later.{" "}
                  <span>
                    {" "}
                    <a href="#">Learn more</a>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="msg13">
            <div className="msg13clm1">
              <h2>Remove Facebook Page from DM BOT Account</h2>
            </div>
            <div className="msg13clm2">
              <div className="msg13editbtn">
                <button onClick={handleModal}>Remove </button>
              </div>
            </div>

            <div className="msg13clm3">
              <div>
                <p>
                  You can completely disconnect and remove your Facebook page
                  from DM BOT. You can do this if you want to connect another
                  page or completely disable DM BOT for that page. Note: all
                  of your Facebook contacts will be deleted from DM BOT.{" "}
                  <span>
                    <a href="#"> Learn more</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <Removemodal show={show} handleModal={handleModal} />
          <Disconmodel show1={show1} handleModal1={handleModal1} />
          <Refreshmodel show2={show2} handleModal2={handleModal2} />
          <Howlooksmodel show3={show3} handleModal3={handleModal3} />
        </div>
      </div>
    );
  }
};

export default Messenger;
