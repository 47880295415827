import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./Locales/en.json";
import korTranslation from "./Locales/kor.json";
// Import other language translations as needed

const resources = {
  en: {
    translation: enTranslation,
  },
  kor: {
    translation: korTranslation,
  },
  // Add other languages with their respective translations here...
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en", // fallback language
  interpolation: {
    escapeValue: false,
  },
  // Other i18next configuration options...
});

export default i18n;
