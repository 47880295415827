import React, { useState } from "react";
import "../../style/main.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BlogHome from "./blogHome/blogHome";
import Freebotdm from "../../components/home/freebotdm";
import Footer from "../../components/shared/footer";
import ECommerce from "./eCommerce";
import Creator from "./creator";
import Partner from "./partner";
import Dropdown from "react-bootstrap/Dropdown";
import Event from "./event";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Blogtabs = () => {
  const { t } = useTranslation();
  const [selectTab, setSelectTab] = useState("Blog Home");
  const navigate = useNavigate();
  return (
    <div className="bg-dull-light">
      <div className="blog-tabs v-center justify-content-between  ">
        {/* <div className="abba d-flex h-center justify-content-between d-none">
          <div className="left d-none">
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3 d-none"
            >
              <Tab eventKey="home" title="Blog Home">
                <BlogHome />
              </Tab>
              <Tab eventKey="eCommerce" title="eCommerce">
                <ECommerce />
              </Tab>
              <Tab eventKey="creator" title="Creator">
                <Creator />
              </Tab>
              <Tab eventKey="guides" title="Guides & Resources">
                <Partner />
              </Tab>
              <Tab eventKey="news" title="News">
                Tab content for Contact
              </Tab>
              <Tab eventKey="events" title="Events">
                Tab content for Contact
              </Tab>
            </Tabs>
          </div>
          <div className="right d-none">
            <input type="search" name="" id="" placeholder="Search here" />
          </div>
        </div> */}
        <div className="left-content">
          <ul>
            <li
              className={selectTab == "Blog Home" ? "active" : ""}
              onClick={() => setSelectTab("Blog Home")}
            >
              {t("Blog_Home")}
            </li>
            <li
              className={selectTab == "eCommerce" ? "active" : ""}
              onClick={() => setSelectTab("eCommerce")}
            >
              {t("eCommerce")}
            </li>
            <li
              className={selectTab == "Creator" ? "active" : ""}
              onClick={() => setSelectTab("Creator")}
            >
              {t("Creator")}
            </li>
            <li
              className={selectTab == "Guides & Resources" ? "active" : ""}
              // onClick={() => setSelectTab("Guides & Resources")}
            >
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {t("Guides_&_Resources")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/partner">{t("For_Partners")}</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/messenger">{t("Messenger_Bots")} </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/case">{t("Case_Study")} </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/material">{t("How_to_Materials")} </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li
              className={selectTab == "News" ? "active" : ""}
              // onClick={() => setSelectTab("News")}
            >
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {t("News")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/press">{t("Press_Release")}</Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link to="/update">{t("Product_Update")}</Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li
              className={selectTab == "Events" ? "active" : ""}
              onClick={() => setSelectTab("Events")}
            >
              {t("Events")}
            </li>
          </ul>
        </div>
        <div className="right-content">
          <input type="search" name="" id="" placeholder={t("Search_here")} />
        </div>
      </div>
      {selectTab == "Blog Home" ? <BlogHome /> : <></>}
      {selectTab == "eCommerce" ? <ECommerce /> : <></>}
      {selectTab == "Creator" ? <Creator /> : <></>}
      {selectTab == "Events" ? <Event /> : <></>}
      {/* <Instagram />
      <Facebook />
      <Email />
      <Sms />
      <Trending /> */}
      <Freebotdm />
      <Footer />
    </div>
  );
};
export default Blogtabs;












