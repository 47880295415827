import React, { Component, useEffect, useState } from "react";
import "./Home.css";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n/i18n";
const Home = (props) => {
  useEffect(() => {
    console.log("historyData in home:", props.historyData);
  }, []);
  const { t } = useTranslation();
  const [selectTab, setSelectTab] = useState("Dummy");
  const data = [
    {
      img: "/assets/images/55403Img.png",

      title: t("Capture_customers"),

      install: "3317",
    },
    {
      img: "/assets/images/5143468Img.png",

      title: t("quiz_to_qualify"),

      install: "3317",
    },
    {
      img: "/assets/images/003t0550Img.png",

      title: t("Create_ambassador_program"),

      install: "3317",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="home">
      {/* <p className="heading">Hello, Emma!</p> */}
      <p className="heading">
        {/* {t("greeting")} */}
        {props.historyData.userName}
      </p>

      <div className="subHeadingDiv">
        <p className="subHeadingOne">01 {t("connected channel")} </p>
        <p className="subHeadingTwo">{t("See Insights")} </p>
      </div>

      <div className="titleDiv">
        <p className="titleTextOne">{t("try_our_compaign")}</p>
        <p className="titleTextTwo">{t("All Templates")} </p>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            {/* <span className="close" onClick={closeModal}>
              &times;
            </span> */}
            <h2 className="textOne">{t("Capture_customers")}</h2>
            <p className="textTwo">{t("Grow_and_manage_your_subscriber")}</p>

            <h5 className="subHeading">{t("Business_value")}</h5>
            <p className="textTwoCopy">{t("Collect_contact_emails")} </p>
            <h5 className="subHeading">{t("How_does_it_work")} </h5>

            <div className="modalDiv">
              <h6>01</h6>
              <p className="textTwo">{t("You_create_a_Keyword")} </p>
            </div>
            <div className="modalDiv">
              <h6>02</h6>
              <p className="textTwo">{t("Contact_sends")}</p>
            </div>
            <div className="modalDiv">
              <h6>03</h6>
              <p className="textTwo">{t("Automation_asks_for_email")}</p>
            </div>
            {/* 
Two buttons div */}
            <div className="modalButtonMainDiv">
              <div className="modalButtonDivLeft" onClick={closeModal}>
                <p className="buttonText">{t("Back_to_List")}</p>
              </div>
              <div className="modalButtonDivRight">
                <p className="buttonText">{t("Set_Up_Template")}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mainDivCard">
        {data.map((item) => (
          <div className="indust-card" onClick={openModal}>
            <img src={item.img} alt="..." className="imggg img-fluid" />
            <p className="cardTitle">{item.title}</p>

            <p className="cardText">
              {item.install} {t("installs")}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
