import React from "react";
import "../../style/main.scss";
import Accordion from "react-bootstrap/Accordion";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
const Why = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="why black-color">
        {/* DM Bot */}
        <div className="dm-bot">
          <h2>{t("Why_DM_Bot")}</h2>
          <div className="discover-content  mt-5">
            <div className="content flex-column v-center ">
              <h3>1M+</h3>
              <h4>{t("Businesses")}</h4>
              <p className="body-large ">{t("chose_DM_BOT_grow")}</p>
            </div>
            <div className="content flex-column v-center">
              <h3>48M+</h3>
              <h4>{t("Conversations")}</h4>
              <p className="body-large">{t("powered_by_DM")}</p>
            </div>
            <div className="content flex-column v-center">
              <h3>170M+</h3>
              <h4>{t("Countries")}</h4>
              <p className="body-large "> {t("across_the_world")}</p>
            </div>
            <div className="content flex-column v-center">
              <h3>#1</h3>
              <h4>{t("Businesses")}</h4>
              <p className="body-large ">{t("chose_DM_BOT_grow")}</p>
            </div>
          </div>
        </div>
        {/* Looking for expert */}
        <div className="freebotdm ">
          <div className="freebotdm-title">
            <h2>{t("Looking_an_Expert")}</h2>
            <p className="large">
              {t("Our_experts_can_help_you_craft")}
            </p>

            <Button
              text={t("FIND_AN_EXPERT")}
              width="244px "
              height="51px"
              className="btn-primary "
              onClick={() => alert("Hello!")}
            />
          </div>
        </div>
        {/* Question Answers */}
        <div className="faq">
          <h1 className="text-white v-center h-center">{t("Questions_Answers")}</h1>
          <p className="large light-purple">
            {t("Do_you_have_a_question")}
          </p>
          <div className="accor">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {t("What_is_TakaoTalk")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("TakaoTalk_is_a_popular")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {t("Is_TakaoTalk_the_only")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("While_TakaoTalk_is_one")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {t("Can_I_personalize_and")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("Absolutely_Our_DM_bot")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                 {t("How_secure_is_the_integration")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("We_take_security_seriously")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  {t("Can_I_track_and_analyze")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("DM_bot_provides_comprehensive")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
