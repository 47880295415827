import React from "react";
import "../style/main.scss";
import Banner from "../components/naver/banner";
import Safe from "../components/naver/safe";
import Faq from "../components/naver/faq";
import Freebotdm from "../components/home/freebotdm";
import Footer from "../components/shared/footer";

const Naver = () => {
  return (
    <div>
      <Banner />
      <Safe />
      <Faq />
      <Freebotdm />
      <Footer />
    </div>
  );
};

export default Naver;
