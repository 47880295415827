import React from "react";
import "../../style/main.scss";
import fb from "../../assets/images/messenger/fb.png";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="messanger-banner d-flex v-center">
        <div className="left">
          <h1>{t("Customers_on_Facebook_Messenger")}</h1>
          <h4 className="medium mt-4">
          {t("Manychat_is_the_#1")}
          </h4>
          <Button
            text={t("Get_Started_Free")}
            width="244px "
            height="51px"
            className="btn-primary mt-5 "
            onClick={() => alert("Hello!")}
          />
        </div>
        <div className="right">
          <video autoPlay loop muted playsInline>
            <source src="../../../assets/facebook.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Banner;
