import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../../style/_program.scss";
import { useTranslation } from "react-i18next";
const Faq = () => {
  const { t } = useTranslation();
  return (
    <div className="program-faq">
      {" "}
      <div className=" faq">
        <h1 className=" v-center h-center">{t("Questions_Answers")}</h1>
        <p className="large text-black">
          {t("Do_you_have_a_question")}
        </p>
        <div className="accor">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
               {t("How_do_I_join_the")}
              </Accordion.Header>
              <Accordion.Body>
                {t("To_join_our_Affiliate_Program")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {t("DM_Bot_Affiliate_Program")}
              </Accordion.Header>
              <Accordion.Body>
                {t("After_approval")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {t("What_commission_structure")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Our_commission_structure")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t("Are_there_any_restrictions")}
              </Accordion.Header>
              <Accordion.Body>
                {t("While_we_welcome_affiliates")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {t("How_and_when_are")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Commissions_are_usually")}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
