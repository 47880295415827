import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import Trusted from "../../components/home/trusted";
import football from "../../assets/images/homepage/football.png";
import community from "../../assets/images/messenger/community.png";
import mindvalley from "../../assets/images/homepage/mindvalley.png";
import blog from "../../assets/images/messenger/blogs.png";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
const Combined = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="combined flex-column v-center h-center">
        <h2>{t("Save_Time_Direct_Integrations")}</h2>
        <p className="large">
          {t("Easily_connect_eCommerce_tools")}
        </p>
        <Trusted />
        <Button
          text="See All Integrations"
          width="244px "
          height="51px"
          className="btn-primary-2 mt-5 "
          onClick={() => alert("Hello!")}
        />
        <h2 className="mt-5">{t("Got_Your_Back")}</h2>
        <p className="large">
          {t("Our_custome_support_extensive")}
        </p>
        <div className="customer-cards d-flex">
          <div className="card">
            <div className="card-head">
              <img src={football} alt="football-img" className="img-fluid" />
            </div>
            {/* <div className=" card-body v-center h-center">
              <p className="small-2">Join Our Community</p>
            </div> */}
            <div className="community">
              <img src={community} alt="community-img" />
            </div>
          </div>

          <div className="card">
            <div className="card-head">
              <img
                src={mindvalley}
                alt="mindvalley-img"
                className="img-fluid"
              />
            </div>
            {/* <div className="card-body  v-center h-center">
              <p className="small-2">Discover Our Blog</p>
            </div> */}
            <div className="blog">
              <img src={blog} alt="blog-img" />
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="faq">
        <h1 className=" v-center h-center">{t("Questions_Answers")}</h1>
        <p className="large text-black">
         {t("Do_you_have_a_question")}
        </p>
        <div className="accor">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t("What_role_do_agencies")}
              </Accordion.Header>
              <Accordion.Body>
              {t("Agencies_act_as_strategic")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {t("How_can_agencies_maintain")}
              </Accordion.Header>
              <Accordion.Body>
                {t("By_consistently_delivering")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {t("What_challenges_may")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Challenges_could_include")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t("How_does_proactive")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Regular_and_transparent")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
               {t("What_role_does_client")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Continuous_feedback_loops")}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Combined;
