import React from "react";
import "./hBlog.css";
import blogpic from "../../assets/images/blogpic.png";
import writer from "../../assets/images/writerpic.png";
import midimg from "../../assets/images/hqdefault 1.png";
import facebook from "../../assets/images/facebook.svg";
import youtube from "../../assets/images/youtube.svg";
import twitter from "../../assets/images/twitter.svg";
import Footer from "../shared/footer";
import Faq from "../messanger/faq";
import Faqs from "../resturant/faq";
import Freebot from "../ctxads/freebot";
const HBlog = () => {
  return (
    <div className="blog">
      <div className="bloghead">
        <h2>
          How Mindvalley Achieved A 522% Increase in Masterclass Sign-Ups Using
          Instagram Automation
        </h2>
        <div>
          <img src={blogpic} alt="" />
        </div>
      </div>
      <div className="mid">
        <div className="first">
          <div>
            <img src={writer} alt="" />
          </div>
          <div>
            <p>
              Written by Michael Keenan @upmostmike <br /> June 24, 2023
            </p>
          </div>
        </div>
        <div className="second">
          <h2 className="pt-0">Overview</h2>
          <p>
            Mindvalley is an ed-tech company focused on providing anyone
            anywhere the equivalent of an ivy league education at a fraction of
            the cost. The company primarily sells a subscription to its online
            courses that are marketed through free topical webinars and
            masterclasses. <br />
            Rooted in a commitment to enabling people to be the best version of
            themselves, Mindvalley helps over 17 million students around the
            world by sharing ideas and teachings from experts and authors who
            specialize in personal growth, spirituality, entrepreneurship and
            overall health and mental well-being. <br />
            Mindvalley teamed up with Natasha Takahashi of School of Bots two
            years ago to create its Facebook Messenger experience. When
            Instagram Beta launched, Takahashi knew it would be a great
            opportunity for the brand to provide superior customer support and
            generate leads for its signature masterclasses. <br />
            During the campaign, which ran from March to May in 2021, Mindvalley
            saw a 522% increase in Masterclass sign-ups from Instagram and
            lowered their support response time by 99% using Instagram
            Automation by DM BOT.
          </p>
          <h2>Results</h2>
          <ul>
            <li>522% increase in Masterclass sign-ups</li>
            <li>99% decrease in response time on Instagram</li>
            <li>7,296+ DM BOT contact list growth</li>
          </ul>
          <h2>Challenge</h2>
          <p>
            One of the key KPIs for the marketing team at Mindvalley is to drive
            up lead acquisition for its masterclasses. At the time they were
            dependent on swipe up stories and link in bio to drive people from
            awareness to the masterclasses. Since the team is fully behind
            innovation, they jumped on the opportunity to test out the Instagram
            Automation by DM BOT. <br />
            With over 1.3 million Instagram followers, people are constantly
            messaging the brand on Instagram with questions about its
            masterclasses. With the abundance of direct messages, the Mindvalley
            team saw Instagram Automation as one of the tools to help them
            respond to followers quicker than manually answering messages.
          </p>
          <h2>Goal</h2>
          <p>
            The goal of this project was to not only use Instagram Automation to
            increase masterclass sign-ups, but also provide a more personalized
            experience for followers and start conversations with them that
            would drive more engagement.
          </p>
          <div>
            <img className="py-4" src={midimg} alt="" />
          </div>
          <h2>Approach</h2>
          <ul>
            <li>
              <span> Journey mapping.</span> School of Bots worked with
              Mindvalley to create an automated campaign and conversation
              design. The chatbot building brand used DM BOT’s Flow Builder to
              map out all messages and actions in one place, and created a
              journey that led followers to sign up for
            </li>
            <li>
              <span> Instagram Stories.</span> Mindvalley used organic Instagram
              content to promote the upcoming masterclasses, publishing a mix of
              single-image Stories, feed posts, and interactive quizzes that
              used the Questions sticker to start conversations with customers.
              They used concise copy asking followers to DM them with a specific
              word, phrase, or emoji to chat with them in Instagram Messenger.
              They also placed a call to action (CTA) in the brand’s Instagram
              bio to extend the promotion’s reach.
            </li>
            <li>
              <span>Keyword automation.</span>When followers interacted with
              Mindvalley’s content, they were prompted to message Mindvalley in
              Instagram Messages with a keyword. This keyword would trigger an
              automated response from DM BOT.
            </li>
            <li>
              <span>Data collection. </span> Mindvalley took advantage of User
              Inputs to power the entire conversation, and collected demographic
              data about the person’s goals, challenges, and experience level
              with specific topics. Based on that information, they tailored
              message copy and offers to turn more people into leads.
            </li>
            <li>
              {" "}
              <span>Live Chat.</span>During any point of the conversation, the
              Mindvalley team could jump into a chat to support people further
              if needed. Using DM BOT’s Live Chat feature, they could see the
              person’s entire history with the company. This allowed the team to
              provide personalized customer care rather than just answer
              messages from Instagram’s native inbox.
            </li>
            <h2>
              How Mindvalley increased webinar sign-ups by 522% with DM BOT
            </h2>
            <p>
              Mindvalley launches new products and promotions every week. Free
              masterclasses are the top-of-the-funnel offer they use to attract
              and introduce new people to the brand. It’s a model they’ve
              perfected over the last decade, and is responsible for over $100
              million in annual revenue. Millions of people attend their
              masterclasses each year.
            </p>
            <p>
              Mindvalley has over 100 free masterclass funnels that sell online
              courses and their subscription product. Each week, they promote a
              different masterclass on Instagram with posts, Stories, and links
              in their bio.
            </p>
            <p>
              They don’t promote products for direct sales on Instagram, and
              only promote free masterclasses, which they sell to people through
              pre existing funnels via email and Facebook Messenger bot
              channels.
            </p>
            <p>
              Masterclass registrations are their most important success metric
              for Instagram overall. So Takahashi and the School of Bots team
              designed a Chat Marketing campaign using DM BOT to get more
              sign-ups.
            </p>
            <p>
              The team created Instagram stories with CTA text telling people to
              message a specific word in order to gain access to a free
              Masterclass. For Donna Eden’s class on energy medicine, people
              were prompted to message the keyword “energy” to sign up.
            </p>
          </ul>
        </div>
        <div className="third ">
          <div>
            <a href="#">
              <img src={youtube} alt=".." />
            </a>
          </div>
          <div>
            {" "}
            <a href="#">
              <img src={facebook} alt=".." />
            </a>
          </div>
          <div>
            <a href="">
              <img src={twitter} alt=".." />
            </a>
          </div>
        </div>
      </div>
      <div className="resturant-last">
          <Faqs />
        </div>
        <Freebot
          style={{ backgroundColor: "8A6EFF" }}
          bgColor={"#8A6EFF"}
          txtColor={"#FFF"}
        />
      <Footer />
    </div>
  );
};

export default HBlog;
