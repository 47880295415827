import React from "react";
import "../../style/_guides.scss";
import Firstimg from "../../assets/images/guide/Rectangle 148.png";
import Secondimg from "../../assets/images/guide/Rectangle 148 (1).png";
import Thirdimg from "../../assets/images/guide/Rectangle 148 (2).png";
import Fourimg from "../../assets/images/guide/Rectangle 148 (3).png";
import Fiveimg from "../../assets/images/guide/Rectangle 149.png";
import Siximg from "../../assets/images/guide/Rectangle 149 (1).png";
import Sevenimg from "../../assets/images/guide/Rectangle 149 (2).png";
import Eightimg from "../../assets/images/guide/Rectangle 149 (3).png";
import Nineimg from "../../assets/images/guide/Rectangle 148 (4).png";
import Tenimg from "../../assets/images/guide/Rectangle 148 (5).png";
import Twelveimg from "../../assets/images/guide/Rectangle 148 (6).png";
import Thirteenimg from "../../assets/images/guide/Rectangle 148 (7).png";
import Fourteenimg from "../../assets/images/guide/Rectangle 148 (8).png";
import Fifteenimg from "../../assets/images/guide/Rectangle 148 (9).png";
import Sixteenimg from "../../assets/images/guide/Rectangle 148 (10).png";
import Seventeenimg from "../../assets/images/guide/Rectangle 148 (11).png";
import Eighteenimg from "../../assets/images/guide/Rectangle 148 (12).png";
import Ninteenimg from "../../assets/images/guide/Rectangle 148 (13).png";
import Twentyimg from "../../assets/images/guide/Rectangle 148 (14).png";
import Twentyoneimg from "../../assets/images/guide/Rectangle 148 (15).png";
import Twentytwoimg from "../../assets/images/guide/Rectangle 148 (16).png";
import Twentythreeimg from "../../assets/images/guide/Rectangle 148 (17).png";
import Twentyfourimg from "../../assets/images/guide/Rectangle 148 (18).png";
import { useTranslation } from "react-i18next";
const Imagegallery = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="imagegallery">
        <div className="gallery-content">
          <div className="firstImage">
            <img src={Firstimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Secondimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Thirdimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Fourimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Fiveimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Fiveimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Siximg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Sevenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Eightimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Nineimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Tenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Twelveimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Thirteenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Fourteenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Fifteenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Sixteenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Seventeenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Eighteenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Ninteenimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Twentyimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Twentyoneimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Twentytwoimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Twentythreeimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
          <div className="firstImage">
            <img src={Twentyfourimg} alt="..." />
            <span>{t("How_to_Connect_your_WhatsApp")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Imagegallery;