import React from "react";
import "./customers.css";
import { useTranslation } from "react-i18next";
const Instagram = () => {
  const { t } = useTranslation();
  return (
    <div className="customers">
      <div className="cont">
        <h2>{t("Instagram_Channel")}</h2>
        <p>{t("Start_communicating")}</p>
        <div className="connectbtn">
          <button className="bg-primary">{t("Connect")}</button>
        </div>
      </div>
    </div>
  );
};

export default Instagram;
