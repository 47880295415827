import React from "react";
import "../../style/_integration.scss";
import Button from "../shared/button";
import Googlesheet from "../../assets/icons/googlesheet.svg";
import Zapier from "../../assets/icons/zapier.svg";
import { useLoaderData, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Popular = () => {
  const { t } = useTranslation();
  const location = useLocation();
  console.log(location);

  return (
    <>
      <div className="popular">
        <div className="freebotdm ">
          <div className="freebotdm-title">
            <h2>{t("dm_bot_Integrations")}</h2>
            <p>{t("Connect_DM_BOT_favorite")}</p>
            {location.pathname === "/ctxads" ? (
              <Button
                text={t("Get_Started_Free")}
                width="244px "
                height="41px"
                className="btn-primary freestartBtn "
                onClick={() => alert("Hello!")}
              />
            ) : null}
          </div>
        </div>
        <div className="topContent">
          <div className="title">
            <h6>{t("Most_popular")}</h6>
          </div>
          <div className="cards">
            <div className="cardleft">
              <div className="cardTop">
                <img src={Googlesheet} alt="..." />
              </div>
              <div className="cardbottom">
                <h6>{t("Google_Sheets")}</h6>
                <p>
                  {t("Save_update_retrieve")}
                </p>
              </div>
            </div>
            <div className="cardleft">
              <div className="cardTop">
                <img src={Zapier} alt="..." />
              </div>
              <div className="cardbottom">
                <h6>{t("Zapier")}</h6>
                <p>
                  {t("DM_BOT_is_the_fastest")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popular;
