import React, { Component, useState, useCallback, useEffect } from "react";
import "./Flow.css";
import { Icon, InlineIcon } from "@iconify/react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import FlowMessenger from "./flowMessenger";
import MessengerInputNode from "./messengerInputNode";
import MessengerCanvas from "./messengerCanvas.js";
import { addDataToFirestore } from "../../../../../../config/firebase.js";
import { setText } from "../../../../../../slices/textSlice.js";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  Node,
  Edge,
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  MarkerType,
  useEdges,
  useNodeId,
} from "reactflow";
import "reactflow/dist/style.css";
import MessengerNode from "./MessengerNode.js";
import ScratchNode from "./scratchNode";
import { useSelector } from "react-redux";
import { updateState } from "../../../../../../slices/updaterSlice";
import { useDispatch } from "react-redux";
import { setToTrue, setToFalse } from "../../../../../../slices/updaterSlice";
import { getDataFromFirestore } from "../../../../../../config/firebase.js";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useTranslation } from "react-i18next";
import ActionNode from "../../actionNode.js";

const nodeTypes = {
  MessengerUpdater: MessengerNode,
  ScratchUpdater: ScratchNode,
  MessengerInputUpdate: MessengerInputNode,
  ActionInputNode: ActionNode,
};

const Scratch = () => {
  const updateStates = useSelector(updateState);
  const dispatch = useDispatch();
  const [firebaseData, setFirebaseData] = useState();
  const [showTemp, setShowTemp] = useState(false);
  const [arrayData, setArrayData] = useState([{}]);
  const { state } = useLocation();
  const historyData = state || {};

  const [newCheck, setNewCheck] = useState(true);
  const [newPosition, setNewPosition] = useState({ x: 0, y: 0 });
  const [deletePosition, setDeletePosition] = useState({
    x: 0,
    y: 0,
  });
  const [showDeleteCursor, setShowDeleteCursor] = useState(false);
  // const [edgeId, setEdgeId] = useState("");

  const [buttonColor, setButtonColor] = useState("");

  const [nodeId, setNodeId] = useState("2");
  const [selectTabModal, setSelectTabModal] = useState(true);
  const [selectTabModal2, setSelectTabModal2] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [inputChange, setInputChange] = useState("");

  const [isLeftDivVisible, setIsLeftDivVisible] = useState(false);
  // const [dikha, setdikha] = useState(false);
  // const handleClose = () => setdikha(false);
  const [speed, setSpeed] = useState(false);

  // const handleClosed = () => setSpeed(false);
  // const handleShowd = () => setSpeed(true);
  const [dikha, setdikha] = useState(false);
  const handleClosed = () => setdikha(false);
  const handleShowd = () => setdikha(true);
  const [showFlowComponent, setShowFlowComponent] = useState(false);
  const [conditioncanvas, setconditioncanvas] = useState(false);
  const handleCloseFlowComponent = () => setShowFlowComponent(false);
  const handleShowFlowComponent = () => setShowFlowComponent(true);
  const handleClosecondition = () => setconditioncanvas(false);
  const handleShowcondition = () => setconditioncanvas(true);
  const navigate = useNavigate();

  let x = 1000;
  let y = 100;

  useEffect(() => {
    {
      if (historyData.item.data) {
        if (historyData.item.data.metaData) {
          setButtonColor("green");
        }
        const keys = Object.keys(historyData.item.data);
        let newArray = keys.filter((word) => word !== "metaData");
        setNodeId(
          newArray[newArray.length - 1] ? newArray[newArray.length - 1] : " 2"
        );
        for (let i = 0; i < newArray.length; i++) {
          const newNode = {
            id: newArray[i],
            type: "MessengerInputUpdate",
            position: { x: x, y: y },
            data: {},
          };
          x = x + 400;
          y = y + 100;
          setNodes((nds) => [...nds, newNode]);

          const newEdge = {
            id: `${parseInt(newArray[i] - 1)} - ${parseInt(newArray[i])}`,
            source: `${parseInt(newArray[i] - 1)}`,
            target: newArray[i],
            arrowHeadType: "arrowclosed",
          };
          setEdges((eds) => [...eds, newEdge]);
        }
      }
    }
  }, []);

  // REACT FLOW
  const { t } = useTranslation();
  const replaceNode = (id, xPos, yPos) => {
    const replaceNodeID = id;

    if (replaceNodeID === "2") {
      const newNode = {
        id: "2",
        type: "MessengerInputUpdate",
        position: { x: xPos, y: yPos },
        data: { value: 123, newArray: arrayData },
      };

      setNodes((nds) => [...nds, newNode]);
    }
  };
  // let newId = 3;
  // const getId = () => `${id++}`;
  const addNode = () => {
    // const id = getId();
    const newNode = {
      id: `${parseInt(nodeId) + 1}`,
      type: "MessengerInputUpdate",
      position: {
        x: nodes[nodes.length - 1].position.x + 400,
        y: nodes[nodes.length - 1].position.y + 100,
      },
    };

    setNodeId(parseInt(nodeId) + 1);
    setNodes((nds) => [...nds, newNode]);

    const newEdge = {
      id: `${parseInt(nodeId)}-${parseInt(nodeId) + 1}`,
      source: `${parseInt(nodeId)}`,
      target: `${parseInt(nodeId) + 1}`,
      arrowHeadType: "arrowclosed",
    };

    setEdges((eds) => [...eds, newEdge]);
  };

  useEffect(() => {
    replaceNode();
  }, [arrayData]);

  let initialNodes = [];

  initialNodes = [
    {
      id: "1",
      type: "ScratchUpdater",
      position: { x: 400, y: 200 },
      data: { value: 123 },
    },
    {
      id: "2",
      type: "MessengerUpdater",
      position: { x: 1000, y: 100 },
      data: {
        handleChangeMessage: replaceNode,
        showTemp,
        setShowTemp,
      },
    },

    {
      id: "3",
      type: "ActionInputNode",
      position: { x: 800, y: 500 },
      // data: { value: 123 },
    },
  ];

  const initialEdges = [
    {
      id: "edge-1",
      source: "1",
      target: "2",

      arrowHeadType: "arrowclosed",
    },
  ];

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const handleClose = () => {
    dispatch(setToFalse());
  };

  const removeEdge = (e, id) => {
    const edgeId = id.id;

    setEdges((eds) => eds.filter((edge) => edge.id !== edgeId));
  };

  const onConnect = useCallback(
    (connection) =>
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            markerEnd: { type: MarkerType.ArrowClosed },
            data: { setEdges },
          },
          eds
        )
      ),
    [setEdges]
  );

  //----------------------------------------------

  const [dataOne, setDataOne] = useState([
    {
      id: "1",
      headingOne: "Facebook Ads Json",
      headingTwo: "User clicks a Facebook Ad",
    },
    {
      id: "2",
      headingOne: "Facebook Comments",
      headingTwo: "User comments on your Post",
    },
    {
      id: "3",
      headingOne: "Messenger Keywords",
      headingTwo: "User ends a Direct Message with a keyword",
    },
    {
      id: "4",
      headingOne: "Messenger Ref Url",
      headingTwo: "User clicks a referal link",
    },
    {
      id: "5",
      headingOne: "QR code",
      headingTwo: "User scans a qr code",
    },
    {
      id: "6",
      headingOne: "Facebook Shop Message",
      headingTwo: "User messages from your facebook shop",
    },
  ]);
  const [dataTwo, setDataTwo] = useState([
    {
      id: "1",
      headingOne: "Message Model",
      headingTwo: "Pop-up modal window",
    },
    {
      id: "2",
      headingOne: "Slide-In Modal",
      headingTwo: "Slide-in modal window",
    },
    {
      id: "3",
      headingOne: "Landing Page",
      headingTwo: "Landing Page",
    },
    {
      id: "4",
      headingOne: "Messenger Customer Chat",
      headingTwo: "Customer Chat",
    },
    {
      id: "5",
      headingOne: "Opt-In Banner",
      headingTwo: "Banner",
    },
    {
      id: "6",
      headingOne: "Takeover Modal",
      headingTwo: "Page takeover modal window",
    },
    {
      id: "7",
      headingOne: "Opt-In Button",
      headingTwo: "Embeddable Button",
    },
    {
      id: "8",
      headingOne: "Opt-In Box",
      headingTwo: "Embeddable Box",
    },
    {
      id: "9",
      headingOne: "Checkbox",
      headingTwo: "Checkbox plugin",
    },
  ]);
  const [dataThree, setDataThree] = useState([
    {
      id: "1",
      headingOne: "Contact Event",
      headingTwo: "New contact created",
    },
    {
      id: "2",
      headingOne: "Contact Event",
      headingTwo: "Custom field value is changed",
    },
    {
      id: "3",
      headingOne: "Contact Event",
      headingTwo: "System field value is changed",
    },
    {
      id: "4",
      headingOne: "Contact Event",
      headingTwo: "System field value is changed",
    },
    {
      id: "5",
      headingOne: "Contact Event",
      headingTwo: "Tag is applied to contact",
    },
    {
      id: "6",
      headingOne: "Contact Event",
      headingTwo: "Tag is removed from a contact",
    },
    {
      id: "7",
      headingOne: "Contact Event",
      headingTwo: "contact subscribed to a sequence",
    },
    {
      id: "8",
      headingOne: "Contact Event",
      headingTwo: "contact unsubscribed from a sequence",
    },
    {
      id: "9",
      headingOne: "Contact Event",
      headingTwo: "Date/Time occured",
    },
  ]);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const ModalTab = () => {
    setSelectTabModal(true);
    setSelectTabModal2(false);
  };
  const ModalTab2 = () => {
    setSelectTabModal(false);
    setSelectTabModal2(true);
  };
  const handleCanvas = () => {
    setShow(!show);
  };
  const handleShow = () => setShow(true);
  const handleShow2 = () => {
    setShow(!show);
    setdikha(!dikha);
  };

  const setLive = () => {
    const userData = secureLocalStorage.getItem("currentPageProfile");

    const metaData = { live: true, accessToken: userData.access_token };

    addDataToFirestore("Flow", historyData.docName, metaData, "metaData")
      .then((documentId) => {
        console.log(`Data added successfully with ID: ${documentId}`);
      })
      .catch((error) => {
        console.error(`Error adding data: ${error}`);
      });
  };
  return (
    <div className="mainFlow">
      {/* <div className="speedDial">
        <button onClick={handleShowd}>+</button>


      </div> */}
      {/* {showDeleteCursor === true ? (
        <>
          <button
            style={{
              position: "absolute",
              top: `${deletePosition.y - 20}px`,
              left: `${deletePosition.x}px`,
              // zIndex: 5,
              padding: "10px 30px",
            }}
            onClick={() => {
              removeEdge();

              console.log("clicked delete buttons");
            }}
          >
            Delete!
          </button>
        </>
      ) : null} */}

      <div style={{ height: "100%", width: "100%" }}>
        <div className="edittop">
          <div className="edithead">
            <h3
              style={{
                display: "inline",
                border: "2px solid white",
                borderRadius: "25px",
                padding: "0 10px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              {"<"}
            </h3>

            <h3
              style={{ display: "inline", paddingLeft: "20px" }}
              // style={{ paddingLeft: "20px", color: "black", fontWeight: "800" }}
              // onClick={sendingDataToFirestore}
            >
              {t("EDIT")} {">"}{" "}
            </h3>
          </div>
          <div className="topbtns">
            <div className="addtrigbtn">
              <button
                onClick={() => {
                  addNode();
                }}
              >
                {t("Add_trigger")}
              </button>
            </div>
            <div className="setlivebtn">
              <button
                // style={{
                //   margin: "10px 30px",
                //   padding: "10px",
                //   backgroundColor: buttonColor,
                //   borderRadius: "10px",
                //   border: "1px solid white",
                //   color: "white",
                // }}

                style={{ backgroundColor: buttonColor && buttonColor }}
                onClick={() => {
                  setLive();
                  setButtonColor("green");
                  // addDataToFirestore("Flow", historyData.docName, "", "", true);
                }}
              >
                {t("setLive")}
              </button>
            </div>
          </div>
          {/* <input
            type="text"
            name=""
            id=""
            onChange={(e) => {
              sendingDataToFirestore(e.target.value);
            }}
          /> */}
        </div>

        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          onNodeClick={(e, id) => {
            if (id.type === "MessengerInputUpdate") {
              dispatch(setToTrue());

              dispatch(setText(id.id));
            }

            setNewPosition({});
          }}
          onNodeMouseEnter={(e, id) => {
            // setNodeId(id);
            // console.log("id::::", id);
          }}
          onNodeDoubleClick={(e, id) => {
            // replaceNode(e, id);
          }}
          onEdgeClick={(e, id) => {}}
          onEdgeDoubleClick={(e, id) => {
            removeEdge(e, id);
          }}
          onEdgeMouseEnter={(e, id) => {
            // console.log("event::", e, "id::", id);

            setDeletePosition({
              x: e.pageX,
              y: e.pageY,
            });

            // setShowDeleteCursor(true);
            // setEdgeId(id.id);
          }}
          // onEdgeMouseLeave={() => {
          //   setShowDeleteCursor(false);
          // }}
        >
          {/* <Background /> */}
        </ReactFlow>
        <MessengerCanvas
          show={updateStates}
          handleClose={handleClose}
          // arrayCallback={arrayCallback}
        />
      </div>
      {/* <Offcanvas show={speed} onHide={handleClosed} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Starting Step</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="canvbody-right">
            <div className="upperSection">
              <div className="trigger">
                <Icon
                  icon="clarity:play-line"
                  color="#72e1aa"
                  width="25"
                  height="25"
                />
                <span>Trigger</span>
              </div>
            </div>
            <div className="upperTitle">
              <h6>Content</h6>
            </div>
            <div className="middleSection">
              <div className="triggers" onClick={handleShowFlowComponent}>
                <Icon
                  icon="logos:messenger"
                  color="black"
                  width="25"
                  height="25"
                />
                <span>Messenger</span>
              </div>
              <div className="triggers">
                <Icon icon="tabler:plus" color="black" width="25" height="25" />
                <span>Channel</span>
              </div>
              <div className="triggers">
                <Icon
                  icon="clarity:play-line"
                  color="#72e1aa"
                  width="25"
                  height="25"
                />
                <span>Start Flow</span>
              </div>
            </div>
            <div className="upperTitle">
              <h6>Logic</h6>
            </div>
            <div className="middleSection">
              <div className="triggers">
                <Icon
                  icon="mdi:thunder"
                  color="#f90"
                  width="24"
                  height="24"
                  className="iconMessenger"
                />
                <span>Actions</span>
              </div>
              <div className="triggers">
                <Icon
                  icon="mingcute:filter-line"
                  color="#35CAEF"
                  width="24"
                  height="24"
                  className="iconMessenger"
                />
                <span>Conditions</span>
              </div>
              <div className="triggers">
                <Icon
                  icon="humbleicons:arrow-split"
                  color="#9969F8"
                  width="24"
                  height="24"
                  className="iconMessenger"
                />
                <span>Randomizer</span>
              </div>
              <div className="triggers">
                <Icon
                  icon="ep:clock"
                  color="#EB5139"
                  width="24"
                  height="24"
                  className="iconMessenger"
                />{" "}
                <span>Smart Delay</span>
              </div>
            </div>
            <div className="upperTitle">
              <h6>Extras</h6>
            </div>
            <div className="middleSection">
              <div className="triggers">
                <Icon
                  icon="basil:document-solid"
                  color="#ffbd00"
                  width="25"
                  height="25"
                />
                <span>Comment</span>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      {/* Wrapper of two components */}
      <div className="two">
        {/* design/when */}
        {/* <div className="flow">
          <div className="one">
            <Icon
              icon={"material-symbols:electric-bolt"}
              width="1em"
              height="1em"
              color="black"
            />
            <p className="text" onClick={handleCanvas}>
              When...
            </p>
          </div>
          <div className="two" onClick={handleCanvas}>
            <p className="text">
              A Trigger is an event that starts your Flow{" "}
              <strong style={{ fontWeight: "bold" }}>Click to</strong> add a
              Trigger
            </p>
          </div>
          <div className="three" onClick={openModal}>
            <p className="text">+ Start From Scratch</p>
          </div>
        </div> */}
        {/* Off Canvas */}
        <Offcanvas show={show} onClick={handleCanvas} className=" offcanvas">
          <Offcanvas.Header className="canvasHeader">
            <Offcanvas.Title className="titletext">
              <div className="canvasHeader">
                <span> When...</span>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="offcanvasBody">
              <div className="topSection">
                <span>+ New Trigger</span>
              </div>
              <hr />
              <div className="middleSection">
                <span>Then...</span>
              </div>
              <div className="topSection" onClick={handleShow2}>
                <span> Choose Next Step</span>
              </div>
            </div>
            <></>
          </Offcanvas.Body>
        </Offcanvas>
        {/* Modal */}
        <Modal show={dikha} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Choose Next Step</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="modalBody">
              <div className="topSections">
                <span>
                  {" "}
                  <Icon
                    icon="logos:messenger"
                    width="20"
                    height="20"
                    className="iconMessenger"
                  />{" "}
                  Messenger
                </span>
              </div>
              <div className="topSections">
                <span>
                  {" "}
                  <Icon
                    icon="mdi:thunder"
                    color="#f90"
                    width="24"
                    height="24"
                    className="iconMessenger"
                  />
                  Perform Actions
                </span>
              </div>
              <div className="topSections">
                <span>
                  {" "}
                  <Icon
                    icon="mingcute:filter-line"
                    color="#35caef"
                    width="24"
                    height="24"
                    className="iconMessenger"
                  />
                  Conditions
                  <button>PRO</button>
                </span>
              </div>
              <div className="topSections">
                <span>
                  {" "}
                  <Icon
                    icon="humbleicons:arrow-split"
                    color="#9969f8"
                    width="24"
                    height="24"
                    className="iconMessenger"
                  />
                  Randomizer <button>PRO</button>
                </span>
              </div>
              <div className="topSections">
                <span>
                  {" "}
                  <Icon
                    icon="ep:clock"
                    color="#eb5139"
                    width="24"
                    height="24"
                    className="iconMessenger"
                  />{" "}
                  Smart Delay <button>PRO</button>
                </span>
              </div>
              <div className="topSections">
                <span>
                  {" "}
                  <Icon
                    icon="iconoir:frame-select"
                    color="#3fa2ff"
                    width="24"
                    height="24"
                    className="iconMessenger"
                  />
                  Select Existing Step
                </span>
              </div>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>

        {/* Modal */}
        {isModalOpen == true ? (
          <div className="modal">
            <div className="modal-content">
              <div className="header">
                {/* <button
                className="text-black cursor-pointer"
                onClick={closeModal}
              >
                close
              </button> */}
                <Icon
                  icon="ion:close-outline"
                  width="40"
                  height="40"
                  onClick={closeModal}
                />
                <h5>Start automation when...</h5>
                <input
                  className="inputStyles"
                  placeholder="Search in Messenger"
                ></input>
              </div>
              <div className="menuModal">
                <div className="left">
                  <div className="divOne" onClick={ModalTab}>
                    <Icon
                      icon={"logos:messenger"}
                      width="1em"
                      height="1em"
                      color="black"
                    />
                    <p className="text">Messenger</p>
                  </div>
                  <div className="divTwo" onClick={ModalTab2}>
                    <Icon
                      icon={"cil:contact"}
                      width="1em"
                      height="1em"
                      color="black"
                    />
                    <p className="text">Contact Events</p>
                  </div>
                </div>
                <div className="right">
                  {selectTabModal == true ? (
                    <>
                      <p className="heading">Triggers</p>
                      <p className="subHeading">
                        Specific Messenger event that starts your
                      </p>

                      {dataOne.map((item) => (
                        <div className="mapDiv">
                          <Icon
                            icon={"logos:messenger"}
                            width="1em"
                            height="1em"
                            color="black"
                          />
                          <div className="mapDivInner">
                            <p className="textOne">{item.headingOne}</p>
                            <p className="textTwo">{item.headingTwo}</p>
                          </div>
                        </div>
                      ))}

                      <p className="heading">Website widgets</p>
                      <p className="subHeading">
                        Embeddable blocks with call to action button or checkbox
                        on your website.
                      </p>
                      {dataTwo.map((item) => (
                        <div className="mapDiv">
                          <Icon
                            icon={"logos:messenger"}
                            width="1em"
                            height="1em"
                            color="black"
                          />
                          <div className="mapDivInner">
                            <p className="textOne">{item.headingOne}</p>
                            <p className="textTwo">{item.headingTwo}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : selectTabModal2 == true ? (
                    <>
                      <p className="heading">Contact Events</p>
                      <p className="subHeading">
                        Contact event is a specific event on the DM BOT side
                        that starts your automation.
                      </p>

                      {dataThree.map((item) => (
                        <div className="mapDiv">
                          <Icon
                            icon={"cil:contact"}
                            width="1em"
                            height="1em"
                            color="black"
                          />
                          <div className="mapDivInner">
                            <p className="textOne">{item.headingOne}</p>
                            <p className="textTwo">{item.headingTwo}</p>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* <div className="choose">
          <FlowMessenger
            show={showFlowComponent}
            handleClose={handleCloseFlowComponent}
            handleShow={handleShowFlowComponent}
            Close={handleClosed}
            sho={handleShowd}
            close={handleClosecondition}
            shw={handleShowcondition}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Scratch;
