import React from 'react'
import "./botfield.css"
import { useTranslation } from "react-i18next";
const Botfield = () => {
  const { t } = useTranslation();
  return (
    <div className='bot'>
        <div className="botfield">
            <h2>{t("No_Bot")}</h2>
            <p>{t("first_Bot_field")}</p>
            <div className="botbtn">
                <button>+ {t("New_Bot_Field")}</button>
            </div>
        </div>
      
    </div>
  )
}

export default Botfield
