import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Terms.css";
import { useTranslation } from "react-i18next";
const PrivacyPolicy = ({ privacyModelValue, setprivacyModelValue }) => {
  const handleClose = () => setprivacyModelValue(false);
  const { t } = useTranslation();
  // const handleShow = () => setModalValue(true);

  return (
    //

    <>
      <Modal
        className="TermModal"
        scrollable
        show={privacyModelValue}
        onHide={handleClose}
      >
        <Modal.Header
          style={{
            background: "linear-gradient(#4ECCAA, #A1F7E2, #97F5E3, #7EDCC3)",
          }}
          closeButton
        >
          <Modal.Title className="m-auto">
            <h5>{t("Privacy_Policy_DM_Bot")}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Body">
            <p>{t("Privacy_is_among_the")}</p>
            <h6
              style={{
                marginBottom: "4%",
              }}
            >
              {t("We_engage_in_the_processing")}
            </h6>
            <ol
              style={{
                listStyleType: "lower-roman",
              }}
            >
              <li>{t("For_our_internal")}</li>
              <li>{t("Based_on_the_instructions")}</li>
              <li>{t("In_the_latter_case")}</li>
            </ol>
            <p>{t("This_Privacy_Policy_outlines")}</p>
            <p>{t("The_scope_of_this_policy")}</p>
            <p>{t("For_any_inquiries")}</p>
            <p>{t("This_Privacy_Policy_is_designed")}</p>
            <h5>1. {t("How_We_Collect")}</h5>
            <p>{t("We_are_liable_to_collect")}</p>

            <ul
              style={{
                marginBottom: "4%",
                marginLeft:"8%"
              }}
            >
              <li>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("User_provided")}:
                </span>{" "}
                {t("refers_to_when_you_create")}
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Automated_Data")}:
                </span>
                {t("may_automatically_collect_information")}
              </li>
              <li>

                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Using_the_website")}:
                </span>{" "}
                {t("means_agreeing_to_share")}
              </li>
              <li>
                {" "}
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Contact_us")}:
                </span>{" "}
                {t("for_query_emails_or")}
              </li>
            </ul>
            <h5>2. {t("What_Types_of_Personal")}</h5>
            <p>{t("We_gather_and_manage")}</p>
            <ul
              style={{
                marginBottom: "4%",
                marginLeft:"8%"
              }}
            >
              <li>
                {" "}
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Customer_Account")}:
                </span>{" "}
                {t("To_establish_or_update_your")}
              </li>
              <li>
                {" "}
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Financial_Information")}:
                </span>{" "}
                {t("To_facilitate_payment_processing")}
              </li>
              <li>
                {" "}
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Contact_Details_and")}:
                </span>{" "}
                {t("Information_about_our_customers")}
              </li>
              <li>
                {" "}
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Requests_Messages")}:
                </span>{" "}
                {t("Messages_support_requests")}
              </li>
              <li>
                {" "}
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Usage_Data_Logs")}:
                </span>{" "}
                {t("Interaction_with_the_Service")}
              </li>
              <li>
                {" "}
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Website_Cookies")}:
                </span>{" "}
                {t("Cookie_technology")}
              </li>
              <li>
                {" "}
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Customer_Content")}:
                </span>{" "}
                {t("As_you_utilize_our_Service")}
              </li>
            </ul>

            <p>{t("We_kindly_request_that_you")}</p>
            <h5>3. {t("For_Which_Purposes")}</h5>
            <p>{t("We_gather_and_process")}</p>
            <ul
              style={{
                marginBottom: "4%",
                marginLeft:"8%"
              }}
            >
              <li>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Service_Operations")}:
                </span>{" "}
                {t("Your_data_is_utilized")}
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Legal_Compliance")}:
                </span>{" "}
                {t("Your_personal_data_is_utilized")}
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Negotiation")}:
                </span>{" "}
                {t("Information_about_our_counterparts")}
              </li>
              <li>
                <span
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {t("Compliance")}:
                </span>{" "}
                {t("Your_personal_data_is")}
              </li>
            </ul>

            <h5>4. {t("How_We_Share")}</h5>
            <p>{t("We_refrain_from_selling")}</p>
            <p>{t("We_reveal_personal")}</p>
            <ul
              style={{
                marginBottom: "4%",
                marginLeft:"8%"
              }}
            >
              <li>{t("We_may_share_personal")}</li>
              <li>{t("Similarly_the_said")}</li>
              <li>{t("For_the_provision_of")}</li>
            </ul>
            <h5>5. {t("Your_Data_Protection")}</h5>
            <p>{t("You_have_the")}</p>
            <ul
              style={{
                marginBottom: "4%",
                marginLeft:"8%"
              }}
            >
              <li
                style={{
                  fontWeight: "600",
                }}
              >
                {t("Access_Correct")}
              </li>
            </ul>
            <p>{t("Access_correct_or_delete")}</p>
            <ul
              style={{
                marginBottom: "4%",
                marginLeft:"8%"
              }}
            >
              <li
                style={{
                  fontWeight: "600",
                }}
              >
                {t("Opt_Out")}
              </li>
            </ul>
            <p>{t("Opt_Out_of_certain")}</p>
            <h5>6. {t("For_How_Long")}</h5>
            <p>{t("We_retain_personal")}</p>
            <h5>7. {t("Data_Transfers")}</h5>
            <p>{t("Your_personal_data_may")}</p>
            <h5>8. {t("Security")}</h5>
            <p>{t("We_employ_reasonable")}</p>
            <p>{t("Despite_our_endeavors")}</p>
            <p>{t("Our_credit_card_processing")}</p>
            <p>{t("For_inquiries_regarding")}</p>
            <ul
              style={{
                marginLeft:"8%"
              }}
            >
              <li style={{
                fontWeight:"600"
              }}>{t("Security_Breach")}</li>
            </ul>
            <p>{t("In_the_event_of")}</p>
            <h5>9. {t("Changes_to_Our")}</h5>
            <p>{t("We_may_update_this")}</p>
            <p>{t("questions_or_concerns_about")}</p>
            <p>{t("you_for_trusting")}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PrivacyPolicy;
