import React from "react";
import "../style/_facebook.scss";
import { Icon } from "@iconify/react";
const Facebook = () => {
  return (
    <div>
      <div className="facebookSection">
        <div className="facebookContent">
          <div className="leftContent">
            <div className="content">
              <div className="title">
                <h6>DM Bot </h6>
              </div>
              <div className="midSection">
                {/* <img src="" alt=".." /> */}
                <div className="msngicon">
                <Icon
                  icon="logos:messenger"
                  color="gray"
                  width="200"
                  height="200"
                /></div>
                <div className="titleHeading">
                  <h6>
                    Connect <br /> Facebook Page
                  </h6>
                  <p>
                    Follow the instruction to create your first Messenger <br />
                    automation
                  </p>
                </div>
              </div>
              <div className="bottomSection">
                <Icon
                  icon="iconamoon:arrow-up-2-light"
                  color="gray"
                  width="25"
                  height="25"
                  rotate={3}
                />{" "}
                <span>Choose another channel</span>
              </div>
            </div>
          </div>
          <div className="rightContent">
            <div className="content">
              <div className="title">
                <h6>We found 2 facebook pages managed by you.</h6>
              </div>
              <div className="combine">
                <div className="profilesection">
                  <div className="left">
                    <Icon
                      icon="pajamas:profile"
                      color="gray"
                      width="20"
                      height="20"
                    />
                    <span>Asian Noobs</span>
                  </div>
                  <div className="right">
                    <button>Connected</button>
                  </div>
                </div>
                <hr />
                <div className="profilesection">
                  <div className="left">
                    <Icon
                      icon="pajamas:profile"
                      color="gray"
                      width="20"
                      height="20"
                    />
                    <span>Asian Noobs</span>
                  </div>
                  <div className="right">
                    <button>Connected</button>
                  </div>
                </div>
                <hr />
              </div>

              <div className="links">
                <a href="#">I can't see the page I want</a>
                <a href="#">Create new page</a>
                <a href="#">Refresh Page list</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facebook;
