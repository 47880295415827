import React from "react";
import "../../style/_template.scss";
import BannerImg from "../../assets/images/template-banner.png";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="templateBanner">
        <div className="banner-content">
          <div className="title">
            <h6>{t("Chat_Marketing_Templates")}</h6>
            <span>{t("By_Neil")}</span>
          </div>
          <div className="bannerBottom">
            <div className="left">
              <img src={BannerImg} alt="..." />
            </div>
            <div className="right">
              <div className="right-paragrapgh">
                <p>
                  {t("Whether_you_new")}
                </p>
                <div className="template-btn">
                  <button>{t("Get_All")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
