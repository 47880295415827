import React from 'react'
import "./installtemp.css"
import { useTranslation } from "react-i18next";
const Installtemp = () => {
  const { t } = useTranslation();
  return (
    <div className='installtemp'>
      <div className="tempheader">
        <h2>{t("Installed_Templates")}</h2>
        {/* <hr /> */}
      </div>
      <div className="tempcont">
        <h1>{t("haven’t_installed_templates")}</h1>
        <button>{t("Install_First_Templates")}</button>
      </div>
    </div>
  )
}

export default Installtemp
