import React from "react";
import Banner from "./eCommerce/banner";
import Editor from "./eCommerce/editor";
import Latest from "./eCommerce/latest";

const ECommerce = () => {
  return (
    <div>
      <Banner />
      <Editor />
      <Latest />
    </div>
  );
};

export default ECommerce;
