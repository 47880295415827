import React from "react";
import "../../style/_ecommerce.scss";
import Football from "../../assets/images/homepage/first.png";
import FootballKor from "../../assets/images/homepage/first.png";
import Blogs from "../../assets/images/homepage/second.png";
import BlogsKor from "../../assets/images/homepage/second.png";
import Agencies from "../../assets/images/homepage/third.png";
import AgenciesKor from "../../assets/images/homepage/third.png";
import { useTranslation } from "react-i18next";
const Gotback = () => {
  const { t } = useTranslation();
  const {i18n} = useTranslation();
  return (
    <>
      <div className="guide-gotback">
        <div className="gotback">
          <div className="acticards v-center h-center flex-column">
            <h3 className="medium text-black">{t("Got_Your_Back")}</h3>
            <p className="large text-black mt-4">
             {t("Our_customer_support_extensive_library_of_best")}
            </p>
            <div className="customer-cards d-flex">
              <div className="card">
                <div className="card-head">
                  <img
                    src={i18n.language == 'en' ? Football : FootballKor}
                    alt="football-img"
                    className="img-fluid"
                  />
                </div>
                <div className=" card-body v-center h-center">
                  <p className="small-2">{t("Join_Our_Community")}</p>
                </div>
                <div className="community">
                  {/* <img src={community} alt="community-img" /> */}
                </div>
              </div>

              <div className="card">
                <div className="card-head">
                  <img src={i18n.language == 'en' ? Blogs : BlogsKor} alt="mindvalley-img" className="img-fluid" />
                </div>
                <div className="card-body  v-center h-center">
                  <p className="small-2">{t("Discover_Our_Blog")}</p>
                </div>
                <div className="blog">
                  {/* <img src={blog} alt="blog-img" /> */}
                </div>
              </div>

              <div className="card">
                <div className="card-head">
                  <img src={i18n.language == 'en' ? Agencies : AgenciesKor} alt="frazer-img" className="img-fluid" />
                </div>
                <div className=" card-body v-center h-center">
                  <p className="small-2">{t("Get_Help_Experts")}</p>
                </div>
                <div className="agencies">
                  {/* <img src={agencies} alt="agencies-img" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gotback;
