import React from "react";
import "../../../style/main.scss";
import press from "../../../assets/images/blog/press.png";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="partner-banner v-center h-center flex-column">
        <div className="freebotdm-title v-center h-center flex-column">
          <h2 className="one">{t("Press_Release")}</h2>
          {/* <p className="large text-black">
            Get the latest insights to grow your marketing agency.
          </p> */}
          <img src={press} alt="partner-image" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};
export default Banner;












