import React from "react";
import "../../../style/main.scss";
import aa from "../../../assets/images/blog/Rectangle 148.png";
import bb from "../../../assets/images/blog/Rectangle 148 (1).png";
import cc from "../../../assets/images/blog/Rectangle 148 (2).png";
import dd from "../../../assets/images/blog/Rectangle 148 (3).png";
import ee from "../../../assets/images/blog/Rectangle 148 (4).png";
import ff from "../../../assets/images/blog/Rectangle 148 (5).png";
import gg from "../../../assets/images/blog/Rectangle 148 (6).png";
import hh from "../../../assets/images/blog/Rectangle 148 (7).png";
import ii from "../../../assets/images/blog/Rectangle 148 (8).png";
import jj from "../../../assets/images/blog/Rectangle 148 (9).png";
import kk from "../../../assets/images/blog/Rectangle 148 (10).png";
import ll from "../../../assets/images/blog/Rectangle 148 (11).png";
import mm from "../../../assets/images/blog/Rectangle 148 (12).png";
import nn from "../../../assets/images/blog/Rectangle 148 (13).png";
import oo from "../../../assets/images/blog/Rectangle 148 (14).png";
import pp from "../../../assets/images/blog/Rectangle 148 (15).png";
import qq from "../../../assets/images/blog/Rectangle 148 (16).png";
import rr from "../../../assets/images/blog/Rectangle 148 (17).png";
import ss from "../../../assets/images/blog/Rectangle 148 (18).png";
import tt from "../../../assets/images/blog/Rectangle 148 (19).png";
import uu from "../../../assets/images/blog/Rectangle 148 (20).png";
import vv from "../../../assets/images/blog/Rectangle 148 (21).png";
import ww from "../../../assets/images/blog/Rectangle 148 (22).png";
import xx from "../../../assets/images/blog/Rectangle 148 (23).png";
import { useTranslation } from "react-i18next";
const Latest = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="latest-posts">
        <h3>{t("LATEST_POSTS")}</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={aa} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={bb} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={cc} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={dd} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={ee} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={ff} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={gg} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={hh} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={ii} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={jj} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={kk} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={ll} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={mm} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={nn} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={oo} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={pp} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={qq} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={rr} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={ss} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={tt} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={uu} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={vv} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={ww} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={xx} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Latest;









