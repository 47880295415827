import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
const Increase = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="increase ">
        <div className="freebotdm-title v-center h-center flex-column">
          <h2 className="one">{t("Reach_SMS_Messaging")}</h2>
          <p className="large text-black">
            {t("85_customers_prefer")}
          </p>

          <Button
            text={t("Get_Started_Free")}
            width="244px "
            height="51px"
            className="btn-primary mt-5 "
            onClick={() => alert("Hello!")}
          />
        </div>
      </div>
    </div>
  );
};

export default Increase;
