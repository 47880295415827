import React, { useState } from "react";
import "../../../../style/_main.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

const SecondTab = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div>
        <div className="secondTab">
          <div className="secondTab-Content">
            <div className="cardSection">
              <div className="cardCpntent">
                <h6>{t("Create_first_Group")}</h6>
                <p>
                  {t("Group_team_members_based")}{" "}
                  <br />
                  {t("Use_groups_to_organize")} <br />
                  {t("conversations_Learn_more")}
                </p>
                <button onClick={handleShow}>{t("+_Group")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-title">{t("Create_Group")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="modalBody">
            <div className="topSection">
              <label htmlFor="Group Name" className="gName">
                {t("Group_Name")}
              </label>{" "}
              <br />
              <input type="text" placeholder={t("new_group_name")}/>
            </div>
            <div className="topSection">
              <label htmlFor="Group Name" className="gName">
                {t("Select_Group_Members")}
              </label>{" "}
              <br />
              <div className="radioButton">
                <div className="maincheckbox">
                  <input
                    type="checkbox"
                    id="coding"
                    name="interest"
                    value="coding"
                    className="checkbox"
                  />
                  <label for="coding" className=" checkbox-label">
                   {t("Only_team_members_with")}{" "}
                    <br />
                    {t("conversations_assignment")}.
                  </label>
                </div>
              </div>
            </div>
            <div className="bottomsection">
              <div className="sectionContentf">
                <div className="bottomLeft">
                  <h6>{t("Team_members")}</h6>
                </div>
                <div className="bottomLeft">
                  <h6>{t("Live_Chat_Seat")}</h6>
                </div>
                <div className="bottomLeft">
                  <h6>{("Role")}</h6>
                </div>
              </div>
              {/* <hr /> */}
              <div className="sectionContent">
                <div className="bottomLeft">
                  <div className="radioButton">
                    <div className="maincheckbox">
                      <input
                        type="checkbox"
                        id="coding"
                        name="interest"
                        value="coding"
                        className="checkbox"
                      />
                      <label for="coding" className=" checkbox-label2">
                        {t("Emma_Loop")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="bottomLeft"></div>
                <div className="bottomLeft">
                  <h6>{("Owner")}</h6>
                </div>
              </div>
            </div>
          </div>{" "}
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Button onClick={handleClose} className="cancelButton">
            {t("Cancel")}
          </Button>
          <Button onClick={handleClose} className="saveButton">
            {t("Save_Changes")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SecondTab;
