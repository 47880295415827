import React from "react";
import "../../style/_ctxAds.scss";
import { useTranslation } from "react-i18next";
// import Vedio from "assets\video-ad-format-in-stream-5-second-skip-ad-forma-2022-09-23-16-10-53-utc.mp4";
const Drag = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="drag-section">
        <div className="drag">
          <div className="drag-content flex-column v-center h-center">
            <h3 className="medium">{t("Support_every_step")}</h3>
            <p className="large">
              {t("Access_over_600_advanced")}
            </p>
            <h5>
              {t("Learn_more_about_how")}
            </h5>
            <div className="pics mt-4">
              <video autoPlay loop muted playsInline>
                <source
                  src="assets\video-ad-format-in-stream-5-second-skip-ad-forma-2022-09-23-16-10-53-utc.mp4"
                  type="video/mp4"
                />
              </video>
              {/* <img src={drag} alt="drag" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drag;
