import React from 'react'
import "../log.css"
import { useTranslation } from "react-i18next";
const Generaltab = () => {
  const { t } = useTranslation();
  return (
    <div className='generl'>
      <h2>
      {t("No_Facebook_notifications")}
      </h2>
      <p>   
      {t("see_Facebook_warning")}
      </p>
    </div>
  )
}

export default Generaltab
