import React from "react";
import Banner from "./partner/banner";
import Editor from "./partner/editor";
import Latest from "./partner/latest";
import Freebotdm from "../home/freebotdm";
import Footer from "../shared/footer";

const Partner = () => {
  return (
    <div>
      <Banner />
      <Editor />
      <Latest />
      <Freebotdm />
      <Footer />
    </div>
  );
};

export default Partner;
