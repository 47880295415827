import React from 'react'
import "./installapp.css"
import { useTranslation } from "react-i18next";
const Installapp = () => {
  const { t } = useTranslation();
  return (
    <div className='installapp'>
      <div className="header">
        <h2>{t("Installed_Apps")}</h2>
        {/* <hr /> */}
      </div>
      <div className="appcont">
        <h1>{t("no_installed_Apps")}</h1>
<div className="appbtn">
<button>{t("Visit_Store")}</button>
</div>

      </div>
    </div>
  )
}

export default Installapp
