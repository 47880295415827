import React, { useEffect, useState, useCallback } from "react";
import { Handle, Position, useNodeId } from "reactflow";
import { Icon, InlineIcon } from "@iconify/react";
import Button from "react-bootstrap/Button";
import "./MessengerNode.css";
import MessengerCanvas from "./messengerCanvas";
import { setText } from "../../../../../../slices/textSlice";
import { setToTrue, setToFalse } from "../../../../../../slices/updaterSlice";
import { useSelector } from "react-redux";
import { updateState } from "../../../../../../slices/updaterSlice";
import { useDispatch } from "react-redux";
import { t } from "i18next";

const MessengerNode = (props) => {
  const dispatch = useDispatch();
  const updateStates = useSelector(updateState);

  const [show, setShow] = useState(props.data.showTemp);
  const handleClose = () => {
    dispatch(setToFalse());
  };
  const handleShow = () => {
    dispatch(setToTrue());
  };

  const handleClickCanvas = useCallback(() => {
    handleShow();
  }, []);
  const handleClickNode = () => {
    props.data.handleChangeMessage(props.id, props.xPos, props.yPos);
    dispatch(setText("2"));
  };

  return (
    <div className="Messenger-node">
      <Handle type="target" position={Position.Left} id="a" />
      <div className="main">
        <h6 className="heading">*{t("Choose_first_step")}</h6>
        <p className="content">{t("Content")}</p>
        <div
          className="messenger-block block"
          onClick={() => {
            dispatch(setToTrue());
            handleClickNode();

            handleShow();

            // setTimeout(() => {
            handleClickCanvas();
            // }, 3000);
          }}
        >
          <Icon icon="logos:messenger" width="20" height="20" />
          <p>{t("Messenger")}</p>
        </div>
        <div></div>
        <p className="content">{t("Logic")}</p>

        <div className="actions-block block">
          <Icon icon="mdi:thunder" color="#f90" width="20" height="20" />
          <p>{t("Actions")}</p>
        </div>

        <div className="Condition-block block">
          <Icon
            icon="mingcute:filter-line"
            color="#35CAEF"
            width="20"
            height="20"
          />
          <p>{t("Condition")}</p>
        </div>

        <div className="randomizer-block block">
          <Icon
            icon="humbleicons:arrow-split"
            color="#9969F8"
            width="20"
            height="20"
          />
          <p>{t("Randomizer")}</p>
        </div>

        <div className="delay-block block">
          <Icon icon="ep:clock" color="#EB5139" width="20" height="20" />
          <p>{t("Smart_Delay")}</p>
        </div>
      </div>
    </div>
  );
};

export default MessengerNode;
