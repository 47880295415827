import React from "react";
import "../../../style/_main.scss";
import { useTranslation } from "react-i18next";
const General = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="general">
        <div className="generalContent">
          <div className="topSection">
            <div className="lefts">
              <h6>{t("Card_URL_Shortener")}</h6>
            </div>
            <div className="middle">
              {" "}
              <button>{t("My_DMBot_com")}</button>
            </div>
            <div className="right">
              <p>
              {t("disable_link_shortening")}
              </p>
            </div>
          </div>
          <div className="topSection">
            <div className="lefts">
              <h6>{t("Account_Time_Zone")}</h6>
            </div>
            <div className="middle">
              {" "}
              <button>{t("Pacific_Time_Los")}</button>
            </div>
            <div className="right">
              <p>
              {t("displayed_and_exported_according")}
              </p>
            </div>
          </div>
          <div className="topSection">
            <div className="lefts">
              <h6>{t("Use_as_Template")}</h6>
            </div>
            <div className="middleFirst">
              {" "}
              <button>{t("Clone_Bot")}</button>
            </div>
            <div className="right">
              <p>{t("Create_a_snapshot")}</p>
            </div>
          </div>
          <div className="topSection">
            <div className="lefts">
              <h6>{t("Use_as_Template")}</h6>
            </div>
            <div className="middleFirst">
              {" "}
              <button>{t("Create_Bot")}</button>
            </div>
            <div className="right">
              <p>{t("Create_a_snapshot")}</p>
            </div>
          </div>
          <div className="topSection">
            <div className="lefts">
              <h6>{t("Contacts_Visibility")}</h6>
            </div>
            <div className="middleFirst">
              {" "}
              <div className="maincheckbox">
                <input
                  type="checkbox"
                  id="coding"
                  name="interest"
                  value="coding"
                  className="checkbox"
                />
                <label for="coding" className=" checkbox-label">
                {t("subscribed_contacts")}
                </label>
              </div>
            </div>
            <div className="right">
              <p>{t("deleted_and_unsubscribed_contacts")}</p>
            </div>
          </div>
          <div className="topSection">
            <div className="lefts">
              <h6>{t("Leave_Account")}</h6>
            </div>
            <div className="middleSecond">
              {" "}
              <button> {t("Leave")}</button>
            </div>
            <div className="right">
              <p>
              {t("Transfer_your_ownership")}
              </p>
            </div>
          </div>
          <div className="topSection">
            <div className="lefts">
              <h6>{t("Delete_Account")}</h6>
            </div>
            <div className="middleThird">
              {" "}
              <button>{t("Delete")}</button>
            </div>
            <div className="right">
              <p>{t("Continue_account_deletion")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
