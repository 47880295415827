import React from "react";
import "../../style/main.scss";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
const Faq = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="faq">
        <h1 className="light-purple v-center h-center">{t("FAQ")}</h1>
        <div className="accor">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t("Can_I_upgrade")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Absolutely")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {t("free_trial")}
              </Accordion.Header>
              <Accordion.Body>
                {t("we_provide_a_free")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {t("What_payment_methods")}
              </Accordion.Header>
              <Accordion.Body>
               {t("We_accept_various_payment")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t("pricing_structured")}
              </Accordion.Header>
              <Accordion.Body>
               {t("Our_pricing_is_based")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
               {t("hidden_fees")}
              </Accordion.Header>
              <Accordion.Body>
                {t("transparent_pricing")}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
