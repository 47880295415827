import { createSlice } from "@reduxjs/toolkit";

const updaterSlice = createSlice({
  name: "updater",
  initialState: {
    value: false,
  },
  reducers: {
    setToTrue: (state) => {
      state.value = true;
    },
    setToFalse: (state) => {
      state.value = false;
    },
  },
});

export const { setToTrue, setToFalse } = updaterSlice.actions;
export const updateState = (state) => state.updater.value;
export default updaterSlice.reducer;
