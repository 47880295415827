import axios from "axios";
import React, { Component, useEffect, useRef, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import ChatBot from "./Chatbot";
import "./LiveChat.css";
import MessengerCustomerChat from "react-messenger-customer-chat";
import conversation from "../../../../assets/images/facebook-conversation.svg";
import io from "socket.io-client";
import useWebSocket from "react-use-websocket";
import { Icon } from "@iconify/react";
import {
  getAllMessagesFromMessageIds,
  getUserData,
  sendMessageToUser,
} from "../../../../config/facebookApis";
import moment from "moment/moment";
import { firestoredb } from "../../../../config/firebase";
import {
  collection,
  doc,
  documentId,
  onSnapshot,
  where,
} from "firebase/firestore";
import { query } from "firebase/database";
// const socket = io("https://hostguru-python.onrender.com");
const socket = io(process.env.REACT_APP_SERVER_URL);
// const socket = io("http://localhost:3000");

// const WS_URL = "ws://127.0.0.1:8000";
const LiveChat = () => {
  const page = secureLocalStorage.getItem("currentPageProfile");

  const [showContactMessages, setshowContactMessages] = useState();
  const [messages, setMessages] = useState([]);
  const messageScreenRef = useRef(null);
  function scrolltoBottom() {
    if (messageScreenRef && messageScreenRef?.current) {
      messageScreenRef.current.scrollTop =
        messageScreenRef.current.scrollHeight;
    }
  }
  useEffect(() => {
    console.log(messages);
    scrolltoBottom();
  }, [messageScreenRef, showContactMessages, messages]);
  //connected to socket
  useEffect(() => {
    console.log("socket:", socket);
    socket.on("message", (message) => {
      console.log("received a new message from the server: ", message);
      message.map((item, index) => {
        console.log(item);
        item.messaging.map((msg, i) => {
          console.log(msg);

          setMessages((prev) => [
            ...prev,
            {
              id: msg.message.mid,
              msg: msg.message,
              status: msg.sender.id == page.id ? "sending" : "receiving",
              timestamp: msg.timestamp,
            },
          ]);
        });
      });
    });
  }, []);
  const [selectTab, setSelectTab] = useState("Unassigned");
  const [chatbotTab, setChatbotTab] = useState(false);

  //user messages
  const [pageAllConversations, setPageAllConversations] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [closeConversation, setCloseConversation] = useState(true);

  //Participant Detail View
  const [psid, setPSID] = useState("");
  const [psName, setPSName] = useState("");
  const [psImage, setPSImage] = useState("");
  const [dataMessages, setDataMessages] = useState([
    {
      image: "/assets/images/image1511.png",
      name: "FriendOne",
      message: "This is a Message  of contact one",
      time: "15 min",
    },
    {
      image: "/assets/images/image1511.png",
      name: "FriendTwo",
      message: "This is a Message of contact two",
      time: "15 min",
    },
    {
      image: "/assets/images/image1511.png",
      name: "FriendThree",
      message: "This is a Message  of contact three",
      time: "15 min",
    },
  ]);

  const [user, setUser] = useState("");
  useEffect(() => {
    const userData = secureLocalStorage.getItem("user");
    setUser(userData);
  }, []);
  useEffect(() => {
    if (user) {
      //Get Conversations of page
      // const page = secureLocalStorage.getItem("currentPageProfile")
      // const apiUrl = `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/${page.id}/conversations`;
      // const fields = "participants,messages{id,message,created_time,from,to}";
      // const accessToken = page.access_token;
      // axios
      //   .get(apiUrl, {
      //     params: {
      //       fields,
      //       access_token: accessToken,
      //     },
      //   })
      //   .then(async (response) => {
      //     // Handle the response data here
      //     console.log(response.data);
      //     const conversations = response.data.data;
      //     // console.log(conversations)
      //     // Fetch profile images for each participant
      //     for (const conversation of conversations) {
      //       // const participants = conversation.participants.data;
      //       let participantImage = ""
      //       // const participantId = participants[0].id;
      //       // // console.log(participantId)
      //       // // Make an additional request to fetch the participant's profile picture
      //       // const participantImageUrl = `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/${participantId}/picture`;
      //       // try {
      //       //   const participantImageResponse = await axios.get(participantImageUrl, {
      //       //     params: {
      //       //       access_token: accessToken
      //       //     }
      //       //   });
      //       //   // console.log(participantImageResponse.data)
      //       //   participantImage = participantImageResponse.data
      //       //   // participant.profileImage = participantImageResponse.request.res.responseUrl;
      //       // } catch (error) {
      //       //   // Handle any errors that occur while fetching profile images
      //       //   console.error(`Error fetching profile image for participant ${participantId}: ${error}`);
      //       // }
      //       // setPageAllConversations(prev => [...prev, { picture: participantImage, messageData: conversation }])
      //     }
      //     // setPageAllConversations(prev => [...prev, { picture: participantImage, messageData: conversation }])
      //   })
      //   .catch((error) => {
      //     // Handle any errors here
      //     console.error(error);
      //   });
    }
  }, [user]);

  const openMessengerNew = () => {
    // Replace the URL with the messenger.com URL for your page

    window.open(`https://www.messenger.com/t/${page.id}`, "_blank");
  };

  const showMessages = (item) => {
    scrolltoBottom();
    setInputValue("");
    setshowContactMessages(item);
    setMessages([]);
    messages.length = 0;
    // conversationIdMessages(item)
    console.log("Messages data", item);
    setPSName(item.userData.first_name + " " + item.userData.last_name);
    setPSID(item.participantId);
    setPSImage(item.userData.profile_pic);
    // getAllMessagesFromMessageIds(item.messages.data)
    // const messageArr = item.messageData.messages.data
    item.messageData.map((message, index) => {
      console.log(message);
      setMessages((prev) => [
        ...prev,
        {
          id: message.id,
          msg: message.msg,
          status: message.status,
          timestamp: message.timestamp,
        },
      ]);
    });
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const sendMessage = () => {
    sendMessageToUser(psid, inputValue);
    setInputValue("");
  };

  function trimAndAddEllipsis(inputString) {
    if (inputString.length > 10) {
      return inputString.slice(0, 10) + "...";
    }
    return inputString;
  }
  const [collectionData, setCollectionData] = useState([]);

  useEffect(() => {
    // Create a reference to your Firestore collection
    const page = secureLocalStorage.getItem("currentPageProfile");
    const collectionRef = doc(firestoredb, "userMessages", page.id);
    // Set up a real-time listener
    const unsubscribe = onSnapshot(collectionRef, (docRes) => {
      // console.log(docRes.exists())
      const source = docRes.metadata.hasPendingWrites ? "Local" : "Server";
      console.log(source, " data: ", docRes.data());
      setPageAllConversations([]);
      pageAllConversations.length = 0;
      if (docRes.exists()) {
        Object.entries(docRes.data()).map(([key, value]) => {
          console.log(key, value);
          getUserData(key)
            .then((res) => {
              setPageAllConversations((prev) => [
                ...prev,
                { participantId: key, messageData: value, userData: res },
              ]);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    });

    return () => {
      // Unsubscribe from the listener when the component unmounts
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    console.log(pageAllConversations);
    // if (pageAllConversations && pageAllConversations.length > 0) {
    //   pageAllConversations.sort(function (a, b) {
    //     return b?.messageData[b.messageData.length - 1]?.timestamp - a?.messageData[a?.messageData.length - 1]?.timestamp
    //   })
    // }
  }, [pageAllConversations]);
  return (
    <div className="livechat">
      <div className="mainDiv">
        <div className="firstSection">
          <div className="first">
            <p className="heading">Conversations</p>
            <p
              className="subHeading"
              onClick={() => setSelectTab("Unassigned")}
            >
              Unassigned
            </p>
            <p className="subHeading" onClick={() => setSelectTab("You")}>
              You
            </p>
            <p className="subHeading" onClick={() => setSelectTab("Team")}>
              Team
            </p>
            <p className="subHeading" onClick={() => setSelectTab("All")}>
              All
            </p>
          </div>

          <div className="second">
            <p className="headingTwo">Channels</p>
            <p
              className="subHeading"
              onClick={() => setSelectTab("All channels")}
            >
              All channels
            </p>
            <p className="subHeading" onClick={() => setSelectTab("Facebook")}>
              Facebook
            </p>
          </div>
        </div>
        <div className="secondSection">
          <div className="upper">
            <p className="heading">{selectTab}</p>
            <p className="subHeading">Open 0</p>
          </div>

          <div className="center">
            {closeConversation === true ? (
              <div
                className="centerDesign"
                onClick={() => setCloseConversation(false)}
              >
                <p className="text">No opened conversations</p>
                <div className="button">
                  <p className="text">Go To Closed Conversation</p>
                </div>
              </div>
            ) : /* This design is for contacts shown on the left */

              pageAllConversations && pageAllConversations.length > 0 ? (
                pageAllConversations.map((item, index) => (
                  // console.log(item)
                  <div
                    className="centerDesignContact"
                    onClick={() => {
                      if (item.participantId != psid) {
                        showMessages(item);
                      }
                    }}
                  >
                    {/* <Icon icon="healthicons:ui-user-profile" width={40} height={40} /> */}
                    <img
                      src={item.userData.profile_pic}
                      style={{ height: 40, width: 40, borderRadius: 30 }}
                      alt="User Avatar"
                    ></img>
                    <div className="inner">
                      <p className="textUserName">
                        {item?.userData?.first_name +
                          " " +
                          item?.userData?.last_name}
                      </p>
                      {item.messageData[item.messageData.length - 1].msg.text ? (
                        <p className="textsecond">
                          {trimAndAddEllipsis(
                            item.messageData[item.messageData.length - 1].msg.text
                          )}
                        </p>
                      ) : item.messageData[item.messageData.length - 1].msg
                        .attachments &&
                        item.messageData[item.messageData.length - 1].msg
                          .attachments.length > 0 ? (
                        <img
                          src={
                            item.messageData[item.messageData.length - 1].msg
                              .attachments[0].payload.url
                          }
                          alt="img"
                          width={40}
                          height={40}
                        />
                      ) : (
                        <></>
                      )}
                      {/* <p className="textsecond">{trimAndAddEllipsis(item.messageData[item.messageData.length - 1].msg.text)}</p> */}
                    </div>
                    <p className="textTime">
                      {moment(
                        item.messageData[item.messageData.length - 1].timestamp
                      ).fromNow(true)}
                    </p>
                  </div>
                ))
              ) : (
                <></>
              )}
          </div>

          <div className="lower"></div>
        </div>

        <div className="thirdSection">
          {showContactMessages != null ? (
            // This is a design of  chat
            <div className="chatDivMain">
              <div className="header">
                <div className="innerDiv">
                  <img
                    src={psImage}
                    style={{ height: 28, width: 28, borderRadius: 30 }}
                    alt="User Avatar"
                  />
                  <p className="text">{psName}</p>
                </div>
                <div className="innerDiv">
                  <Icon
                    icon={"logos:messenger"}
                    width="25"
                    height="25"
                    color="#ff0000"
                  />
                  <p className="text">Facebook</p>
                </div>
              </div>
              <div className="center" ref={messageScreenRef}>
                {messages.map((item) => {
                  const status = item.status;

                  if (status === "sending") {
                    // Sender
                    return (
                      <div className="sender">
                        <div>
                          {item.msg.text ? (
                            <p className="textSender">{item.msg.text}</p>
                          ) : item.msg.attachments &&
                            item.msg.attachments.length > 0 ? (
                            <img
                              src={item.msg.attachments[0].payload.url}
                              alt="img"
                              width={40}
                              height={40}
                            />
                          ) : (
                            <></>
                          )}
                          {/* <p className="textSender">{item.msg}</p> */}

                          <p className="timeText">
                            {moment(item.timestamp).format("LT")}
                          </p>
                        </div>
                      </div>
                    );
                  } else if (status === "receiving") {
                    // Receiver
                    return (
                      <div className="reciever">
                        <div>
                          {item.msg.text ? (
                            <p className="textReciever">{item.msg.text}</p>
                          ) : item.msg.attachments &&
                            item.msg.attachments.length > 0 ? (
                            <img
                              src={item.msg.attachments[0].payload.url}
                              alt="img"
                            />
                          ) : (
                            <></>
                          )}
                          <p className="timeText">
                            {moment(item.timestamp).format("LT")}
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    <></>;
                  }
                })}
              </div>
              <div className="bottom">
                <input
                  type={"text"}
                  value={inputValue}
                  onChange={handleInputChange}
                  className="inputField"
                />
                <div
                  className="button"
                  onClick={(e) => {
                    sendMessage(psid);
                  }}
                >
                  <p className="text">Send</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="bannerMain">
              <p className="text">
                Start by sending a message to your bot to try Live Chat
              </p>
              <div className="button" onClick={openMessengerNew}>
                <p className="textOne">Facebook</p>
                <p className="textTwo">Open</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveChat;
