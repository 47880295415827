import React from "react";
import Banner from "../components/home/banner";
import Trusted from "../components/home/trusted";
import People from "../components/home/people";
import Card from "../components/home/socialcard";
import UseChat from "../components/home/useChat";
import Discover from "../components/home/discover";
import Enterpreneur from "../components/home/enterpreneur";
import ActivityCard from "../components/home/activityCard";
import Time from "../components/home/time";
import StepCards from "../components/home/stepCards";
import Freebotdm from "../components/home/freebotdm";
import Footer from "../components/shared/footer";
import homeb from "../assets/images/home-banner.png";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Banner />
      <Trusted />
      <People />
      <Card />
      <UseChat head={t("Use_Chat_Marketing")} />
      <Discover />
      <Enterpreneur />
      <ActivityCard />
      <Time />
      <StepCards
        head={t("GET_AUTOMATED_WITH")}
        customClass="dark-purple"
      />
      <Freebotdm />
      <Footer />
    </div>
  );
};

export default Home;
