import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const getLongLivedToken = async (
  shortAccessResponse,
  navigate,
  navigateTo = "#"
) => {
  try {
    const apiUrl = `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/oauth/access_token`;
    const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const appSecret = process.env.REACT_APP_FACEBOOK_APP_SECRET;
    const shortLivedToken = shortAccessResponse.authResponse.accessToken;

    const params = {
      grant_type: "fb_exchange_token",
      client_id: appId,
      client_secret: appSecret,
      fb_exchange_token: shortLivedToken,
    };
    axios
      .get(apiUrl, { params })
      .then(async (longResponse) => {
        const longLivedToken = longResponse.data.access_token;
        const expiresIn = longResponse.data.expires_in; // This will give you the token's expiration time in seconds.
        const data = shortAccessResponse;
        secureLocalStorage.setItem("user", { data, longLivedToken, expiresIn });
        console.log("Long-lived token:", longLivedToken);
        console.log("Expires in:", expiresIn);
        navigate(navigateTo);
      })
      .catch((error) => {
        // reject({ success: false })
        console.error("Error getting long-lived token:", error);
      });
  } catch (err) {
    console.log(err);
    // reject({ success: false })
  }

  try {
    const response = await axios.get(
      "https://graph.instagram.com/access_token",
      {
        params: {
          grant_type: "ig_exchange_token",
          client_secret: "f91ad9fc6a6f5b9761f08e24a086341f",
          access_token:
            "IGQWRPaFFtV0c3UlV3MUd2dTRPVUIzUnAzUk1VNHZApME1NbFA0aTN0RXVqbnZAhQjFvUkRtV2ZA3VUVMSEdybTMxX3I3YS1MZAEZA1M3pHV09HSlpJcjlnS2UtdldPZA0ZAfZA3dZAMzVRSkNybG1tQzFja1JiMzBnVGY3VEkZD",
        },
      }
    );

    if (response.status === 200) {
      const { access_token } = response.data;
      console.log("User Access Token:", access_token);
      // return access_token;
    } else {
      console.error("Error:", response.status, response.data);
      return null;
    }
  } catch (error) {
    console.error("Error:", error.response.status, error.response.data);
    return null;
  }
};
const getAllMessagesFromMessageIds = (messageIds) => {
  const page = secureLocalStorage.getItem("currentPageProfile");

  const accessToken = page.access_token;
  messageIds.map((mId, index) => {
    const apiUrl = `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/${mId}`;
    const fields = "id,created_time,from,to,message";

    axios
      .get(apiUrl, {
        params: {
          fields,
          access_token: accessToken,
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
const sendMessageToUser = (PSID, messageValue) => {
  new Promise((resolve, reject) => {
    try {
      const page = secureLocalStorage.getItem("currentPageProfile");
      const apiUrl = `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/${page.id}/messages?recipient={'id':'${PSID}'}&messaging_type=RESPONSE&message={'text':'${messageValue}'}&access_token=${page.access_token}`;
      console.log(apiUrl);
      const accessToken = page.access_token;
      const recipient = {
        id: PSID,
      };

      const message = {
        text: messageValue,
      };

      const messagingType = "RESPONSE";
      // const accessToken = 'PAGE-ACCESS-TOKEN';

      axios
        .post(apiUrl)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
          reject({ success: false });
        });
    } catch (err) {
      console.log(err);
      reject({ success: false });
    }
  });
};
const getUserData = async (personId) => {
  return new Promise((resolve, reject) => {
    const page = secureLocalStorage.getItem("currentPageProfile");

    const accessToken = page.access_token; // Replace with your access token
    // const personId = '<PERSON_ID>'; // Replace with the person's ID

    const apiUrl = `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/${personId}/`;

    axios
      .get(apiUrl, {
        params: {
          access_token: accessToken,
        },
      })
      .then((response) => {
        // Handle the response here
        console.log(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        // Handle errors here
        console.error(error);
        reject(error);
      });
  });
};
const getMyData = async () => {
  return new Promise((resolve, reject) => {
    const user = secureLocalStorage.getItem("user");

    axios
      .get(
        `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/me`,
        {
          params: {
            fields: "id,name,picture,email",
            access_token: user.longLivedToken, // You should replace this with your actual access token
          },
        }
      )
      .then((response) => {
        // Insert your code here
        console.log(response);
        secureLocalStorage.setItem("userProfileData", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });
};
const getAllPages = () => {
  return new Promise((resolve, reject) => {
    const user = secureLocalStorage.getItem("user");
    const accessToken = user.longLivedToken;
    const userId = user.data.userID; // Replace with the actual user ID

    const url = `https://graph.facebook.com/${process.env.REACT_APP_LATEST_API_VERSION}/me/accounts`;

    axios
      .get(url, {
        params: {
          access_token: accessToken,
          fields:
            "id,name,picture,access_token,instagram_business_account{id,name,profile_picture_url}",
        },
      })
      .then((response) => {
        console.log(response);
        resolve([response.data.data, accessToken]);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export {
  getAllMessagesFromMessageIds,
  sendMessageToUser,
  getUserData,
  getAllPages,
  getLongLivedToken,
  getMyData,
};
