import React, { Component, useEffect, useState } from "react";
import "./Automation.css";
import { Icon, InlineIcon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import Scratch from "./Components/Flow/Flow";
import { getCollection } from "../../../../config/firebase";
import { setFlow } from "../../../../slices/flowSlice";
import { useDispatch } from "react-redux";
import { addDataToFirestore } from "../../../../config/firebase";
import { deleteDocument } from "../../../../config/firebase";
import { useTranslation } from "react-i18next";
const Automation = (props) => {
  const { t } = useTranslation();
  const [selectTab, setSelectTab] = useState(false);
  const [showFlow, setShowFlow] = useState(false);
  const [showData, setShowData] = useState([]);
  const [docNum, setDocNum] = useState(1);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const data = [
    {
      title: "Collect Opt-Ins: engage your customers with Broadcasts",
      text:
        "Build your Messenger list and regularly engage with customers outside 24 hours of their last interaction. With customer consent, you have the opportunity to send out Broadcasts.",
    },
    {
      title:
        "Conversation Starters: help customers start a conversation with your business",
      text:
        "When new contacts open a chat with your Facebook account, they will see frequently asked questions buttons. Once they click a button, they will instantly get an automated message",
    },
    {
      title: "Welcome Message: greet your new contacts with a message",
      text:
        'When new contacts open a chat in your Facebook account and click "Get started," they receive an automated Welcome Message that helps establish a great relationship from the first interaction.Set Up',
    },
    {
      title: "Default Reply: send instant replies to incoming Direct Messages",
      text:
        "Set up a Default Reply to manage high message volume. When contacts send a message that does not match any keywords, they will receive a predefined Default Reply.",
    },
  ];
  const toggle = () => {
    setSelectTab(!selectTab);
  };

  const startFlow = (item, id) => {
    const docName = `Flow - ${id}`;
    navigate(`/scratch/Flow${id}`, { state: { item, docName } });
    dispatch(setFlow(docName));
  };
  useEffect(() => {
    // getCollection("Flow")
    //   .then((data) => {
    //     console.log("Collection data:", data);

    //     setShowData(data.length > 0 ? data : []);
    //     // setDocNum(data.length + 1);

    //     {
    //       data.length > 0 ? setShowFlow(true) : setShowFlow(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error getting documents: ", error);
    //   });

    setShowData(props?.data?.length > 0 ? props.data : []);
    setDocNum(props?.data?.length + 1);

    {
      showData?.length > 0 ? setShowFlow(true) : setShowFlow(false);
    }
  }, []);

  useEffect(() => {
    if (showData?.length > 0) {
      setShowFlow(true);
    } else {
      setShowFlow(false);
    }
  }, [showData]);

  const addNewFlow = (newId) => {
    let newFlow = [];
    newFlow[2] = [
      {
        id: 1,
        type: "text",
        image: null,
        title: "",
        text: "",
        paragraph: "",
      },
    ];

    let newFlowData = [
      {
        id: `Flow - ${docNum}`,
        data: newFlow,
      },
    ];

    newFlowData[0].data["live"] = false;
    sendingDataToFirestore(`Flow - ${docNum}`, newFlowData, "2");
    // setShowData([...showData, newFlowData]);
    startFlow({}, docNum);
    setDocNum(docNum + 1);
  };

  const sendingDataToFirestore = (flowName, value, nodeNum) => {
    addDataToFirestore("Flow", flowName, value, nodeNum)
      .then((documentId) => {
        console.log(`Data added successfully with ID: ${documentId}`);
      })
      .catch((error) => {
        console.error(`Error adding data: ${error}`);
      });
  };

  const handleDelete = (id) => {
    deleteDocument(`Flow + ${id + 1}`)
      .then(() => {
        console.log("Document deleted from nested collection!");
      })
      .catch((error) => {
        console.error("Error deleting document:", error);
      });

    const updatedItems = showData.filter((item, index) => index !== id);
    setShowData(updatedItems);
  };

  return (
    <div className="automation">
      {selectTab == false ? (
        <div>
          <div className="firstDiv">
            <p className="text">{t("Flow")} </p>
            <p className="text">{t("Keywords")} </p>
            <p className="text">{t("Sequence")}</p>
            <p className="text">{t("Rules")}</p>
          </div>
          <div className="secondDiv">
            <p className="text">{t("Evergreen_Automation")}</p>
            <div className="secondInner">
              <div className="EmmahopDiv">
                <p className="text">{t("Emmashop")}</p>
                <Icon
                  onClick={toggle}
                  icon={"mingcute:menu-fill"}
                  width="1em"
                  height="1em"
                  color="black"
                />
              </div>

              <p className="text">01{t("live_automation")}</p>
            </div>
          </div>
          <div className="thirdDiv" style={{ display: "flex" }}>
            <h4 className="text-black mt-4">{t("Flow")}</h4>

            <div
              className="add-Flow"
              onClick={() => {
                addNewFlow();
              }}
            >
              {t("add_flow")}
            </div>
          </div>
          {/* code below is for banner */}

          {showFlow === true ? (
            // <Scratch />
            <>
              {showData ? (
                //       style={{ color: "#000" }} //

                showData.map((item, id) => (
                  <>
                    <div className="list-container">
                      <div
                        key={id}
                        className="list"
                        onClick={() => {
                          startFlow(item, id + 1);
                          // addNewFlow(id + 1);
                        }}
                      >
                        <h5>Flow {id + 1}</h5>
                      </div>

                      <button
                        onClick={() => {
                          handleDelete(id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div>
                    <h1>Loading....</h1>
                  </div>
                </>
              )}
            </>
          ) : (
            // null
            <div className="bannerMain">
              <p className="heading">{t("Create_your_first_Flow")} </p>
              <p className="text">{t("Flows_are_where_you_create")}</p>

              <div
                className="button"
                onClick={() => {
                  startFlow({}, 1);
                }}
              >
                <p className="buttonText">{t("New_Flow")} </p>
              </div>
            </div>
          )}
        </div>
      ) : selectTab == true ? (
        <>
          <div className="secondDiv">
            <p className="text">Evergreen Automation - Emmashop</p>
            <div className="secondInner">
              <div className="EmmahopDiv">
                <Icon
                  onClick={toggle}
                  icon={"mingcute:menu-fill"}
                  width="1em"
                  height="1em"
                  color="black"
                />
                <p className="text">
                  Main Menu: assist followers in locating information quickly
                </p>
              </div>
            </div>
          </div>

          {/* code below is for banner tab 2 */}

          {data.map((item) => (
            <div className="bannerMainTwo">
              <p className="heading">{item.title}</p>
              <p className="text">{item.text}</p>

              <div className="buttonTwo">
                <p className="buttonTwoText">Set Up</p>
              </div>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Automation;
