import React from "react";
import "../../../style/main.scss";
import laptop from "../../../assets/images/blog/laptop.png";
import whatsapp from "../../../assets/images/blog/whatsapp.png";
import instagram from "../../../assets/images/blog/instagram.png";
import low from "../../../assets/images/blog/low.png";
import little from "../../../assets/images/blog/little.png";
import clever from "../../../assets/images/blog/clever.png";
import Latest from "../../blog/blogHome/latest";
import Instagram from "../../blog/blogHome/instagram";
import Facebook from "../../blog/blogHome/facebook";
import Email from "../../blog/blogHome/email";
import Sms from "../../blog/blogHome/sms";
import Trending from "../../blog/blogHome/trending";
import Freebotdm from "../../home/freebotdm";
import Footer from "../../shared/footer";
import { useTranslation } from "react-i18next";

const BlogHome = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="bloghome-abba">
        <div className="blog-home">
          <h3 className="text-black">{t("Editor's_choice")} </h3>
          <div className="blog-parent">
            <div className="home-cards">
              <img src={laptop} alt="laptop" className="img-fluid" />
              <p>{t("Unlocking_Seamless_Customer")} </p>
            </div>
            <div className="home-cards">
              <img src={whatsapp} alt="laptop" className="img-fluid" />
              <p>{t("Mastering_Engagement")}</p>
            </div>
            <div className="home-cards">
              <img src={instagram} alt="laptop" className="img-fluid" />
              <p>{t("Cart_Abandonment_No_More")}</p>
            </div>
            <div className="home-cards">
              <img src={low} alt="low" className="img-fluid" />
              <p>{t("Revolutionizing_Learning")}</p>
            </div>
            <div className="home-cards">
              <img src={little} alt="little" className="img-fluid" />
              <p>{t("Analytics_Unleashed")}</p>
            </div>
            <div className="home-cards">
              <img src={clever} alt="clever" className="img-fluid" />
              <p>{t("Privacy_by_Design")}</p>
            </div>
          </div>
        </div>
        <Latest />
        <Instagram />
        <Facebook />
        <Email />
        <Sms />
        <Trending />
        {/* <Freebotdm />
        <Footer /> */}
      </div>
    </div>
  );
};

export default BlogHome;
