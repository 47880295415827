import React from "react";
import "../../../style/main.scss";
import laptop from "../../../assets/images/blog/Rectangle 102.png";
import whatsapp from "../../../assets/images/blog/Rectangle 103.png";
import instagram from "../../../assets/images/blog/Rectangle 104.png";
import { useTranslation } from "react-i18next";

const Editor = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="editor">
        <h3>{t("Editor's_choice")}</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={laptop} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={whatsapp} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={instagram} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editor;
