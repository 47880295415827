import React, { useState } from "react";
import "../Flow/addButtonModal.scss";
import { Icon, InlineIcon } from "@iconify/react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const AddButtonModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Button  style={{ marginLeft: '20px' }} variant="primary" onClick={handleShow}>
        + Add Button
      </Button>
      <div className="add-button-modal">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>Edit Button</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="body">
              <h6>Button Title</h6>
              <input type="text" name="" id="" className="mt-2" />
              <h6 className="mt-3">When this button is pressed</h6>
              <div className="multiple-buttons">
                <div className="messenger">
                  <span>
                    <Icon
                      icon="logos:messenger"
                      width="20"
                      height="20"
                      className="iconMessenger"
                    />
                    Messenger
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="arcticons:open"
                      color="#0084ff"
                      width="24"
                      height="24"
                    />
                    Open Website
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="ic:baseline-call"
                      color="#0084ff"
                      width="24"
                      height="24"
                    />
                    Call Number
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="ant-design:dollar-outlined"
                      color="#0084ff"
                      width="24"
                      height="24"
                    />
                    Buy Button
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="mdi:thunder"
                      color="#f90"
                      width="24"
                      height="24"
                      className="iconMessenger"
                    />
                    Perform Actions
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="mingcute:filter-line"
                      color="#35caef"
                      width="24"
                      height="24"
                      className="iconMessenger"
                    />
                    Condition
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="humbleicons:arrow-split"
                      color="#9969f8"
                      width="24"
                      height="24"
                    />
                    Randomizer
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="ep:clock"
                      color="#eb5139"
                      width="24"
                      height="24"
                      className="iconMessenger"
                    />
                    Smart Delay
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="logos:messenger"
                      width="20"
                      height="20"
                      className="iconMessenger"
                    />
                    Start another Flow
                  </span>
                </div>
                <div className="messenger">
                  <span>
                    <Icon
                      icon="iconoir:frame-select"
                      color="#3fa2ff"
                      width="24"
                      height="24"
                      className="iconMessenger"
                    />
                    Select Exsisting Flow
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <Icon
                icon="material-symbols:delete-outline"
                color="#ba3329"
                width="24"
                height="24"
              />
              Remove
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AddButtonModal;
