import React from "react";
import "../../style/main.scss";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
const Happy = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div className="happy ">
          <div className="freebotdm-title">
            <h2 className="one">{t("Happy_Clients")}</h2>
            <p className="large text-black">
              {t("At_DM_BOT_we_understand")}
            </p>
            <video
              autoPlay
              loop
              muted
              playsInline
              // poster="\assets\images\hero\banner.jpg"
            >
              <source src="../../../assets/cheering.mp4" type="video/mp4" />
            </video>
            <Button
              text={t("Get_Started_For_Free")}
              width="244px "
              height="51px"
              className="btn-primary mt-5 "
              onClick={() => alert("Hello!")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Happy;
