import React from 'react'
import "./livechat.css"
import { useTranslation } from "react-i18next";
const Livechat = () => {
    const { t } = useTranslation();
  return (
    <div className="chat">
    <div className='chattop'>
        <div className="row1">
            <div className='ist'> <p>{t("Live_Chat_Behavior")}</p></div>
            <div className='sec'>
                <div className='chk1'>
                    <input type="checkbox"  />
                     <span>{t("message_starts_conversation")}</span>
                </div>
                <div className='chk2'>
                    <input type="checkbox"  /> <span>{t("Conversation_opened_explicitly")}</span>
                </div>
            </div>
            <div className='thi'> 
            <p>{t("new_chat_in_Live_Chat")}</p>
                </div>
        </div>
        <div className="row2">
            <div className='r2para'>
                <p>
                {t("Close_assigned_conversations")}
                </p>
            </div>
            <div className='r2btn'>
                <button>
                {t("Close_All")}
                </button>
            </div>
        </div>

        <div className="row3">
            <div className='r3col1'>
                <p>
                {t("Automation_pause_Conversations")}
                </p>
            </div>
            <div className='r3col2'>
                <div className="inpfield">
                  <div> <input type="text" placeholder='30' /></div> 
                  <div> <input type="text" placeholder={t("minutes")} /></div> 
                </div>
                <div className="updatebtn">
                    <div>
                        <p>
                    {t("pause_duration")}
                    </p>
                    </div>
                    <div className='butn'> 
                        <button>
                    {t("Update_btn")}
                    </button></div>

                </div>
            </div>
            <div className='r3col3'>
                    <p>{t("Enable_instant_popup_notifications")}</p>
            </div>
        </div>
      
    </div>

    <div className="chatbott">
        <div className="row4">
            <div className='r4col1'>
                    <p>{t("Re-opened_conversations")}</p>
            </div>
            <div className='r4col2'>
            <div class="form-check form-switch">
  <input class="form-check-input btn" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
  <label class="form-check-label" for="flexSwitchCheckDefault">{t("Assign_same_agent")}</label>
</div>
            </div>
            <div className='r4col3'>
                    <p>{t("When_contact_re-opens_closed_conversation")}</p>
            </div>
        </div>
        <div className="row5">
            <div className='r5col1'>
                    <p>{t("Conversations_visibility")}</p>
            </div>
            <div className='r5col2'>
            <div class="form-check form-switch">
  <input class="form-check-input btn" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
  <label class="form-check-label" for="flexSwitchCheckDefault">{t("Grant_access_conversations")}</label>
</div>
            </div>
            <div className='r5col3'>
                    <p>{t("Disable_this_option")}</p>
            </div>
        </div>

        <div className="row6">
            <div className="r6col1">
                <p>
                {t("Sound_notifications")}
                </p>
            </div>

            <div className='r6col2'>
            <div class="form-check form-switch">
  <input class="form-check-input btn" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
  <label class="form-check-label" for="flexSwitchCheckDefault">{t("Allow_In-app")}</label>
</div>
            </div>
        </div>

        <div className="row7">
            <div className="r7col1">
                <p>{t("Canned_Response")}</p>
            </div>
            <div className="r7col2">
                <p>{t("No_Cancel_Responses")}</p>
            </div>
            <div className="r7col3">
                    <button>+{t("New_Canned_Response")}</button>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Livechat
