import React, { useState } from "react";
import { Handle, Position } from "reactflow";
import { Icon } from "@iconify/react";
import "./actionNode.css";
import { useTranslation } from "react-i18next";
import Action from "./Components/Flow/action";

const ActionNode = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        id="a"
        isConnectable={true}
      />

      <Handle position={Position.Right} isConnectable={true} />
      <div className="action-input-node">
        <div className="header">
          <div>
            {/* <Icon icon="logos:messenger" width="30" height="30" /> */}
            <Icon
              icon="akar-icons:thunder"
              width="30"
              height="30"
              color="#ffb538"
            />
          </div>
          <div>
            <p className="text-1">Actions</p>
            {/* <p className="text-2">Send Message</p> */}
          </div>
        </div>
        <div className="body">
          {" "}
          <div
            className="three"
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            <p className="text">Click to add an action</p>
          </div>
        </div>
      </div>

      <Action show={modalOpen} setShow={setModalOpen} />
    </>
  );
};

export default ActionNode;
