import React from "react";
import time from "../../assets/images/homepage/time.png";
import timeKor from "../../assets/images/homepage/time.png";
import { useTranslation } from "react-i18next";

const Time = () => {
  const { t } = useTranslation();
  const {i18n} = useTranslation()
  return (
    <div>
      <div className="time d-flex">
        <div className="left v-center h-center">
          <h1 className="dark-purple">{t("It's_time_to_enter")}</h1>
        </div>
        <div className="right">
          <img src={i18n.language == 'en'? time : timeKor} alt="time" />
        </div>
      </div>
    </div>
  );
};

export default Time;
