import React from "react";
import "../../style/main.scss";
import Button from "../../components/shared/button";
import tiktok2 from "../../assets/images/tiktok/tiktok2.png";
import fire from "../../assets/images/tiktok/fire.png";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="tiktok-banner ">
        <div className="two d-flex">
          <div className="left">
            <h1>{t("real_power_of_Tiktok")}</h1>
            <h4 className="medium mt-4">
              {t("Attract_quality_leads")}
            </h4>
            <Button
              text={t("Get_Started_Free")}
              width="244px "
              height="51px"
              className="btn-primary mt-5 "
              onClick={() => alert("Hello!")}
            />
          </div>
          <div className="right">
            <img src={tiktok2} alt="tiktok-banner" className="banner-img" />
          </div>
        </div>
        <div className="drive v-center h-center text-center mt-5">
          <h3 className="medium text-black v-center h-center">
           {t("Drive_higher_conversions")}
          </h3>
        </div>
      </div>{" "}
      <div className="mockup-sec">
        <img src={fire} alt="fire-banner"  className="facebook-img  img-fluid" />
      </div>
    </div>
  );
};

export default Banner;
