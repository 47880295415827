import React from "react";
import "../../style/main.scss";
import chart from "../../assets/images/naver/chart.png";
import card from "../../assets/images/naver/card.png";
import market from "../../assets/images/naver/market.png";
import naverlogo from "../../assets/images/naver/naverlogo.png";
import whatsapp from "../../assets/images/naver/whatsapp.png";
import pinkwhat from "../../assets/images/naver/pinkwhat.png";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
const Safe = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="safe">
        <div className="two-div">
          <div className="top  h-center flex-column v-center">
            <h2 className="one">{t("100_safe_easy")}</h2>
            <p className="large text-black">
              {t("official_WhatsApp_Business")}
            </p>
            <div className="usechat-content ist d-flex v-center mt-5">
              <div className="left">
                <img src={chart} alt="chart" className="img-fluid" />
              </div>
              <div className="right">
                <img src={card} alt="card-image" className="img-fluid" />
              </div>
            </div>
            <div className="usechat-content second d-flex v-center mt-5">
              <div className="left">
                <img src={market} alt="market" className="img-fluid" />
              </div>
              <div className="right">
                <img
                  src={naverlogo}
                  alt="naverlogo-image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          <div className="bottom">
            <h2>{t("Power_WhatsApp_Marketing")}</h2>
            <div className="usechat-content second d-flex v-center mt-5">
              <div className="left">
                <img src={whatsapp} alt="whatsapp" className="img-fluid" />
              </div>
              <div  className="right">
                <img
                  src={pinkwhat}
                  alt="pinkwhat-image"
                  className="img-fluid"
                  
                />
              </div>
            </div>
            {/* DM Bot */}
            <div className="dm-bot mt-5 ">
              <h2>{t("Why_DM_Bot")}</h2>
              <div className="discover-content d-flex v-center h-center  mt-5">
                <div className="content flex-column v-center h-center">
                  <h3>1M+</h3>
                  <h4>{t("Businesses")}</h4>
                  <p className="body-large text-white">{t("chose_DM_BOT_grow")}</p>
                </div>
                <div className="content flex-column v-center h-center">
                  <h3>48M+</h3>
                  <h4>{t("Conversations")}</h4>
                  <p className="body-large text-white">{t("powered_by_DM")}</p>
                </div>
                <div className="content flex-column v-center h-center">
                  <h3>170M+</h3>
                  <h4>{t("Countries")}</h4>
                  <p className="body-large text-white">
                   {t("across_the_world")}
                  </p>
                </div>
                <div className="content flex-column v-center h-center">
                  <h3>#1</h3>
                  <h4>{t("Businesses")}</h4>
                  <p className="body-large text-white">{t("chose_DM_BOT_grow")}</p>
                </div>
              </div>
              <div className="search-btn v-center h-center mt-5">
                <Button
                  text={t("Get_Started_Free")}
                  width="244px "
                  height="51px"
                  className="btn-primary mt-5 "
                  onClick={() => alert("Hello!")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Safe;
