import React from "react";
import "../../../style/main.scss";
import aa from "../../../assets/images/blog/aik.png";
import bb from "../../../assets/images/blog/do.png";
import cc from "../../../assets/images/blog/teen.png";
import dd from "../../../assets/images/blog/char.png";
import ee from "../../../assets/images/blog/panch.png";
import ff from "../../../assets/images/blog/chay.png";
import gg from "../../../assets/images/blog/saat.png";
import hh from "../../../assets/images/blog/aath.png";
import ii from "../../../assets/images/blog/nou.png";
import jj from "../../../assets/images/blog/das.png";
import kk from "../../../assets/images/blog/giyara.png";
import ll from "../../../assets/images/blog/bara.png";
import mm from "../../../assets/images/blog/teira.png";
import nn from "../../../assets/images/blog/chouda.png";
import oo from "../../../assets/images/blog/pandra.png";
import pp from "../../../assets/images/blog/sola.png";
import qq from "../../../assets/images/blog/satra.png";
import rr from "../../../assets/images/blog/athra.png";
import ss from "../../../assets/images/blog/unees.png";
import tt from "../../../assets/images/blog/bees.png";
import uu from "../../../assets/images/blog/ikis.png";
import vv from "../../../assets/images/blog/baees.png";
import ww from "../../../assets/images/blog/taees.png";
import xx from "../../../assets/images/blog/chobees.png";
import { useTranslation } from "react-i18next";
const Latest = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="latest-posts">
        <h3>{t("LATEST_POSTS")}</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={aa} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={bb} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={cc} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={dd} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={ee} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={ff} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={gg} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={hh} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={ii} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={jj} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={kk} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={ll} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={mm} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={nn} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={oo} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={pp} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={qq} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={rr} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={ss} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={tt} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={uu} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Connect_your")}</p>
          </div>
          <div className="home-cards">
            <img src={vv} alt="laptop" className="img-fluid" />
            <p>{t("Connecting_Your_WhatsApp")}</p>
          </div>
          <div className="home-cards">
            <img src={ww} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
          <div className="home-cards">
            <img src={xx} alt="laptop" className="img-fluid" />
            <p>{t("How_to_Get_Started_with_Instagram")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Latest;