import React from "react";
import "../../style/_creater.scss";
import { useTranslation } from "react-i18next";
const Title = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="happaycustomer">
        <div className="customer-content">
          <div className="title">
            <h6>{t("happy_customers")}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
