import React from "react";
import "../../style/main.scss";
import make from "../../assets/images/messenger/make.png";
import turn from "../../assets/images/messenger/turn.png";
import direct from "../../assets/images/messenger/direct.png";
import understand from "../../assets/images/messenger/understand.png";

const Grow = (props) => {
  return (
    <div>
      <div className="grow">
        <div className="usechat-title">
          <h1 className="text-center">{props.head}</h1>
        </div>
        <div className="usechat-content ist d-flex v-center mt-5">
          <div className="left">
            <img src={make} alt="make" className="img-fluid" />
          </div>
          <div className="right">
            <img src={turn} alt="turn" className="img-fluid" />
          </div>
        </div>
        <div className="usechat-content second d-flex v-center mt-5">
          <div className="left">
            <img src={direct} alt="direct" className="img-fluid" />
          </div>
          <div className="right">
            <img src={understand} alt="understand" className="img-fluid" />
          </div>
        </div>
        {/* <div className="usechat-content ist d-flex v-center mt-5">
          <div className="left">
            <img src={direct} alt="direct" className="img-fluid" />
          </div>
          <div className="right">
            <h4 className="large">
              Direct Customers to Your Facebook Messenger Chatbot from Anywhere
            </h4>
            <p className="body-large mt-3">
              Use Manychat’s Messenger URL Growth Tool to create a messenger
              link for your chatbot that customers can access from their
              preferred channel. Website. Email. Text message. Anywhere.
            </p>
          </div>
        </div>
        <div className="usechat-content second d-flex v-center mt-5">
          <div className="left">
            <h4 className="large">Understand What’s Working</h4>
            <p className="body-large mt-3">
              With Manychat insights and analytics tools, connect your customer
              conversations to conversions on your website or in your store.
            </p>
          </div>
          <div className="right">
            <img src={understand} alt="understand" className="img-fluid" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Grow;
