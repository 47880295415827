import React from "react";
import "../../style/_price.scss";
import Cardtick from "../../assets/icons/cardtick.svg";
import Crad3tick from "../../assets/icons/card3tick.svg";
import { useTranslation } from "react-i18next";
const PricePlan = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pricePlan">
        <div className="priceplan-content">
          <div className="topSection">
            <h2>{t("a_DMBOT_plan")}</h2>
            <h4 className="text-black">{t("Right_Plan_for")}</h4>
            <p>
              {t("have_several_powerful")} <br /> {t("creative_entrepreneurs")}
              
            </p>
            <div className="topLast">
              <span className="bill-month">{t("Bill_Monthly")}</span>
              <div className="form-check form-switch">
                <input
                  className="form-check-input switchbtn"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
              <span className="bill-annualy">{t("Bill_Annualy")}</span>
            </div>
          </div>
          <div className="priceCards">
            <div className="pricecard">
              <div className="cardHeader">
                <div className="left">
                  <span>{t("Intro")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span>{t("Upload_Video_up")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLefts">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLefts">
                  <span>{t("Post_Scheduling")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLefts">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLefts">
                  <span>{t("your_rates")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLefts">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLefts">
                  <span>{t("Exclusive_Deals")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLefts">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLefts">
                  <span>{t("Advanced_Statistics")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <h6>
                    $ <span className="number">00</span>{" "}
                    <span className="month">/{t("month")}</span>
                  </h6>
                </div>
                {/* <div className="middleLeft">
                  <span>Upload Video up to 720p Resolution</span>
                </div> */}
              </div>
              <div className="card-footer">
                <div className="lastBtn">
                  <button>{t("Choose")}</button>
                </div>
              </div>
            </div>
            <div className="pricecard">
              <div className="cardHeader">
                <div className="left">
                  <span>{t("Base")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span>{t("Upload_Video_up")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span>{t("Post_Scheduling")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLefts">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLefts">
                  <span>{t("your_rates")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLefts">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLefts">
                  <span>{t("Exclusive_Deals")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLefts">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLefts">
                  <span>{t("Advanced_Statistics")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <h6>
                    $ <span className="number">123</span>{" "}
                    <span className="month">/{t("month")}</span>
                  </h6>
                </div>
                {/* <div className="middleLeft">
                  <span>Upload Video up to 720p Resolution</span>
                </div> */}
              </div>
              <div className="card-footer">
                <div className="lastBtn">
                  <button>{t("Choose")}</button>
                </div>
              </div>
            </div>
            <div className="pricecard pricecard3">
              <div className="cardHeader">
                <div className="left">
                  <span className="text-white">{t("Pro")}</span>
                  <button>{t("Save")} $40</button>
                </div>
              </div>
              <div className="MiddleContent ">
                <div className="middleLeft thirdcardimg">
                  <img src={Crad3tick} alt="..." />
                </div>
                <div className="middleLeft three">
                  <span className="text-white">
                    Upload Video with HD Resolution
                  </span>
                </div>
              </div>
              <div className="MiddleContent ">
                <div className="middleLeft">
                  <img src={Crad3tick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span className="text-white">
                    {t("Post_Scheduling")}
                  </span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Crad3tick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span className="text-white">{t("your_rates")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Crad3tick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span className="text-white">{t("Exclusive_Deals")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Crad3tick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span className="text-white">{t("Advanced_Statistics")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <h6 className="text-white">
                    $ <span className="number text-white">123</span>{" "}
                    <span className="month text-white">/{t("month")}</span>
                  </h6>
                </div>
                {/* <div className="middleLeft">
                  <span>Upload Video up to 720p Resolution</span>
                </div> */}
              </div>
              <div className="card-footer">
                <div className="lastBtn">
                  <button className="thirdcardbtn">{t("Try")} 1 {t("month")}</button>
                </div>
              </div>
            </div>

            <div className="pricecard">
              <div className="cardHeader">
                <div className="left">
                  <span>{t("Enterprise")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span>{t("Upload_Video_up")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span>{t("Post_Scheduling")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span>{t("your_rates")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span>{t("Exclusive_Deals")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <img src={Cardtick} alt="..." />
                </div>
                <div className="middleLeft">
                  <span>{t("Advanced_Statistics")}</span>
                </div>
              </div>
              <div className="MiddleContent">
                <div className="middleLeft">
                  <h6>
                    $ <span className="number">123</span>{" "}
                    <span className="month">/{t("month")}</span>
                  </h6>
                </div>
                {/* <div className="middleLeft">
                  <span>Upload Video up to 720p Resolution</span>
                </div> */}
              </div>
              <div className="card-footer">
                <div className="lastBtn">
                  <button>{t("Choose")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricePlan;
