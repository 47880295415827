import "../../style/_webnar.scss";
import React, { useState } from "react";
import Tab1 from "./tab1";
import { useTranslation } from "react-i18next";
const Tabz = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <div>
      <div className="Tab">
        <div className="main-profile">
          <div className="main-primary">
            {/* <div className="profile-heading">
              <span className="SellerProfile">Seller Profile</span>
            </div> */}
            <div className="tabs-content">
              <div className="tabs-container">
                <div className="tabs mb-2">
                  <a
                    className={`tab ${activeTab === 0 ? "active" : ""} `}
                    onClick={() => handleTabClick(0)}
                  >
                    {t("Show_All")}
                  </a>
                  <a
                    className={`tab ${activeTab === 1 ? "active" : ""}`}
                    onClick={() => handleTabClick(1)}
                  >
                    {t("Live")}
                  </a>
                  <a
                    className={`tab ${activeTab === 2 ? "active" : ""}`}
                    onClick={() => handleTabClick(2)}
                  >
                    {t("On_-demand")}
                  </a>
                </div>
                <div className="tab-content">
                  <div
                    className={`tab-pane ${activeTab === 0 ? "active" : ""}`}
                  >
                    <Tab1 />
                    {/* <AdminAccountTab1 /> */}
                  </div>
                  <div
                    className={`tab-pane ${activeTab === 1 ? "active" : ""}`}
                  >
                    <span>test2</span>
                    {/* <AdminAccountTab2 /> */}
                  </div>
                  <div
                    className={`tab-pane ${activeTab === 2 ? "active" : ""}`}
                  >
                    <span>test3</span>
                    {/* <AdminAccountTab2 /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tabz;