import React from "react";
import "../../style/_price.scss";
import Questionmark from "../../assets/icons/questionmarksB.svg";
import Tick from "../../assets/icons/tick.svg";
import Line from "../../assets/icons/line.svg";
import { useTranslation } from "react-i18next";
const Feature = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="feature">
        <div className="feature-title">
          <span>{t("Features")}</span>
        </div>
        <div className="feature-outer-outer">
          <div className="feature-outer">
            <div className="feature-content">
              <div className="feature-first">
                <span>
                {t("Build_custom")} {" "}
                  <img src={Questionmark} alt="..." />  
                </span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("Segment_your_contacts")}  
                  <img src={Questionmark} alt="..." />  
                </span>
              </div>
              <div className="feature-second">
                <span>10 {t("Tags")}</span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("Run_automated")}  
                  {/* <img src={Questionmark} alt="..." />   */}
                </span>
              </div>
              <div className="feature-second">
                <span>2 {t("Sequences")}</span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("Team_member")}    
                  <img src={Questionmark} alt="..." />  
                </span>
              </div>
              <div className="feature-second">
                <span>1 {t("Team Member")}</span>
              </div>
              <div className="feature-second">
                <span>3 {t("Team Member")}  </span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("Create_custom")} 
                  {/* <img src={Questionmark} alt="..." />   */}
                </span>
              </div>
              <div className="feature-second">
              <span>3 {t("Keywords")}</span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
              <div className="feature-second">
              <span>{t("Unlimited")}  </span>
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("Chat_for_Messenger")}   
                  <img src={Questionmark} alt="..." />  
                </span>
              </div>
              <div className="feature-second">
                <img src={Tick} alt="..." />
              </div>
              <div className="feature-second">
                <img src={Tick} alt="..." />
              </div>
              <div className="feature-second">
                <img src={Tick} alt="..." />
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("Access_quick_campaign")}  
                  {/* <img src={Questionmark} alt="..." />   */}
                </span>
              </div>
              <div className="feature-second">
                <img src={Tick} alt=".." />
              </div>
              <div className="feature-second">
                <img src={Tick} alt=".." />
              </div>
              <div className="feature-second">
                <img src={Tick} alt=".." />
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("One_tap")}   
                  <img src={Questionmark} alt="..." />  
                </span>
              </div>
              <div className="feature-second">
                <img src={Line} alt="..." />
              </div>
              <div className="feature-second">
                <img src={Tick} alt="..." />
              </div>
              <div className="feature-second">
                <img src={Tick} alt="..." />
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("Segment_your_contacts")}  
                  {/* <img src={Questionmark} alt="..." />   */}
                </span>
              </div>
              <div className="feature-second">
                <img src={Line} alt="..." />
              </div>
              <div className="feature-second">
                <img src={Tick} alt="..." />
              </div>
              <div className="feature-second">
                <img src={Tick} alt="..." />
              </div>
            </div>
            <div className="feature-content">
              <div className="feature-first">
                <span>
                  {t("Segment_your_contacts")}  
                  {/* <img src={Questionmark} alt="..." />   */}
                </span>
              </div>
              <div className="feature-second">
                <span>
                  “{t("Powered_by")} <br />
                  DM BOT”
                </span>
              </div>
              <div className="feature-second">
                <span>{t("No_Branding")}</span>
              </div>
              <div className="feature-second">
                <span>{t("No_Branding")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feature;
