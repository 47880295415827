import { Icon } from "@iconify/react";
import React from "react";
import "./Help.css";
import { useTranslation } from "react-i18next";
const HelpCard = () => {
  const { t } = useTranslation();
  const helperCard = [
    {
      img: "uil:react",
      head: t("Getting_Started"),
      detail: t("Get_to_know_your"),
    },
    {
      img: "uil:setting",
      head: t("DM_BOT_Basics"),
      detail: t("How_to_engage_your"),
    },
    {
      img: "mdi:instagram",
      head: t("Instagram"),
      detail: t("How_to_use_Instagram_Channel"),
    },
    {
      img: "solar:magnet-linear",
      head: t("Growth_Tools"),
      detail: t("Different_ways_to_grow"),
    },
    {
      img: "wpf:sent",
      head: t("DM_BOT_Advanced"),
      detail: t("Integrations,_transferable"),
    },
    {
      img: "streamline:money-cash-dollar-coin-accounting-billing-payment-cash-coin-currency-money-finance",
      head: t("Billing"),
      detail: t("All_questions_concerning"),
    },
    {
      img: "simple-icons:naver",
      head: "Naver",
      detail: t("How_to_use_WhatsApp_Channel"),
    },
    {
      img: "uil:setting",
      head: t("Troubleshooting"),
      // detail: "How to engage your subscribers and create automated messages",
    },
    {
      img: "mingcute:kakao-talk-fill",
      head: t("Kakao Talk"),
      detail: t("How_to_use_Telegram_Channel"),
    },
    {
      img: "bx:bot",
      head: t("DM_BOT_Inbox"),
      // detail: "How to engage your subscribers and create automated messages",
    },
  ];
  return (
    <>
      {helperCard.map((item, index) => {
        return (
          <div className="cardDivs">
            <Icon icon={item.img} color="black" width="24" height="24" />
            <h4 className="cardHeading"> {item.head}</h4>
            <p className="cardText">{item.detail}</p>
          </div>
        );
      })}
    </>
  );
};
export default HelpCard;