import React from "react";
import "./Help.css";
import { Icon, InlineIcon } from "@iconify/react";
import HelpCard from "./helpCard";

import Footer from "../shared/footer";

import { useTranslation } from "react-i18next";

function Help() {
  const { t } = useTranslation();
  return (
    <div className="helpMainDiv">
      <h1 className="headingMain">{t("HOW_CAN_WE_HELP_YOU?")}</h1>
      {/* Search DIV */}
      <div className="searchBar">
        <input
          type="text"
          id="textInput"
          placeholder={t("Enter_Your_Search")}
          className="inputStyle"
        />
      </div>
      {/* Images DIV */}
      <div className="imagesDiv">
        <img src={"/assets/images/image1511.png"} className="imagesTop" />
        <img src={"/assets/images/image151.png"} className="imagesTop" />
      </div>
      <div className="firstMainDiv">
        <HelpCard />
       
      </div>
      <Footer />
    </div>
  );
}
export default Help;