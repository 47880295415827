import React from "react";
import "../../../style/main.scss";
import partner from "../../../assets/images/blog/partner.png";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="partner-banner v-center h-center flex-column">
        <div className="freebotdm-title v-center h-center flex-column">
          <h2 className="one">{t("Agency")}</h2>
          <p className="large text-black">{t("Get_the_latest_insights")}</p>
          <img src={partner} alt="partner-image" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};
export default Banner;







