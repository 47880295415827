// src/slices/objectSlice.js
import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";

const objectSlice = createSlice({
  name: "objects",
  initialState: [],
  reducers: {
    addObject: (state, action) => {
      state.push(action.payload);

      // state = {...state, action.payload}
    },
    removeObject: (state, action) => {
      return state.filter((obj) => obj.id !== action.payload);
    },
    updateObjectText: (state, action) => {
      const { objectId, newText } = action.payload;
      const object = state.find((obj) => obj.id === objectId);
      if (object) {
        return state.map((obj) =>
          obj.id === objectId ? { ...obj, text: newText } : obj
        );
      }
      return state;
    },
  },
});

export const { addObject, removeObject, updateObjectText } =
  objectSlice.actions;
export default objectSlice.reducer;
