import React from "react";
import "../../../style/main.scss";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="eCommerce-banner v-center h-center flex-column">
        <div className="freebotdm-title v-center h-center flex-column">
          <h2 className="one">{t("eCommerce")}</h2>
          <p className="large text-black">
            {t("From_DM_BOT_features_to_Chat")}
          </p>
          <video
            autoPlay
            loop
            muted
            playsInline
            // poster="\assets\images\hero\banner.jpg"
          >
            <source src="../../../assets/cheering.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Banner;
