import React, { useState, useEffect } from "react";
import "./MyDashboard.css";
import Settings from "./DashboardComponents/Settings/Settings";
import Home from "./DashboardComponents/Home/Home";
import Contacts from "./DashboardComponents/Contacts/Contacts";
import GrowthTools from "./DashboardComponents/GrowthTools/GrowthTools";
import Broadcasting from "./DashboardComponents/Broadcasting/Broadcasting";
import Automation from "./DashboardComponents/Automation/Automation";
import LiveChat from "./DashboardComponents/LiveChat/LiveChat";
import Profile from "./DashboardComponents/Profile/Profile";
import { Icon, InlineIcon } from "@iconify/react";
import { FacebookProvider } from "react-facebook";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import send from "../../assets/images/sendEmial.png";
import sendKor from "../../assets/images/sendEmial.png";
import prefer from "../../assets/images/prefer.png";
import preferKor from "../../assets/images/prefer.png";
import tell from "../../assets/images/tell.png";
import tellKor from "../../assets/images/tell.png";
import fromFacebook from "../../assets/images/from.png";
import fromFacebookKor from "../../assets/images/from.png";
import promote from "../../assets/images/promote.png";
import promoteKor from "../../assets/images/promote.png";
import { getCollection } from "../../config/firebase";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
const MyDashboard = () => {
  const { t } = useTranslation();
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const historyData = state || {};
  const [layoutMobile, setlayoutMobile] = useState(false);
  const windowWidth = window.innerWidth;
  useEffect(() => {
    console.log("WindowWidth", windowWidth);
  }, [windowWidth]);

  const setLayout = () => {
    setlayoutMobile(!layoutMobile);
  };

  const [selectTab, setSelectTab] = useState("Home");
  //  Modal
  const [showGrowth, setShowGrowth] = useState(false);

  const handleCloseGrowth = () => setShowGrowth(false);
  const handleShowGrowth = () => setShowGrowth(true);

  const [showData, setShowData] = useState([]);

  useEffect(() => {
    getCollection("Flow")
      .then((data) => {
        console.log("Collection data:", data);
        setShowData(data.length > 0 ? data : []);

        // {
        //   data.length == 0 &&
        //     addDataToFirestore("Flow", "Flow - 1", {}, "2")
        //       .then((documentId) => {
        //         console.log(`Data added successfully with ID: ${documentId}`);
        //       })
        //       .catch((error) => {
        //         console.error(`Error adding data: ${error}`);
        //       });
        // }
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });

    console.log("historyData :", historyData);
  }, []);

  return (
    <div className="dashboard">
      {/* Left Menu */}
      {windowWidth < 1000 ? (
        <>
          {layoutMobile === true ? (
            <div className="left">
              <div className="headSection">
                <img src={"/assets/images/dmLogo.png"} alt="..." />
              </div>
              <div className="otherContentSection">
                <p
                  className="menuOptionsText"
                  onClick={() => {
                    setSelectTab("Home");
                    setLayout();
                  }}
                >
                  Home
                </p>
                <p
                  className="menuOptionsText"
                  onClick={() => {
                    setSelectTab("Contacts");
                    setLayout();
                  }}
                >
                  Contacts
                </p>
                <p
                  className="menuOptionsText"
                  onClick={() => {
                    setSelectTab("Growth Tools");
                    setLayout();
                  }}
                >
                  Growth To
                </p>
                <p
                  className="menuOptionsText"
                  onClick={() => {
                    setSelectTab("Automation");
                    setLayout();
                  }}
                >
                  Automation
                </p>
                <p
                  className="menuOptionsText"
                  onClick={() => {
                    setSelectTab("Live Chat");
                    setLayout();
                  }}
                >
                  Live Chat
                </p>
                <p
                  className="menuOptionsText"
                  onClick={() => {
                    setSelectTab("Broadcasting");
                    setLayout();
                  }}
                >
                  Broadcasting
                </p>
                <p
                  className="menuOptionsText"
                  onClick={() => {
                    setSelectTab("Settings");
                    setLayout(); // Close the sidebar
                  }}
                >
                  Settings
                </p>

                {/* <Icon
                  icon="ion:arrow-back-sharp"
                  width={25}
                  height={25}
                  color="white"
                  onClick={setLayout}
                  style={{ marginTop: 15 }}
                /> */}
              </div>
              <div
                className="profileDiv"
                onClick={() => {
                  navigate("/profile/dashboard");
                  // setSelectTab("Profile");
                }}
              >
                <div className="innerProfileDiv"></div>
                <p className="profileDivText">Profile</p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : windowWidth >= 1001 ? (
        <div className="left">
          <div className="headSection">
            <img
              src={"/assets/images/dashboard.png"}
              alt="..."
              className="ttt"
            />
          </div>
          <div className="otherContentSection">
            <p className="menuOptionsText" onClick={() => setSelectTab("Home")}>
              {t("Home")}
            </p>
            <p
              className="menuOptionsText"
              onClick={() => setSelectTab("Contacts")}
            >
              {t("Contacts")}
            </p>
            <p
              className="menuOptionsText"
              onClick={() => setSelectTab("Growth Tools")}
            >
              {t("Growth_Tools")}
            </p>
            <p
              className="menuOptionsText"
              onClick={() => setSelectTab("Automation")}
            >
              {t("Automation")}
            </p>
            <p
              className="menuOptionsText"
              onClick={() => setSelectTab("Live Chat")}
            >
              {t("Live_Chat")}
            </p>
            <p
              className="menuOptionsText"
              onClick={() => setSelectTab("Broadcasting")}
            >
              {t("Broadcasting")}
            </p>
            <p
              className="menuOptionsText "
              onClick={() => setSelectTab("Settings")}
            >
              {t("Settings")}
            </p>
            <p
              className="menuOptionsText "
              onClick={() => {
                navigate("/profile/dashboard");
                //  setSelectTab("Profile")
              }}
              Profile
            >
              <div
                className="profileDiv"
                onClick={() => setSelectTab("Profile")}
                // onClick={() => {
                //   navigate("/profile/dashboard")
                //   // setSelectTab("Profile")
                // }}
              >
                {/* <div className="innerProfileDiv"></div> */}
                <p className="profileDivText">{t("Profile")}</p>
              </div>
            </p>
          </div>
          {/* <div className="profileDiv" onClick={() => setSelectTab("Profile")}>
            <div className="innerProfileDiv"></div>
            <p className="profileDivText">Profile</p>
          </div> */}
        </div>
      ) : (
        <></>
      )}

      {/* Right Content */}
      <div className="right">
        {/* The dashboard Component will show selected tab and buttons  */}
        <div className="headSection">
          <div className="headInnerFirst">
            {windowWidth < 1000 ? (
              <Icon
                icon="eva:menu-fill"
                width={30}
                height={30}
                color="#3EB095"
                onClick={setLayout}
              />
            ) : (
              <></>
            )}

            {/* <p className="selectTabText">
              {" "}
              {selectTab == "Home" ? (
                t("Home")
              ) : selectTab == "Contacts" ? (
                t("Contacts")
              ) : selectTab == "Growth Tools" ? (
                t("Growth_Tools")
              ) : selectTab == "Broadcasting" ? (
                <Broadcasting />
              ) : selectTab == "Automation" ? (
                t("Automation")
              ) : selectTab == "Live Chat" ? (
                <LiveChat />
              ) : selectTab == "Settings" ? (
                <Settings />
              ) : (
                <></>
              )}
            </p> */}
          </div>

          {selectTab == "Home" ? (
            <></>
          ) : selectTab == "Contacts" ? (
            <div className="buttonContact">
              <p className="text">{t("Create_New_Contact")}</p>
            </div>
          ) : selectTab == "Growth Tools" || selectTab == "Automation" ? (
            <div className="buttonContact">
              <p className="text" onClick={handleShowGrowth}>
                {t("New_Growth_Tool")}
              </p>
              {/* Modal opens on click of New Growth Tool button */}
              <div className="growth-modal">
                <Modal
                  show={showGrowth}
                  onHide={handleCloseGrowth}
                  animation={false}
                  centered
                  size="xl"
                >
                  <Modal.Header>
                    <Modal.Title>
                      <div className="growth-modal-header">
                        <h4 className="text-black">
                          {t("Ready_to_go_Templates")}{" "}
                        </h4>
                        <div className="right">
                          <label htmlFor="templates">
                            <input type="checkbox" name="" id="templates" />
                            <p className="text-black" id="templates">
                              {t("Don't_show_Templates_again")}
                            </p>
                          </label>
                          <div className="scratch-btn">
                            <button>{t("Start_From_Scratch")}</button>
                          </div>
                        </div>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="growth-modal-body">
                      <input
                        type="search"
                        name=""
                        id=""
                        placeholder={t("Search_Templates")}
                      />
                      <div className="all-templates mt-4">
                        <div className="capture">
                          <img src={i18n.language == 'en' ? send : sendKor} alt="" />
                          <h6>{t("Capture_customer_data")}</h6>
                          <div className="two d-flex mt-4">
                            <Icon
                              icon="logos:messenger"
                              width="20"
                              height="20"
                            />
                            <div className="left">
                              <Icon
                                icon="mdi:tick-all"
                                width="20"
                                height="20"
                              />
                              <span>3,317 {t("Install")}</span>
                            </div>
                          </div>
                        </div>
                        <div className="capture">
                          <img src={i18n.language == 'en' ? prefer : preferKor} alt="" />
                          <h6>{t("Use_a_Quiz_to_Qualify_Leads")} </h6>
                          <div className="two d-flex mt-4">
                            <Icon
                              icon="logos:messenger"
                              width="20"
                              height="20"
                            />
                            <div className="left">
                              <Icon
                                icon="mdi:tick-all"
                                width="20"
                                height="20"
                              />
                              <span>4,107 {t("Install")}</span>
                            </div>
                          </div>
                        </div>
                        <div className="capture">
                          <img src={i18n.language == 'en' ? tell : tellKor} alt="" />
                          <h6>{t("Create_Ambassador_Program")}</h6>
                          <div className="two d-flex mt-4">
                            <Icon
                              icon="logos:messenger"
                              width="20"
                              height="20"
                            />
                            <div className="left">
                              <Icon
                                icon="mdi:tick-all"
                                width="20"
                                height="20"
                              />
                              <span>4,119 {t("Install")}</span>
                            </div>
                          </div>
                        </div>
                        <div className="capture">
                          <img src={i18n.language == 'en' ? fromFacebook: fromFacebookKor} alt="" />
                          <h6>{t("Redirect_Customers_to_your_Website")}</h6>
                          <div className="two d-flex mt-4">
                            <Icon
                              icon="logos:messenger"
                              width="20"
                              height="20"
                            />
                            <div className="left">
                              <Icon
                                icon="mdi:tick-all"
                                width="20"
                                height="20"
                              />
                              <span>3,618{t("Install")} </span>
                            </div>
                          </div>
                        </div>
                        <div className="capture">
                          <img src={i18n.language == 'en' ? promote : promoteKor} alt="" />
                          <h6>{t("Use_FB_Ads_leads_to")}</h6>
                          <div className="two d-flex mt-4">
                            <Icon
                              icon="logos:messenger"
                              width="20"
                              height="20"
                            />
                            <div className="left">
                              <Icon
                                icon="mdi:tick-all"
                                width="20"
                                height="20"
                              />
                              <span>3,253{t("Install")} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    {/* <Button variant="secondary" onClick={handleCloseGrowth}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseGrowth}>
                      Save Changes
                    </Button> */}
                  </Modal.Footer>
                </Modal>
              </div>
              {/* Modal Ends here */}
            </div>
          ) : selectTab == "Live Chat" ? (
            <div className="liveChatSearch">
              <Icon icon="iconamoon:search-light" width="24" height="24" />
              <input
                type="text"
                id="textInput"
                placeholder="Enter Your Search Term Here"
                className="inputStyle"
              />
            </div>
          ) : selectTab == "Broadcasting" ? (
            <div className="buttonsParentDiv">
              <div className="buttonBroadcaseOne">
                <p className="text">+ {t("Broadcast_Flow")}</p>
              </div>
              <div className="buttonBroadcase">
                <p className="text">+ {t("New_Broadcast")}</p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* The  Component will show content of selected tab */}
        <div className="otherContentSection">
          {selectTab == "Home" ? (
            <Home historyData={historyData} />
          ) : selectTab == "Contacts" ? (
            <Contacts />
          ) : selectTab == "Growth Tools" ? (
            <GrowthTools />
          ) : selectTab == "Broadcasting" ? (
            <Broadcasting />
          ) : selectTab == "Automation" ? (
            <Automation data={showData} />
          ) : selectTab == "Live Chat" ? (
            <LiveChat />
          ) : selectTab == "Settings" ? (
            <Settings />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyDashboard;
