import React from "react";
import "../../style/main.scss";
import backbtn from "../../assets/icons/backbtn.svg";
import football from "../../assets/images/homepage/football.png";
import mindvalley from "../../assets/images/homepage/mindvalley.png";
import frazer from "../../assets/images/homepage/frazer.png";
import { useTranslation } from "react-i18next";

const ActivityCard = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="activity-card flex-column v-center h-center">
        <div className="back-btn">
          <img src={backbtn} alt="backbtn-img" />
        </div>
        <div className="multi-cards d-flex">
          <div className="card ">
            <div className="card-head">
              <img src={football} alt="football" className="img-fluid" />
            </div>
            <div className="card-body">
              <p className="medium">{t("How_Nike_Football")}</p>
            </div>
          </div>
          <div className="card">
            <div className="card-head">
              <img src={mindvalley} alt="mindvalley" className="img-fluid" />
            </div>
            <div className="card-body">
              <p className="medium">{t("How_Mindvalley_Achieved")}</p>
            </div>
          </div>
          <div className="card">
            <div className="card-head">
              <img src={frazer} alt="frazer" className="img-fluid" />
            </div>
            <div className="card-body">
              <p className="medium">{t("How_Frazer_Brookes")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
