import React from "react";
import "../../style/main.scss";
import enterpreneur from "../../assets/images/homepage/enterpreneur.png";
import { useTranslation } from "react-i18next";

const Enterpreneur = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <div className="enterpreneur">
        <div className="inner">
          <div className="left">
            <img
              src={enterpreneur}
              alt="enterpreneur-img"
              className="img-fluid"
            />
          </div>
          <div className="right ">
            <h4>
              "Manychat helped us drive over $70,000 in course sales in 3 weeks.
              I saw a MASSIVE increase in conversions and engagement within a
              week of using Manychat and haven't stopped using keywords since!
              It's a $25 monthly investment that has tripled my ROI and saved me
              so much time.”
            </h4>
            <div className="kahlea mt-5">
              <p className="body-large">KAHLEA WADE, ENTREPRENEUR</p>
              <p className="body-large">@KAHLEANICOLEE, </p>
              <p className="body-large">24.4K FOLLOWERS</p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="enterpreneur">
        <div className="enterpreneus-content">
          <div className="right">
            <div className="background-div">
              <img
                src={enterpreneur}
                alt="enterpreneur-img"
                className="img-fluid enterpreneur-img"
              />
              <div className="right-content">
                <p>{t("Manychat_helped_us_drive")} </p>
                <div className="subheading">
                  <h3>{t("KAHLEA_WADE,_ENTREPRENEUR")}</h3>
                  <h3>@KAHLEANICOLEE, </h3>
                  <h3>{t("24.4K_FOLLOWERS")}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Enterpreneur;
