import React from "react";
import "../../style/main.scss";
// import { Button } from "bootstrap";
import Button from "../shared/button";
import homeb from "../../assets/images/home-banner.png";
import { useTranslation } from "react-i18next";
const Banner = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="home-banner d-flex">
        <div className="left">
          <h1>{t("Unleash_the_power")}</h1>
          <h4 className="medium mt-4">{t("Drive_more_sales")}</h4>
          <Button
            text={t("Get_Started_For_Free")}
            width="244px "
            height="51px"
            className="btn-primary mt-5 "
            onClick={() => alert("Hello!")}
          />
        </div>
        <div className="right">
          <img src={homeb} alt="home-banner" className="banner-img" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
