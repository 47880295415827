import React from "react";
import "../../../../style/_main.scss";
import { useTranslation } from "react-i18next";
const FirstTab = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="firstTab">
        <div className="tabContent">
          <h6 className="border-bottom pb-2">{t("members_for_Emmashop")}</h6>
          {/* <hr className="hr" /> */}
          <div className="paracontent">
            <span>{t("Owner")}</span>
            <p>
              {t("Owner_controls_contact_roles")}
            </p>
          </div>
          <div className="nameSection">
            <div className="leftName">
              <h6>{t("Team_Member_Name")}</h6>
            </div>
            <div className="rightName">
              <div className="right-a">
                {" "}
                <h6>{t("Live_Chat_Seat")}</h6>
              </div>
              <div className="right-a">
                <h6>{t("Team_Member_Billing")}</h6>
              </div>
            </div>
          </div>
          <div className="nametext">
            <span>{t("Emma_Loop")}</span>
            <span>{t("It’s_me")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstTab;
