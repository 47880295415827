import React from 'react'
import "./customers.css"
import { useTranslation } from "react-i18next";
const Customers = () => {
  const { t } = useTranslation();
  return (
    <div className='customers'>
        <div className="cont">
      <h2>{t("Text_customers")}</h2>
      <p>{t("text_messages_are_read")} <span><a href="">{t("Use_Auto_Assignment_learn_More")}</a></span></p>
      <div className='connectbtn'>
        <button>{t("Connect")}</button>
        </div>
      </div>
    </div>
  )
}

export default Customers
