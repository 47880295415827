import React from 'react'
import "./billings.css"
import { useTranslation } from "react-i18next";
const Billings = () => {
  const { t } = useTranslation();
  return (
    <div className='billing'>
            <div className="subfee">
                <h2 >{t("Subscription_FREE")}</h2>
                 {/* < hr className='hr' /> */}
            </div>
            <div className="inbox">
              <div className="ibhead">
                <div className='prod'><p>{t("Product")}</p></div>
                <div className='detls'><p>{t("Details")}</p></div>
                <div className='price'><p>{t("Price")}</p></div>
                <div className='status'><p>{t("Status")}</p></div>
                

              </div>
              <div className="ibbott">
                  <div className='firstcol'>
                    <p>{t("Inbox")}</p>
                    <p>{t("Marketing_Automation")}</p>
                  </div>
                  <div className='secondcol'>
                    <p>{t("Live_Chat_seats")}</p>
                    <p>{t("500_contacts_included")}</p>
                  </div>
                  <div className='thridcol'>
                    <p> <span>US$99/</span> {t("month")}</p>
                    <p> <span>US$99/</span> {t("month")}</p>
                    
                  </div>
                  <div className='forthcol'>
                  <div class="form-check form-switch swichbtn">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
  <label class="form-check-label" for="flexSwitchCheckChecked"></label>
</div>
<div class="form-check form-switch swichbtn2">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
  <label class="form-check-label" for="flexSwitchCheckChecked"></label>
</div>
                  </div>
                  

              </div>
            </div>
      
    </div>
  )
}

export default Billings
