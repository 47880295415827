import { onAuthStateChanged } from "firebase/auth";
import React, { Component, useEffect, useState } from "react";
import { useLogin } from "react-facebook";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { getLongLivedToken } from "../../config/facebookApis";
import { auth, registerWithEmailAndPassword } from "../../config/firebase";
import "./Signup.css";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const Signup = () => {
  const { login, status, isLoading, error } = useLogin();
  const { t } = useTranslation();
  const navigate = useNavigate();

  //Navigate user to dashboard if already logged in
  useEffect(() => {
    const userData = secureLocalStorage.getItem("user")
    if (userData) {
      navigate("/profile/dashboard")
    }
  }, [])
  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     console.log(user)
  //     navigate("/")
  //   } else {
  //     //User is not signed in
  //     console.log("No User")
  //   }
  // })
  const {
    register,
    handleSubmit,
    watch, getValues,
    formState: { errors },
  } = useForm()
  const onSubmit = (data) => {
    console.log(data)
    registerWithEmailAndPassword(data.email, data.password, data.username, data.fullName)
  }
  const [selectTab, setSelectTab] = useState("Dummy");
  async function handleFacebookLogin() {
    try {
      const response = await login({
        scope: process.env.REACT_APP_SCOPE,
      });
      console.log(response);
      getLongLivedToken(response, navigate, "/dashboard")
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <div className="signup">
      <img
        src={"/assets/images/registerLoginBack.png"}
        alt="..."
        className="image"
      />

      <div className="upper">
        <div className="extra"></div>
        <p className="text">{t("Fill_out_form")}</p>
        <div className="button" onClick={e => { navigate("/login") }}>
          <p className="text">{t("SIGN_IN")}</p>
        </div>
      </div>
      <div className="centermain" onSubmit={handleSubmit(onSubmit)}>
        <div className="center">
          {/* Section One */}
          <p className="heading">{t("Full_name")}:</p>
          <input className="inputstyles"  {...register("fullName", { required: true })} />
          {errors.fullName?.type === "required" && (
            <p role="alert" className="customRed">{t("Full_Name_is")}</p>
          )}
          {/* Section One */}
          <p className="heading">{t("Username")}:</p>
          <input className="inputstyles" {...register("username", { required: true, pattern: /^(?!^\d+$)[a-z][a-zA-Z0-9_]{3,20}$/ })}></input>
          {errors.username?.type === "required" && (
            <p role="alert" className="customRed">{t("Username_is")}</p>
          )}
          {errors?.username?.type === "pattern" && (
            <p className="customRed">{t("Invalid_Username")}</p>
          )}
          {/* Section One */}
          <p className="heading">{t("Email")}:</p>
          <input className="inputstyles"{...register("email", { required: true, pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })}></input>
          {errors?.email?.type === "required" && <p className="customRed">{t("This_field")}</p>}
          {errors?.email?.type === "pattern" && (
            <p className="customRed">{t("Invalid_Email")}</p>
          )}
          {/* Section One */}
          <p className="heading">{t("Password")}:</p>
          <input type="password" className="inputstyles"{...register("password", { required: true, pattern: /^(?=.*[A-Z])(?=.*\d).{8,20}$/ })} />
          {errors?.password?.type === "required" && <p className="customRed">{t("This_field")}</p>}
          {errors?.password?.type === "pattern" && (
            <p className="customRed">{t("Password_must")}("#,& etc.") </p>
          )}
          {/* Section One */}
          <p className="heading">{t("Confirm_Password")}:</p>
          <input type="password" className="inputstyles"
            {...register("confirmPassword", {
              required: true, validate: (value) => {
                if (watch("password") !== value) {
                  return "Your passwords do not match";
                }
              }
            })} />
          {errors?.confirmPassword?.type === "required" && <p className="customRed">{t("Invalid_Password")}</p>}

          {errors?.confirmPassword?.type === "validate" && <p className="customRed">{t("Passwords_do")}</p>}

          <button className="buttonSubmit" type="submit">{t("Register")}</button>
          <div className="divvv">
            <p className="textOne">{t("Yes_i_have")}</p>
            <p className="textTwo cursor" onClick={(e) => { navigate("/Login") }}>{t("Login")} </p>
          </div>
          <p className="textOR">{t("OR")} </p>
          <div className="imagesDiv">
            <img className="customPointer" src={"/assets/images/googleImage.png"} alt="..." />

            <img className="customPointer" src={"/assets/images/appleImage.png"} alt="..." />

            <img className="customPointer" src={"/assets/images/facebookImage.png"} alt="..." onClick={handleFacebookLogin} />
          </div>
        </div>
      </div>
      <div className="lower">
        <img src={"/assets/images/dmLogo.png"} alt="..." />


        <p className="text">© 2021. - 2025 {t("All_Rights")}</p>
        <div className="extra"></div>

      </div>
    </div>
  );
};

export default Signup;
