import React from "react";
import "../../style/_program.scss";
import Tool1 from "../../assets/images/tool1.png";
import Tool2 from "../../assets/images/tool2.png";
import { useTranslation } from "react-i18next";
const Testimonial = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="testimonial">
        <div className="testimonial-content">
          <div className="title">
            <h6>{t("our_Affiliate_Program")}</h6>
          </div>

          <div className="tools-section">
            <div className="left">
              <img src={Tool1} alt="..." className="img-fluid" />
            </div>
            <div className="right">
              <span>{t("Industry_leading")}</span>
              <p>
                {t("hyper_growth_business")}
              </p>
            </div>
          </div>
          <div className="tools-section">
            <div className="right">
              <span>{t("comissions")}</span>
              <p>
                {t("Once_your_affiliates")}
              </p>
            </div>
            <div className="left">
              <img src={Tool2} alt="..." className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
