import React from "react";
import "../../style/main.scss";
import mockup from "../../assets/images/messenger/mockup.png";
import mockupKor from "../../assets/images/messenger/mockup.png";
import { useTranslation } from "react-i18next";
const Deliver = () => {
  const { t } = useTranslation();
  const {i18n} = useTranslation();
  return (
    <div>
      <div className="deliver ">
        <div className="deliver-content d-flex flex-column v-center h-center">
          <h1>{t("Deliver_on_Mobile")}</h1>
          <p className="body-large mt-3">
            {t("2.4B_people_will_use")}
          </p>

          <div className="deliver-list ">
            <ul>
              <li>{t("Qualify_Leads")}</li>
              <li>{t("Book_Appointments")}</li>
              <li>{t("Send_Order_Updates")}</li>
              <li>{t("Recover_Abandoned_Carts")}</li>
              <li>{t("Share_Coupons_Updates")}</li>
              <li>{t("Answer_Customer_Questions")}</li>
            </ul>
          </div>
        </div>
        <div className="mockup">
          <img src={i18n.language == 'en' ? mockup : mockupKor } alt="mockup-img" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Deliver;
