import React from "react";
import "../../style/_resturant.scss";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
const Freebot = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="freebots">
        <div className="freebotdm ">
          <div className="freebotdm-title">
            <h2 className="one">
              {t("Start_building_your")}
            </h2>
            <p className="one body-large">
               {t("Transform_your_customer_interactions")}
            </p>

            <Button
              text={t("Get_Started")}
              width="244px "
              height="51px"
              bgColor={props.bgColor}
              txtColor={props.txtColor}
              className="btn-secondary"
              onClick={() => alert("Hello!")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Freebot;
