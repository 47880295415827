import React from "react";
import "../../../style/main.scss";
import laptop from "../../../assets/images/blog/Rectangle 129.png";
import whatsapp from "../../../assets/images/blog/Rectangle 131.png";
import instagram from "../../../assets/images/blog/Rectangle 133.png";
import low from "../../../assets/images/blog/Rectangle 130.png";
import little from "../../../assets/images/blog/Rectangle 132.png";
import clever from "../../../assets/images/blog/Rectangle 134.png";
import { useTranslation } from "react-i18next";
const Email = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="email">
        <h3>{t("EMAIL")}</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={laptop} alt="laptop" className="img-fluid" />
            <p>{t("6_Best_Free")}</p>
          </div>
          <div className="home-cards">
            <img src={whatsapp} alt="laptop" className="img-fluid" />
            <p>{t("Email_Marketing_Best")}</p>
          </div>
          <div className="home-cards">
            <img src={instagram} alt="laptop" className="img-fluid" />
            <p>{t("Take_Your_Email")} </p>
          </div>
          <div className="home-cards">
            <img src={low} alt="low" className="img-fluid" />
            <p>{t("The_Best_Time_to")}</p>
          </div>
          <div className="home-cards">
            <img src={little} alt="little" className="img-fluid" />
            <p>{t("What_is_Drip")}</p>
          </div>
          <div className="home-cards">
            <img src={clever} alt="clever" className="img-fluid" />
            <p>{t("To_Buy_an_Email")} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
