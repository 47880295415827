import React from "react";
import "../../../style/main.scss";
import laptop from "../../../assets/images/blog/Rectangle 108.png";
import whatsapp from "../../../assets/images/blog/Rectangle 110.png";
import instagram from "../../../assets/images/blog/Rectangle 112.png";
import low from "../../../assets/images/blog/Rectangle 109.png";
import little from "../../../assets/images/blog/Rectangle 111.png";
import clever from "../../../assets/images/blog/Rectangle 113.png";
import seven from "../../../assets/images/blog/Rectangle 114.png";
import eight from "../../../assets/images/blog/Rectangle 115.png";
import nine from "../../../assets/images/blog/Rectangle 116.png";
import { useTranslation } from "react-i18next";
const Latest = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="latest">
        <h3>{t("LATEST")}</h3>
        <div className="blog-parent">
          <div className="home-cards">
            <img src={laptop} alt="laptop" className="img-fluid" />
            
            <p>{t("Transform_Conversations_Across")}</p>
          </div>
          <div className="home-cards">
            <img src={whatsapp} alt="laptop" className="img-fluid" />
            <p>{t("Unlock_Efficiency_with")}</p>
          </div>
          <div className="home-cards">
            <img src={instagram} alt="laptop" className="img-fluid" />
            <p>{t("Revolutionize_Sales")}</p>
          </div>
          <div className="home-cards">
            <img src={low} alt="low" className="img-fluid" />
            <p>{t("Templates_Galore")}</p>
          </div>
          <div className="home-cards">
            <img src={little} alt="little" className="img-fluid" />
            <p>{t("One_Tool")}</p>
          </div>
          <div className="home-cards">
            <img src={clever} alt="clever" className="img-fluid" />
            <p>{t("Lead_Generation_on")}</p>
          </div>
          <div className="home-cards">
            <img src={seven} alt="low" className="img-fluid" />
            <p>{t("Data_Driven_Success")}</p>
          </div>
          <div className="home-cards">
            <img src={eight} alt="little" className="img-fluid" />
            <p>{t("Empower_Learning")}</p>
          </div>
          <div className="home-cards">
            <img src={nine} alt="clever" className="img-fluid" />
            <p>{t("Securing_Trust")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Latest;