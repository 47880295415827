import React from "react";
import "../style/main.scss";
import Increase from "../components/sms/increase";
import PinkSec from "../components/sms/pinkSec";
import Engage from "../components/sms/engage";
import Why from "../components/sms/why";
import Freebotdm from "../components/home/freebotdm";
import Footer from "../components/shared/footer";

const Sms = () => {
  return (
    <div>
      <Increase />
      <PinkSec />
      {/* <Engage /> */}
      <Why />
      <div className="for-top mt-5">
        <Freebotdm />
      </div>
      <Footer />
    </div>
  );
};

export default Sms;
