// After Optimization
import React, { useEffect, useState } from "react";
import "../../../../../../style/main.scss";
import { Icon, InlineIcon } from "@iconify/react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import ConditionCanvas from "./conditionCanvas";
import RandomCanvas from "./randomCanvas";
import SmartDelay from "./smartDelay";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import AddButtonModal from "./addButtonModal";
import Action from "./action";

const FlowMessenger = ({ show, handleClose, handleShow, sho, Close }) => {
  const [sections, setSections] = useState([]);
  const [showrandom, setShowrandom] = useState(false);
  const [showCondition, setShowCondition] = useState(false);
  const [showRandom, setShowRandom] = useState(false);
  const [showsdelay, setShowDelay] = useState(false);
  const handleCloserandom = () => setShowrandom(false);
  const handleShowrandom = () => setShowrandom(true);
  const [sectionsVideo, setVideo] = useState([{ id: 0 }]);
  const [sectionsFile, setFile] = useState([{ id: 1, files: [] }]);
  const [sectionsDelay, setDelay] = useState([]);
  const [shery, setShery] = useState(false);
  const sheryClosed = () => setShery(false);
  const sheryShowed = () => setShery(true);
  const [messengerList, setMessengerList] = useState([]);
  const [input, setInput] = useState([]);
  const [showMod, setMod] = useState(false);
  const [dynamic, setDynamic] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAction, setShowAction] = useState(false);

  const handleModalaction = () => {
    setShowAction(!showAction);
  };
  const addSection = (type) => {
    const newSection = {
      id: sections.length + 1,
      type: type,
      image: null,
      title: "",
      paragraph: "",
    };
    setSections([...sections, newSection]);
  };
  const removeSection = (index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };
  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...sections];
    updatedSections[index][field] = value;
    setSections(updatedSections);
  };
  const handleModal = () => {
    setShowCondition(!showCondition);
  };
  const handleModalrandom = () => {
    setShowRandom(!showRandom);
  };
  const handleModaldelay = () => {
    setShowDelay(!showsdelay);
  };
  //  Video
  const addVideo = () => {
    const newId = sectionsVideo.length;
    setVideo([...sectionsVideo, { id: newId }]);
  };
  const removeVideo = (index) => {
    const updatedSections = [...sectionsVideo];
    updatedSections.splice(index, 1);
    setVideo(updatedSections);
  };
  // File
  const addFile = () => {
    const newSection = {
      id: sectionsFile.length + 1,
      files: [],
    };
    setFile([...sectionsFile, newSection]);
  };
  const handleFileChange = (sectionId, event) => {
    const selectedFiles = event.target.files;
    const updatedSections = sectionsFile.map((section) => {
      if (section.id === sectionId) {
        return { ...section, files: selectedFiles };
      }
      return section;
    });
    setFile(updatedSections);
  };
  const removeFile = (index) => {
    const updatedSections = [...sectionsFile];
    updatedSections.splice(index, 1);
    setFile(updatedSections);
  };
  // Delay
  const addDelay = () => {
    const newSection = { id: sectionsDelay.length + 1 };
    setDelay([...sectionsDelay, newSection]);
  };
  const removeDelay = (index) => {
    const updatedSections = [...sectionsDelay];
    updatedSections.splice(index, 1);
    setDelay(updatedSections);
  };
  // Messenger List
  const addMessenger = () => {
    const newSection = {
      image: "",
      heading: "",
      paragraph: "",
    };
    setMessengerList([...messengerList, newSection]);
  };
  const handleImageChange = (index, event) => {
    const updatedSections = [...messengerList];
    updatedSections[index].image = event.target.value;
    setMessengerList(updatedSections);
  };

  const removeMessengerList = (index) => {
    const updatedSections = [...messengerList];
    updatedSections.splice(index, 1);
    setMessengerList(updatedSections);
  };
  const handleOptionChange = (index, event) => {
    const updatedSections = [...messengerList];
    updatedSections[index].selectedOption = event.target.value;
    setMessengerList(updatedSections);
  };
  // User Input
  const addInput = () => {
    const newSections = [...input];
    newSections.push({ id: Date.now() });
    setInput(newSections);
  };
  const openModal = () => {
    setMod(true);
  };
  const closeModal = () => {
    setMod(false);
  };
  const removeInput = (index) => {
    const updatedSections = [...input];
    updatedSections.splice(index, 1);
    setInput(updatedSections);
  };
  // DYNAMICS
  const addDynamic = () => {
    const newSections = [...dynamic];
    newSections.push({ id: Date.now() });
    setDynamic(newSections);
  };
  const openDynamicModal = () => {
    setShowModal(true);
  };
  const closeDynamicModal = () => {
    setShowModal(false);
  };
  const removeDynamic = (index) => {
    const updatedSections = [...dynamic];
    updatedSections.splice(index, 1);
    setDynamic(updatedSections);
  };
  return (
    <div>
      {/* Choose first Step */}
      <div className="flow-messenger">
        <div className="choose">
          <h4>Choose first step</h4>
          <div className="steps">
            <p className="mt-3">Content</p>
            {/* Message */}
            <div className="img d-flex">
              <Button variant="primary" onClick={handleShow}>
                <Icon icon="logos:messenger" width="30" height="30" />
                <p>Messenger</p>
              </Button>
              {/* Message ka  Canvas */}
              <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    Send Message <Icon icon="ph:pen" width="25" height="25" />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="drop">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Send{" "}
                        <span className="text-primary">
                          within 24 hour window{" "}
                          <Icon
                            icon="ph:question"
                            color="#808094"
                            width="16"
                            height="16"
                          />
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">
                          within 24 hour window
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-2">
                          outside 24 hour window
                        </Dropdown.Item>
                        <Dropdown.Item href="#/action-3">
                          as Comment Reply
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <p className="for-bor">
                    Use the buttons below to add text and pictures
                  </p>
                  {/* On click add Text field */}
                  <div className="add-text-field">
                    {sections.map((section, index) => {
                      if (section.type === "text") {
                        return (
                          <div key={section.id} className="section">
                            <span
                              className="close-icon"
                              onClick={() => removeSection(index)}
                            >
                              &#10006;
                            </span>
                            <input
                              type="text"
                              value={section.text}
                              onChange={(e) =>
                                handleSectionChange(
                                  index,
                                  "text",
                                  e.target.value
                                )
                              }
                              placeholder="Enter your text..."
                            />{" "}
                            <button className="add-btn">+ Add Button</button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* On click add  Image */}
                  <div className="add-image-field">
                    {sections.map((section, index) => {
                      if (section.type === "image") {
                        return (
                          <div key={section.id} className="section">
                            <span
                              className="close-icon"
                              onClick={() => removeSection(index)}
                            >
                              &#10006;
                            </span>
                            <label
                              htmlFor="img"
                              className="add-image-field-inner "
                            >
                              <Icon
                                icon="ph:image-thin"
                                width="40"
                                height="40"
                              />
                              <p className="text-black mt-2">
                                <span className="text-primary">
                                  Upload Image
                                </span>{" "}
                                or{" "}
                                <span className="text-primary">insert URL</span>
                              </p>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleSectionChange(
                                    index,
                                    "image",
                                    e.target.files[0]
                                  )
                                }
                                id="img"
                                className="d-none"
                              />
                              {section.image && (
                                <img
                                  src={URL.createObjectURL(section.image)}
                                  alt="Uploaded Image"
                                />
                              )}
                            </label>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* On click add card */}
                  <div className="add-card-field">
                    {sections.map((section, index) => {
                      if (section.type === "card") {
                        return (
                          <div key={section.id} className="section">
                            <span
                              className="close-icon"
                              onClick={() => removeSection(index)}
                            >
                              &#10006;
                            </span>
                            <div className="choose-img">
                              <Icon
                                icon="ph:image-thin"
                                width="40"
                                height="40"
                              />{" "}
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleSectionChange(
                                    index,
                                    "image",
                                    e.target.files[0]
                                  )
                                }
                              />
                            </div>
                            <div className="title">
                              <input
                                type="text"
                                value={section.title}
                                onChange={(e) =>
                                  handleSectionChange(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter title..."
                              />
                            </div>
                            <textarea
                              value={section.paragraph}
                              onChange={(e) =>
                                handleSectionChange(
                                  index,
                                  "paragraph",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Subtitle..."
                            />
                            <button className="add-btn">+ Add Button</button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* On click add Gallery */}
                  <div className="add-card-field">
                    {sections.map((section, index) => {
                      if (section.type === "gallery") {
                        return (
                          <div key={section.id} className="section">
                            <span
                              className="close-icon"
                              onClick={() => removeSection(index)}
                            >
                              &#10006;
                            </span>
                            <div className="choose-img">
                              <Icon
                                icon="ph:image-thin"
                                width="40"
                                height="40"
                              />{" "}
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleSectionChange(
                                    index,
                                    "image",
                                    e.target.files[0]
                                  )
                                }
                              />
                            </div>
                            <div className="title">
                              <input
                                type="text"
                                value={section.title}
                                onChange={(e) =>
                                  handleSectionChange(
                                    index,
                                    "title",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter title..."
                              />
                            </div>
                            <textarea
                              value={section.paragraph}
                              onChange={(e) =>
                                handleSectionChange(
                                  index,
                                  "paragraph",
                                  e.target.value
                                )
                              }
                              placeholder="Enter Subtitle..."
                            />
                            <button className="add-btn">+ Add Button</button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* On click add Audio */}
                  <div className="add-audio-field">
                    {sections.map((section, index) => {
                      if (section.type === "audio") {
                        return (
                          <div key={section.id} className="section">
                            <span
                              className="close-icon"
                              onClick={() => removeSection(index)}
                            >
                              &#10006;
                            </span>
                            {/* Add input or other audio-related components here */}
                            <div className="audio-inner">
                              <p className="text-black">
                                Upload an audio file{" "}
                                <Icon
                                  icon="streamline:entertainment-volume-level-off-volume-speaker-control-music-audio"
                                  width="30"
                                  height="30"
                                />
                              </p>

                              <input
                                type="file"
                                accept="audio/*"
                                onChange={(e) =>
                                  handleSectionChange(
                                    index,
                                    "audio",
                                    e.target.files[0]
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                  {/* On click add Video */}
                  <div className="add-video-field">
                    {sectionsVideo.map((section, index) => (
                      <div key={section.id} className="section">
                        <div className="video-closer">
                          <span
                            className="close-icon"
                            onClick={() => removeVideo(index)}
                          >
                            &#10006;
                          </span>
                        </div>
                        <div className="upload-section">
                          <Icon
                            icon="icon-park-outline:video"
                            width="40"
                            height="40"
                          />
                          <input type="file" accept="video/*" />
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* On click add File */}
                  <div className="add-video-field">
                    {sectionsFile.map((section, index) => (
                      <section key={section.id} className="section">
                        <div className="video-closer">
                          <span
                            className="close-icon"
                            onClick={() => removeFile(index)}
                          >
                            &#10006;
                          </span>
                        </div>
                        <div className="upload-section">
                          <Icon icon="ph:file" width="40" height="40" />
                          <input
                            type="file"
                            onChange={(e) => handleFileChange(section.id, e)}
                            multiple
                          />
                        </div>
                        <ul>
                          {section.files.map((file, index) => (
                            <li key={index}>{file.name}</li>
                          ))}
                        </ul>
                      </section>
                    ))}
                  </div>
                  {/* On Click add delay */}{" "}
                  <div className="add-delay-field">
                    {sectionsDelay.map((section, index) => (
                      <div key={section.id} className="section mt-5">
                        <div className="video-closer">
                          <span
                            className="close-icon"
                            onClick={() => removeDelay(index)}
                          >
                            &#10006;
                          </span>
                        </div>
                        <div className="upload-section">
                          <button onClick={sheryShowed}>
                            Typing 3{" "}
                            <Icon icon="ph:clock" width="20" height="20" />{" "}
                            seconds
                          </button>
                        </div>
                        <div className="delay-modal">
                          <Modal
                            show={shery}
                            onHide={sheryClosed}
                            className="zam"
                          >
                            <Modal.Header closeButton className="bg-primary">
                              <Modal.Title>
                                <h4 className="text-black ">Delay</h4>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="bodyyyyyy">
                              <div className="delay-modal-design">
                                <div className="upper">
                                  <h5>Delay Time</h5>
                                  <input type="number" name="" id="1" />
                                  seconds
                                </div>
                                <div className="lower">
                                  <h5>Typing Action</h5>
                                  <label htmlFor="myCheckbox">
                                    <input
                                      type="checkbox"
                                      name=""
                                      id="myCheckbox"
                                    />
                                    Show "typing..."during delay
                                  </label>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* On click add Messenger List */}
                  <div className="add-messenger-field">
                    {messengerList.map((section, index) => (
                      <div key={index} className="section">
                        <div className="video-closer">
                          <span
                            className="close-icon"
                            onClick={() => removeMessengerList(index)}
                          >
                            &#10006;
                          </span>
                        </div>
                        <div className="upload-section">
                          {/* Dropdown */}
                          <select
                            value={section.selectedOption}
                            onChange={(event) =>
                              handleOptionChange(index, event)
                            }
                          >
                            <option value="option1">Promo and Update</option>
                            <option value="option2">Option 1</option>
                          </select>
                          {/* Image uploading section */}
                          <div className="both">
                            <Icon icon="ph:image-thin" width="30" height="30" />
                            <input
                              type="file"
                              value={section.image}
                              onChange={(event) =>
                                handleImageChange(index, event)
                              }
                            />
                          </div>
                          <div className="dyna">
                            <h6>Subscribe to our updates</h6>
                            <p className="text-black mt-3">
                              Want to get additional messages from us ? You can
                              out at any time.
                            </p>
                          </div>
                          <div className="sub">
                            <button>Get message</button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* On click add user input */}
                  <div className="add-messenger-field">
                    {input.map((section, index) => (
                      <div key={section.id} className="section">
                        <div className="video-closer">
                          <span
                            className="close-icon"
                            onClick={() => removeInput(index)}
                          >
                            &#10006;
                          </span>
                        </div>
                        <div className="upload-section">
                          <Form.Control
                            type="text"
                            onClick={openModal}
                            placeholder="Click to Open Modal"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <Modal show={showMod} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Modal Title</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>This is the modal content.</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={closeModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* On click add DYNAMICS */}
                  <div className="add-dynamic-field">
                    {dynamic.map((section, index) => (
                      <div key={section.id} className="section">
                        <div className="video-closer">
                          <span
                            className="close-icon"
                            onClick={() => removeDynamic(index)}
                          >
                            &#10006;
                          </span>
                        </div>
                        <div className="upload-section">
                          <div className="uploa" onClick={openDynamicModal}>
                            <Icon
                              icon="bytesize:upload"
                              width="30"
                              height="30"
                            />
                            <p className="text-black">
                              Add a request for dynamic content
                            </p>
                          </div>
                          <div className="uploa-btn">
                            <button>+ Fallback Step</button>
                          </div>
                        </div>
                      </div>
                    ))}{" "}
                  </div>
                  <Modal show={showModal} onHide={closeModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Modal Title</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>This is the modal content.</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={closeDynamicModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <div className="boxes">
                    {/* Text */}
                    <div className="box " onClick={() => addSection("text")}>
                      <Icon icon="mdi:text" width="30" height="30" />
                      <h6>Text</h6>
                    </div>
                    {/* Image */}
                    <div className="box" onClick={() => addSection("image")}>
                      <Icon icon="ph:image-thin" width="30" height="30" />
                      <h6>Image</h6>
                    </div>
                    {/* Card */}
                    <div className="box" onClick={() => addSection("card")}>
                      <Icon icon="ic:outline-add-card" width="30" height="30" />
                      <h6>Card</h6>
                    </div>
                    {/* Gallery */}
                    <div className="box" onClick={() => addSection("gallery")}>
                      <Icon
                        icon="dashicons:format-gallery"
                        width="30"
                        height="30"
                      />
                      <h6>Gallery</h6>
                    </div>
                    {/* Audio */}
                    <div className="box " onClick={() => addSection("audio")}>
                      <Icon
                        icon="streamline:entertainment-volume-level-off-volume-speaker-control-music-audio"
                        width="30"
                        height="30"
                      />
                      <h6>Audio</h6>
                    </div>
                    {/* Video */}
                    <div className="box" onClick={addVideo}>
                      <Icon
                        icon="icon-park-outline:video"
                        width="30"
                        height="30"
                      />
                      <h6>Video</h6>
                    </div>
                    {/* File */}
                    <div className="box" onClick={addFile}>
                      <Icon icon="ph:file" width="30" height="30" />
                      <h6>File</h6>
                    </div>
                    {/* Delay */}
                    <div className="box" onClick={addDelay}>
                      <Icon icon="ph:clock" width="30" height="30" />
                      <h6>Delay</h6>
                    </div>
                    {/* Messenger List */}
                    <div className="box" onClick={addMessenger}>
                      <Icon icon="mdi:bell-outline" width="30" height="30" />
                      <h6>Messenger List</h6>
                    </div>
                    {/* User Input */}
                    <div className="box" onClick={addInput}>
                      <Icon icon="ic:baseline-input" width="30" height="30" />
                      <h6>User Input</h6>
                    </div>
                    <div className="box" onClick={addDynamic}>
                      <Icon icon="bytesize:upload" width="30" height="30" />
                      <h6>Dynamic</h6>
                    </div>
                  </div>
                  <button>Choose Next Step</button>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
            <p className="mt-3">Logic</p>
            {/* Actions */}
            <div className="img d-flex">
              <Button variant="primary">
                <Icon
                  icon="mdi:thunder"
                  color="#f90"
                  width="30"
                  height="30"
                  className="iconMessenger"
                />
                <p
                  onClick={() => {
                    handleModalaction();
                  }}
                >
                  Actions
                </p>
              </Button>
              <Action
                show={showAction}
                handleModalaction={handleModalaction}
                sho={sho}
              />
            </div>
            {/* Condition */}
            <div className="img d-flex">
              <Button variant="primary">
                <Icon
                  icon="mingcute:filter-line"
                  color="#35CAEF"
                  width="30"
                  height="30"
                  className="iconMessenger"
                />
                <p
                  onClick={() => {
                    handleModal();
                  }}
                >
                  Condition
                </p>
              </Button>
              <ConditionCanvas
                show={showCondition}
                handleModal={handleModal}
                sho={sho}
              />
            </div>
            {/* Randomizer */}
            <div className="img d-flex">
              <Button variant="primary">
                <Icon
                  icon="humbleicons:arrow-split"
                  color="#9969F8"
                  width="30"
                  height="30"
                  className="iconMessenger"
                />
                <p
                  onClick={() => {
                    handleModalrandom();
                  }}
                >
                  Randomizer
                </p>
              </Button>
              <RandomCanvas
                show={showRandom}
                handleModalrandom={handleModalrandom}
              />
            </div>
            {/* Smart Delay */}
            <div className="img d-flex">
              <Button variant="primary">
                <Icon
                  icon="ep:clock"
                  color="#EB5139"
                  width="30"
                  height="30"
                  className="iconMessenger"
                />{" "}
                <p
                  onClick={() => {
                    handleModaldelay();
                  }}
                >
                  Smart Delay{" "}
                </p>
              </Button>
              <SmartDelay
                show={showsdelay}
                handleModalrandom={handleModaldelay}
                sho={show}
                Close={handleClose}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowMessenger;
