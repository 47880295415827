import React from "react";
import Banner from "../components/template/banner";
import Imagecard from "../components/template/imagecard";
import Customer from "../components/template/customer";
import Courses from "../components/guide/courses";
import Freebot from "../components/resturant/freebot";
import Faqs from "../components/template/faq";
import Footer from "../components/shared/footer";

const Template = () => {
  return (
    <div>
      <div className="template">
        <Banner />
        <Imagecard />
        <Customer />
        <Courses />
        <div className="resturant-last">
          <Faqs />
        </div>
        <Freebot
          style={{ backgroundColor: "8A6EFF" }}
          bgColor={"#8A6EFF"}
          txtColor={"#FFF"}
        />
        <Footer />
      </div>
    </div>
  );
};

export default Template;
