import React from "react";
import "../../style/main.scss";
import drag from "../../assets/images/messenger/drag.png";
import dragKor from "../../assets/images/messenger/drag.png";
import amazon from "../../assets/images/homepage/amazon.png";
import nike from "../../assets/images/homepage/nike.png";
import behance from "../../assets/images/homepage/behance.png";
import netflix from "../../assets/images/homepage/netflix.png";
// import jenna from "../../assets/images/messenger/jenna.png";
import Button from "../shared/button";
import { useTranslation } from "react-i18next";
const Drag = () => {
  const { t } = useTranslation();
  const {i18n} = useTranslation();
  return (
    <div>
      <div className="drag">
        <div className="drag-content flex-column v-center h-center">
          <h3 className="medium">{t("Drag_Drop_Connect")}</h3>
          <p className="large">
           {t("With_Manychat’s")}
          </p>
          <div className="pic mt-4">
            <img src={i18n.language == 'en' ? drag : dragKor} alt="drag" className="img-fluid" />
          </div>
          <h3 className="medium mt-4">{t("Save_Time_Integrations")}</h3>
          <p className="large">
            {t("Easily_connect_Manychat")}
          </p>
        </div>
        <div className="logos d-flex ">
          <img src={amazon} alt="benefit" className="img-fluid" />
          <img src={nike} alt="nike" className="img-fluid" />
          <img src={behance} alt="behance" className="img-fluid" />
          <img src={netflix} alt="netflix" className="img-fluid" />
          {/* <img src={jenna} alt="jenna" className="img-fluid" /> */}
        </div>
        <div className="free v-center h-center">
          <Button
            text={t("Get_Started_Free")}
            width="194px "
            height="51px"
            className="btn-primary mt-3"
            onClick={() => alert("Hello!")}
          />
        </div>
      </div>
    </div>
  );
};

export default Drag;
