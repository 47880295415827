import React from "react";
import Button from "../shared/button";
import "../../style/_guides.scss";
import { useTranslation } from "react-i18next";
const Freebot = () => {
  const { t } = useTranslation();
  return (
    <div>
      {" "}
      <div className="guideFreebot">
        <div className="freebot-guide">
          <div className="freebotdm ">
            <div className="freebotdm-title">
              <h2 className="one">{t("Try_dm_bot_free")}</h2>
              <p className="one body-large">
                {t("Automating_your_conversations")}
              </p>

              <Button
                text={t("Get_Started")}
                width="244px "
                height="51px"
                className="btn-primary "
                onClick={() => alert("Hello!")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Freebot;
