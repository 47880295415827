import React from "react";
import Usebot from "../components/guide/usebot";
import Footer from "../components/shared/footer";
import Freebot from "../components/webnar/freebot";
import Courses from "../components/webnar/Courses";
import Tabz from "../components/webnar/Tab";

const Webnar = () => {
  return (
    <div>
      <Usebot />
      <Tabz />
      <Courses />
      <Freebot />
      <Footer />
    </div>
  );
};

export default Webnar;
