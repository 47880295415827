import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "../../style/_resturant.scss";
import { useTranslation } from "react-i18next";
const Faqs = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="second-faq">
        <div className="faq">
          <h1 className="light-purple v-center h-center text-white">
            {t("Questions")}
          </h1>

          <div className="accor">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {t("DM_BOT_templates")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("DM_BOT_templates_are")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {t("I_use_DM_BOT")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("Using_DM_BOT_templates_is")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {t("templates_customizable")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("templates_are_designed")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  {t("create_my_own")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("you_have_the_option")}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  {t("Where_can_I")}
                </Accordion.Header>
                <Accordion.Body>
                  {t("You_can_access_a")}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
