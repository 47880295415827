import React from "react";
import "../../style/main.scss";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
const Faq = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="naver-faq">
        <h1 className="text-black  v-center h-center">{t("FAQ")}</h1>
        <div className="accor">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {t("DM_Bot_on_Naver")}
              </Accordion.Header>
              <Accordion.Body>
                {t("DM_Bot_on_Naver_is_suitable")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {t("possible_to_integrate")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Absolutely_You_can")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {t("How_can_I_reach")}
              </Accordion.Header>
              <Accordion.Body>
                {t("While_your_DM_Bot_resides")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {t("I_personalize_interactions")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Yes_you_can_personalize")}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {t("What_languages_are")}
              </Accordion.Header>
              <Accordion.Body>
                {t("Naver_supports_multiple")}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
