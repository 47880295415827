import React from "react";
import "../../style/main.scss";
import cartoons from "../../assets/images/sms/cartoons.png";
import streaming from "../../assets/images/sms/streaming.png";
import chat from "../../assets/images/sms/chat.png";
import pinkcartoon from "../../assets/images/sms/pinkcartoon.png";
import screens from "../../assets/images/sms/screens.png";
// import sure from "../../assets/images/sms/sure.png";
import box from "../../assets/images/sms/box.png";
import dmbot from "../../assets/images/sms/dmbot.png";
import { useTranslation } from "react-i18next";
const PinkSec = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="pink-sec ">
        <div className="usechat-content ist d-flex v-center mt-5">
          <div className="left">
            <img src={cartoons} alt="run" className="img-fluid" />
          </div>
          <div className="right">
            <img src={streaming} alt="streaming" className="img-fluid" />
          </div>
        </div>
        <div className="usechat-content second d-flex v-center mt-5">
          <div className="left">
            <img src={chat} alt="chat" className="img-fluid" />
          </div>
          <div className="right">
            <img src={pinkcartoon} alt="pinkcartoon" className="img-fluid" />
          </div>
        </div>
        <div className="usechat-content ist d-flex v-center mt-5">
          <div className="left">
            <img src={screens} alt="screens" className="img-fluid" />
          </div>
          <div className="right">
            <h2 style={{
              color:"black"
            }}>{t("Get_connected_to")}</h2>
          </div>
        </div>
        <div className="usechat-content ist d-flex v-center mt-5">
          <div className="left">
            <img src={box} alt="box" className="img-fluid" />
          </div>
          <div className="right">
            <img src={dmbot} alt="dmbot" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PinkSec;
