import React, { useEffect } from "react";
import "../../style/_footer.scss";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TermsCondtion from "./TermsCondtion";
import PrivacyPolicy from "./PrivacyPolicy";
const Footer = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [privacyShow, setprivacyShow] = useState(false);


    return (
    <>
  <PrivacyPolicy privacyModelValue={privacyShow} setprivacyModelValue={setprivacyShow}  />
  <TermsCondtion modalValue  =  {show} setModalValue = {setShow} />
    
      <div className="footer-content">
        <div className="Footer">
          <div className="first-div">
            <div className="footer-title">
              <h3>{t("footer_Logo")}</h3>
            </div>
            <div className="footer-paragraph">
              <p>
                {t("DM_BOT_is_a_tool_designed")}
              </p>
            </div>
          </div>
          <div className="first-div">
            <div className="footer-title">
              <h3>{t("footer_About")}</h3>
            </div>
            <div className="footer-links">
              <ul>
                <li>
                  {" "}
                  <a href="#">{t("footer_Product")}</a>
                </li>
                <li>
                  {" "}
                  <a href="#">{t("footer_Resource")}</a>
                </li>
                <li style={{
                  cursor:"pointer"
                }} >
                  {" "}
                  <a  onClick={()=>{setShow(true) ;
                  console.log('hello')}
                  }>{t("footer_Term")}</a>
                </li>
                <li>
                  {" "}
                  <a href="#">{t("FAQ")}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="first-div">
            <div className="footer-title">
              <h3>{t("footer_Company")}</h3>
            </div>
            <div className="footer-links">
              <ul>
                <li>
                  {" "}
                  <a href="#">{t("footer_Our_Team")}</a>
                </li>
                <li>
                  {" "}
                  <a href="#">{t("footer_Partner")}</a>
                </li>
                <li>
                  {" "}
                  <a  style={{
                    cursor:"pointer"
                  }} onClick={()=>{setprivacyShow(true) ;
                  console.log('hello')}
                  }>{t("footer_Privacy")}</a>
                </li>
                <li>
                  {" "}
                  <a href="#">{t("footer_Features")}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="first-div">
            <div className="footer-title">
              <h3>{t("footer_Contact")}</h3>
            </div>
            <div className="footer-links">
              <ul>
                <li>
                  {" "}
                  <a href="#">+012 3456789</a>
                </li>
                <li>
                  {" "}
                  <a href="#">asdaf@gmail.com</a>
                </li>
                <li>
                  <div className="social-icons">
                    <a href="#">
                      <Icon
                        icon="entypo-social:youtube"
                        color="black"
                        width="25"
                        height="25"
                      />
                    </a>
                    <a href="#">
                      <Icon
                        icon="fontisto:discord"
                        color="black"
                        width="25"
                        height="25"
                      />
                    </a>
                    <a href="#">
                      <Icon
                        icon="akar-icons:instagram-fill"
                        color="black"
                        width="25"
                        height="25"
                      />
                    </a>
                  </div>
                </li>
                <li>
                  {" "}
                  <div className="social-icons">
                    <a href="#">
                      <Icon
                        icon="eva:facebook-fill"
                        color="black"
                        width="25"
                        height="25"
                      />
                    </a>
                    <a href="#">
                      <Icon
                        icon="mdi:twitter"
                        color="black"
                        width="25"
                        height="25"
                      />
                    </a>
                    <a href="#">
                      <Icon
                        icon="fa:youtube"
                        color="black"
                        width="25"
                        height="25"
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <span>© Copyright {" "} {t("All_Rights")}</span>
        </div>
      </div>
   
    </>
  );
};

export default Footer;
