import React from "react";
import "../../style/main.scss";
import amazon from "../../assets/images/homepage/amazon.png";
import nike from "../../assets/images/homepage/nike.png";
import behance from "../../assets/images/homepage/behance.png";
import netflix from "../../assets/images/homepage/netflix.png";
import { useTranslation } from "react-i18next";

const Trusted = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="trusted">

        <span>{t("Trusted_By_1M_Business")}</span>

        <div className="img-sec h-center mt-5">
          <img src={amazon} alt="amazon" className="img-fluid" />
          <img src={nike} alt="nike" className="img-fluid" />
          <img src={behance} alt="behance" className="img-fluid" />
          <img src={netflix} alt="netflix" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Trusted;
