import React from "react";
import Banner from "../components/tiktok/banner";
import Turn from "../components/tiktok/turn";
import Drive from "../components/tiktok/drive";
import Enterpreneur from "../components/tiktok/enterpreneur";
import ActiCards from "../components/messanger/actiCards";
import StepCards from "../components/home/stepCards";
import Freebotdm from "../components/home/freebotdm";
import Footer from "../components/shared/footer";
import { useTranslation } from "react-i18next";
const Tiktok = () => {
  const { t } = useTranslation();
  const dataForPage2 = `${t("Drive_up_to")}`
  return (
    <div>
      <Banner />
      <Turn />
      <Drive data={dataForPage2} />
      <Enterpreneur />
      <div className="act ">
        <ActiCards />
      </div>
      <div className="similar back">
        <StepCards />
        <Freebotdm />
      </div>
      <Footer />
    </div>
  );
};

export default Tiktok;
