import React, { useEffect, useState, useRef } from "react";
import "../../../../../../style/main.scss";
import { Icon, InlineIcon } from "@iconify/react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setText } from "../../../../../../slices/textSlice";
import { addDataToFirestore } from "../../../../../../config/firebase";
import { getDataFromFirestore } from "../../../../../../config/firebase";
import { UploadFilesToS3 } from "../../../../../../config/uploadToAws";

const MessengerCanvas = ({ show, handleClose, arrayCallback }) => {
  const [nodeData, setNodedata] = useState();

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const textId = useSelector((state) => state.text.text);
  const [isMounted, setIsMounted] = useState(false);
  const flowName = useSelector((state) => state.flow.text);

  const [sections, setSections] = useState([
    {
      id: 1,
      type: "text",
      image: null,
      video: null,
      title: "",
      text: "",
      paragraph: "",
    },
  ]);

  useEffect(() => {
    getDataFromFirestore("Flow", flowName)
      .then((data) => {
        setSections(
          data[`${textId}`]
            ? data[`${textId}`]
            : [
                {
                  id: 1,
                  type: "text",
                  image: null,
                  video: null,
                  title: "",
                  text: "",
                  paragraph: "",
                },
              ]
        );
      })
      .catch((error) => {
        console.error("Error getting data:", error);
      });
  }, [textId]);

  useEffect(() => {
    getDataFromFirestore("Flow", flowName)
      .then((data) => {
        setSections(data[`${textId}`] ? data[`${textId}`] : data[`2`]);
      })
      .catch((error) => {
        console.error("Error getting data:", error);
      });

    return () => {
      // sendingDataToFirestore(sections);
    };
  }, []);
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const addSection = (type) => {
    const newSection = {
      id: sections.length + 1,
      type: type,
      image: null,
      title: "",
      text: "",
      paragraph: "",
    };
    setSections([...sections, newSection]);
  };

  useEffect(() => {
    if (isMounted) {
      sendingDataToFirestore(sections);
    } else {
      setIsMounted(true);
    }
  }, [sections, isMounted]);

  const removeSection = (index) => {
    const newSection = [...sections];

    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };
  const handleSectionChange = async (index, field, value) => {
    const updatedSections = [...sections];
    if (field == "text") {
      updatedSections[index][field] = value;
      setSections(updatedSections);
    } else if (field == "image") {
      UploadFilesToS3(value, "images")
        .then((res) => {
          if (res.success == true) {
            updatedSections[index][field] = res.thumbnail_url;
            setSections(updatedSections);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // setSections(updatedSections);

      // const base64 = await convertBase64(value);
      // updatedSections[index][field] = base64;
    } else if (field == "video") {
      UploadFilesToS3(value, "videos")
        .then((res) => {
          if (res.success == true) {
            updatedSections[index][field] = res.thumbnail_url;
            setSections(updatedSections);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (field == "audio") {
      UploadFilesToS3(value, "audios")
        .then((res) => {
          if (res.success == true) {
            updatedSections[index][field] = res.thumbnail_url;
            setSections(updatedSections);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (field == "file") {
      UploadFilesToS3(value, "files")
        .then((res) => {
          if (res.success == true) {
            updatedSections[index][field] = res.thumbnail_url;
            setSections(updatedSections);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [showrandom, setShowrandom] = useState(false);
  // const handleCloserandom = () => setShowrandom(false);
  // const handleShowrandom = () => setShowrandom(true);
  const [showCondition, setShowCondition] = useState(false);
  const [showRandom, setShowRandom] = useState(false);
  const [showsdelay, setShowDelay] = useState(false);
  const handleModal = () => {
    setShowCondition(!showCondition);
  };
  const handleModalrandom = () => {
    setShowRandom(!showRandom);
  };
  const handleModaldelay = () => {
    setShowDelay(!showsdelay);
  };
  // const {conditioncanvasClose} = () => setconditioncanvas(false);
  // const conditioncanvasShow = () => setconditioncanvas(true);
  const handleCloserandom = () => setShowrandom(false);
  const handleShowrandom = () => setShowrandom(true);
  // const [showCondition, setShowCondition] = useState(false);
  // const handleModal = () => {
  //   setShowCondition(!showCondition);
  // };
  //  Video
  // const [sectionsVideo, setVideo] = useState([]);
  const addVideo = () => {
    const newId = sections.length;
    setSections([...sections, { id: newId }]);
  };
  const removeVideo = (index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };
  // File
  const [sectionsFile, setFile] = useState([]);

  const addFile = () => {
    const newSection = {
      id: sectionsFile.length + 1,
      files: [],
    };
    setFile([...sectionsFile, newSection]);
  };

  const handleFileChange = (sectionId, event) => {
    const selectedFiles = event.target.files;
    const updatedSections = sectionsFile.map((section) => {
      if (section.id === sectionId) {
        return { ...section, files: selectedFiles };
      }
      return section;
    });
    setFile(updatedSections);
  };
  const removeFile = (index) => {
    const updatedSections = [...sectionsFile];
    updatedSections.splice(index, 1);
    setFile(updatedSections);
  };
  // Delay
  const [sectionsDelay, setDelay] = useState([]);
  const [shery, setShery] = useState(false);
  const sheryClosed = () => setShery(false);
  const sheryShowed = () => setShery(true);
  const addDelay = () => {
    const newSection = { id: sectionsDelay.length + 1 };
    setDelay([...sectionsDelay, newSection]);
  };
  const removeDelay = (index) => {
    const updatedSections = [...sectionsDelay];
    updatedSections.splice(index, 1);
    setDelay(updatedSections);
  };

  // Messenger List
  const [messengerList, setMessengerList] = useState([]);
  const addMessenger = () => {
    const newSection = {
      image: "",
      heading: "",
      paragraph: "",
    };
    setMessengerList([...messengerList, newSection]);
  };

  const handleImageChange = (index, event) => {
    const updatedSections = [...messengerList];
    updatedSections[index].image = event.target.value;
    setMessengerList(updatedSections);
  };

  const handleHeadingChange = (index, event) => {
    const updatedSections = [...messengerList];
    updatedSections[index].heading = event.target.value;
    setMessengerList(updatedSections);
  };

  const handleParagraphChange = (index, event) => {
    const updatedSections = [...messengerList];
    updatedSections[index].paragraph = event.target.value;
    setMessengerList(updatedSections);
  };
  const removeMessengerList = (index) => {
    const updatedSections = [...messengerList];
    updatedSections.splice(index, 1);
    setMessengerList(updatedSections);
  };
  const handleOptionChange = (index, event) => {
    const updatedSections = [...messengerList];
    updatedSections[index].selectedOption = event.target.value;
    setMessengerList(updatedSections);
  };
  // User Input
  const [input, setInput] = useState([]);
  const [showMod, setMod] = useState(false);
  const addInput = () => {
    const newSections = [...input];
    newSections.push({ id: Date.now() });
    setInput(newSections);
  };

  const openModal = () => {
    setMod(true);
  };

  const closeModal = () => {
    setMod(false);
  };
  const removeInput = (index) => {
    const updatedSections = [...input];
    updatedSections.splice(index, 1);
    setInput(updatedSections);
  };
  // DYNAMICS
  const [dynamic, setDynamic] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const addDynamic = () => {
    const newSections = [...dynamic];
    newSections.push({ id: Date.now() });
    setDynamic(newSections);
  };

  const openDynamicModal = () => {
    setShowModal(true);
  };

  const closeDynamicModal = () => {
    setShowModal(false);
  };
  const removeDynamic = (index) => {
    const updatedSections = [...dynamic];
    updatedSections.splice(index, 1);
    setDynamic(updatedSections);
  };

  const sendingDataToFirestore = (value) => {
    addDataToFirestore("Flow", flowName, value, textId)
      .then((documentId) => {
        console.log(`Data added successfully with ID: ${documentId}`);
      })
      .catch((error) => {
        console.error(`Error adding data: ${error}`);
      });
  };

  // sendingDataToFirestore(sections);

  // const handleReplaceObject = (index, value) => {
  //   const objectId = index; // replace with your object ID
  //   const updatedData = value;

  // };
  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title
        // onClick={() => {
        //   sethandleClose(true);
        // }}
        >
          Send Message <Icon icon="ph:pen" width="25" height="25" />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="drop">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Send{" "}
              <span className="text-primary">
                within 24 hour window{" "}
                <Icon
                  icon="ph:question"
                  color="#808094"
                  width="16"
                  height="16"
                />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">
                within 24 hour window
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">
                outside 24 hour window
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">as Comment Reply</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <p className="for-bor">
          Use the buttons below to add text and pictures
        </p>
        {sections.map((section, index) => {
          {
            /* On click add Text field */
          }

          if (section.type === "text") {
            return (
              <div key={index} className="add-text-field">
                <div className="section">
                  <span
                    className="close-icon"
                    onClick={() => {
                      removeSection(index);
                    }}
                  >
                    &#10006;
                  </span>
                  <input
                    type="text"
                    value={section.text}
                    onChange={(e) => {
                      handleSectionChange(index, "text", e.target.value);
                    }}
                    placeholder="Enter your text..."
                  />{" "}
                  <button className="add-btn">+ Add Button</button>
                </div>
              </div>
            );
          }

          {
            /* On click add  Image */
          }

          if (section.type === "image") {
            return (
              <div key={index} className="add-image-field">
                <div className="section">
                  <span
                    className="close-icon"
                    onClick={() => removeSection(index)}
                  >
                    &#10006;
                  </span>
                  <label htmlFor="img" className="add-image-field-inner ">
                    <Icon icon="ph:image-thin" width="40" height="40" />
                    <p className="text-black mt-2">
                      <span className="text-primary">Upload Image</span> or{" "}
                      <span className="text-primary">insert URL</span>
                    </p>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        handleSectionChange(index, "image", e.target.files[0]);
                      }}
                      id="img"
                      className="d-none"
                    />
                    {section.type == "image" && <img src={section.image} />}
                  </label>
                </div>
              </div>
            );
          }

          {
            /* On click add Video */
          }

          if (section.type === "video") {
            return (
              <div key={index} className="add-video-field">
                <div className="section">
                  <div className="video-closer">
                    <span
                      className="close-icon"
                      onClick={() => removeSection(index)}
                    >
                      &#10006;
                    </span>
                  </div>
                  <div className="upload-section">
                    <Icon
                      icon="icon-park-outline:video"
                      width="40"
                      height="40"
                    />
                    <input
                      type="file"
                      accept="video/*"
                      onChange={(e) => {
                        handleSectionChange(index, "video", e.target.files[0]);
                      }}
                    />

                    {section.type == "video" && (
                      <video
                        style={{
                          width: "250px",
                          height: "200px",

                          borderRadius: "10px",
                          border: "none",
                        }}
                        autoPlay={false}
                        // controls={true}
                        src={section.video}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          }

          {
            /* On click add File */
          }

          if (section.type === "file") {
            return (
              <div key={index} className="add-video-field">
                <div className="section">
                  <div className="video-closer">
                    <span
                      className="close-icon"
                      onClick={() => removeSection(index)}
                    >
                      &#10006;
                    </span>
                  </div>
                  <div className="upload-section">
                    <p className="text-black">
                      Upload a file{" "}
                      <Icon icon="ph:file" width="40" height="40" />
                    </p>

                    <input
                      style={{ display: "none" }}
                      type="file"
                      // accept="video/*"
                      onChange={(e) => {
                        handleSectionChange(index, "file", e.target.files[0]);
                      }}
                      id="audioInput"
                    />
                    <label for="audioInput" class="custom-audio-input">
                      Choose File
                    </label>
                    {section.type == "file" && section.file && (
                      <h5>File Uploaded</h5>
                    )}
                  </div>
                </div>
              </div>
            );
          }

          {
            /* On click add Audio */
          }

          if (section.type === "audio") {
            return (
              <div key={index} className="add-audio-field">
                <div className="section">
                  <span
                    className="close-icon"
                    onClick={() => removeSection(index)}
                  >
                    &#10006;
                  </span>
                  {/* Add input or other audio-related components here */}
                  <div className="audio-inner">
                    <p className="text-black">
                      Upload an audio file{" "}
                      <Icon
                        icon="streamline:entertainment-volume-level-off-volume-speaker-control-music-audio"
                        width="30"
                        height="30"
                      />
                    </p>

                    <input
                      type="file"
                      accept="audio/*"
                      onChange={(e) =>
                        handleSectionChange(index, "audio", e.target.files[0])
                      }
                      id="audioInput"
                      style={{ display: "none" }}
                    />
                    <label for="audioInput" class="custom-audio-input">
                      Choose Audio File
                    </label>

                    {section.type == "audio" && section.audio && (
                      <h5>AUDIO UPLOADED</h5>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
        {/* On click add card */}
        <div className="add-card-field">
          {sections.map((section, index) => {
            if (section.type === "card") {
              return (
                <div key={section.id} className="section">
                  <span
                    className="close-icon"
                    onClick={() => removeSection(index)}
                  >
                    &#10006;
                  </span>
                  <div className="choose-img">
                    <Icon icon="ph:image-thin" width="40" height="40" />{" "}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        handleSectionChange(index, "image", e.target.files[0])
                      }
                    />
                  </div>
                  <div className="title">
                    <input
                      type="text"
                      value={section.title}
                      onChange={(e) => {
                        handleSectionChange(index, "title", e.target.value);
                      }}
                      placeholder="Enter title..."
                    />
                  </div>
                  <textarea
                    value={section.paragraph}
                    onChange={(e) =>
                      handleSectionChange(index, "paragraph", e.target.value)
                    }
                    placeholder="Enter Subtitle..."
                  />
                  <button className="add-btn">+ Add Button</button>
                </div>
              );
            }
            return null;
          })}
        </div>
        {/* On click add Gallery */}
        <div className="add-card-field">
          {sections.map((section, index) => {
            if (section.type === "gallery") {
              return (
                <div key={section.id} className="section">
                  <span
                    className="close-icon"
                    onClick={() => removeSection(index)}
                  >
                    &#10006;
                  </span>
                  <div className="choose-img">
                    <Icon icon="ph:image-thin" width="40" height="40" />{" "}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        handleSectionChange(index, "image", e.target.files[0])
                      }
                    />
                  </div>
                  <div className="title">
                    <input
                      type="text"
                      value={section.title}
                      onChange={(e) =>
                        handleSectionChange(index, "title", e.target.value)
                      }
                      placeholder="Enter title..."
                    />
                  </div>
                  <textarea
                    value={section.paragraph}
                    onChange={(e) =>
                      handleSectionChange(index, "paragraph", e.target.value)
                    }
                    placeholder="Enter Subtitle..."
                  />
                  <button className="add-btn">+ Add Button</button>
                </div>
              );
            }
            return null;
          })}
        </div>
        {/* On Click add delay */}{" "}
        <div className="add-video-field">
          {sectionsDelay.map((section, index) => (
            <div key={section.id} className="section mt-5">
              <div className="video-closer">
                <span className="close-icon" onClick={() => removeDelay(index)}>
                  &#10006;
                </span>
              </div>
              <div className="upload-section">
                <button onClick={sheryShowed}>
                  Typing 3 <Icon icon="ph:clock" width="20" height="20" />{" "}
                  seconds
                </button>
              </div>
              <div className="delay-modal">
                <Modal show={shery} onHide={sheryClosed}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h4 className="text-black">Delay</h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="delay-modal-design">
                      <div className="upper">
                        <h5>Delay Time</h5>
                        <input type="number" name="" id="1" />
                        seconds
                      </div>
                      <div className="lower">
                        <h5>Typing Action</h5>
                        <label htmlFor="myCheckbox">
                          <input type="checkbox" name="" id="myCheckbox" />
                          Show "typing..."during delay
                        </label>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          ))}
        </div>
        {/* On click add Messenger List */}
        <div className="add-messenger-field">
          {messengerList.map((section, index) => (
            <div key={index} className="section">
              <div className="video-closer">
                <span
                  className="close-icon"
                  onClick={() => removeMessengerList(index)}
                >
                  &#10006;
                </span>
              </div>
              <div className="upload-section">
                {/* Dropdown */}
                <select
                  value={section.selectedOption}
                  onChange={(event) => handleOptionChange(index, event)}
                >
                  <option value="option1">Promo and Update</option>
                  <option value="option2">Option 1</option>
                </select>
                {/* Image uploading section */}
                <div className="both">
                  <Icon icon="ph:image-thin" width="30" height="30" />
                  <input
                    type="file"
                    value={section.image}
                    onChange={(event) => handleImageChange(index, event)}
                  />
                </div>
                <div className="dyna">
                  <h6>Subscribe to our updates</h6>
                  <p className="text-black mt-3">
                    Want to get additional messages from us ? You can out at any
                    time.
                  </p>
                </div>
                <div className="sub">
                  <button>Get message</button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* On click add user input */}
        <div className="add-messenger-field">
          {input.map((section, index) => (
            <div key={section.id} className="section">
              <div className="video-closer">
                <span className="close-icon" onClick={() => removeInput(index)}>
                  &#10006;
                </span>
              </div>
              <Form.Control
                type="text"
                onClick={openModal}
                placeholder="Click to Open Modal"
              />
            </div>
          ))}
        </div>
        <Modal show={showMod} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Modal Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>This is the modal content.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* On click add DYNAMICS */}
        <div className="add-dynamic-field">
          {dynamic.map((section, index) => (
            <div key={section.id} className="section">
              <div className="video-closer">
                <span
                  className="close-icon"
                  onClick={() => removeDynamic(index)}
                >
                  &#10006;
                </span>
              </div>
              <div className="upload-section">
                <div className="uploa" onClick={openDynamicModal}>
                  <Icon icon="bytesize:upload" width="30" height="30" />
                  <p className="text-black">
                    Add a request for dynamic content
                  </p>
                </div>
                <div className="uploa-btn">
                  <button>+ Fallback Step</button>
                </div>
              </div>
            </div>
          ))}{" "}
        </div>
        <Modal show={showModal} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Modal Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>This is the modal content.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeDynamicModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="boxes">
          {/* Text */}
          <div className="box " onClick={() => addSection("text")}>
            <Icon icon="mdi:text" width="30" height="30" />
            <h6>Text</h6>
          </div>
          {/* Image */}
          <div className="box" onClick={() => addSection("image")}>
            <Icon icon="ph:image-thin" width="30" height="30" />
            <h6>Image</h6>
          </div>
          {/* Card */}
          <div className="box" onClick={() => addSection("card")}>
            <Icon icon="ic:outline-add-card" width="30" height="30" />
            <h6>Card</h6>
          </div>
          {/* Gallery */}
          <div className="box" onClick={() => addSection("gallery")}>
            <Icon icon="dashicons:format-gallery" width="30" height="30" />
            <h6>Gallery</h6>
          </div>
          {/* Audio */}
          <div className="box " onClick={() => addSection("audio")}>
            <Icon
              icon="streamline:entertainment-volume-level-off-volume-speaker-control-music-audio"
              width="30"
              height="30"
            />
            <h6>Audio</h6>
          </div>
          {/* Video */}
          <div
            className="box"
            onClick={() => {
              addSection("video");
            }}
          >
            <Icon icon="icon-park-outline:video" width="30" height="30" />
            <h6>Video</h6>
          </div>
          {/* File */}
          <div className="box" onClick={() => addSection("file")}>
            <Icon icon="ph:file" width="30" height="30" />
            <h6>File</h6>
          </div>
          {/* Delay */}
          <div className="box" onClick={addDelay}>
            <Icon icon="ph:clock" width="30" height="30" />
            <h6>Delay</h6>
          </div>
          {/* Messenger List */}
          <div className="box" onClick={addMessenger}>
            <Icon icon="mdi:bell-outline" width="30" height="30" />
            <h6>Messenger List</h6>
          </div>
          {/* User Input */}
          <div className="box" onClick={addInput}>
            <Icon icon="ic:baseline-input" width="30" height="30" />
            <h6>User Input</h6>
          </div>
          <div className="box" onClick={addDynamic}>
            <Icon icon="bytesize:upload" width="30" height="30" />
            <h6>Dynamic</h6>
          </div>
        </div>
        <button
          onClick={() => {
            // deleteDocumentsfromCollection();
          }}
        >
          Choose Next Step
        </button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default MessengerCanvas;
