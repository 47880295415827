export const initFacebookSdk = () => {
    return new Promise((resolve, reject) => {
        // Load the Facebook SDK asynchronously
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: process.env.REACT_APP_FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: process.env.REACT_APP_LATEST_API_VERSION,

            });
            // Resolve the promise when the SDK is loaded
            resolve();
        };
    })
}
export const getFacebookLoginStatus = () => {
    return new Promise((resolve, reject) => {
        window.FB.getLoginStatus((response) => {
            resolve(response);
        });
    });
};
export const fbLogin = () => {
    return new Promise((resolve, reject) => {
        window.FB.login((response) => {
            resolve(response)
        }, { scope: process.env.REACT_APP_SCOPE })
    })
}